import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import './scss/EnterOTPScreen.css'
import { resetRegistrationForm } from '../../../../Redux/action/RecruiterAction';
import { useDispatch } from 'react-redux';
let host = process.env.REACT_APP_API_SERVER
const EnterOTPScreen2 = ({ email, handleStartTimer, seconds, setSeconds, reloadData }) => {
    const [showLoader, setShowLoader] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [showResend, setShowResend] = useState(false);
    const [showResendButton, setShowResendButton] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (seconds != 0) {setShowResendButton(false);
        } else {setShowResendButton(true);}
    }, [seconds])
    const formik = useFormik({
        initialValues: {
            otp: '',},
        validationSchema: Yup.object({
            otp: Yup.string().required('Otp is required').matches(/^[0-9]+$/, 'Only numbers are allowed').min(4, 'Enter a valid OTP').max(4, 'Enter a valid OTP'),
        }),
        onSubmit: async (values) => {            
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {            
                setSubmitting(true);
                setShowLoader(true);
                document.body.style.overflow = 'hidden';
                const config = {
                    headers: {
                        'content-type': 'application/json',
                        "auth-token": localStorage.getItem("recruiter_token")
                    }
                };
                const formdata = new FormData();
                formdata.append("email_otp", values.otp);
                formdata.append("company_email", email);
               
                await axios.post(`${host}/api/company-email-verify-update-email`, formdata, config)
                    .then(function (response) {
                     
                        if (response.status == 200) {
                            if (localStorage.getItem("seeker_token")) {
                                localStorage.removeItem("seeker_token")
                                toast.warn("Seeker session logged out.");
                            }
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            localStorage.setItem("recruiter_token", response.data["authToken"]);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Otp verified. Please submit details to proceed!' });
                          
                            dispatch(resetRegistrationForm());
                            let closeBtn = document.getElementById("close-modl-rec-register2");
                            if (closeBtn) {
                                closeBtn.click();
                            };
                            reloadData();
                        } else {
                            console.log("in else block of .then method:", response.data.msg);
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });

                        }
                    })
                    .catch(function (error) {
                        console.log(error.message);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in otp verification. Please try again.' });
                    })

            } catch (error) {
                console.log("catch:", error);
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in registration otp verification. Please try again.' });
            }

        },
    });

    const handleResendOtp = async () => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {
            if (!showResend) return; 
            setShowResend(false);
            const values = {

                company_email: email
            }
            const config = {
                headers: {
                    'content-type': 'application/json',
                    "auth-token": localStorage.getItem("recruiter_token")
                }
            };
            await axios.post(`${host}/api/company-email-verify-resend-otp2`, values, config)
                .then(function (response) {
                    setSeconds(30);
                    handleStartTimer();
                    if (response.status == 200) {
                        document.body.style.overflow = 'auto';
                        const otpResponse = response.data.otp;
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `Otp is ${otpResponse}. Please verify.` });
                    } else {
                        console.log("in else block of .then method:", response.data.msg);
                        setSubmitting(false)
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                    }
                })
                .catch(function (error) {
                    console.log(error.message);
                    document.body.style.overflow = 'auto';
                    setSubmitting(false)
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in otp resend. Please try again.' });
                })
        } catch (error) {
            console.log("catch:", error);
            document.body.style.overflow = 'auto';
            setSubmitting(false)
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in resend otp verification. Please try again.' });
        }
        finally {
            setTimeout(() => setShowResend(true), 30000);
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {setShowResend(true);}, 30000);
        return () => clearTimeout(timer);}, []);
    useEffect(() => {
        $(document).ready(function () {
            $('#close-modl-rec-register2').on("click", function () {$('#signup-otp2').hide();});});
    });
    return (
        <section className="create-an-account-popup">
            <div className="modal fade" id="signup-otp2" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close modal-close-button" data-bs-dismiss="modal" aria-label="Close" id="close-modl-rec-register2"></button>
                            <div className="container height-100 d-flex justify-content-center align-items-center">
                                <div className="position-relative">
                                    <div className="card p-2">
                                        <div className="card-left">
                                            <h6>Please enter OTP</h6>
                                            <div className="para text-center">
                                                <span>We have sent an OTP on your mail</span>
                                                <br />
                                                <span>{email}</span>
                                            </div>
                                            <form method='post' onSubmit={formik.handleSubmit}>
                                                <div className="inputs d-flex flex-row justify-content-center">
                                                    <input
                                                        className="m-2 text-center form-control rounded"
                                                        type="text"
                                                        name="otp"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.otp}
                                                        style={{ width: '150px' }}
                                                        maxLength={4}

                                                    />
                                                </div>
                                                {formik.touched.otp && formik.errors.otp && (
                                                    <div className="text-danger text-center">{formik.errors.otp}</div>
                                                )}
                                                {showResendButton ?
                                                    <p className="resend text-center" onClick={handleResendOtp} style={{ cursor: "pointer" }}>
                                                        Resend OTP
                                                    </p> :
                                                    <p className="resend text-center">Resend OTP in {seconds} seconds</p>
                                                }
                                                <div className="mt-4 otp-btn">
                                                    <button type="submit" className="btn btn-danger px-4 validate">
                                                        Validate
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default EnterOTPScreen2