import React, { useEffect, useState } from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../../Commons/Footer'
import FindJobListing2 from './FindJobListing2'
import Loader from '../../Loader'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { findJobPageAPI } from '../../../../Redux/action/SeekerAction'
import Error from '../../../Common/Error/Error'

const FindJob2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let seeker_token = localStorage.getItem("seeker_token")
  useEffect(() => {
    if (seeker_token) {dispatch(findJobPageAPI(seeker_token));
    } else {toast.error("Please login to continue.");navigate(-1);
    }
  }, []);

  let { loadingFindJobPageApi, findJobPageData, findJobPageInterviewData, findJobPageError } = useSelector(state => state?.SeekerReducer);
  if (loadingFindJobPageApi) {
    return <div className="overlay-form-submission">
      <Loader />
    </div>
  }

  if (findJobPageError) {
    return <Error message={findJobPageError} />;
  }


  return (
    <>
      <AfterNavbar />
      <FindJobListing2 jobs={findJobPageData} interviews={findJobPageInterviewData} />
      <Footer />
    </>
  )
}

export default FindJob2