import React from 'react'
import './scss/CareerStepper.css'
import Stepper from 'react-stepper-horizontal';
const CareerStepperAssessment = ({ activeStep }) => {
  const stepButtons = document.querySelectorAll('.step-button');
  const progress = document.querySelector('#progress');
  Array.from(stepButtons).forEach((button, index) => {
    button.addEventListener('click', () => {
      progress.setAttribute('value', index * 100 / (stepButtons?.length - 1));
      stepButtons.forEach((item, secindex) => {
        if (index > secindex) {item.classList.add('done');}
        if (index < secindex) {item.classList.remove('done');}
      })
    })
  })
  return (
    <>
      <section className='career-stepper-sec'>
        <div className="container mt-3 py-5">
          <div className="cus-step">
            <div className="accordion" id="accordionExample">
              <div>
                <Stepper
                  steps={[{ title: 'Review Your Profile' }, { title: "Assessment" }, { title: 'Add CV' }]}
                  activeStep={activeStep}
                  completeColor="rgb(8 56 92)"
                  activeColor="rgb(0 103 204)"
                />
              </div> 
            </div>
          </div>
        </div>
      </section>
    </>

  )
}
export default CareerStepperAssessment;