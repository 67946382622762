import React from 'react'
import $ from "jquery";
import SubmittedSuccessfullyButton from './SubmittedSuccessfullyButton';
import SubmittedSuccessfullyPopup from './SubmittedSuccessfullyPopup';
import './scss/SubmittedSuccessfullyReturnToJob.css'
const SubmittedSuccessfullyReturnToJobContent = () => {$(document).ready(function () {setTimeout(function () {document.getElementById("pop-btn").click(); }, 1000);});
    return (
        <>
            <section className='submitted-successfull-return-job-section'>
                <div className="main-section">
                    <div className="container">
                        <div className="second-section">
                            <div className="row align-items-center">
                                <div className="col-md-7">
                                    <div className='main-head'>
                                        <div className="#">
                                            <div className='register-heading'>
                                                <h2 className="head">Congratulations, your application has been sent</h2>
                                            </div>
                                            <div>
                                                <h5 className="head2">Successful</h5>
                                            </div>
                                            <p className="para">
                                                Thank you for completing the registration process successfully. Your account is now active, and you are all set to explore our platform. Welcome aboard, and we wish you the best in your endeavors!
                                            </p>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div>
                                        <img src="./assets/images_another/success.png" className="img-fluid" alt="" />
                                    </div>
                                </div>
                                <div className='col-lg-12 col-md-12 col-12 my-3 my-lg-5 my-md-5'>
                                    <div>
                                        <SubmittedSuccessfullyButton />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SubmittedSuccessfullyPopup />
                <a data-bs-toggle="modal" id="pop-btn" data-bs-target="#show-rating"></a>
            </section>
        </>
    )}
export default SubmittedSuccessfullyReturnToJobContent