import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { getFeaturesData } from '../../../Redux/action/SeekerAction';

const host = process.env.REACT_APP_API_SERVER

const RecMatchingHiringBannerSec = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getFeaturesData());

    }, []);


    const featurerecruiterData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.featuresDataList?.data?.[0]?.featurerecruiter?.[0]) : "");

    const firstTitle = featurerecruiterData?.section_one_title ? featurerecruiterData?.section_one_title : 'Matching and hiring made easy'
    const firstSubtitle = featurerecruiterData?.section_one_sub_title ? featurerecruiterData?.section_one_sub_title : 'We have all the tools you need to find the people who fit your team and to start hiring today.'

    const img = featurerecruiterData?.section_one_banner_img ? `${host}/${featurerecruiterData?.section_one_banner_img}` : './assets/images/matching-hiring/ban.png';

    return (
        <section className='rec-mt-hi-banner-sec'>
            <div className=''  >
                <div className="">
                    <div className="banner-area" style={{ backgroundImage: `url(${img})` }}>
                       
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6 col-12 banner-sec-lft">
                                    <div className="banner-sec-lft-cus">
                                        <div className="banner-text">
                                            <h1>{firstTitle}</h1>
                                            <p>{firstSubtitle}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>
    )
}

export default RecMatchingHiringBannerSec