import React from 'react'
import './scss/AssessmentScore.css'
import { Link } from 'react-router-dom'
const AssessmentScoreFailedContent = () => {
    return (
        <>
            <section className='assessment-score-sec'>
                <div className="container my-5">
                    <div className="boxshadow_head">
                        <div className="row m-lg-3 m-md-3 m-0">
                            <div className="col-md">
                                <div className="row m-1">
                                    <div className="col-md">
                                        <div>
                                            <a href="#" className="text-decoration-none cust_back">
                                                <img src='./assets/assessment-screen/arrow-left.png' className='me-2'/>
                                                Back
                                            </a>
                                        </div>
                                        <div className="text-center">
                                            <img src="./assets/assessment-screen/check.png" alt="" className="cust_img" />
                                            <h3 className="heading_color">
                                                You Are Eligible To Apply For A Job
                                            </h3>
                                        </div>
                                        <div className="cust_performance">
                                            <div className="heading_style">Profile Performance</div>
                                            <div className="heading_style1">
                                                Number Of Passing Score : 90%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="boxshadow_inner">
                                    <div className="row m-4">
                                        <div className=" col-lg-3 col-md-4">
                                            <div className="circle">
                                                <div className="circle_content">7/10</div>
                                            </div>
                                        </div>
                                        <div className=" col-lg-5 col-md-4">
                                            <table className="table table-borderless">
                                                <tbody>
                                                    <tr>
                                                        <td className="cust_gray">Test:</td>
                                                        <td>Assessment Questions</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="cust_gray">Time Taken:</td>
                                                        <td>40 min</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="cust_gray">Question attempted:</td>
                                                        <td>10</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="cust_gray">Correct answer:</td>
                                                        <td>7</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="cust_gray">Wrong answer:</td>
                                                        <td>10</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-lg-4 col-md-4 align-self-end">
                                            <div className="boxshadow_progressbar">
                                                <div style={{ fontSize: 21, fontWeight: 700, margin: 20 }}>
                                                    Overall result : 75%
                                                </div>
                                                <div
                                                    className="progress"
                                                    style={{ height: 30, borderRadius: 20 }}
                                                >
                                                    <div className="progress-bar color" style={{ width: "75%" }}>
                                                        <span
                                                            className="badge"
                                                            style={{ textAlign: "start", fontSize: 20 }}
                                                        >
                                                            75%
                                                        </span>
                                                    </div>
                                                    <span
                                                        className="badge"
                                                        style={{ color: "black", textAlign: "end", fontSize: 20 }}
                                                    >
                                                        100%
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <div className="text_sujjection">
                                            Now you can apply for this job after 3 months
                                        </div>
                                        <div>
                                            <Link to="/assessment-all-question" className="text-decoration-none">
                                                View All Questions
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-lg-5 m-md-5 m-2">
                        <div className="col text-center">
                            <a href="#" className="m-1">
                                <button
                                    type="button"
                                    className="my-1 btn btn-outline-primary rounded-pill button_width text-dark"
                                >
                                    Back
                                </button>
                            </a>
                            <Link to="/resume-show-screen" className="m-1">
                                <button
                                    type="button"
                                    className="my-1 btn rounded-pill button_width1 text-white"
                                >
                                    Continue
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default AssessmentScoreFailedContent