import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../Loader';
import Error from '../../../Common/Error/Error';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from "yup";
import Select, { components } from 'react-select';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getCompanyProfileSalary, getJobRoleData } from '../../../../Redux/action/SeekerAction';
import './scss/Salaries.css';
const host = process.env.REACT_APP_API_SERVER;
const Salaries = (data) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [role, setRole] = useState([]);
    const [jobLocation, setJobLocation] = useState([]);
    const queryParams = new URLSearchParams(location.search);
    const locationSearch = queryParams.get('location');
    const titleSearch = queryParams.get('title');
    const dateSearch = queryParams.get('date');
    const ratingSearch = queryParams.get('rating');
    const [loggedIn, setLoggedIn] = useState(true);
    useEffect(() => {
        if (!localStorage.getItem("seeker_token")) {setLoggedIn(false);}
        return (() => {
            let loginPopUpModalBtn = document.getElementById("loginPopUpModalBtn");
            if (loginPopUpModalBtn) {loginPopUpModalBtn.click();} })}, []);
    const DropdownIndicator = () => null;
    const IndicatorSeparator = () => null;
    const customStyles = {
        control: (base) => ({...base,boxShadow: 'none',border: 'none',}),
        indicatorsContainer: (base) => ({...base,display: 'none',}),
        dropdownIndicator: (base) => ({...base,display: 'none',}),
        indicatorSeparator: (base) => ({...base,display: 'none',}),
    };
    const searchFormik = useFormik({
        initialValues: {title: '',location: ''},
        validationSchema: Yup.object({}),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const removeEmptyStringValues = (obj) => {
                for (const key in obj) {
                    if (obj[key] === '') { delete obj[key];}}return obj;
            };
            if (dateSearch) {values.date = dateSearch
            }
            if (ratingSearch) {values.date = ratingSearch
            }

            const objectWithoutEmptyStrings = removeEmptyStringValues(values);
            let create_search_string = "";
            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                    create_search_string += `${key}=${objectWithoutEmptyStrings[key]}&`;
                }
            }
            create_search_string = create_search_string.slice(0, -1);
            navigate(`/profile/${data?.name}/salaries?${create_search_string}`);
            resetForm();
        }
    });

    const handleSelectChange = (field, value) => {
        searchFormik.setFieldValue(field, value?.value || '');
    };


    useEffect(() => {
        if (!localStorage.getItem("seeker_token")) {
            if (locationSearch || titleSearch || dateSearch || ratingSearch) {
                const paramsSearch = {};
                if (locationSearch) {
                    paramsSearch.location = locationSearch;
                }
                if (titleSearch) {
                    paramsSearch.title = titleSearch;
                }
                if (dateSearch) {
                    paramsSearch.date = dateSearch;
                }
                if (ratingSearch) {
                    paramsSearch.rating = ratingSearch;
                }
                const removeEmptyStringValues = (obj) => {
                    for (const key in obj) {
                        if (obj[key] === '') {
                            delete obj[key];
                        }
                    }
                    return obj;
                };

                const objectWithoutEmptyStrings = removeEmptyStringValues(paramsSearch);
                let create_search_string = ``;
                for (const key in objectWithoutEmptyStrings) {
                    if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                        create_search_string += `${key}=${objectWithoutEmptyStrings[key]}&`;
                    }
                }
                create_search_string = create_search_string.slice(0, -1);

                dispatch(getCompanyProfileSalary(data?.name, null, create_search_string,));
            }
            else {
                dispatch(getCompanyProfileSalary(data?.name, null, ''));
            }
        } else {
            if (locationSearch || titleSearch || dateSearch || ratingSearch) {
                const paramsSearch = {};
                if (locationSearch) {paramsSearch.location = locationSearch;
                }
                if (titleSearch) {paramsSearch.title = titleSearch;
                }
                if (dateSearch) {paramsSearch.date = dateSearch;
                }
                if (ratingSearch) { paramsSearch.rating = ratingSearch;
                }
                const removeEmptyStringValues = (obj) => {
                    for (const key in obj) {
                        if (obj[key] === '') {
                            delete obj[key];
                        }
                    }
                    return obj;
                };

                const objectWithoutEmptyStrings = removeEmptyStringValues(paramsSearch);
                let create_search_string = ``;
                for (const key in objectWithoutEmptyStrings) {
                    if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                        create_search_string += `${key}=${objectWithoutEmptyStrings[key]}&`;
                    }
                }
                create_search_string = create_search_string.slice(0, -1);

                dispatch(getCompanyProfileSalary(data?.name, localStorage.getItem("seeker_token"), create_search_string,));
            }
            else {
                dispatch(getCompanyProfileSalary(data?.name, localStorage.getItem("seeker_token"), ''));
            }
        }
    }, [locationSearch, titleSearch, dateSearch, ratingSearch]);

    const customNames = {work_life_balance: 'Work-Life Balance',pay_benefits: 'Pay & Benefits',job_security: 'Job Security',job_culture: 'Job Culture',management: 'Management'
    };
    const {loadingCompanyProfileSalaryDataListApi,CompanyProfileSalaryDataListApiData,CompanyProfileSalaryDataListApiError,
    } = useSelector(state => state?.SeekerReducer);
    useEffect(() => {
        if (CompanyProfileSalaryDataListApiData) {
            if (CompanyProfileSalaryDataListApiData.allJobsList) {
                let roleArr = CompanyProfileSalaryDataListApiData.allJobsList.map(j => {
                    return {
                        value: j.job_title, label: j.job_title
                    }
                });
                setRole(roleArr);
                if (titleSearch) {
                    handleSelectChange('title', { value: titleSearch, label: titleSearch })
                }
            };
            if (CompanyProfileSalaryDataListApiData.allCityList) {
                let locationArr = CompanyProfileSalaryDataListApiData.allCityList.map(c => {
                    return {
                        value: c.city_name, label: c.city_name
                    }
                });
                setJobLocation(locationArr);
                if (locationSearch) {
                    handleSelectChange('location', { value: locationSearch, label: locationSearch })
                }

            };
        }
    }, [CompanyProfileSalaryDataListApiData])

    if (loadingCompanyProfileSalaryDataListApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (CompanyProfileSalaryDataListApiError) {
        return <Error message={CompanyProfileSalaryDataListApiError} />;
    }

    var config;

    if (localStorage.getItem("seeker_token")) {
        config = {
            headers: {
                'content-type': 'application/json', "auth-token": localStorage.getItem("seeker_token")
            }
        };
    } else {
        config = {
            headers: { 'content-type': 'application/json' }
        };
    }

    const overallRating = parseFloat(CompanyProfileSalaryDataListApiData?.overAllRatings?.overallRating?.toFixed(1)) || 0;
    const url = new URL(window.location.href);
    const baseUrl = `${url.protocol}/${url.host}`;
    const urlString = window.location.href;
    const url1 = new URL(urlString);
    const path = url1.pathname.slice(1);
    const searchParams = url1.search;
    const q = path + searchParams;
    const calculateAvg = (show_pay_by, min_pay, max_pay, amount) => {
        if (show_pay_by == "Range") {
            let min = 0;
            let max = 0;
            if (min_pay) { min = min_pay }
            if (max_pay) { max = max_pay };
            return ((Number(min) + Number(max)) / 2);
        } else {
            let amt = 0;
            if (amount) { amt = Number(amount) };
            return amt;
        }
    }
    return (
        <>
            <div className=" reviews-section ">
                <div className="d-sm-flex  justify-content-between pb-4">
                    <div className=" ">
                        <h2>{data?.data} Salaries: How much does {data?.data} pay ?</h2>
                    </div>
                    <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#loginPopUpModal" id="loginModalBtn">
                        Launch demo modal
                    </button>
                </div>
                <div className="form-section rounded-t salary-section-company-profile-karlatoon">
                    <form onSubmit={searchFormik.handleSubmit} className="form-section rounded-t">
                        <div className="row py-2 align-items-end">

                            <div className="col-md-5">
                                <label htmlFor="jobTitle">
                                    Job Title
                                </label>
                                <div className="relative">

                                    <Select
                                        id="title"
                                        name="title"
                                        styles={customStyles}
                                        components={{ DropdownIndicator, IndicatorSeparator }}
                                        options={role}
                                        value={role?.find(option => option.value === searchFormik.values.role)}
                                        onChange={option => handleSelectChange('title', option)}
                                        onBlur={searchFormik.handleBlur}
                                    />
                                    <SearchIcon />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <label htmlFor="location">
                                    Location
                                </label>
                                <div className="relative">
                                    <Select
                                        id="location"
                                        name="location"
                                        styles={customStyles}
                                        components={{ DropdownIndicator, IndicatorSeparator }}
                                        options={jobLocation}
                                        value={jobLocation?.find(option => option.value === searchFormik.values.location)}
                                        onChange={option => handleSelectChange('location', option)}
                                        onBlur={searchFormik.handleBlur}
                                    />
                                    <LocationOnIcon />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <button type="submit" className="submit-btn">
                                    Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="row mt-3">
                    <div className="d-flex justify-content-start">
                        <h3>Average salaries at {data?.data}</h3>
                    </div>
                    {
                        CompanyProfileSalaryDataListApiData && CompanyProfileSalaryDataListApiData?.searchedJobList
                            ? CompanyProfileSalaryDataListApiData?.searchedJobList?.length > 0 ?
                                CompanyProfileSalaryDataListApiData?.searchedJobList?.map((job, index) => {
                                    return <div className="col-lg-3 col-md-6 cmp-salary-karlatoon" key={index + 1}>
                                        <Link to={`/job-find-description?j=${job?.job_slug}`} className='card mb-2 remove-link-css'>
                                            <div class="cmp-SalaryCategoryCard">
                                                <span class="cmp-SalaryCategoryCard-title" data-testid="salary-v2-category-group-card-title">{job?.job_title}</span>
                                                <div class="css-u74ql7 eu4oa1w0">
                                                    <span class="cmp-SalaryCategoryCard-SalaryValue">{job?.currency} {calculateAvg(job?.show_pay_by, job?.min_pay, job?.max_pay, job?.amount)}</span>
                                                    <span class="cmp-SalaryCategoryCard-SalaryType"> {job?.rate_per_hour}</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                }) :
                                <div className="text-center">
                                    <i>No jobs found.</i>
                                </div> :
                            <div className="text-center">
                                <i>No jobs found.</i>
                            </div>
                    }
                    <div className="col-lg-2 col-xl-2 col-md-2 col-sm-2">

                    </div>
                </div>
            </div>
        </>
    )
}

export default Salaries