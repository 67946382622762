import React, { useEffect, useState } from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import MyProfileContent from './MyProfileContent'
import Footer from '../Commons/Footer'
import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getMyProfilePage } from '../../../Redux/action/SeekerAction'
import Loader from '../Loader'

const MyProfile = () => {
  let [loader, showLoader] = useState(true);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let authToken = localStorage.getItem("seeker_token");

  useEffect(() => {
    document.body.style.overflow = "auto";
    if (authToken) {
      dispatch(getMyProfilePage(authToken));
    } else {
      toast.error("Please login to continue.");
      navigate(-1);
    }
  }, []);

  const data = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.myProfilePageData : null);

  useEffect(() => {
    if (typeof data === 'object' && !Array.isArray(data)) {
      showLoader(false)
    } else {
      showLoader(true)
    }
  }, [])

  return (
    <>
      {!loader ?
        <><AfterNavbar />
          {data ? <MyProfileContent data={data} /> : <div className="overlay-form-submission"><Loader /></div>}
          <BeforeFooterPlayStore />
          <Footer /></> :
        <div className="overlay-form-submission">
          <Loader />
        </div>
      }
    </>
  )
}

export default MyProfile