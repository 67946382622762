import React, { useEffect, useState } from 'react'
import Footer from '../Commons/Footer';
import Header from '../Commons/Header';
import Navbar from '../Commons/Navbar';
import RecLoginContent from './RecLoginContent';
import RegisterHeader from '../../Seeker/CvBuilder/CvBuilderHeader/RegisterHeader';
import RecHeader from '../Commons/AfterLogin/RecHeader';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import RegisterRecHeader from '../../Seeker/CvBuilder/CvBuilderHeader/RegisterRecHeader';
const RecLogin = () => {
  const navigate = useNavigate();
  const [displayPage, setDisplayPage] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("recruiter_token")) {
      toast.warn("You are already logged in.");
      navigate("/post-job");
    } else {
      setDisplayPage(true);
    }
  }, [])
  return (
    <>
      {
        displayPage &&
        <>
          <RegisterRecHeader />
          <RecLoginContent />
          <Footer />
        </>
      }
    </>
  )
}

export default RecLogin