import React, { useState, useEffect } from 'react'
import './scss/ChooseYourPlan.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FallingLines } from 'react-loader-spinner';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSubscriptionPlans, getRecruiterregistrationData } from '../../../../Redux/action/RecruiterAction';
import { Hidden } from '@mui/material';
const host = process.env.REACT_APP_API_SERVER;
const RecChooseYourPlanContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let [loggedIn, isLoggedIn] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [plans, setPlans] = useState([]);
  const dispatch = useDispatch();
  let initialValues = {
    plan_chose: "",
  }
  let job_id = location.state || "";
  useEffect(() => {
    dispatch(getAllSubscriptionPlans());
    if (localStorage.getItem("recruiter_token")) {
      isLoggedIn(true);
      let authToken = localStorage.getItem("recruiter_token");
      dispatch(getRecruiterregistrationData(authToken));
    } else {
      isLoggedIn(false);
      toast.error("Please fill Company Details first.");
    }
  }, []);
  const subscriptionPlans = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.recruiterSubscriptionPlanDataList : null)
   useEffect(() => {
    if (subscriptionPlans) {setPlans(subscriptionPlans);
    }}, [subscriptionPlans])
  const recruiterRegistrationData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.recruiterRegisterDataList?.data) : "");
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({plan_chose: Yup.string(),}),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const loadingToastId = toast.info('Submitting form...', { autoClose: false });
          try {
        setSubmitting(true);
        document.body.style.overflow = 'hidden';
        const config = {
          headers: {'auth-token': localStorage?.getItem("recruiter_token")}};
        await axios.post(`${host}/api/update-recruiter-plan`, values, config)
          .then(function (response) {if (response.status == 200) {
              let values_form = { job_id: job_id?.job_id }
              {job_id ? axios.post(`${host}/api/update-job-completion`, values_form, config)
                  .then(res => {
                    if (res.status == 200) {document.body.style.overflow = 'auto';setSubmitting(false);localStorage.removeItem("job_id");toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Sponsored successfully and job posted successfully." });navigate("/post-job");
                    } else {document.body.style.overflow = 'auto';setSubmitting(false);toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: res?.data?.msg });
                    }
                  }).catch(error => {console.log(error.message);document.body.style.overflow = 'auto';setSubmitting(false);toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error' });
                  }) :
                  setSubmitting(false)
                document.body.style.overflow = 'auto';
                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Form submitted successfully!' })
                navigate('/rec-you-register-successfully');
              }
            } else {
              console.log("in else block of .then method:", response.data.msg);
              setSubmitting(false)
              document.body.style.overflow = 'auto';
              toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
            }
          })
          .catch(function (error) {
            console.log(error.message);
            document.body.style.overflow = 'auto';
            setSubmitting(false)
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
          })
      } catch (error) {
        console.log("catch:", error);
        document.body.style.overflow = 'auto';
        setSubmitting(false)
        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
      }
    }
  })

  const handlePurchaseSubscription = (plan_id, selling_price) => {
    const loadingToastId = toast.info('Purchasing plan...', { autoClose: false });
    try {
      document.body.style.overflow = "hidden";
      setShowLoader(true);
      const values = { plan_id: plan_id }
      const config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } };
      if (Number(selling_price) == 0) {
        axios.post(`${host}/recruiter/purchase-plan`, values, config)
          .then(response => {
            if (response.status === 200) {document.body.style.overflow = 'auto';setShowLoader(false);toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });navigate("/post-job");
            } else {document.body.style.overflow = 'auto';setShowLoader(false);toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
            }
          }).catch(error => {
            console.log(error.message);
            document.body.style.overflow = 'auto';
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error." });
          })
      } else {
        axios.post(`${host}/recruiter/payment/create-payment-session`, { subscription_plan_id: plan_id }, config)
          .then(response => {
            if (response.status === 200) {document.body.style.overflow = 'auto';setShowLoader(false);toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Redirecting to payment page." });window.location.href = response?.data?.data?.url;
            } else {document.body.style.overflow = 'auto';setShowLoader(false);toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
            }
          }).catch(error => {
            console.log(error.message);
            document.body.style.overflow = 'auto';
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error." });
          })
      }
    } catch (error) {
      console.log(error.message)
      document.body.style.overflow = "auto";
      setShowLoader(false);
      toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
    }
  }

  return (
    <>{isLoggedIn &&
      <section className='rec-choose-your-plan'>
        <div className='container'>
          <div className='choose-you-plan-internal'>
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-12 text-center'>
                <div className='heading-dv'>
                  <h1>Choose Your Plan</h1>
                  <div className='row'>
                    <div className='col-lg-8 col-md-8 offset-lg-2 offset-lg-2'>
                      <p>Whether you want to get organized, keep your professional life on track, or boost workplace productivity, KarLatoon has the right plan for you.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-lg-4 mt-md-4 mt-1 align-items-center'>

              {
                plans?.length > 0 ?
                  plans.map(plan => {
                    return <div className='col-lg-6 col-md-6 col-12 mt-md-0 mt-lg-0 my-3' key={plan?.id}>
                      <div className='left-side-dv'>
                        <div className='content'>
                          <h3>{plan?.name}</h3>
                          <p className='second-p'>{plan?.short_description}</p>
                          <p> <b>Price</b> : ؋{plan?.price}/{plan?.price_type == "YEARLY" ? "yr" : "month"}</p>
                          <p><b>Discount </b>: {plan?.discount}%</p>
                          <b>Payable Amount:</b>
                          <h2>؋{plan?.selling_price}/{plan?.price_type == "YEARLY" ? "yr" : "month"}</h2>
                          <p><b>Benefits:</b></p>
                          <ul>
                            <li className='right-mark'>Upto {plan?.jobpost_count ?? "0"} Job Posts.</li>
                          
                            <li className={plan?.resumedownload_count == 0 ? `wrong-mark` : `right-mark`}>Download resumes.</li>
                            <li className={plan?.add_jobfair == 0 ? `wrong-mark` : `right-mark`}>Post jobs in Job Fair.</li>
                          </ul>
                        </div>
                       
                        {Number(plan?.selling_price) == 0 ? <Link type="submit" to="/rec-you-register-successfully" className='btn get-started'>Get Started</Link> : <button type="submit" className='get-started' onClick={() => handlePurchaseSubscription(plan?.id, plan?.selling_price)}>Buy Subscription</button>}
                      </div>
                    </div>
                  })
                  :
                  <div className='col-lg-12 col-md-12 col-12 mt-md-0 mt-lg-0 mt-3'>
                    <div className='left-side-dv'>
                      <div className='content'>
                        <div className="text-center">
                          <p>No plans available.</p>
                        </div>
                      </div>

                    </div>
                  </div>
              }

            </div>
            <div className='row mt-lg-5 mt-md-5 mt-5'>
              <div className='col-12'>
                <div className='next-button'>
                  <Link to="/rec-you-register-successfully" className='next-a'>Continue with free plan</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          showLoader && <div className="overlay-form-submission">
            <FallingLines
              color="#1b375c"
              width="100"
              visible={true}
              ariaLabel='falling-lines-loading'
            />
          </div>
        }
      </section >
    }
    </>
  )
}

export default RecChooseYourPlanContent