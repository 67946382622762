import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

let host = process.env.REACT_APP_API_SERVER;
const ProjectHistory = ({ setProject, id, refresh, handleNext, handleBack }) => {



    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);

    const [inProcess, setInProcess] = useState(true);
    const [working, setworking] = useState(true);

    const CKEDITORConfig = {
        height: 200,
        validation: {
            maxLength: 500 // Set your desired maximum length here
        },
       
        fontSize: {
            options: [
                9,
                11,
                13,
                'default',
                17,
                19,
                21
            ]
        },
        toolbar: [
            "undo",
            "redo",
            'bold',
            'italic',
            "fontSize",
            "bulletedList",
            "numberedList"
        ],
    }




    const formik = useFormik({
        initialValues: {
            project_title: "",

            client: "",
            currently_working: '',
            start: "",
            end: "",
            description: "",
        },
        validationSchema: Yup.object({
            project_title: Yup.string().required("Projeect title is required"),

            client: Yup.string().required("Client is required."),


            start: Yup.date()
                .max(new Date(), "Project start date cannot be in the future")
                .required("Project start date is required"),
            end: working ? Yup.date()
                .max(new Date(), "Project end date cannot be in the future")
                .min(
                    Yup.ref('start'),
                    "Project end date cannot be earlier than Project start date")
                .required('Project end date is required') : Yup.string(),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });

            try {


                setShowLoader(true);
                setDisableBtn(true);
                if (values.currently_working === true) {
                    values.end = ''
                }

                document.body.style.overflow = 'hidden';

             

                values.cvbuilder_id = id

                let post_url;
                let config;

                if (localStorage.getItem("seeker_token")) {
                    post_url = `${host}/cvbuilder/add-cvbuilder-seeker-project`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "auth-token": localStorage.getItem("seeker_token")
                        }
                    }
                } else if (localStorage.getItem("karlatoon_deviceId")) {
                    post_url = `${host}/cvbuilder/add-cvbuilder-seeker-project-guest`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "device-token": localStorage.getItem("karlatoon_deviceId")
                        }
                    }
                } else {
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Token expired.' });
                    navigate('/build-resume')
                }

                axios.post(post_url, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false);
                            setShowLoader(false);
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Project details updated successfully!' });
                            refresh();
                            setProject(false)
                            refresh();
                        } else {
                            setSubmitting(false);
                            setShowLoader(false);
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        document.body.style.overflow = 'auto';
                        setSubmitting(false);
                        setShowLoader(false);
                        setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    });


            } catch (error) {
                document.body.style.overflow = 'auto';
                console.log(error.message);
                setSubmitting(false);
                setShowLoader(false);
                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    });








    return (
        <div className='mt-3'>
            <form method='post' onSubmit={formik.handleSubmit}>
                <div className='row'>
                    <div className="mb-3 col-md-6">
                        <label htmlFor="Title" className="form-label">
                            <b>Project Title<span className='text-danger'>*</span></b>
                        </label>
                        <input type="text" name='project_title' className='form-control' placeholder='Project Title'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.project_title}
                        />
                        <div className="help-block with-errors">
                            {formik.touched.project_title && formik.errors.project_title ? (
                                <div className='text-danger'>{formik.errors.project_title}</div>
                            ) : null}
                        </div>

                    </div>
                    <div className="mb-3 col-md-6">
                        <label htmlFor="Employer" className="form-label">
                            <b>Client<span className='text-danger'>*</span></b>
                        </label>
                        <input type="text" name='client' className='form-control' placeholder='Client'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.client}
                        />
                        <div className="help-block with-errors">
                            {formik.touched.client && formik.errors.client ? (
                                <div className='text-danger'>{formik.errors.client}</div>
                            ) : null}
                        </div>

                    </div>
                    <div className="col-12"> <input type="checkbox" className="form-check-input" id="exampleCheck1" name='currently_working'
                        onChange={(e) => {
                            setworking(e.target.value == 'true');
                            formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.currently_working}
                    // checked={!inProcess}
                    />
                        <label className="form-check-label ms-3" htmlFor="exampleCheck1">
                            Project in process
                        </label></div>

                    <div className="mb-3 col-md-6">
                        <label htmlFor="Start Year" className="form-label">
                            <b> Start Year<span className='text-danger'>*</span></b>
                        </label>
                        <input type='date' name='start' className='form-control' placeholder='Start Month & Year'

                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.start}

                        />

                        <div className="help-block with-errors">
                            {formik.touched.start && formik.errors.start ? (
                                <div className='text-danger'>{formik.errors.start}</div>
                            ) : null}
                        </div>

                    </div>

                    {(working) && <div className="mb-3 col-md-6">
                        <label htmlFor="End Year" className="form-label">
                            <b>  End Year<span className='text-danger'>*</span></b>
                        </label>
                        <input type='date' name='end' className='form-control' placeholder='End Month & Year'

                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.end}

                        />
                        <div className="help-block with-errors">
                            {formik.touched.end && formik.errors.end ? (
                                <div className='text-danger'>{formik.errors.end}</div>
                            ) : null}
                        </div>


                    </div>}
                    <div className="mb-3 col-md-12">
                        <label htmlFor="description" className="form-label">
                            <b>Description</b>
                        </label>
                        <div>
                            <CKEditor
                                name="description"
                                config={CKEDITORConfig}
                                editor={ClassicEditor}
                                data={formik.values.description}
                                // onBlur={formik.handleBlur}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    formik.setFieldValue('description', data);
                                }}
                            />
                            <div className="help-block with-errors mt-2">
                                {formik.touched.description && formik.errors.description ? (
                                    <div className='text-danger'>{formik.errors.description}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="help-block with-errors">
                            {formik.touched.description && formik.errors.description ? (
                                <div className='text-danger'>{formik.errors.description}</div>
                            ) : null}
                        </div>
                    </div>
                 

                    <div className="mb-3 col-md-12 text-center">
                        <button type="submit" disabled={disableBtn} className="btn btn-primary btn-sm w-fit me-1">
                            + Add
                        </button>
                        <button className="btn btn-outline-primary btn-sm" onClick={() => { setProject(false) }}>Show all</button>
                    </div>

                </div>
            </form>
        </div>
    )
}

export default ProjectHistory