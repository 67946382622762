import React, { useEffect, useState } from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import FindRightPlacesCompanyListingcontent from './FindRightPlacesCompanyListingcontent'
import Footer from '../../Commons/Footer'
import AfterNavbarwithoutLogin from '../../Commons/AfterLoginHeader/AfterNavbarwithoutLogin'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

const FindRightPlacesCompanyListing = () => {
  const [loadPage, setLoadPage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const industry_id = queryParams.get('i');
  useEffect(() => {
    if (!industry_id) {
      toast.error("Please enter correct URL.");
      navigate(-1)
    } else {
    }
  }, [])
  return (
    <>
      <AfterNavbar />
      <FindRightPlacesCompanyListingcontent industry_id={industry_id} />
      <Footer />
    </>
  )
}

export default FindRightPlacesCompanyListing