import React, { useState, useEffect } from 'react'
import $ from "jquery";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';

import './scss/OtpSendEnterSeeker.css';
import EnterNewPassWordContentSeeker from '../EnterNewPassWordSeeker/EnterNewPassWordContentSeeker';
import HourglassLoader from '../../../Seeker/HourglassLoader';
import {
    LoginSocialGoogle, LoginSocialLinkedin,
} from 'reactjs-social-login'

import {
    GoogleLoginButton, LinkedInLoginButton,
} from 'react-social-login-buttons'
const client_id = process.env.REACT_APP_GG_APP_CLIENT_ID;
const host = process.env.REACT_APP_API_SERVER

const OtpSendEnterContent = () => {
    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState(null)

    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState(location.state?.email || '');
    const [seconds, setSeconds] = useState(location.state?.seconds || 30);
    const [timerStarted, setTimerStarted] = useState(false);
    const [disableResend, setdisableResend] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [Submitting, setSubmitting] = useState(false);
    const [showResendButton, setShowResendButton] = useState(false);


    useEffect(() => {
        let intervalId;
        if (timerStarted && seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
                setShowResendButton(false);
            }, 1000);
        } else {
            setShowResendButton(true);
        }
        return () => clearInterval(intervalId);
    }, [timerStarted, seconds]);
    const handleStartTimer = () => {
        setTimerStarted(true);
    };


    useEffect(() => {
        if (location.state?.seconds) {
            handleStartTimer();
        }
    }, [location.state]);



    const [initialValues, setInitialValues] = useState({
        forget_password_otp: ''
    })
    const config = {
        headers: {
            'content-type': 'application/json',
        }
    };
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            forget_password_otp: Yup.string().required("OTP is required.")
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {

                setSubmitting(true);
                setShowLoader(true);
                setDisableBtn(true);
                document.body.style.overflow = 'hidden';


                const formdata = new FormData();
                formdata.append("otp", values.forget_password_otp);
                formdata.append("email", email);
                formdata.append("type", "email");
                await axios.post(`${host}/mobile/verify-otp-forget-password`, formdata, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                            navigate("/rec-enter-new-password-seeker", { state: { email } })

                        } else {
                            setDisableBtn(false);
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
            }

            catch (error) {

                document.body.style.overflow = 'auto';
                setSubmitting(false);
                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    })

    const handleResendOtp = async () => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {
            const values = {

                email: email
            }
            setdisableResend(true);
            setSeconds(30);
            handleStartTimer();
            values.type = "email";
            await axios.post(`${host}/mobile/forget-password-request`, values, config)
                .then(function (response) {


                    if (response.status == 200) {
                        document.body.style.overflow = 'auto';

                        const otpResponse = response.data.otp;
                        setdisableResend(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `Otp is ${otpResponse}. Please verify.` });


                    } else {
                        console.log("in else block of .then method:", response.data.msg);
                        setSubmitting(false);
                        setdisableResend(false);
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });

                    }
                })
                .catch(function (error) {
                    console.log(error.message);
                    document.body.style.overflow = 'auto';
                    setSubmitting(false);
                    setdisableResend(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in otp resend. Please try again.' });
                })

        } catch (error) {
            console.log("catch:", error);
            document.body.style.overflow = 'auto';
            setSubmitting(false);
            setdisableResend(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in resend otp verification. Please try again.' });
        }

        finally {
            setTimeout(() => setShowResendButton(true), 30000);
        }

    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowResendButton(true);
        }, 30000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <section className="otp-password-section">
                <div className="container">
                    <div className="second-section">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12 col-12 order-lg-1 order-md-1 order-2">
                                <form method='post' onSubmit={formik.handleSubmit}>
                                    <div className='text-start mt-custon-1'>
                                        <h3>Enter OTP</h3>
                                        <span className="para-3">Your code was sent to you via email</span>
                                    </div>
                                    <div className='otp-custom-dv'>

                                        <div className="row ">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div
                                                    className="card border-0"
                                                    style={{ boxShadow: "0 12px 15px rgba(0, 0, 0, 0.02)" }}
                                                >
                                                    <div className="card-body">
                                                        <div className="col-12 mb-4 " width={300} >
                                                            <input type="text" name="forget_password_otp" className='input-text' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.forget_password_otp} maxLength={4} />
                                                        </div>
                                                        <div className="help-block with-errors">
                                                            {formik.touched.forget_password_otp && formik.errors.forget_password_otp ? (
                                                                <div className='text-danger'>{formik.errors.forget_password_otp}</div>
                                                            ) : null}
                                                        </div>
                                                        {showResendButton ?
                                                            <p className="resend text-muted mb-0">

                                                                Didn't receive code? <button className='border-0 text-decoration-none bg-transparent text-primary' onClick={handleResendOtp} disabled={disableResend}> {disableResend ? '' : 'Resend'} </button>

                                                            </p>
                                                            : <p className="resend  mb-0 text-primary">Resend OTP in {seconds} seconds</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row row-top">

                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <div className='mt-1 login-btn'>
                                                    <button type="submit" className='btn rounded-pill px-5' style={{ backgroundColor: "#1B375C", color: 'white' }} disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : "Submit OTP"}</button>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>



                                </form>
                            </div>
                            <div className="col-lg-6 col-md-12 col-12 order-lg-2 order-md-2 order-1">
                                <div>
                                    <img
                                        src="././././assets/recruiter-images/singin/back-girl.png" width="100%" height="100%"
                                        alt="" className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OtpSendEnterContent