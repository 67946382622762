import React, { useEffect } from 'react'

import Footer from '../../Commons/Footer'
import AssessmentDetailsContent from './AssessmentDetailsContent'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import CareerStepper from '../CareerStepper/CareerStepper'
import YourCareerCompanyDetailsCard from '../YourCareerCompanyDetailsCard/YourCareerCompanyDetailsCard'
import CareerStepperAssessment from '../CareerStepper/CareerStepperAssessment'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
const AssessmentDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const job_id = queryParams.get('j');
    useEffect(() => {
        if (!job_id) { toast.error("Please enter correct URL.");navigate(-1);}}, [job_id])
    return (
        <>
            <AfterNavbar />
            <CareerStepperAssessment activeStep={1} />
            <AssessmentDetailsContent job_id={job_id} />
            <Footer />
        </>
    )
}
export default AssessmentDetails