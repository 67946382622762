import React, { useEffect, useState } from 'react'
import './scss/FindJob.css'
import AllJob from './AllJobs/AllJob'
import AppliedCard from './AllJobs/AppliedCard/AppliedCard'
import ShortListed from './AllJobs/ShortListed/ShortListed'
import RejectedCard from './AllJobs/RejectedCard/RejectedCard'
import SavedCard from './AllJobs/SavedCard/SavedCard'
import JobFromRecruiter from './AllJobs/JobFromRecruiter/JobFromRecruiter'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllAppliedJobsData, getAppliedJobsData, getHiredJobsData, getRejectedJobsData, getSavedJobsData, getShortlistedJobsData, getinterviewJobsData } from '../../../../Redux/action/SeekerAction'
import Loader from '../../Loader'
import HiredCard from './AllJobs/HiredCard/HiredCard';
import InterviewScheduled from './AllJobs/InterviewScheduled/InterviewScheduled'
import InterviewedCard from './AllJobs/InterviewedCard/InterviewedCard'

const FindJobListing2 = ({ jobs, interviews }) => {

    let [allJobs, setAllJobs] = useState(jobs);
    let [dataCount, setDataCount] = useState(0);
    let [appliedJobs, setAppliedJobs] = useState([]);
    let [rejectedJobs, setRejectedJobs] = useState([]);
    let [shortlistedJobs, setShortlistedJobs] = useState([]);
    let [savedJobs, setSavedJobs] = useState([]);
    let [hiredJobs, setHiredJobs] = useState([]);
    let [interviewedJobs, setInterviewedJobs] = useState([]);

    useEffect(() => {
        setDataCount(jobs?.length);
        const appliedReviewedJobsArr = jobs.filter(job =>
            job.job_data.applied_job_details.some(detail =>
                detail.job_status === "APPLIED" || detail.job_status === "REVIEWED"
            )
        );

        const rejectedJobsArr = jobs.filter(item =>
            item.job_data.applied_job_details.some(detail => detail.job_status === "REJECTED")
        );
        const hiredJobsArr = jobs.filter(item =>
            item.job_data.applied_job_details.some(detail => detail.job_status === "HIRED")
        );
        const shortListedJobsArr = jobs.filter(item =>
            item.job_data.applied_job_details.some(detail => detail.job_status === "SHORTLISTED")
        );
        const interviewedJobsArr = jobs.filter(item =>
            item.job_data.applied_job_details.some(detail => detail.job_status === "INTERVIEW")
        );
        const savedJobsArr = jobs.filter(job =>
            job.job_data.saved_job_detail && job.job_data.saved_job_detail.length > 0
        );
        setAppliedJobs(appliedReviewedJobsArr);
        setShortlistedJobs(shortListedJobsArr);
        setRejectedJobs(rejectedJobsArr);
        setHiredJobs(hiredJobsArr);
        setInterviewedJobs(interviewedJobsArr);
        setSavedJobs(savedJobsArr);
    }, [jobs])



    return (
        <>
            {
                <>
                    <section className='findjoblisting-first-sec'>
                        <div className='container'>
                            <div className='pill-tabs-dv'>
                                <ul className="nav nav-pills " id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-all-job-tab" data-bs-toggle="pill" data-bs-target="#pills-all-job" type="button" role="tab" aria-controls="pills-all-job" aria-selected="true"> All Jobs </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-saved-job-tab" data-bs-toggle="pill" data-bs-target="#pills-saved-job" type="button" role="tab" aria-controls="pills-saved-job" aria-selected="false"> Saved Job </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-applied-tab" data-bs-toggle="pill" data-bs-target="#pills-applied" type="button" role="tab" aria-controls="pills-applied" aria-selected="false"> Applied </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-short-listed-tab" data-bs-toggle="pill" data-bs-target="#pills-short-listed" type="button" role="tab" aria-controls="pills-short-listed" aria-selected="false"> Short Listed </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-interviewed-job-tab" data-bs-toggle="pill" data-bs-target="#pills-interviewed-job" type="button" role="tab" aria-controls="pills-interviewed-job" aria-selected="false"> Interviewed</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-hired-tab" data-bs-toggle="pill" data-bs-target="#pills-hired" type="button" role="tab" aria-controls="pills-hired-job" aria-selected="false"> Hired </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-rejected-tab" data-bs-toggle="pill" data-bs-target="#pills-rejected" type="button" role="tab" aria-controls="pills-rejected" aria-selected="false"> Rejected </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-interviews-list-tab" data-bs-toggle="pill" data-bs-target="#pills-interviews-list" type="button" role="tab" aria-controls="pills-interviews-list" aria-selected="false"> Interviews </button>
                                    </li>


                                 
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className='findjoblisting-second-sec mb-5'>
                        <div className='container'>
                            <div className='after-find-job-listing'>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-all-job" role="tabpanel" aria-labelledby="pills-all-job-tab">
                                        <div className='container'>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12 col-12 text-start'>
                                                    <div className='job-heading'>
                                                        <h2>{dataCount} Jobs</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <AllJob /> */}
                                        {<AllJob jobs={allJobs} />}
                                    </div>
                                    <div className="tab-pane fade" id="pills-applied" role="tabpanel" aria-labelledby="pills-applied-tab">
                                        {<AppliedCard jobs={appliedJobs} />}
                                    </div>
                                    <div className="tab-pane fade" id="pills-short-listed" role="tabpanel" aria-labelledby="pills-short-listed-tab">
                                        {<ShortListed jobs={shortlistedJobs} />}
                                    </div>
                                    <div className="tab-pane fade" id="pills-rejected" role="tabpanel" aria-labelledby="pills-rejected-tab">
                                        {<RejectedCard jobs={rejectedJobs} />}
                                    </div>
                                    <div className="tab-pane fade" id="pills-saved-job" role="tabpanel" aria-labelledby="pills-saved-job-tab">
                                        {<SavedCard jobs={savedJobs} />}
                                    </div>
                                    <div className="tab-pane fade" id="pills-interviewed-job" role="tabpanel" aria-labelledby="pills-interviewed-job-tab">
                                        {<InterviewedCard jobs={interviewedJobs} />}
                                    </div>
                                    <div className="tab-pane fade" id="pills-hired" role="tabpanel" aria-labelledby="pills-hired-tab">
                                        {<HiredCard jobs={hiredJobs} />}
                                    </div>
                                    <div className="tab-pane fade" id="pills-interviews-list" role="tabpanel" aria-labelledby="pills-interviews-list-tab">
                                        {<InterviewScheduled jobs={interviews} />}
                                    </div>
                                   
                                </div>
                               
                            </div>

                        </div>
                    </section>
                </>
            }
        </>
    )
}

export default FindJobListing2