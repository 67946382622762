import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'
import Footer from '../Commons/Footer'
import Loader from '../Loader'
import { getAllNewsData, getNewsBySlug } from '../../../Redux/action/MasterAction'
import NewsListContent from './NewsListContent'
import { toast } from 'react-toastify'
import NewsDetailContent from './NewsDetailContent'

const   NewsDetail = () => {
  const dispatch = useDispatch();
  const { news_slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!news_slug) {
      toast.error("Please enter correct URL.")
      navigate(-1);} else {dispatch(getNewsBySlug(news_slug));
    }}, [])
  const data = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.newsBySlug : null);
  return (
    <>{data ? <>
      < AfterNavbar />
      {data && <NewsDetailContent data={data} />}
      <BeforeFooterPlayStore />
      <Footer />
    </> :
      <div className="overlay-form-submission">
        <Loader />
      </div>
    }</>
  )
}

export default NewsDetail