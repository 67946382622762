import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import RecMySubsCriptionPlanHistoryContent from './RecMySubsCriptionPlanHistoryContent'
import Footer from '../Commons/Footer'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { getRecruiterAllPurchasedPlans, getRecruiterCurrentActivePlan } from '../../../Redux/action/RecruiterAction'
import Loader from '../Loader'

const RecMySubsCriptionPlanHistory = () => {
    let navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(true);
    const [allPlans, setAllPlans] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            toast.error("Please login to continue.");
            navigate("/recruiter-login");
        } else {
            const token = localStorage.getItem("recruiter_token");
            dispatch(getRecruiterAllPurchasedPlans(token));
        }
    }, []);

    const allPurchasedPlans = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.recruiterAllPurchasedPlanDataList : null)
    
    useEffect(() => {
        if (allPurchasedPlans) {
            setAllPlans(allPurchasedPlans);
            setShowLoader(false);
        } else {
            setShowLoader(true);
        }
    }, [allPurchasedPlans])

    return (
        <>
            {!showLoader ? <>
                <RecAfterLoginNavbar />
                <RecMySubsCriptionPlanHistoryContent allPlans={allPlans} />
                <Footer />
            </> :
                <div className="overlay-form-submission">
                    <Loader />
                </div>
            }
        </>
    )
}

export default RecMySubsCriptionPlanHistory