import React, { useState } from 'react';
import "../Scss/ViewJobsProject.css";
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import CandidateDetailModal from './CandidateDetailModal';
import { toast } from 'react-toastify';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER;

const SeekerListContent = ({ data, reload }) => {
    let [showFullDescription, setShowFullDescription] = useState(false);

    const [seeker, setSeeker] = useState({});

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();

        return `${month}-${year}`;
    }


    const showCandidate = (candidateJSON) => {
        setSeeker(candidateJSON);
        let modalBtn = document.getElementById("candidatedetail-modal-btn");
        if (modalBtn) { modalBtn.click() };
    };



    const handleSave = (id) => {
        let values = {
            slug: data?.projectDetails?.slug,
            job_slug: data?.jobDetails?.job_slug,
            seeker_id: id,
        };
        // console.log(values)
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {

            document.body.style.overflow = "hidden";
            let config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } };

            axios.post(`${host}/recruiter/project/add-candidate-to-pipeline`, values, config)
                .then(response => {
                    if (response.status == 200) {
                        reload();
                        document.body.style.overflow = "auto";
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Candidate added to pipeline successfully!' });
                    } else {
                        document.body.style.overflow = "auto";
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                    }
                });

        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = "auto";
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error!' });
        }

    }

    const removeCandidate = (id) => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {

            document.body.style.overflow = "hidden";
            let config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } };

            axios.delete(`${host}/recruiter/project/remove-candidate?seeker_id=${id}&slug=${data?.projectDetails?.slug}`, config)
                .then(response => {
                    if (response.status == 200) {
                        reload();
                        document.body.style.overflow = "auto";
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Candidate removed from pipeline successfully!' });
                    } else {
                        document.body.style.overflow = "auto";
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                    }
                })

        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = "auto";
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error!' });
        }
    };


    return (
        <>
            <section className='view-jobs-project-section'>
                <div className='container'>
                    <div className='jobs-project-upper'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading text-center'>
                                        <h2 className='project-heading'>Project</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='view-jobs-project-section'>
                <div className='container'>
                    <div className='jobs-project-upper'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <table className="table table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">Project</th>
                                            <td scope="col">{data?.projectDetails?.name}</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">Job Title:</th>
                                            <td><Link to={`/rec-job-details-page?jobId=${data?.jobDetails?.job_slug}&ji=${data?.jobDetails?.id}`}>{data?.jobDetails?.job_title}</Link></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Description:</th>
                                            <td>

                                                {showFullDescription ?
                                                    <>
                                                        <div>
                                                            {parse(`${data?.jobDetails?.description}`)}
                                                        </div>
                                                        <div className="row text-center">
                                                            <p style={{ cursor: "pointer", fontWeight: "bold", color: "blue" }} onClick={() => { setShowFullDescription(false) }}>Show Less</p>
                                                        </div>
                                                    </> :
                                                    <>
                                                        <div className="line-clamp-2">
                                                            {parse(`${data?.jobDetails?.description}`)}

                                                        </div>
                                                        <div className="row text-center">
                                                            <p style={{ cursor: "pointer", fontWeight: "bold", color: "blue" }} onClick={() => { setShowFullDescription(true) }}>Show More</p>
                                                        </div>
                                                    </>
                                                }</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='view-jobs-project-section'>
                <div className='container'>
                    <div className='jobs-project-upper'>
                        <div className='container pb-2'>
                            <div className='row align-items-center'>
                                <h2>Candidates</h2>
                            </div>
                            {data?.appliedCandiates && data?.appliedCandiates?.length > 0 ?
                                data?.appliedCandiates?.map((candidate, index) => {
                                    // console.log(candidate.in_pipeline)
                                    return <div className="card my-2" key={index + 1}>
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <span className="project-job-candidate-name" onClick={() => showCandidate(candidate)}><b>{candidate?.firstName} {candidate?.lastName}</b></span>
                                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id='candidatedetail-modal-btn' style={{ display: "none" }}>
                                                </button>
                                                <CandidateDetailModal seeker={seeker} />
                                                <div>
                                                    {!Number(candidate?.in_pipeline) ?
                                                        <button onClick={() => handleSave(candidate?.id)} className='update-button-primary' title="Add candidate to pipeline"><i className="fa-solid fa-user-plus"></i></button>
                                                        :
                                                        <button onClick={() => removeCandidate(candidate?.id)} className='update-button-danger' title='Remove candidate from pipeline'><i className="fa-solid fa-right-from-bracket"></i></button>
                                                    }
                                                </div>
                                            </div>

                                            {candidate?.Seeker_professional_details?.length > 0 && (
                                                <div className="row">
                                                    {(() => {
                                                        const workingObjects = candidate.Seeker_professional_details.filter(item => item.working === 1);
                                                        if (workingObjects.length === 0) {
                                                            return null;
                                                        } else if (workingObjects.length === 1) {
                                                            const foundObject = workingObjects[0];
                                                            return (
                                                                <div className="col-12">
                                                                    <div className="row my-2">
                                                                        <div className="col-md-12">
                                                                            <b>Current:</b>{foundObject.designation} at {foundObject?.current_last_company}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        } else {
                                                            let foundObject = workingObjects[0];
                                                            for (let i = 1; i < workingObjects.length; i++) {
                                                                if (new Date(workingObjects[i].joining_date) > new Date(foundObject.joining_date)) {
                                                                    foundObject = workingObjects[i];
                                                                }
                                                            }
                                                            return (
                                                                <div className="col-12">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <b>Current: </b> {foundObject.designation} at {foundObject?.current_last_company}({formatDate(foundObject?.joining_date)} - {Number(foundObject?.working) == 0 ? formatDate(foundObject?.relieving_date) : "Current"})
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    })()}

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <b>Past:</b>
                                                        </div>
                                                        <div className="">
                                                            {
                                                                candidate?.Seeker_professional_details?.length > 0 ? candidate?.Seeker_professional_details?.map((prof, index) => {
                                                                    return (Number(prof?.working) !== 1) && <div className='col-md-12 col-lg-12'>
                                                                        <b>{prof?.designation}</b> at {prof?.current_last_company} ({formatDate(prof?.joining_date)} - {Number(prof?.working) == 0 ? formatDate(prof?.relieving_date) : "Current"})
                                                                    </div>
                                                                }) :
                                                                    <div className='col-md-12 col-lg-12'>
                                                                        <i>No infomration found.</i>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                }) :
                                <div className="card my-2">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <i>No Candidates applied for this job.</i>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </section >
        </>
    )
}

export default SeekerListContent