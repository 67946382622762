import React, { useEffect, useState } from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../../Commons/Footer'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify'
import SearchCompanyResultListing from './SearchCompanyResultListing'
const SearchCompanyResult = () => {
  const [loadPage, setLoadPage] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const industry_id = queryParams.get('industry_id');
  return (
    <>
      <AfterNavbar />
      <SearchCompanyResultListing/>
      <Footer />
    </>
  )
}

export default SearchCompanyResult