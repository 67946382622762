import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import "./scss/BlogDetail.css";
import BlogCategory from '../BlogCategory/BlogCategory';
import Footer from '../../Commons/Footer';
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar';
import { getBlogData, getBlogIdData, getBlogSearchData, getBlogbySlugData } from '../../../../Redux/action/RecruiterAction';
import BlogCategoryDetail from '../BlogCategoryDetail/BlogCategoryDetail';
import { InlineShareButtons } from 'sharethis-reactjs';
const host = process.env.REACT_APP_API_SERVER;
const BlogDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let BlogId
  const location = useLocation();
  const { name } = useParams();
  useEffect(() => {dispatch(getBlogbySlugData(name)); }, [name]);
  const handleSearchChange = (e) => {const jobTitle = e.target.value; dispatch(getBlogSearchData(jobTitle)); };
  const blogIdData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.blogSlugDataList) : []);
  const handleGoBack = () => { navigate(-1)}
  return (
    <>
      <AfterNavbar />
      <section className="blog-list mt-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="blog-head text-center">
              <Link to={-1}>
                <span>
                  <i className="fa-solid fa-arrow-left"></i>Back
                </span>
              </Link>
              <h1>Blog Detail</h1>
            </div>
            <div className="row blog-info pt-5">
              <div className="col-lg-9 col-md-7">
                <div className="blog-card mb-5">
                  <img
                    src={
                      blogIdData?.image ? `${host}${blogIdData?.image}` : ''
                    }
                    className="img-fluid w-100"
                    alt=""
                  />
                  <div className="blog-category mt-3 row mx-auto ">

                    <div className="category d-flex align-items-center col-lg-4 col-md-6 px-0 pt-md-0 pt-2">
                      <img src="/assets/images/blogs/pen.png" className='img-fluid' alt="" />
                      <h5>Karlatoon</h5>
                    </div>
                    <div className="date  d-flex align-items-center col-lg-4 col-md-6 px-0 pt-md-0 pt-2">
                      <img
                        src="/assets/images/blogs/calen.png"
                        className="img-fluid"
                        alt=""
                      />
                      <h5>
                        {new Date(blogIdData?.createdAt).toDateString()}
                      </h5>
                    </div>
                    <div className="share-btn-blog d-flex align-items-center justify-content-md-center pt-lg-0 pt-md-2 pt-2  col-lg-4 col-12 px-0">
                      <InlineShareButtons
                        config={{
                          alignment: 'center',
                          color: 'social',
                          enabled: true,
                          font_size: 12,
                          labels: null,
                          language: 'en',
                          networks: [ 
                            'whatsapp',
                            'linkedin',
                            'facebook',
                            'twitter'
                          ],
                          padding: 10,
                          radius: 4,
                          show_total: false,
                          size: 30,
                          url: `${window.location.href}`,
                          image: `${host}/${blogIdData?.image}`,                          
                          title: `${blogIdData?.title}`,
                          summary: `${blogIdData?.summmary}`,
                          description: `${blogIdData?.description}`,                          
                        }}
                      />
                    </div>
                  </div>
                  <div className="blog-title py-3">
                    <h2>{blogIdData?.title}</h2>
                  </div>
                  {blogIdData?.summmary && (
                    <div className="blog-para">
                      <>{parse(blogIdData?.summmary)}</>{' '}
                    </div>
                  )}
                  {blogIdData?.description ? (
                    <div className='blog-desc-para'>{parse(blogIdData?.description)} </div>
                  ) : (
                    <>

                    </>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-md-5">
                <BlogCategory />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default BlogDetail