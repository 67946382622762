import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'
import Footer from '../Commons/Footer'
import Loader from '../Loader'
import { getNewsByCategorySlug } from '../../../Redux/action/MasterAction'
import NewsListContent from './NewsListContent'
import { toast } from 'react-toastify'
import NewsDetailContent from './NewsDetailContent'
import NewsByCategoryContent from './NewsByCategoryContent'
const NewsByCategory = () => {
    const dispatch = useDispatch();
    const { cat_slug } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (!cat_slug) { toast.error("Please enter correct URL.")
            navigate(-1);
        } else {dispatch(getNewsByCategorySlug(cat_slug));
        }}, [])
    const data = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.newsByCategorySlug : null);
   
    return (
        <>{data ? <>
            < AfterNavbar />
            {data && <NewsByCategoryContent data={data} />}
            <BeforeFooterPlayStore />
            <Footer />
        </> :
            <div className="overlay-form-submission">
                <Loader />
            </div>
        }</>
    )
}

export default NewsByCategory