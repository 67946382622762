import React, { useState, useEffect, useCallback, useRef } from 'react'
import './scss/SignIn.css';
import $ from "jquery";
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';

import SignInPopup from './scss/SignInPopup/SignInPopup';
import Loader from '../Loader';
import MobileLoginPopup from './scss/SignInPopup/MobileLoginPopup';
import { useAuth0 } from "@auth0/auth0-react";
import HourglassLoader from '../HourglassLoader';
import {
   LoginSocialGoogle, LoginSocialLinkedin,
} from 'reactjs-social-login'

import {
   GoogleLoginButton, LinkedInLoginButton,
} from 'react-social-login-buttons'
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import { width } from '@mui/system';

const host = process.env.REACT_APP_API_SERVER
const REDIRECT_URI = 'https://karlatoon.alobhadev.com/signin'
const client_id = process.env.REACT_APP_GG_APP_CLIENT_ID;
const SignInContent = () => {

   const urlParams = new URLSearchParams(window.location.search);
   const queryUri = urlParams.get('q');

   const googleLoginBtnRef = useRef(null);
   const handleCustomGoogleLoginButtonClick = () => {
      googleLoginBtnRef.current.click();
   };

   const { linkedInLogin } = useLinkedIn({
      clientId: '86vhj2q7ukf83q',
      redirectUri: `${window.location.origin}/linkedin`, 
      onSuccess: (code) => {
         console.log(code);
      },
      onError: (error) => {
         console.log(error);
      },
   });

   const [provider, setProvider] = useState('')
   const [profile, setProfile] = useState(null)
   const [lkData, setlkData] = useState(null);

   const onLoginStart = useCallback(() => {

   }, [])

   const onLogoutSuccess = useCallback(() => {
      setProfile(null)
      setProvider('')
      alert('logout success')
   }, [])

   const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

   const [showLoader, setShowLoader] = useState(false);
   const navigate = useNavigate();


   const [email, setEmail] = useState('');
   const [email_mobile, setEmail_mobile] = useState('');
   const [timerStarted, setTimerStarted] = useState(false);
   const [seconds, setSeconds] = useState(30);

   const [disableBtn, setDisableBtn] = useState(false);
   const handleStartTimer = () => {
      setTimerStarted(true);
   };
   useEffect(() => {
      let intervalId;
      if (timerStarted && seconds > 0) {
         intervalId = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
         }, 1000);
      }

      return () => clearInterval(intervalId);
   }, [timerStarted, seconds]);
   const handleEmailChange = (e) => {
      setEmail(e.target.value);
   };



   const [initialValues, setinitialValues] = useState({
email: "", email_mobile: "", password: "",   });





   const formik = useFormik({
      initialValues: initialValues,
      validationSchema: Yup.object({
         email_mobile: Yup.string().required("Please enter email or mobile number"),

      }),
      onSubmit: async (values, { setSubmitting }) => {



         const loadingToastId = toast.info('Submitting form...', { autoClose: false });
         document.body.style.overflow = "hidden"
         setShowLoader(true);
         setDisableBtn(true);
         try {

            setEmail_mobile(values.email_mobile);
            let email_regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
            let mobile_regex = /^[0-9]+$/;
            if (mobile_regex.test(values.email_mobile)) {
               let config = {
                  headers: {
                     'content-type': 'application/json'
                  }
               }
               axios.post(`${host}/mobile/check-deleted-account-emailmobile-web`, values, config)
                  .then((response) => {
                     if (response.status == 200) {
                        if (Number(response?.data?.isdeleted) == 0) {
                           axios.post(`${host}/mobile/seeker-login-email-mobile-web`, values, config)
                              .then((res) => {
                                 if (res.status == 200) {
                                    if (localStorage.getItem("recruiter_token")) {
                                       localStorage.removeItem("recruiter_token")
                                       toast.warn("Recruiter session logged out.")
                                    }
                                    document.body.style.overflow = "auto";
                                    setShowLoader(false);
                                    setDisableBtn(false);
                                    toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `OTP is ${res?.data?.otp}. Please verify.` });
                                 
                                    const modal = document.getElementById('mobile-login-otp');
                                    if (modal) {
                                       modal.classList.add('show');
                                       modal.style.display = 'block';
                                       handleStartTimer();
                                    }
                                 } else {
                                    document.body.style.overflow = "auto";
                                    setShowLoader(false);
                                    setDisableBtn(false);
                                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: res?.data?.msg });
                                 }
                              })
                        } else if (Number(response?.data?.isdeleted == 1)) {
                           const modal = document.getElementById('reActivateAccountModalMobileLogin');
                           toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `Please enter password.` });

                           if (modal) {
                              document.body.style.overflow = "auto";
                              setShowLoader(false);
                              setDisableBtn(false);
                              modal.classList.add('show');
                              modal.style.display = 'block';

                              const yesButton = modal.querySelector('.otp-btn-mobile-login .validate:first-child');
                              const noButton = modal.querySelector('.otp-btn-mobile-login .validate:last-child');

                              if (yesButton && noButton) {
                                 yesButton.addEventListener('click', async function () {
                                    await handleYesButtonClickMobileLogin();
                                 });

                                 noButton.addEventListener('click', function () {
                                    modal.classList.remove('show');
                                    modal.style.display = 'none';
                                 });
                              }
                           }
                        } else {
                           document.body.style.overflow = "auto";
                           setShowLoader(false);
                           setDisableBtn(false);
                           toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal Server Error.' });
                        }
                     } else {
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });

                     }

                  }).catch(error => {
                     console.log(error);
                     document.body.style.overflow = "auto";
                     setShowLoader(false);
                     setDisableBtn(false);
                     toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal Server Error.' });
                  })
            } else if (email_regex.test(values.email_mobile)) {
               let config = { headers: { 'content-type': 'application/json' } };
               axios.post(`${host}/mobile/check-deleted-account-emailmobile-web`, values, config)
                  .then((response) => {
                     // console.log(response);
                     if (response.status == 200) {
                        if (Number(response?.data?.isdeleted) == 0) {
                           document.body.style.overflow = "auto";
                           setShowLoader(false);
                           setDisableBtn(false);
                           toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 2000, render: response.data.msg });
                           setShowLoader(false);
                           if (queryUri) {
                              navigate(`/enter-password?q=${encodeURIComponent(queryUri)}`, { state: { email_mobile: values.email_mobile } })
                           } else {
                              navigate("/enter-password", { state: { email_mobile: values.email_mobile } })
                           }
                        } else if (Number(response?.data?.isdeleted == 1)) {
                           const modal = document.getElementById('reActivateAccountModal');
                           if (modal) {
                              document.body.style.overflow = "auto";
                              setShowLoader(false);
                              setDisableBtn(false);
                              modal.classList.add('show');
                              modal.style.display = 'block';
                              const yesButton = modal.querySelector('.otp-btn .validate:first-child');
                              const noButton = modal.querySelector('.otp-btn .validate:last-child');

                              if (yesButton && noButton) {
                                 yesButton.addEventListener('click', async function () {
                                    await handleYesButtonClick();
                                 });

                                 noButton.addEventListener('click', function () {
                                    modal.classList.remove('show');
                                    modal.style.display = 'none';
                                 });
                              }
                           }
                        } else {
                           document.body.style.overflow = "auto";
                           setShowLoader(false);
                           setDisableBtn(false);
                           toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: 'Internal Server Error.' });
                        }
                     } else {
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: response?.data?.msg });
                     }
                  }).catch(error => {
                     console.log(error);
                     document.body.style.overflow = "auto";
                     setShowLoader(false);
                     setDisableBtn(false);
                     toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: 'Internal Server Error.' });
                  })
            } else {
               document.body.style.overflow = "auto";
               setSubmitting(false);
               setDisableBtn(false);
               setShowLoader(false);
               toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: 'Please enter a valid email address.' });
            }

         } catch (error) {
            console.log(error);
            document.body.style.overflow = "auto";
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: 'Internal Server Error.' });
         }

         async function handleYesButtonClick() {
            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 1000, render: `Please enter password.` });
            navigate("/enter-password", { state: { email_mobile: values.email_mobile } })

         }

         function handleYesButtonClickMobileLogin() {
            let config = {
               headers: {
                  'content-type': 'application/json'
               }
            }

            axios.post(`${host}/mobile/seeker-login-email-mobile-web`, values, config)
               .then((res) => {

                  if (res.status == 200) {
                     document.body.style.overflow = "auto";
                     setShowLoader(false);
                     setDisableBtn(false);
                     toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 1000, render: `OTP is ${res?.data?.otp}. Please verify.` });
                   const thismodal = document.getElementById('reActivateAccountModalMobileLogin');
                     thismodal.classList.remove('show');
                     thismodal.style.display = 'none';
                     const modal = document.getElementById('mobile-login-otp');
                     if (modal) {
                        modal.classList.add('show');
                        modal.style.display = 'block';
                        handleStartTimer();
                     }
                  } else {
                     document.body.style.overflow = "auto";
                     setShowLoader(false);
                     setDisableBtn(false);
                     toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: res?.data?.msg });
                  }
               }).catch(error => {
                  console.log(error.message);
                  document.body.style.overflow = "auto";
                  setShowLoader(false);
                  setDisableBtn(false);
                  toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: `Internal Server Error.` });
               })
         }

         function handleErrorResponse(response) {
            if (response.data.errors && response.data.errors?.length > 0) {
               const errorMessage = response.data.msg;
               setSubmitting(false);
               setDisableBtn(false);
               document.body.style.overflow = 'auto';
               toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: errorMessage });
            } else {
              const errorMessage = response.data.msg;
               console.error('Unexpected error response format:', response.data);
               setSubmitting(false);
               setDisableBtn(false);
               document.body.style.overflow = 'auto';
               toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: errorMessage });
            }
         }

         function handleCatchError(error) {
            console.log("catch:", error);
            document.body.style.overflow = 'auto';
            setSubmitting(false);
            setDisableBtn(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
         }


      }
   })

   if (profile?.email_verified) {


      try {
         const formdata = {
            firstName: profile?.given_name,
            lastName: profile?.family_name,
            email: profile?.email,
         }
         axios.post(`${host}/mobile/seeker-google-login-web`, formdata).
            then((response) => {
               if (response.status == 200) {
                  if (localStorage.getItem("recruiter_token")) {
                     localStorage.removeItem("recruiter_token")
                     toast.warn("Recruiter session logged out.")
                  }
                  localStorage.setItem("seeker_token", response.data.authToken);
                  toast({ type: toast.TYPE.SUCCESS, autoClose: 3000, render: response?.data?.msg });
                  if (response.data.newUser) {
                     navigate('/personal-details')

                  }
                  else {
                     navigate('/seeker-login-profile')
                  }
               }
               else {

                  toast({ type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
               }
            })
      }
      catch (error) {

         toast({ type: toast.TYPE.ERROR, autoClose: 3000, render: "Error loggin In" });

      }
   }


   if (lkData?.access_token) {


      try {
         const value = {

            accessToken: lkData.access_token, 

         }
         axios.post(`${host}/mobile/seeker-linkedIn-login-web`, value).
            then((response) => {
               if (response.status == 200) {if (localStorage.getItem("recruiter_token")) {
                     localStorage.removeItem("recruiter_token")
                     toast.warn("Recruiter session logged out.")
                  }
                  localStorage.setItem("seeker_token", response.data.authToken);
                  toast({ type: toast.TYPE.SUCCESS, autoClose: 3000, render: response?.data?.msg });
                  if (response.data.newUser) {
                     navigate('/personal-details')

                  }
                  else {
                     navigate('/seeker-login-profile')
                  }
               }
               else {

                  toast({ type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
               }
            })
      }
      catch (error) {

         toast({ type: toast.TYPE.ERROR, autoClose: 3000, render: "Error loggin In" });

      }
   }

   return (
      <>

       

         <section className="signin-section-seeker">
            <div className="container">
               <div className="second-section">
                  <div className="row align-items-center">
                     <div className="col-lg-6 col-md-12 col-12 order-lg-1 order-md-1 order-2">
                        <form method='post' onSubmit={formik.handleSubmit}>
                           <div className='text-start mt-custon-1'>
                              <h3>Sign in</h3>
                              <span className="para-3">If you don’t have an account</span>
                              <Link to={encodeURIComponent(queryUri) ? `/create-an-account?q=${encodeURIComponent(queryUri)}` : "/create-an-account"}> <b>Register here !</b></Link>
                     </div>
                     <div>

                        <div className="row">
                           <p className="para">Email/Mobile No.</p>
                           <div className="col-12">
                              <div className='user-envlop-cls'>
                                 <span><img src='././././assets/recruiter-images/singin/Vector1.png' /></span>
                                 <input className="input-text" type="text" name="email_mobile" placeholder="Enter your email address or mobile number"
                                    onChange={(e) => { formik.handleChange(e); handleEmailChange(e); }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email_mobile} />
                              </div>
                           </div>
                           <div className="help-block with-errors">
                              {formik.touched.email_mobile && formik.errors.email_mobile ? (
                                 <div className='text-danger'>{formik.errors.email_mobile}</div>
                              ) : null}
                           </div>
                        </div>

                        <div className="row">
                           <div className="col-12 text-center">
                              <div className='mt-5 login-btn'>
                                 {/* <Link to='/seeker-login-profile'> Login</Link> */}
                                 <button type="submit" className='btn px-5 rounded-pill' style={{ backgroundColor: "#1B375C", color: 'white' }} disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : "Login"}</button>
                              </div>
                           </div>
                        </div>
                        <div className='row'>
                           <div className='col-lg-12 col-md-12 col-12 text-center'>
                              <p className="pt-3">or continue with</p>
                              <ul className='goog-linke-login-btn-ul'>
                                
                                 <li>
                                    <LoginSocialGoogle
                                       client_id={client_id}
                                       scope="openid profile email"
                                       className='signin-goog-login-cls'
                                       onResolve={({ provider, data }) => {
                                          setProvider(provider)
                                          setProfile(data)
                                          // console.log('profile is', profile)

                                       }}
                                       onReject={(err) => {

                                       }}
                                    >
                                       <GoogleLoginButton style={{ width: '46px', borderRadius: '50px' }} text='' />
                                    </LoginSocialGoogle>
                                 </li>
                                 <li><LoginSocialLinkedin
                                    isOnlyGetToken
                                    client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ''}
                                    client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ''}
                                    redirect_uri={REDIRECT_URI}
                                    className='signin-linke-login-cls'
                                    scope='profile email openid'
                                    onResolve={({ provider, data }) => {

                                       setlkData(data)

                                    }}
                                    onReject={(err) => {
                                       console.log(err)
                                    }}
                                 >
                                    <LinkedInLoginButton style={{ width: '46px', borderRadius: '50px' }} text='' />
                                 </LoginSocialLinkedin ></li>
                              </ul>

                           </div>
                        </div>
                     </div>
                  </form>
               </div>
               <div className="col-lg-6 col-md-12 col-12 order-lg-2 order-md-2 order-1">
                  <div>
                     <img
                        src="././././assets/recruiter-images/singin/back-girl.png" width="100%" height="100%"
                        alt="" className='img-fluid' />
                  </div>
               </div>
            </div>
         </div>
      </div >

            <>
               {/* Modal for Re-activating account */}
               <div className="modal fade" id="reActivateAccountModal" tabIndex={-1} aria-labelledby="exampleModalLabel"
                  aria-hidden="true" >
                  <div className="modal-dialog modal-dialog-centered">
                     <div className="modal-content">

                        <div className="modal-body">
                           <div className="container height-100 d-flex justify-content-center align-items-center text-center">
                              <div className="position-relative">

                                 <div className="card-left">
                                    <h6>Do you want to re-activate your account?</h6>


                                    <div className="mt-4 otp-btn">
                                       <button className="btn btn-danger px-4 mx-2 validate">
                                          Yes
                                       </button>
                                       <button className="btn btn-danger px-4 mx-2 validate">
                                          No
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               {/* Modal for reactivating account with mobile login */}
               <div className="modal fade" id="reActivateAccountModalMobileLogin" tabIndex={-1} aria-labelledby="exampleModalLabel"
                  aria-hidden="true" >
                  <div className="modal-dialog modal-dialog-centered">
                     <div className="modal-content">

                        <div className="modal-body">
                           <div className="container height-100 d-flex justify-content-center align-items-center text-center">
                              <div className="position-relative">

                                 <div className="card-left">
                                    <h6>Do you want to re-activate your account?</h6>

                                    <div className="mt-4 otp-btn-mobile-login">
                                       <button className="btn btn-danger px-4 mx-2 validate">
                                          Yes
                                       </button>
                                       <button className="btn btn-danger px-4 mx-2 validate">
                                          No
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               {showLoader && <div className="overlay-form-submission">
                  <Loader />
               </div>}
            </>

            <SignInPopup email={email} timerStarted={timerStarted} setTimerStarted={setTimerStarted} handleStartTimer={handleStartTimer} seconds={seconds} setSeconds={setSeconds} />
            <MobileLoginPopup email_mobile={email_mobile} timerStarted={timerStarted} setTimerStarted={setTimerStarted} handleStartTimer={handleStartTimer} seconds={seconds} setSeconds={setSeconds} />



         </section >
      </>
   )
}
export default SignInContent
