import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './Redux/store'
import { Auth0Provider } from '@auth0/auth0-react';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Auth0Provider domain="dev-dtlxrkgqp1gll8zl.us.auth0.com"clientId="WOi7lYuiuSuEFj3YWd7tEZ2ymxIvHWNg"authorizationParams={{ redirect_uri: "http://localhost:3000"}}><App /></Auth0Provider>
  </Provider>);
reportWebVitals();
