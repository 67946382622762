import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './scss/blog-category.css'
import { getBlogCatData, getBlogCategoryData, getBlogData, getBlogSearchData, getBlogSmallData } from '../../../../Redux/action/RecruiterAction';
const host = process.env.REACT_APP_API_SERVER;
const BlogCategory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {dispatch(getBlogCategoryData()); dispatch(getBlogSmallData());}, []);
    const handleCatClick = (name_slug) => {navigate(`/career-guide-category-new/${name_slug}`)};
    const handleOnClick = (blog_slug) => {
        if (blog_slug !== undefined) {navigate(`/blog-detail/${blog_slug}`)}}
    const blogSmallData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.blogSmallDataList?.data) : []);
    const blogCategoryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.blogCategoryDataList?.data) : []);
    const length = blogCategoryData?.length;
    const midpoint = Math.ceil(length / 2);
    const data1 = blogCategoryData?.slice(0, midpoint);
    const data2 = blogCategoryData?.slice(midpoint);
    return (
        <section className='blog-category'>
            <div className="container">
                <div className="row"> 
                    <div className="recent-post">
                        <h3>Recent Post</h3>
                        <div className="custm-posts">
                            {blogSmallData?.slice(0, 7)?.map((data) => (
                                <div className="post-detail mb-4 d-flex " onClick={() => { handleOnClick(data?.blog_slug) }} style={{ cursor: "pointer" }}>
                                    <img src={data?.image ? `${host}${data?.image}` : ""} className='img-fluid img-small' alt="" />
                                    <div className="post-text">
                                        <h4>{data?.title}</h4>
                                        <h5>{new Date(data?.createdAt).toDateString()}</h5>
                                    </div>
                                </div>))}
                        </div>
                    </div>                   
                </div>
            </div >

        </section >
    )
}

export default BlogCategory