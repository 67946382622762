import React, { useEffect, useState } from 'react'
import RegisterHeader from './CvBuilderHeader/RegisterHeader'
import Footer from '../Commons/Footer'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Steps from './Steps';
import Loader from "../Loader";
import HourglassLoader from '../HourglassLoader';
import { getSkillsData, getSkillProficiencyData, getSeekerKeySkillData } from '../../../Redux/action/SeekerAction';
import Steps2 from './Steps2';
let host = process.env.REACT_APP_API_SERVER

const KeySkills = () => {

    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedId, setEditedId] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);
    const [other, setother] = useState(false);

    useEffect(() => {

        if (localStorage.getItem("seeker_token")) {
            let authToken = localStorage.getItem("seeker_token");
            dispatch(getSeekerKeySkillData(authToken));


        } else {
            toast.error("Please register to update information.")
            navigate("/seeker-register")
        }


        dispatch(getSkillsData());
        dispatch(getSkillProficiencyData());


    }, []);


    const skills_usedData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.skillsDataList?.data) : "")
    const skillProficiencyData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.skillProficiencyDataList?.data) : "");
    const [seekerKeySkillData, setseekerKeySkillData] = useState([]);
    const initialseekerKeySkillData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerKeySkillDataList) : "")



    useEffect(() => {
        if (initialseekerKeySkillData) {
            setseekerKeySkillData(initialseekerKeySkillData);

        }
    }, [initialseekerKeySkillData])


    const [initialValues, setinitialValues] = useState({
        skill_name: "",
        expertise: "",
        other_skill_name: "",
    });

    const config = {
        headers: {

            'content-type': 'application/json',
            "auth-token": localStorage.getItem("seeker_token")
        }
    };


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            skill_name: Yup.string().required("Please select skill name."),
            expertise: Yup.string().required("Please select skill proficiency."),
            other_skill_name: other ? Yup.string().required(" Please enter skill name.") : Yup.string(),

        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const id = editedId;
            setIsSubmitting(true);
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            setDisableBtn(true);
            try {
                setSubmitting(true);
                setShowLoader(true);
                document.body.style.overflow = 'hidden';


                if (isEditing) {

                    axios.post(`${host}/mobile/update-keyskills?id=${id}`, values, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false);
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Key Skills Updated Successfully!' });
                                dispatch(getSeekerKeySkillData(localStorage.getItem("seeker_token")));
                            } else {

                                setSubmitting(false)
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        })
                        .catch(function (error) {

                            setShowLoader(false);
                            document.body.style.overflow = 'auto';
                            setSubmitting(false);
                            setDisableBtn(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Key Skills. Please try again.' });
                        })
                }
                else {
                    axios.post(`${host}/mobile/add-keyskills`, values, config)

                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false)
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Key Skills Added successfully!' });
                                dispatch(getSeekerKeySkillData(localStorage.getItem("seeker_token")));
                            } else {

                                setSubmitting(false)
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        })
                        .catch(function (error) {

                            document.body.style.overflow = 'auto';
                            setSubmitting(false)
                            setShowLoader(false);
                            setDisableBtn(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Key Skills. Please try again.' });
                        })

                }
                triggerModalClose();

            }
            catch (error) {

                document.body.style.overflow = 'auto';
                setSubmitting(false)
                setShowLoader(false);
                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Key Skills. Please try again.' });
            }

        }
    })


    const handleDelete = async (id) => {
        try {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            const response = await axios.get(`${host}/mobile/delete-keyskills?id=${id}`, config);
            if (response.status === 200) {
                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Detail deleted successfully.!' });
                const updateseekerKeySkillData = seekerKeySkillData.filter(item => item.id !== id);

                setseekerKeySkillData(updateseekerKeySkillData);
            } else {

                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
            }
        } catch (error) {
            document.body.style.overflow = 'auto';


        }
    };


    const handleEdit = (id) => {
        const selectedData = seekerKeySkillData.find(item => item?.id === id);

        if (selectedData) {
            formik.setValues({
                skill_name: selectedData?.skill_name ?? "",
                expertise: selectedData?.expertise ?? "",
                other_skill_name: selectedData?.other_skill_name ?? "",
            });

        }
        setIsEditing(true);
        setEditedId(id);
        setother(selectedData?.skill_name === "0");


    };

    const triggerModalClose = () => {
        const closeButton = document.querySelector('.modal.fade .btn-close');
        if (closeButton) {
            closeButton.click();
        }
        setIsSubmitting(false);
    };

    const handleNext = () => {

        if (seekerKeySkillData != "") {
            navigate('/certifications');
        }
        else {
            toast.error("Atleast one detail should be added");
           
        }
    }

    const handleAdd = () => {
        formik.setValues({
            skill_name: "",
            expertise: "",

        });
        setother(false);
    }

    const handleSubmitWithCustomLogic = async (event) => {
        event.preventDefault();
        await formik.handleSubmit(event);
    };

    const otherSkill = (e) => {


        setother(e.target.value == "0");

    }

    return (

        <>
            <RegisterHeader />

            <section className='key-skills'>
                <div className="container">
                    <div className="row">
                       
                        <div className="col-lg-12">
                            <div className="mb-2">
                                <Steps2 activeStepCount={4} />
                            </div>
                        </div>
                        <div className="col-lg-12 my-lg-0 my-md-0 my-2">
                            <div className="form-area">
                                <div className="row align-items-center">
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-12 profile-back-btn">
                                        <div className="back-btn">
                                            <Link to='/project-details'><i className="fa-solid fa-arrow-left mx-2"></i>Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-5 col-6 project-title">
                                        <h1>Key Skills</h1>
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-6 profile-edit-btn">
                                        <div className="">

                                            <button type="button" className="cust-add-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                                onClick={() => {
                                                    setIsEditing(false);
                                                    handleAdd();
                                                }
                                                }
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                 
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead className='table-light'>
                                                <tr>
                                                    <th scope="col">Sr No.</th>
                                                    <th scope="col">Skills</th>
                                                    <th scope="col">Skill Proficiency</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {seekerKeySkillData?.map((keySkill, index) => (
                                                    <tr key={index}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{keySkill?.other_skill_name ? keySkill?.other_skill_name : keySkill?.skill?.skill}</td>
                                                        <td>{keySkill?.expertise_level?.skillproficiency}</td>

                                                        <td className='p-0'>
                                                            <button type='button' className="btn p-0 me-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                                                onClick={() => {
                                                                    handleEdit(keySkill.id);

                                                                }}
                                                            > <img src="./assets/my-profile/Icon-pen.png" alt="" /></button>
                                                            <button type='button' className="btn  p-0 me-1"
                                                                onClick={() => {
                                                                    handleDelete(keySkill.id);

                                                                }
                                                                }> <img src="./assets/my-profile/trush-square.png" alt="" /></button>

                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>

                                        </table>
                                    </div>
                                    {/* )} */}
                                    <div className='custm-botton-btn my-2 d-flex justify-content-center'>
                                        <Link to='/certifications' ><button className='skip me-2' type='button' >Skip </button></Link>
                                        <button className='next' type='button' onClick={handleNext} >Submit and Next </button>

                                    </div>

                                    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <div className="col-lg-12 col-12 d-flex justify-content-center position-relative">
                                                        <h1 className='edu-title text-center fw-bold'>{isEditing ? "Update" : "Add"} Key Skills</h1>
                                                        <button type="button" className="btn-close position-absolute end-0" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>

                                                </div>

                                                <div className="modal-body">
                                                    <form method='post' onSubmit={handleSubmitWithCustomLogic} className='mt-5'>
                                                        <div className="row">
                                                            <div className="col-md-6 mb-3">
                                                                <label htmlFor="" className='mb-2'>Skill Name<span className='text-danger'>*</span></label>
                                                                <select name="skill_name" className='form-control form-select' id=""
                                                                    onChange={(e) => {
                                                                        otherSkill(e);
                                                                        formik.handleChange(e)
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.skill_name}
                                                                >
                                                                    <option>--Select an option--</option>
                                                                    {skills_usedData?.map(skill => {
                                                                        let key = skill?.id
                                                                        return <option key={key} value={skill?.id}>{skill?.skill}</option>
                                                                    })}
                                                                    <option value="0">Other</option>
                                                                </select>
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.skill_name && formik.errors.skill_name ? (
                                                                        <div className='text-danger'>{formik.errors.skill_name}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            {other &&
                                                                <div className="col-md-6 mb-3 d-grid">
                                                                    <label htmlFor="" className='mb-2'>Other Skill<span>*</span></label>
                                                                    <input type="text" name='other_skill_name' className='form-control' placeholder='Enter Other Skill Name'
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        value={formik.values.other_skill_name} />
                                                                    <div className="help-block with-errors">
                                                                        {formik.touched.other_skill_name && formik.errors.other_skill_name ? (
                                                                            <div className='text-danger'>{formik.errors.other_skill_name}</div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>}
                                                            <div className="col-md-6 mb-3">
                                                                <label htmlFor="" className='mb-2'>Skill Proficiency<span className='text-danger'>*</span></label>
                                                                <select name="expertise" className='form-control form-select' id=""
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.expertise}>
                                                                    <option

                                                                    >--Select an option--</option>
                                                                    {skillProficiencyData?.map(proficiency => {
                                                                        let key = proficiency?.id
                                                                        return <option key={key} value={proficiency?.id}>{proficiency?.skillproficiency}</option>
                                                                    })}
                                                                </select>
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.expertise && formik.errors.expertise ? (
                                                                        <div className='text-danger'>{formik.errors.expertise}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>


                                                            <div className="col-md-12 mt-5 mb-5 custm-btn-sec d-flex justify-content-center">

                                                                <div className='custm-botton-btn mb-2'>
                                                                    <button type='submit' className='next mx-2' disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : isEditing ? "Update and Submit" : "Submit"}</button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                
            </section >

            <Footer />

        </>

    )
}

export default KeySkills