import React, { useState } from 'react'
// import './scss/BuildYourResume.css'
import BuildResumeBannner from './BuildResumeBannner'
import BuildResume3Step from './BuildResume3Step'
import BuildResumeSnapShotEditor from './BuildResumeSnapShotEditor'
import BuildResumeTemplet from './BuildResumeTemplet'
import BuildResumeAboutUs from './BuildResumeAboutUs'
import BuildResumeBenefits from './BuildResumeBenefits'
import BuildResumeTestimonials from './BuildResumeTestimonials'
import Loader from '../Loader';
const BuildYourResumeContent = () => {
  let [loader, setLoader] = useState(false);
  let show = () => {
    setLoader(true);
  }
  let hide = () => {
    setLoader(false);
  }
  return (
    <>{
      loader ?
        <div className='overlay-form-submission'>
          <Loader />
        </div>
        : <>
          <BuildResumeBannner showLoader={show} hideLoader={hide}/>
          <BuildResume3Step />
          <BuildResumeSnapShotEditor />
          <BuildResumeTemplet />
          <BuildResumeAboutUs />
          <BuildResumeBenefits />
          <BuildResumeTestimonials />
        </>}
    </>
  )
}

export default BuildYourResumeContent