import { useEffect, useState } from 'react'
import React from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../../Commons/Footer'
import LicenseContent from './LicenseContent'
import RecAfterLoginNavbar from '../../../Recruiter/Commons/AfterLogin/RecAfterLoginNavbar'
import Header from '../../Commons/Header'
const License = () => {
  const [showSeek, setShowSeek] = useState(false)
  useEffect(() => {
    if (localStorage.getItem("recruiter_token")) {
      setShowSeek(false)

    } else {
      setShowSeek(true)
    }
  }, []);
  return (
    <>
   
      {localStorage.getItem("seeker_token") && <AfterNavbar />}
      {localStorage.getItem("recruiter_token") && <RecAfterLoginNavbar />}
      { (!localStorage.getItem("seeker_token")) && (!localStorage.getItem("recruiter_token")) && <Header />}
      <LicenseContent />
      <Footer />
    </>
  )
}

export default License