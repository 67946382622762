import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import './scss/Questions.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Loader from '../../Loader';
import Error from '../../../Common/Error/Error';
import { ansDataApi } from '../../../../Redux/action/SeekerAction';
import { getQuestionType } from '../../../../Redux/action/MasterAction';
const host = process.env.REACT_APP_API_SERVER;

const Answers = ({ company_name }) => {
    const location = useLocation();
    const questionID = location.state || {};
    const { qu_slug, name } = useParams();
    const [votes, setVotes] = useState({});

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        ANS_DataError,
        ANS_Data,
        loadingANSData,
    } = useSelector(state => state?.SeekerReducer);
    const {
        QuestionType
    } = useSelector(state => state?.MasterReducer);
    useEffect(() => {
       
        dispatch(ansDataApi(name, qu_slug, localStorage.getItem("seeker_token")));
        dispatch(getQuestionType(localStorage.getItem("seeker_token")));
    }, []);
    const formik = useFormik({
        initialValues: {
            answer: '',
            company_slug: name,
            question_id: questionID?.questionID
        },
        validationSchema: Yup.object({
            answer: Yup.string()
                .max(500, 'Must be 500 characters or less')
                .required('Please enter answer.'),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
           
            if (!localStorage.getItem("seeker_token")) {
                let loginModalAnswersBtn = document.getElementById("loginModalAnswersBtn");
                if (loginModalAnswersBtn) {
                    loginModalAnswersBtn.click();
                }
            } else {
                try {
                    const response = await axios.post(
                        `${host}/web/recruiter-question-page-answer-question?company_slug=${name}`,
                        values,
                        {
                            headers: { "auth-token": localStorage.getItem("seeker_token") },
                        }
                    );
                    if (response.status === 200) {
                        if (response.data?.data.active === true) {
                            toast.success("Answer added successfully.");
                            dispatch(ansDataApi(name, qu_slug, localStorage.getItem("seeker_token")));
                        } else {
                            toast.warn(response.data?.msg);
                        }
                    } else {
                        toast.error(response.data?.msg);
                    }
                } catch (error) {
                    console.error('Error submitting form', error.message);
                    toast.error("Internal server error");
                }
                setSubmitting(false);
                resetForm();
            }

        },
    });
    const handleVote = async (answerId, vote, questionId) => {
        if (!localStorage.getItem("seeker_token")) {
            let loginModalAnswersBtn = document.getElementById("loginModalAnswersBtn");
            if (loginModalAnswersBtn) {
                loginModalAnswersBtn.click();
            }
        } else {
            try {
                const response = await axios.post(`${host}/web/recruiter-question-page-vote-answer`, {
                    answer_id: answerId,
                    vote: vote,
                    question_id: questionId,

                }, {
                    headers: { "auth-token": localStorage.getItem("seeker_token") },
                });

                if (response.status === 200) {
                    setVotes((prevVotes) => ({
                        ...prevVotes,
                        [answerId]: vote,
                    }));
                    dispatch(ansDataApi(name, qu_slug, localStorage.getItem("seeker_token")));
                }
            } catch (error) {
                console.error('Error voting:', error);
            }
        }

    };

    if (loadingANSData) {
        return (
            <div className="overlay-form-submission">
                <Loader />
            </div>
        );
    }

    if (ANS_DataError) {
        return <Error message={ANS_DataError} />;
    }

   
    const urlString = window.location.href;
    const url1 = new URL(urlString);
    const path = url1.pathname.slice(1);
    const searchParams = url1.search;
    const q = path + searchParams;
    const handleLoginRedirect = () => {
        let loginPopUpModalAnswersCloseBtn = document.getElementById("loginPopUpModalAnswersCloseBtn");
        if (!localStorage.getItem("seeker_token")) {
            if (loginPopUpModalAnswersCloseBtn) {
                loginPopUpModalAnswersCloseBtn.click();
                navigate(`/signin?q=${encodeURIComponent(q)}`);
            }
        } else {
            if (loginPopUpModalAnswersCloseBtn) {
                loginPopUpModalAnswersCloseBtn.click();
            }
        }
    }

    return (
        <>
            <div className=" Answers-section ">
                <div className="d-sm-flex  justify-content-between pb-4">
                    <div className=" ">
                        <Link onClick={() => {
                            navigate(-1)
                        }} className='text-decoration-none font-semibold'>
                            <KeyboardArrowLeftIcon />  View all {ANS_Data?.data?.allQuestionsLength} questions about {company_name}
                        </Link>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row justify-content-between align-items-start">
                        <div className='col-lg-12'>
                            <h4 className='mt-3 mb-1' style={{ textAlign: "justify" }}>{ANS_Data?.data?.answers?.question} ?</h4>
                            <span>{ANS_Data?.createdAt}</span>
                        </div>


                    </div>

                </div>
                <div className="row justify-content-lg-between ">
                    <div className="col-lg-8  ">
                        <div className="my-4">
                            {ANS_Data?.data?.answers?.recruiter_question_answer?.map((value, index) => {
                                return (
                                    <div className=" col-12  feedback border-bottom pb-4 mb-4">
                                      
                                        <p className='my-2'>{value?.answer}</p>
                                        <div className="radio-group">
                                            <input
                                                type="radio"
                                                id={`radio-upvote-${index}`}
                                                name={`rating-${index}`}
                                                value="UPVOTE"
                                                checked={votes[value?.id] === 'UPVOTE'}
                                                onChange={() => handleVote(value?.id, 'UPVOTE', value?.question_id)}
                                            />
                                            <label htmlFor={`radio-upvote-${index}`} className='w-fit'>{value?.upvoteCount} Upvoted</label>

                                            <input
                                                type="radio"
                                                id={`radio-downvote-${index}`}
                                                name={`rating-${index}`}
                                                value="DOWNVOTE"
                                                checked={votes[value?.id] === 'DOWNVOTE'}
                                                onChange={() => handleVote(value?.id, 'DOWNVOTE', value?.question_id)}
                                            />
                                            <label className='w-fit' htmlFor={`radio-downvote-${index}`}>{value?.downvoteCount} Downvote</label>
                                        </div>

                                    </div>
                                )
                            })}
                        </div>

                        <div className="box-rounded mt-5 mb-3">
                            <div className="help-full-ans ">
                                <form onSubmit={formik.handleSubmit}>
                                    <label className='font-semibold'>
                                        Add an answer
                                    </label>
                                    <div className="d-sm-flex justify-content-between align-items-end mb-2">
                                        <ul className='list-style-none ps-0 mb-0 mt-2'>
                                            <li>
                                                <p className="mb-0">
                                                    Submit the first answer! Help jobseekers learn about the company by being objective and to the point.
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <textarea
                                        className={`form-control mb-3 ${formik.touched.answer && formik.errors.answer ? 'is-invalid' : ''}`}
                                        id="exampleFormControlTextarea1"
                                        rows={3}
                                        {...formik.getFieldProps('answer')}
                                    />
                                    {formik.touched.answer && formik.errors.answer ? (
                                        <div className="text-danger">{formik.errors.answer}</div>
                                    ) : null}
                                    <div className="useful-review my-3">
                                        <span>Help jobseekers learn about the company by being objective and to the point.
                                            Your answer will be posted publicly. Please don't submit any personal information.</span>
                                    </div>
                                    <button type="submit" className="submit-btn" disabled={formik.isSubmitting}>
                                        Submit Answer
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-xl-3">
                        <div className="box-rounded mb-3">
                            <h4 className="mb-3">
                                Questions about {company_name}
                            </h4>
                            {
                                ANS_Data?.data?.fiveExtraQuestions.map((value, index) => {
                                    return (

                                        <div key={index} onClick={() => {
                                            navigate(`/profile/${name}/Answer/${value?.slug}/`, { state: { questionID: value?.id } });
                                            dispatch(ansDataApi(name, value?.slug, localStorage.getItem("seeker_token")));
                                        }} className='text-decoration-none cursor-pointer mb-3'>
                                            <p className=' color-light mb-0'>{value?.question}</p>
                                            <p>{value?.activeAnswerCount} people answered</p>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <div className="box-rounded mb-3">
                            <h4 className="mb-3">
                                Questions about {company_name}
                            </h4>
                            <div className="d-flex gap-3 flex-wrap review-box-container mt-3 mb-5">
                                {QuestionType?.data?.map((data, index) => {
                                    return (
                                        <div className="review-box cursor-pointer">{data?.question_type}</div>
                                    )
                                })}

                            </div>

                        </div>


                    </div>
                </div>

                <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#loginPopUpModalAnswers" id="loginModalAnswersBtn">
                    Launch demo modal
                </button>
                <div className="modal fade" id="loginPopUpModalAnswers" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-center">
                                <h5 className="modal-title" id="exampleModalLabel"><img src="/assets/images/logo.png" /></h5>
                                <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" id="loginPopUpModalAnswersCloseBtn"></button>
                            </div>
                            <div className="modal-body">
                                <div className="card-body p-3">
                                    <h3 className="text-center" style={{
                                        fontSize: '20px !important',
                                        marginBottom: '20px',
                                    }}>
                                     
                                        Ready to take next step ?
                                    </h3>
                                    <p className="text-center" style={{ fontSize: "15px" }}>Sign in to Karlatoon.</p>
                                    <p className="text-muted text-justify">
                                        <small>
                                            You may also apply directly on the company's website. By
                                            creating an account or signing in, you understand and agree to
                                            Karlatoon's <Link to="/terms-conditions" target='_blank'>Terms <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You also consent to our{" "}
                                            <Link to="/privacy-policy" target='_blank'>Privacy policies <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You will receive
                                            marketing messages from Karlatoon and may opt out at any time by
                                            following the unsubscribe link in our messages, or as detailed in
                                            our terms.
                                        </small>
                                    </p>

                                    <div className="text-center mb-3"></div>
                                    <div className="d-grid gap-2 mb-3 justify-content-center">
                                        <button className="btn btn-outline-secondary popup-login-btn" type="button" onClick={handleLoginRedirect}
                                            style={{
                                                padding: "5px 20px",
                                                borderRadius: "40px",
                                                background: "#1B375C",
                                                textDecoration: "none",
                                                color: "#FFF",
                                                textAlign: "center",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                border: "1px solid #1B375C",
                                                width: "fit-content"
                                            }}
                                        >
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default Answers