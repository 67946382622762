import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFeaturesData } from '../../../Redux/action/SeekerAction';
const host = process.env.REACT_APP_API_SERVER;
const AddBudget = () => {
    const dispatch = useDispatch();useEffect(() => { dispatch(getFeaturesData());}, []);
    const featuresSeekerData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.featuresDataList?.data?.[1]?.featureseeker?.[0]) : "");
    const thirdTitle = featuresSeekerData?.section_three_heading ? featuresSeekerData?.section_three_heading : '';
    const thirdSectionCardData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.featuresDataList?.data?.[1]?.seeker_section_three_card) : "");
    return (
        <section className='add-budget py-5'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="head-area mb-5 text-center">
                        <h2>{thirdTitle ? thirdTitle : "Add a Budget for Faster Matches"}</h2>
                    </div>
                    {thirdSectionCardData?.map((card, index) => (
                        <React.Fragment key={index}>
                            {index % 2 === 0 ? (
                                <>
                                    <div className="col-lg-6 col-md-6 mb-5">
                                        <div className="custm-bud-text">
                                            <h4>{card.title ? card.title : "Add a budget for faster matches"}</h4>
                                            <p className='custom-scroll'>{card.description ? card.description : "When you sponsor your job, you start seeing candidates who match your job description immediately. You stay in control of your budget by deciding which applications are qualified and if you want to keep them. When you see a good match, invite them to apply!"}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 mb-5">
                                        <div className="custm-bud-img text-center d-flex justify-content-md-end justify-content-center">
                                            <img src={(card.img_file) ? `${host}${card.img_file}` : "./assets/images/matching-hiring/bud1.png"} className='img-fluid' alt={card.title} />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-lg-6 col-md-6 mb-5">
                                        <div className="custm-bud-img text-center">
                                            <img src={(card.img_file) ? `${host}${card.img_file}` : "./assets/images/matching-hiring/bud2.png"} className='img-fluid' alt={card.title} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 mb-5">
                                        <div className="custm-bud-text">
                                            <h4>{card.title ? card.title : "Sponsored Jobs get better visibility."}</h4>
                                            <p className='custom-scroll'>{card.description ? card.description : "Receive higher visibility for relevant search results longer when you sponsor your job. With Sponsored Jobs, you only pay for the candidates who meet your job criteria with pay per application pricing."}</p>
                                        </div>
                                    </div>
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default AddBudget;
