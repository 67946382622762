import React, { useEffect, useState } from 'react'

import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import HourglassLoader from '../../HourglassLoader';
import './scss/CompanyTopNavbar.css'
import RateAndReview from '../../RateAndReview/RateAndReview';
let host = process.env.REACT_APP_API_SERVER
const CompanyTopNavbar = ({ id, logo, company }) => {
  const company_id = id;
  const recruiterRegisterData = logo
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [follow, setFollow] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("seeker_token")) {setIsLoggedIn(true); let authToken = localStorage.getItem("seeker_token");
    } else {
      toast.error("Please login to continue.")
      navigate(-1);
    }

  }, []);
  useEffect(() => {
    if (company && company?.followCompany == true) {setFollow(false);}
  }, [company])
  const config = {
    headers: {
      'content-type': 'application/json',
      "auth-token": localStorage.getItem("seeker_token")
    }
  };
  const handleClick = () => {
    const value = {
      'company_slug': company_id
    }
    axios.post(`${host}/mobile/follow-unfollow-company-by-slug`, value, config)
      .then(function (response) {
        if (response.status == 200) {
          setShowLoader(false);setDisableBtn(false);
          document.body.style.overflow = 'auto';toast.success(response.data.msg);
          if (response.data.msg === 'Company followed successfully.') {setFollow(false)}
          if (response.data.msg === "Company unfollowed successfully.") {setFollow(true)}
        } else {setShowLoader(false);setDisableBtn(false);document.body.style.overflow = 'auto';toast.error(response.data.msg);
        }
      })
      .catch(function (error) {document.body.style.overflow = 'auto';setShowLoader(false);setDisableBtn(false);toast.error('Error submitting form. Please try again.');
      })

  }

  return (
    <section className='company-top-navbar'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6 col-md-6 col-4'>
            <div className='menu-left-logo'>
              <img src={recruiterRegisterData ? `${host}/uploaded-files/${recruiterRegisterData}` : ""} style={{ width: "50px" }} className='img-fluid' />
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-8'>
            <div className='menu-right-content text-end'>
              <div className='write-review'>
                <a className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Write a review</a>
              </div>
              <div className='follow-btn'>
                <a className='text-dark' style={{ cursor: "pointer" }} onClick={handleClick}>{follow ? "+ Follow" : "Unfollow"}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RateAndReview id={company_id} company={company}/>
    </section>
  )
}

export default CompanyTopNavbar