import React from 'react'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
const host= process.env.REACT_APP_API_SERVER;


const SnapShot = (data) => {

    const cdata = data?.data


    return (
        <>
            <div className="row">
                <div className="col-12 pb-4 pt-2">
                    <span>
                        {cdata?.company_name} Careers and Employment
                    </span>
                </div>
                <div className="col-12 pb-4">
                    <h2>About the company</h2>
                </div>

                <div className="row mb-4">
                    {cdata?.ceo_image && <div div className="col-lg-3 col-md-5">
                        <img src={`${host}/uploaded-files/${cdata?.ceo_image}`} className='w-100' alt="CEO" />
                    </div>
                    }
                    <div className="col-lg-9 col-md-7">
                        <div className="about-box">
                            <div className="companyInfo-box d-flex ">
                                <h5 className='mb-0'>CEO</h5>
                                <div>

                                    <p className='mb-0'>{cdata?.company_ceo}</p>
                                </div>
                            </div>
                            <div className="companyInfo-box d-flex ">
                                <h5 className='mb-0'>Founded</h5>
                                <div>

                                    <p className='mb-0'>{cdata?.founded}</p>
                                </div>
                            </div>
                            <div className="companyInfo-box d-flex ">
                                <h5 className='mb-0'>Company size</h5>
                                <div>
                                    <span>more than</span>
                                    <p className='mb-0'>{cdata?.company_size?.companysize}</p>
                                </div>
                            </div>
                            <div className="companyInfo-box d-flex ">
                                <h5 className='mb-0'>Revenue</h5>
                                <div>

                                    <p className='mb-0'>{cdata?.net_worth}</p>
                                </div>
                            </div>
                            <div className="companyInfo-box d-flex ">
                                <h5 className='mb-0'>Industry</h5>
                                <div>

                                    <p className='mb-0'>{cdata?.industry_details?.industry}</p>
                                </div>
                            </div>
                            <div className="companyInfo-box d-flex ">
                                <h5 className='mb-0'>Headquarters</h5>
                                <div>

                                    <p className='mb-0'>{`${cdata?.hq_location_data?.city_name}, ${cdata?.hq_location_data?.state?.state_name}, ${cdata?.hq_location_data?.state?.country?.name}`}</p>
                                </div>
                            </div>
                            <div className="companyInfo-box d-flex ">
                                <h5 className='mb-0'>Link</h5>
                                <a href={cdata?.website} target='blank'>{cdata?.website}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-start">
                    <div className="col-lg-12 about-company-div-snap-shot">
                        {cdata?.about ? parse(cdata?.about) : ''}
                        <Link to={`/profile/${cdata?.company_slug}/why-join-us`} className='font-semibold '>
                            Learn more<ChevronRightRoundedIcon />
                        </Link>
                    </div>
                </div>

            </div >
        </>
    )
}

export default SnapShot