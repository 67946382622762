import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { getJobStatusData, getJobStatusParamsData, getJobStatusBySearchData } from '../../../Redux/action/RecruiterAction';
import { useDispatch, useSelector } from 'react-redux';
import './scss/JobsStatus.css'
import './scss/ResponsiveJobsStatus.css'
import Loader from '../../Seeker/Loader';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
const host = process.env.REACT_APP_API_SERVER;
const JobsStatusContent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let authToken = localStorage.getItem("recruiter_token");
    useEffect(() => {
        if (!authToken) {
            toast.error("Please login to continue.");
            navigate(-1)
        } else {
            dispatch(getJobStatusData(authToken));
        }
    }, []);

    const jobStatusData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.jobStatusDataList?.data) : "")

    const openCount = jobStatusData?.filter(job => job.current_status === 'ACTIVE')?.length;
    const pausedCount = jobStatusData?.filter(job => job.current_status === 'PAUSED')?.length;
    const closedCount = jobStatusData?.filter(job => job.current_status === 'CLOSED')?.length;
    const [filter, setFilter] = useState('active');
    const [showPagination, setShowPagination] = useState(false);
    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };

    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 5;

    const filteredData = jobStatusData?.filter(job => {
        switch (filter) {
            case 'active':
                return job?.current_status === 'ACTIVE';
            case 'paused':
                return job?.current_status === 'PAUSED';
            case 'closed':
                return job?.current_status === 'CLOSED';
            default:
                return true;
        }
    });
    useEffect(() => {
        const endOffset = dataOffset + dataPerPage;
        setCurrentData(filteredData?.slice(dataOffset, endOffset));
        setPageCount(Math.ceil(filteredData?.length / dataPerPage));
        if (pageCount > 1) {
            setShowPagination(true);
        }
    }, [filter, jobStatusData, dataOffset, dataPerPage, filteredData]);
    const handlePageClick = (event) => {
        window.scroll(0, 0);
        const newOffset = (event.selected * dataPerPage) % filteredData?.length;
        setDataOffset(newOffset);
    };


    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';

        }
        return text;
    };



    const handleStatusChange = async (e, jobId) => {
        const currentStatus = e.target.value;
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {

            const value = {
                'job_id': jobId,
                'current_status': currentStatus
            }
            const config = {
                headers: {

                    "auth-token": authToken
                }
            };

            document.body.style.overflow = 'hidden';
            await axios.post(`${host}/recruiter/change-job-status`, value, config)
                .then(function (response) {
                    if (response.status == 200) {

                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Job Status Updated Successfully!' });
                        dispatch(getJobStatusData(localStorage.getItem("recruiter_token")));

                    } else {

                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                    }
                })
                .catch(function (error) {

                    document.body.style.overflow = 'auto';

                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Job Status Form. Please try again.' });
                })
        }
        catch (error) {

            document.body.style.overflow = 'auto';

            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Professional Details Form. Please try again.' });
        }
    }

    const handleClick = (jobId, ji) => {
        if (jobId !== undefined) {
            navigate(`/rec-job-details-page?jobId=${jobId}&ji=${ji}`)
        }
    }

    const handleSortChange = (e) => {

        const sortOption = e.target.value;

        dispatch(getJobStatusParamsData(authToken, sortOption));
    };

    const handleSearchChange = (e) => {

        const jobTitle = e.target.value;

        dispatch(getJobStatusBySearchData(authToken, jobTitle));
    };

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        const formattedDate = `${day}-${months[monthIndex]}-${year}`;

        return formattedDate;
    }


    return (
        <>
            <section className='job-status-content-section'>
                <div className='container'>
                    <div className='candidate-pro-section-inner-dv'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-12'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6 col-md-6 col-6'>
                                        <div className='condidate-pro-heading'>
                                            <h2>Jobs Status</h2>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-6 text-lg-end text-md-end text-end'>
                                        <div className='candidate-pro-btn'>
                                            <Link to='/like-to-post-job' className='post-btn'>Post A Job</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='row align-items-center'>
                                    <div className='col-xl-4 col-lg-6 col-md-12 col-12'>
                                        <div className='open-closed-btn mt-2 pt-2'>
                                            <a href='#' className={`a-opn-cls-btn ${filter === 'active' ? 'active' : ''} `} onClick={() => handleFilterChange('active')}>
                                                Open ({openCount ?? 0})
                                            </a>
                                            <a href='#' className={`a-opn-cls-btn ${filter === 'paused' ? 'active' : ''}`} onClick={() => handleFilterChange('paused')} >
                                                Paused ({pausedCount ?? 0})
                                            </a>
                                            <a href='#' className={`a-opn-cls-btn ${filter === 'closed' ? 'active' : ''}`} onClick={() => handleFilterChange('closed')} style={{ marginRight: 0 }}>
                                                Closed ({closedCount ?? 0})
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-xl-8 col-lg-6 col-md-12 col-12'>
                                        <div className='right-side-search-filter-btns'>
                                            <div className='inpt-search margin-rgt-lft'>
                                                <input type='text' placeholder='Search' onChange={handleSearchChange} />
                                                <div className='search-icon'>
                                                    <a href='#'><img src='./assets/recruiter-images/job-status/search.png' className='img-fluid' /></a>
                                                </div>
                                            </div>

                                            <div className="dropdown margin-rgt-lft " id='sort'>
                                                <img src='./assets/recruiter-images/job-status/calendar.png' className='' />
                                                <select onChange={handleSortChange} className='border-0 text-dark'>
                                                    <option >Sort by</option>
                                                    <option value="ASC">Asc</option>
                                                    <option value="DESC">Desc</option>
                                                    <option value="id">Id</option>

                                                </select>
                                            </div>

                                        </div >
                                    </div >
                                </div >
                                {(currentData && currentData?.length > 0) ? currentData?.map((job) => {
                                    return <div key={job.id} className='postwise-active-inactive-card-parent'>
                                        <div className='row align-items-center  py-2'>
                                            <div className='col-xl-4 col-md-4 col-12 mt-lg-0 mt-md-0 '>

                                                <div className=' text-dark make-curosr-pointer' onClick={() => handleClick(job?.job_slug, job?.id)} title='View Job'>
                                                    <div className='rght-post-des'>
                                                        <span className='make-curosr-pointer d-lg-flex d-md-block d-flex justify-content-between align-items-center'>
                                                            <h5 style={{ fontWeight: "bold" }}>{job?.job_title}</h5>
                                                            <span className='small-font mt-lg-0 mt-md-2 mt-0'><i className="fa fa-calendar" aria-hidden="true"></i> {formatDate(job?.createdAt)}</span>
                                                        </span>
                                                        <h4><span className='post-a-tag'>{job?.Company_details?.company_name}</span> <span href='#' className='post-a-tag'>{job?.job_type_list?.[0]?.job_type_detail?.jobtypename}</span></h4>

                                                        <div className='post-description-para'>
                                                            {job?.description && parse(`${truncateText(job?.description, 20)}`)}

                                                        </div>

                                                    </div >
                                                </div >


                                            </div >
                                            <div className='col-xl-8 col-md-8 col-12 mt-lg-0 mt-md-0 mt-2'>
                                                <div className="row align-items-center">
                                                    <div className="col-xl-9">

                                                        <div className='post-job-four-small-card'>
                                                            <div className='count-card' title='Total no. of applicants applied on this job.'>
                                                                <p>Applied</p>
                                                                <p>{job?.applyJobCount}</p>
                                                            </div>
                                                            <div className='count-card' title='No. of applicants put on reviewed.'>
                                                                <p>Reviwed</p>
                                                                <p>{job?.reviewedJobCount}</p>
                                                            </div>
                                                            <div className='count-card' title='No. of applicants rejected.'>
                                                                <p>Rejected</p>
                                                                <p>{job?.rejectedJobCount}</p>
                                                            </div>
                                                            <div className='count-card' title='No. of applicants hired.'>
                                                                <p>Hired</p>
                                                                <p>{job?.hiredJobCount} of {job?.no_of_people_to_hire ?? "0"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-xl-3  col-12 my-lg-3 my-md-3 my-2'>
                                                        <div className='post-description-start-set'>
                                                            <div className='post-select-dropdown'>
                                                                <div className="dropdown">

                                                                    {job?.isJobCompleted ? filter != "closed" ? <select type="button" onChange={(e) => handleStatusChange(e, job.id)}
                                                                        className={`pe-2 custom-select ${job.current_status === 'ACTIVE' ? 'select-active' :
                                                                            job.current_status === 'CLOSED' ? 'select-closed' :
                                                                                job.current_status === 'PAUSED' ? 'select-paused' : ''
                                                                            }`}>
                                                                        <option value="" className='text-dark fw-bold'>Select Status</option>
                                                                        <option value="ACTIVE" selected={job.current_status === 'ACTIVE'} style={{ color: '#32A071' }}>ACTIVE</option>
                                                                        <option value="PAUSED" selected={job.current_status === 'PAUSED'} style={{ color: '#d15107' }}>PAUSED</option>
                                                                       


                                                                    </select>
                                                                        :
                                                                        <span className='text-danger'><i>This job is closed.</i></span>
                                                                        :
                                                                        <span><i>This job is not posted yet.</i></span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div >
                                    </div >
                                }) :
                                    <div className='postwise-active-inactive-card-parent'>
                                        <div className='row align-items-center py-2'>
                                            <div className='col-xl-7 col-md-7 col-12 mt-lg-0 mt-md-3 '>
                                                <i>No data available</i>
                                            </div>
                                        </div>
                                    </div>
                                }


                            </div >

                        </ div >
                        {filteredData && filteredData?.length > 5 && <div className='row justify-content-center'>
                            <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                <section className='pagination-section'>
                                    <div className='container'>
                                        <nav aria-label="Page navigation example" className='nav-class'>
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=">>"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={pageCount}
                                                previousLabel="<<"
                                                renderOnZeroPageCount={null}
                                                containerClassName='pagination'
                                                pageLinkClassName='page-num'
                                                previousLinkClassName='page-num'
                                                nextLinkClassName='page-num'
                                                activeClassName='active'
                                            />
                                        </nav>
                                    </div>
                                </section>

                            </div>
                        </div>}
                    </div >

                </div >

            </section >
        </>
    )
}

export default JobsStatusContent