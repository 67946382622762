import React, { useEffect, useState } from 'react'
import './scss/SearchResumes.css'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { useFormik } from 'formik'
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { getCitiesData } from '../../../Redux/action/MasterAction';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER

const SearchResumes = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cities, setCities] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    const initialValues = {
        search_text: "",
        city_text: ""
    };
    useEffect(() => {
        dispatch(getCitiesData());
    }, [])

    const cityData = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.cityList : null);
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({}),
        onSubmit: (values) => {
            function removeEmptyStringValues(obj) {
                for (const key in obj) {
                    if (obj[key] === '') {
                        delete obj[key];
                    }
                }
                return obj;
            }
            const objectWithoutEmptyStrings = removeEmptyStringValues(values);
            let create_search_string = ""
            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                    create_search_string = create_search_string + key + "=" + objectWithoutEmptyStrings[key] + "&";
                }
            };
            axios.get(`${host}/recruiter/can-search-candidate-resume`, { headers: { "auth-token": localStorage.getItem("recruiter_token") } })
                .then(response => {
                    if (response.status == 200) {
                        // if(response)
                        if (response?.data?.data == true) {
                            navigate(`/searched-resumes?${create_search_string}`);
                        } else {
                            toast.warn("Please purchase a subscription to continue.");
                            navigate(`/choose-subscription-plan`)
                        }
                    } else {
                        toast.error(response?.data?.msg);

                    }
                }).catch(error => {
                    console.log(error.message);
                    toast.error("Internal server error")
                })
        }
    })

    useEffect(() => {
        if (cityData) {
            setCities(cityData);
            setFilteredCities(cityData);
        }
    }, [cityData])

    const handleInputChange = event => {
        const inputValue = event.target.value.toLowerCase();
        formik.handleChange(event);

        if (inputValue === '' || inputValue?.length === 1) {
            setFilteredCities(cities);
        } else {
            const filtered = cities.filter(city =>
                city.city_name.toLowerCase().includes(inputValue)
            );
            setFilteredCities(filtered);
        }
        console.log("filteredCities", filteredCities);
    };

    return (
        <>
            <section className='search-resume-sec-parent'>
                <section className="search-resumes-section">
                    <div className="container">
                        <div className="search-resumes-bg">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="search-resumes-display">
                                        <div className="search-heading">
                                            <h3> Search Resumes </h3>
                                        </div>
                                        <div className="srch mt-3">
                                            <Link to="/saved-search" className="search-btn1 mx-2">Saved Search</Link>
                                             <Link to="/post-job" className="search-btn3 mx-2">Post A Job</Link>
                                        </div>
                                    </div>
                                    <div>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="mt-4">
                                                <div className="d-flex search-area">
                                                    <div className="custom-form-section">
                                                        <div className="search-custom">
                                                            <img src="./assets/images/search-resume/MagnifyingGlass.png" className="img-fluid" alt="" />
                                                            <input
                                                                type="text"
                                                                className="form-control form-custom form-cus-one"
                                                                placeholder="Job title, skill, company, or search"
                                                                name="search_text"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.search_text}
                                                            />
                                                        </div>
                                                        <div className="search-custom">
                                                            <img src="./assets/images/search-resume/MapPinLine .png" className="img-fluid" alt="" />
                                                            <input
                                                                type="text"
                                                                className="form-control form-custom form-cus-two"
                                                                name="city_text"
                                                                autoComplete='off'
                                                                onChange={handleInputChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.city_text}
                                                                placeholder="Enter city name"
                                                            />
                                                        </div>
                                                        
                                                        <div>
                                                            <div className='text-center'>
                                                                <button type='submit' className="form-custom-btn mb-2">Search Resume</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="text-center">
                                                <img src="./assets/images/search-resume/search-bg-image.png" className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default SearchResumes