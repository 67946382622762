import React from 'react'
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
const host = process.env.REACT_APP_API_SERVER;

const Entertainment = ({ top, dataByCat, trending, mostViewed }) => {
    const truncateText = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length > wordLimit) {return words.slice(0, wordLimit).join(' ') + '...';}return text;};
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        const formattedDate = `${day}-${months[monthIndex]}-${year}`;
        return formattedDate;
    }
    return (
        <>{dataByCat && dataByCat.length > 0 ? <section className="entertainment">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 py-4">
                        <div className="heading">
                            <h1>{dataByCat[0]?.name}</h1>
                        </div>
                        <hr />
                        {dataByCat[0]?.news?.length > 0 && <div className="row">
                            <div className="col-lg-5 news-display pt-3">
                                <img src={`${host}/${dataByCat[0]?.news[0]?.image}`} className='img-fluid' alt="" />
                                <ul className=' pb-1 d-flex'>
                                    <li>{formatDate(dataByCat[0]?.news[0]?.createdAt)}</li>
                                </ul>
                                <Link className='remove-link-property-news-page' to={`/karlatoon-news-detail/${dataByCat[0]?.news[0]?.news_slug}`}><h3 className='line-clamp-2'>{dataByCat[0]?.news[0]?.title}</h3></Link>
                                <p className='line-clamp-4-news first-description'>{parse(`${truncateText(dataByCat[0]?.news[0]?.description, 50)}`)}</p>
                            </div>
                            {dataByCat[0]?.news?.length > 1 ?
                                <div className="col-lg-7 py-4 pt-3">
                                    {
                                        dataByCat[0]?.news?.map((news, index) => {
                                            return (index !== 0 && index < 7) && <div className="news-list row pb-3" key={index + 1}>
                                                <div className="col-lg-3">
                                                    <img src={`${host}/${news.image}`} className='img-fluid' alt="" />
                                                </div>
                                                <div className="col-lg-7">
                                                    <ul className='d-flex'>
                                                        <li>{formatDate(news.createdAt)}</li>
                                                    </ul>
                                                    <div className="para">
                                                        <Link className='remove-link-property-news-page line-clamp-2' to={`/karlatoon-news-detail/${news.news_slug}`}><h3 className="line-clamp-2">{news.title}</h3></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }

                                 
                                </div> :
                                <div className="col-lg-7 py-4 pt-3">
                                    <div className="text-center">
                                        <i> More news not available.</i>
                                    </div>
                                </div>
                            }
                        </div>}
                    </div>
                    <div className="col-lg-3 py-4">
                        <nav className='mb-2'>
                            <div className="nav nav-tabs justify-content-around" id="nav-tab" role="tablist">
                                <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Recent</button>
                                <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Trending</button>
                                <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Most Views</button>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                                {top && top.length > 0 ?
                                    top.map((news, index) => {
                                        return index < 6 && <div className="news-list row pb-3" key={index + 1}>
                                            <div className="col-lg-4 mb-lg-0 mb-3">
                                                <img src={`${host}/${news.image}`} className='img-fluid' alt="" />
                                            </div>
                                            <div className="col-lg-8">
                                                <ul className='d-flex'>
                                                    <li>{formatDate(news.createdAt)}</li>
                                                </ul>
                                                <div className="para">
                                                    <Link className='remove-link-property-news-page' to={`/karlatoon-news-detail/${news?.news_slug}`}> <h3 className='line-clamp-2'>{news.title}</h3></Link>
                                                </div>
                                            </div>
                                        </div>
                                    }) :

                                    <div className="news-list row pb-3">
                                        <div className="text-center my-2">
                                            No posts available.
                                        </div>
                                    </div>
                                }
                              
                            </div>
                            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                {trending && trending.length > 0 ?
                                    trending.map((news, index) => {
                                        return index < 6 && <div className="news-list row pb-3" key={index + 1}>
                                            <div className="col-lg-4">
                                                <img src={`${host}/${news.image}`} className='img-fluid' alt="" />
                                            </div>
                                            <div className="col-lg-8">
                                                <ul className='d-flex'>
                                                    <li>{formatDate(news.createdAt)}</li>
                                                </ul>
                                                <div className="para">
                                                    <Link className='remove-link-property-news-page' to={`/karlatoon-news-detail/${news?.news_slug}`}><h3 className='line-clamp-2'>{news.title}</h3></Link>
                                                </div>
                                            </div>
                                        </div>
                                    }) :

                                    <div className="news-list row pb-3">
                                        <div className="text-center my-2">
                                            No posts available.
                                        </div>
                                    </div>
                                }
                                
                            </div>
                            <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                {mostViewed && mostViewed.length > 0 ?
                                    mostViewed.map((news, index) => {
                                        return index < 6 && <div className="news-list row pb-3" key={index + 1}>
                                            <div className="col-lg-4">
                                                <img src={`${host}/${news.image}`} className='img-fluid' alt="" />
                                            </div>
                                            <div className="col-lg-8">
                                                <ul className='d-flex'>
                                                    <li>{formatDate(news.createdAt)}</li>
                                                </ul>
                                                <div className="para">
                                                    <Link className='remove-link-property-news-page' to={`/karlatoon-news-detail/${news?.news_slug}`}> <h3 className='line-clamp-2'>{news.title}</h3></Link>
                                                </div>
                                            </div>
                                        </div>
                                    }) :

                                    <div className="news-list row pb-3">
                                        <div className="text-center my-2">
                                            No posts available.
                                        </div>
                                    </div>
                                }
                               
                            </div>
                        </div>
                    </div>
                </div>
                {dataByCat[0]?.news?.length > 7 && <div className="row">
                    <div className="text-center">
                        <Link to={`/karlatoon-news-category/${dataByCat[0]?.name_slug}`}>
                            View All
                        </Link>
                    </div>
                </div>}
            </div>
        </section> :
            <div className="text-center mb-2">
                No news found.
            </div>
        }</>
    )
}

export default Entertainment