import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Slider from "react-slick";
import { getHowLikeyToRecommend, getProfilePercent, getProfilePerformanceData, getRecommendedJobsData, getSeekerData, getSeekerProfilePageData, getSeekerProfilePageData2 } from '../../../Redux/action/SeekerAction';
import { createImageFromInitials, getRandomColor } from '../../Utils';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { FormatLineSpacing } from '@mui/icons-material';
import axios from 'axios';
import Loader from '../Loader';
import { getFamousCompanies } from '../../../Redux/action/MasterAction';
import parse from 'html-react-parser';
import Swal from 'sweetalert2';
const host = process.env.REACT_APP_API_SERVER
const AfterLoginProfile = () => {
    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [completionPercent, setCompletionPercent] = useState(0);
    const [famous, setFamous] = useState(null);
    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {
            setShowLoader(true)
            setIsLoggedIn(true);
            let authToken = localStorage.getItem("seeker_token");
            dispatch(getSeekerProfilePageData2(authToken));
            dispatch(getProfilePerformanceData(localStorage.getItem("seeker_token")));
            dispatch(getRecommendedJobsData(localStorage.getItem("seeker_token")));
            dispatch(getProfilePercent(authToken));
            dispatch(getHowLikeyToRecommend(authToken));
            dispatch(getFamousCompanies());
        } else {
            toast.error("Please register to update information.")
            navigate("/seeker-register")
        }}, []);
    const profileData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerProfilePageDataList2) : "");
    const famousCompanies = useSelector((state) => (state?.MasterReducer) ? (state?.MasterReducer?.famousCompaniesData) : null);
    useEffect(() => {
        if (famousCompanies) { setFamous(famousCompanies)}
    }, [famousCompanies])
    const firstName = (profileData?.firstName) ? (profileData?.firstName) : '';
    const lastName = (profileData?.lastName) ? (profileData?.lastName) : '';
    const designation = profileData?.latestProfession ? profileData?.latestProfession?.designation : " - "
    const educationDetails = (profileData?.Seeker_educational_details) ? (profileData?.Seeker_educational_details) : "";
    const performanceData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.profileperformanceDataList : {});
    const recommendedJobData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.recommendedjobDataList : []);
    const profilePercentData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.profilepercentDataList : {});
    const getHowLikelyToRecommendData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.getHowLikelyToRecommendDataList : null);
    useEffect(() => {
        if (profileData) {
            if (typeof profileData === 'object' && !Array.isArray(profileData) && profileData !== null) { setShowLoader(false); } else {setShowLoader(true);}
        } else { setShowLoader(true)}
    }, [profileData])
    useEffect(() => {
        if (recommendedJobData) { }
    }, [recommendedJobData])
    useEffect(() => {
        if (profilePercentData) {setCompletionPercent(profilePercentData?.profile_completion_percent);}
    }, [profilePercentData])
    const findEducationByLevel = (level) => educationDetails;
    const educationLevels = ['3', '2', '1'];
    let selectedEducation;
    for (const level of educationLevels) {
        selectedEducation = findEducationByLevel(level);
    if (selectedEducation) {break;}}
    const courseType = (profileData?.latestEducation) ? (profileData?.latestEducation?.educationlevel ? profileData?.latestEducation?.educationlevel?.educationlevel : "-") : "-";
    const address = (profileData?.address) ? (profileData?.address) : "";
    const city = (profileData?.City?.city_name) ? (profileData?.City?.city_name) : "";
    const state = (profileData?.State) ? (profileData?.State?.state_name) : "";
    const country = (profileData?.Country) ? (profileData?.Country?.name) : "";
    const mobile = (profileData?.mobile) ? (profileData?.mobile) : "";
    const email = (profileData?.email) ? (profileData?.email) : "";
    const Seeker_keyskills = (profileData?.Seeker_keyskills) ? (profileData?.Seeker_keyskills) : []
    const experience_years = (profileData?.experience_years) ? (profileData?.experience_years) : ""
    const experience_months = (profileData?.experience_months) ? (profileData?.experience_months) : ""
    const professionalDetails = (profileData?.Seeker_professional_details) ? (profileData?.Seeker_professional_details) : "";
    const professionalWithNotice = professionalDetails ? professionalDetails?.find(professional => professional?.serving_notice === '1') : "";
 const salary = professionalWithNotice ? professionalWithNotice?.current_last_salary : '';
const notice_period = professionalWithNotice ? professionalWithNotice?.notice_period : '';
const company = professionalWithNotice ? professionalWithNotice?.current_last_company : '';
 const settings = {dots: false,infinite: true,autoplay: true,autoplaySpeed: 3000,slidesToShow: 2,slidesToScroll: 1,centerMode: true, centerPadding: 0,responsive: [{breakpoint: 1024,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: true,dots: false}},{breakpoint: 780,settings: {slidesToShow: 1,slidesToScroll: 2,infinite: true,dots: false,arrows: false}},{breakpoint: 480,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: true,dots: false,arrows: false}}]};
    const settings_famous = {dots: false,infinite: famousCompanies && famousCompanies > 2,autoplay: true,autoplaySpeed: 3000,slidesToShow: 2,slidesToScroll: 1,centerMode: true,centerPadding: 0,responsive: [{breakpoint: 1024,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: famousCompanies && famousCompanies?.length > 1,dots: false}},{breakpoint: 780,settings: {slidesToShow: 1,slidesToScroll: 2,infinite: famousCompanies && famousCompanies?.length > 1,dots: false,arrows: false}},{breakpoint: 480,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: famousCompanies && famousCompanies?.length > 1,dots: false,arrows: false}}]};
    const settings_recommended = {dots: false,infinite: recommendedJobData && recommendedJobData?.data && recommendedJobData?.data?.length > 2,autoplay: true,autoplaySpeed: 3000,slidesToShow: 2,slidesToScroll: 1,centerMode: true,centerPadding: 0,responsive: [{breakpoint: 1024,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: recommendedJobData && recommendedJobData?.data && recommendedJobData?.data?.length > 1,dots: false}},{breakpoint: 780,settings: {slidesToShow: 1,slidesToScroll: 2,infinite: recommendedJobData && recommendedJobData?.data && recommendedJobData?.data?.length > 1,dots: false,arrows: false}},{breakpoint: 480,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: recommendedJobData && recommendedJobData?.data && recommendedJobData?.data?.length > 1,dots: false,arrows: false}}]};
    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const previousDate = new Date(timestamp);
        const timeDifference = currentDate - previousDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        if (months > 0) {return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) {return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) { return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) { return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else { return 'Just now'; }};
    const formik = useFormik({
        initialValues: { recommendation_count: 0 },
        validataionSchema: Yup.object({recommendation_count: Yup.string().required("Please select a count."),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                const config = {
                    headers: {'content-type': 'application/json',"auth-token": localStorage.getItem("seeker_token")
                    }
                };
                document.body.style.overflow = "hidden";
                setShowLoader(true);
                axios.post(`${host}/mobile/how-likely-to-recommend`, values, config).
                    then(response => {
                        if (response.status == 200) {document.body.style.overflow = "auto"; setShowLoader(false);toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Successfully submitted.' });
                            dispatch(getHowLikeyToRecommend(localStorage.getItem("seeker_token")));
                        } else {document.body.style.overflow = "auto";setShowLoader(false);toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    }).catch((error) => {console.log(error.message);document.body.style.overflow = "auto";setShowLoader(false);toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error." });
                    })
            } catch (error) {console.log(error.message);document.body.style.overflow = "auto";setShowLoader(false);toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error." });
            }
        }
    });
    useEffect(() => {
        if (getHowLikelyToRecommendData) {
            formik.setFieldValue("recommendation_count", getHowLikelyToRecommendData.recommendation_count ?? 0);
        }
    }, [getHowLikelyToRecommendData]);
    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {return words.slice(0, wordLimit).join(' ') + '...';}return text;
    };
    const handleCopy = () => {
        let currentURL = window.location.href;
        const baseURL = new URL(currentURL).origin
        navigator.clipboard.writeText(`${baseURL}/create-an-account`)
            .then(() => {
                Swal.fire({ position: "top",icon: "success",title: "Link copied to clipboard. Share with your friends.",showConfirmButton: false,timer: 1500 })
            })
            .catch((error) => {
                Swal.fire({position: "top",icon: "error", title: "Some error occured. Please try again later.",showConfirmButton: false,timer: 1500})
            });
    }
    const handleClick = (slug) => {
        if (slug !== undefined) { navigate(`/profile/${slug}/snap-shot`) }
    }
    return (
        <section className='after-login-dash'>
            {profileData &&
                <div className="container">
                    <div className="row prof-main">
                        <div className="profile-head">
                            <img src="./assets/images/after-login-dash/rectangle.png" className='img-fluid bg-banner' alt="" />
                            <div className="edit-prfile">
                                <Link to="/personal-details">
                                    <img src="./assets/images/after-login-dash/edit.png" className='img-fluid p-2 ' alt="" /> Edit Profile</Link>
                            </div>                           
                        </div>
                        <div className="profile-info col-lg-12 mb-5">
                            <div className="row align-items-center">
                                <div className="col-lg-2 col-md-6 col-sm-12 text-center">
                                    <div className="profile-img">
                                        {(profileData && <img
                                            src={                                               
                                                `${host}/uploaded-files/${profileData?.profile_img}`
                                                
                                            }
                                            alt="Avatar"
                                            style={{ cursor: 'pointer', borderRadius: '50%', objectFit: 'cover' }}
                                            
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = createImageFromInitials(500, `${profileData.firstName} ${profileData.lastName}`, getRandomColor());
                                            }}
                                        />)}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-12 text-center">
                                    <ul>
                                        {firstName ? <li><b>{`${firstName} ${lastName}`} </b></li> : <li>-</li>}
                                        {designation ? <li>{designation}</li> : <li><i> Desigation not mentioned</i></li>}
                                        {profileData?.latestProfession ? <li>{profileData?.latestProfession?.current_last_company}</li> : <li><i>Company not mentioned</i></li>}
                                        {courseType ? <li>{courseType}</li> : <li><i>Education not mentioned</i></li>}
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 text-center mt-3">
                                    <ul>
                                        <li>
                                            <img src="./assets/images/after-login-dash/navigation.png" alt="" />
                                            {city ?? <i>Not mentioned</i>} {state && <span>{`, ${state}`}</span>}                                           
                                        </li>
                                        <li>
                                            <img src="./assets/images/after-login-dash/navigation.png" alt="" />
                                            {mobile ?
                                                <span>{mobile} <img src="./assets/images/after-login-dash/verified.png" alt="" /></span>
                                                : <i>Not mentioned</i>}
                                        </li>
                                        <li>
                                            <img src="./assets/images/after-login-dash/navigation.png" alt="" />
                                            {email ?
                                                <span>{email} <img src="./assets/images/after-login-dash/verified.png" alt="" /></span>
                                                : <i>Not mentioned</i>}
                                        </li>                                      

                                        <li>
                                            <img src="./assets/images/after-login-dash/navigation.png" alt="" />
                                            Last updated {timeAgoCalculator(profileData?.createdAt) ?? <i>Not mentioned</i>}
                                            
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-5 col-md-6 col-sm-12 text-center pro-complete p-4">
                                    <div className="row">
                                        <div className="col-lg-7 sec-lft">
                                            <h3>Profile Complete</h3>
                                            {(profileData && <div className="row">
                                                {Seeker_keyskills?.length > 0 ? Seeker_keyskills?.map((skill, index) => {
                                                    return index < 4 && <div key={index + 1} className="col-lg-6 d-flex mb-3 profile-skill">
                                                        <img src="./assets/images/after-login-dash/tick.png" alt="" />
                                                        <div>
                                                            <p>{Number(skill?.skill_name) === 0 ? skill?.other_skill_name : skill?.skill?.skill}</p>
                                                        </div>
                                                    </div>
                                                }
                                                ) :
                                                    <div className="col-lg-6 d-flex mb-3 profile-skill">
                                                        <p>
                                                            <i>Key skills not mentioned.</i>
                                                        </p>
                                                    </div>
                                                }
                                            </div>)}
                                        </div>
                                        <div className="col-lg-5 sec-rit">
                                            <div className="img" style={{ width: "80px", height: "100px" }}>                                                
                                                <CircularProgressbar
                                                    value={completionPercent}
                                                    text={<tspan dy={true ? -2 : 0}>{completionPercent}%</tspan>}
                                                    background
                                                    backgroundPadding={6}
                                                    styles={buildStyles({
                                                        backgroundColor: "transparent",
                                                        textColor: "#ff5400",
                                                        pathColor: "#ff5400",
                                                        trailColor: "#f6f6f6",
                                                        textSize: "20px",
                                                    })}                                                
                                                />
                                            </div>
                                            {Number(completionPercent) != 100 ? <div className="mission-btn">
                                                <Link to='/personal-details'><button>Add Missing Details</button></Link>
                                            </div> :
                                                <div className="mission-btn">
                                                    <Link to='/personal-details'><button>Edit Details</button></Link>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row custm-pro-det">
                        <div className="col-lg-4 col-sm-4 performance">
                            <div className='rec-actn'>
                                <div className="row">
                                    <div className="col-lg-12 head">
                                        <h4>Profile Performance</h4>
                                    </div>
                                    <div className="col-lg-6">
                                        <h5>Resume Downloaded</h5>
                                        <h2>{performanceData?.downloaded_cv_count ?? "0"}</h2>
                                    </div>
                                    <div className="col-lg-6">
                                        <h5>Recruiter Actions</h5>
                                        <h2>{performanceData?.response_rate_count ?? "0"}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-4 resume">
                            <div className="custm-resume h-100">
                                <img src="./assets/images/after-login-dash/target.png" className='img-fluid' alt="" />
                                <h3>Highlight Your Resume From Others</h3>
                                <p>Our remote company is seeking a passionate person to become the lead developer on our website.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-4 apk">
                            <div className="custm-apk">
                                <div className="row">
                                    <div className="col-lg-12 head">
                                        <h4>Track your activities at your smartphone now</h4>
                                    </div>
                                    <div className="col-lg-6 custm-qr">
                                        <img src="./assets/images/after-login-dash/qr.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="col-lg-6 custm-download">
                                        <img src="./assets/images/after-login-dash/store1.png" className='img-fluid' alt="" />
                                        <img src="./assets/images/after-login-dash/store2.png" className='img-fluid' alt="" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row custm-jobs">
                        <div className="col-lg-4">
                            <div className="custm-left-sec">
                                <div className="desc">
                                    <img src="./assets/images/after-login-dash/vec1.png" alt="" />

                                    <div className="textarea">
                                        <h5>Company</h5>
                                        <h6>{profileData?.latestProfession ? <b>{profileData?.latestProfession?.current_last_company}</b> : <i>Not mentioned</i>}</h6>
                                    </div>
                                </div>
                                <div className="desc">
                                    <img src="./assets/images/after-login-dash/vec2.png" alt="" />
                                    <div className="textarea">
                                        <h5>Total Experience</h5>
                                        <h6>{<b>{profileData?.totalExp} </b> ?? <i>Not mentioned</i>}</h6>
                                    </div>
                                </div>
                                <div className="desc">
                                    <img src="./assets/images/after-login-dash/vec3.png" alt="" />
                                    <div className="textarea">
                                        <h5>Annual Salary</h5>
                                        <h6>{profileData?.latestProfession ? <b>{profileData?.latestProfession?.current_last_salary}</b> : <i>Not mentioned</i>}</h6>
                                    </div>
                                </div>
                                <div className="desc">
                                    <img src="./assets/images/after-login-dash/vec4.png" alt="" />
                                    <div className="textarea">
                                        <h5>Notice Period</h5>
                                        <h6>{profileData?.latestProfession ?
                                            profileData?.latestProfession?.serving_notice ? <b>{profileData?.latestProfession?.notice_period} days</b> : <i>Not mentioned</i>
                                            : <i>Not mentioned</i>
                                        }</h6>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="custm-right-sec">
                                <div className="head-area">
                                    <h3>{recommendedJobData ? recommendedJobData?.data ? recommendedJobData?.data?.length : "0" : "0"} Recommended Jobs</h3>
                                </div>
                                <Slider {...settings_recommended} className='mb-4'>
                                    {
                                        recommendedJobData?.data?.map((job) => {
                                            return <div className="col-lg-3" key={job?.job_data?.id}>
                                                <Link to={`/job-find-description?j=${job?.job_data?.job_slug}`} style={{ textDecoration: "none" }}>
                                                    <div className="latest-job-card-two">
                                                        <div className="company-group d-flex justify-content-between">
                                                            <div className="job-role d-flex">
                                                                <img className="img-fluid comp-logo"
                                                                    src={job?.job_data?.Company_details?.logo ? `${host}/uploaded-files/${job?.job_data?.Company_details?.logo}` : `${createImageFromInitials(500, job?.job_data?.Company_details?.company_name, getRandomColor())}`}
                                                                    alt={`${job?.job_data?.Company_details?.company_name}`}
                                                                    title={`${job?.job_data?.Company_details?.company_name}`}
                                                                    onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null; // prevents looping
                                                                        currentTarget.src = createImageFromInitials(500, job?.job_data?.Company_details?.company_name, getRandomColor());
                                                                    }}
                                                                />
                                                                <div className="comp-text ms-3">
                                                                    <h5 className='line-clmap-one-com-name'>{job?.job_data?.Company_details?.company_name}</h5>
                                                                    
                                                                    {(job.job_data.show_pay_by) && <>
                                                                        {job?.job_data?.show_pay_by == "Range" && <h6>  {job?.job_data?.currency} {job?.job_data?.min_pay}  - {job?.job_data?.currency} {job?.job_data?.max_pay}   {job?.job_data?.rate_per_hour}</h6>}
                                                                        {job?.job_data?.show_pay_by == "Starting Amount" && <h6>  {job?.job_data?.currency} {job?.job_data?.amount}   {job?.job_data?.rate_per_hour}</h6>}
                                                                        {job?.job_data?.show_pay_by == "Maximum Amount" && <h6>  {job?.job_data?.currency} {job?.job_data?.amount}   {job?.job_data?.rate_per_hour}</h6>}
                                                                        {job?.job_data?.show_pay_by == "Exact Amount" && <h6>  {job?.job_data?.currency} {job?.job_data?.amount}   {job?.job_data?.rate_per_hour}</h6>}
                                                                    </>}
                                                                </div>
                                                            </div>
                                                            <h4>{timeAgoCalculator(job?.job_data?.createdAt)}</h4>
                                                        </div>
                                                        <div className="job-title">
                                                            <h5>{job?.job_data?.job_title}</h5>
                                                            <h6>Full Time -  {job?.job_data?.address_city && <>
                                                                {job?.job_data?.job_city?.city_name}, {job?.job_data?.job_city?.state_of_city?.state_name}, {job?.job_data?.job_city?.state_of_city?.country_of_state?.name}
                                                            </>
                                                            }</h6>
                                                        </div>
                                                        {job?.job_data?.job_type_list?.length > 0 && <div className="job-loc d-flex">
                                                            <button><Link href={undefined}>{job?.job_data?.job_type_list[0]?.job_type_detail?.jobtypename}</Link></button>
                                                            {job?.job_data?.job_type_list?.length > 1 && <button><Link href={undefined}>{job?.job_data?.job_type_list[1]?.job_type_detail?.jobtypename}</Link></button>}
                                                        </div>}
                                                    </div>
                                                </Link>
                                            </div>
                                        })
                                    }
                                    
                                </Slider>
                                <div className="show-btn d-flex justify-content-lg-start">
                                    <Link to="/recommended-jobs-listing"><button>Show All</button></Link>
                                </div>
                            </div>
                            {/* ============Rating Area============ */}
                            <div className="rating-head mt-4 text-center">
                                <h3>How Likely are you to recommend us to a friend of family?</h3>
                                <form method="post" onSubmit={formik.handleSubmit}>
                                    <div className='rating_scale'>
                                        <label>
                                            <input type="radio" name="recommendation_count" value={1}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                checked={Number(formik.values.recommendation_count) === 1}
                                            /><span>1</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="recommendation_count" value={2}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                checked={Number(formik.values.recommendation_count) === 2}
                                            /><span>2</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="recommendation_count" value={3}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                checked={Number(formik.values.recommendation_count) === 3}
                                            /><span>3</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="recommendation_count" value={4}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                checked={Number(formik.values.recommendation_count) === 4}
                                            /><span>4</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="recommendation_count" value={5}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                checked={Number(formik.values.recommendation_count) === 5}
                                            /><span>5</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="recommendation_count" value={6}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                checked={Number(formik.values.recommendation_count) === 6}
                                            /><span>6</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="recommendation_count" value={7}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                checked={Number(formik.values.recommendation_count) === 7}
                                            /><span>7</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="recommendation_count" value={8}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                checked={Number(formik.values.recommendation_count) === 8}
                                            /><span>8</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="recommendation_count" value={9}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                checked={Number(formik.values.recommendation_count) === 9}
                                            /><span>9</span>
                                        </label>F
                                        <label>
                                            <input type="radio" name="recommendation_count" value={10}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                checked={Number(formik.values.recommendation_count) === 10}
                                            /><span>10</span>
                                        </label>
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.recommendation_count && formik.errors.recommendation_count ? (
                                            <div className='text-danger'>{formik.errors.recommendation_count}</div>
                                        ) : null}
                                    </div>
                                    <button type="submit">Submit</button>
                                </form>
                            </div>
                            {/* ==============Recruiters are invitingyou to apply!============== */}
                            <div className="recruiters d-flex mt-4">
                                <div className="col-lg-6 left-sec d-flex align-items-center">
                                    <div className="lft-detail d-grid">
                                        <img src="./assets/images/after-login-dash/rec1.png" className='img-fluid' alt="" />
                                        <h4>Recruiters are inviting you to apply!</h4>
                                    </div>
                                </div>
                                <div className="col-lg-6 p-4">
                                    <div className='custom-scroll-job'>

                                        {recommendedJobData?.data && recommendedJobData?.data?.length > 0 ?
                                            recommendedJobData?.data?.map((job, index) => {
                                                return index < 10 && <div className="right-sec d-flex mb-4">
                                                    <img src={`${host}/uploaded-files/${job?.job_data?.Company_details?.logo}`} className='img-fluid' alt=""
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = createImageFromInitials(500, job?.job_data?.Company_details?.company_name, getRandomColor());
                                                        }}
                                                    />
                                                    <div className="text-area ms-4">
                                                        <h3>
                                                            <Link to={`/job-find-description?j=${job?.job_data?.job_slug}`}>
                                                                {job?.job_data?.job_title}</Link></h3>
                                                        <div className="loc d-flex">
                                                            <h4>{job?.job_data?.Company_details?.company_name}</h4>
                                                            <h5>{job?.job_data?.address_city ? job?.job_data?.job_city?.city_name : <span>Location not mentioned.</span>}</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                            })
                                            :
                                            <div className="text-center">
                                                <i>No jobs available.</i>
                                            </div>
                                        }
                                    </div>
                                </div>                                
                            </div>
                            {/* ===============Famous Company============= */}
                            <div className="famous-coumpany mt-4">
                                <div className="head-area mb-4 d-flex justify-content-between align-items-center">
                                    <h2>Famous Company</h2>
                                    <Link to="/browse-company-by-industry"><button>Show All</button></Link>
                                </div>
                                {famousCompanies && famous && famous?.length > 0 ? <Slider {...settings_famous} className='mb-4'>
                                    {
                                        famous.map((company, index) => {
                                            return <div className="famous-custom-compp px-3" key={index + 1}>
                                                <div className="custm-fam-comp mx-1" >
                                                    <div className='pb-3 d-flex justify-content-between align-items-center'>
                                                        <div className="custm-company-info d-flex align-items-center">                                                            
                                                            <img
                                                                src={`${host}/uploaded-files/${company?.company_details?.logo}`}
                                                                style={{ height: "32px", width: "32px" }}
                                                                className='img-fluid me-3'
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null;
                                                                    currentTarget.src = createImageFromInitials(500, `${company?.company_details?.company_name}`, getRandomColor());
                                                                }}
                                                                alt={company?.company_details?.company_name}
                                                            />
                                                            <div onClick={() => { handleClick(company?.company_details?.company_slug) }} className='remove-link-css'>

                                                                <h3 title={company?.company_details?.company_email}>{company?.company_details?.company_name}</h3>
                                                            </div>
                                                            
                                                        </div>
                                                        <h5>{company?.recentJob ? timeAgoCalculator(company?.recentJob?.createdAt) : <span>New</span>}</h5>
                                                    </div>
                                                    {company?.recentJob ? <div className='comp-title'>
                                                        <Link to={`/job-find-description?j=${company?.recentJob?.job_slug}`} className='remove-link-css'>
                                                            <h2>{company?.recentJob?.job_title}</h2>
                                                        </Link>
                                                        <p className="job_descripition_famous_comp">{parse(`${truncateText(company?.recentJob?.description, 20)}`)}</p>
                                                    </div> :
                                                        <div className="comp-title">
                                                            <i>No jobs available.</i>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        })
                                    }
                                    
                                </Slider> :
                                    <div className="text-center">
                                        No famous comapnies found
                                    </div>
                                }
                            </div>
                            {/* ===============Invite Friends================ */}
                            <div className="row invite my-4">
                                <div className="col-lg-6 mb-3">
                                    <div className="custm-invite wtsp-update">
                                        <img src="./assets/images/after-login-dash/invite.png" className='img-fluid me-3' alt="" />
                                        <div className="share-txt">
                                            <h2>Get update directly on <br /> WhatsApp!</h2>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <div className="custm-invite d-flex">
                                        <div className="col-lg-6 share-txt">
                                            <h2>Invite Friends</h2>
                                            <p>Invite your friends and earn referal bonus from us.</p>
                                            <button onClick={handleCopy}>Invite</button>
                                        </div>
                                        <div className="col-lg-6">
                                            <img src="./assets/images/after-login-dash/share.png" className='img-fluid me-3' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >}
            {showLoader && <div className="overlay-form-submission">
                <Loader />
            </div>}
        </section>
    )
};

export default AfterLoginProfile