import React, { useEffect, useState } from 'react'
import "./CareerPreferences.css"
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../Commons/Footer'
import CareerPreferencesContent from './CareerPreferencesContent'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { getCareerPreferenceData } from '../../../Redux/action/SeekerAction'
import Loader from '../Loader'
const CareerPreferences = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let [data, setData] = useState({});

    useEffect(() => {
        let token = localStorage.getItem("seeker_token");
        if (!token) {
            toast.error("Please login to continue.");
            navigate("/signin");
        } else {
            dispatch(getCareerPreferenceData(token));
        }
    }, [])

    const careerPreference = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.careerPreferenceData : null)

    useEffect(() => {
        if (careerPreference) {
            setData(careerPreference)
        }
    }, [careerPreference]);
    const reload = () => {
        let token = localStorage.getItem("seeker_token");
        dispatch(getCareerPreferenceData(token));
    }
    return (
        <>
            <AfterNavbar />
            {careerPreference ? <CareerPreferencesContent data={data} reload={reload} /> : <div className="overlay-form-submission"><Loader /></div>}
            <Footer />

        </>
    )
}

export default CareerPreferences