import React from 'react'
import Header from '../Commons/Header'
import Footer from '../Commons/Footer'
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
const ProfileListing = () => {
    const navigate = useNavigate();
    return (<>
        <RecAfterLoginNavbar />
        <div className="container py-5">
            <div className="row align-items-center justify-content-center">
                <div className="col-lg-10 col-md-10">
                    <div className="row mb-3">
                        <div className="col-12">
                            <h4 className='capitalize'><b>Profile Details</b> </h4>
                        </div>
                    </div>
                    <div className="border table-responsive shadow">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th scope="col" className='py-3 center-text'>#</th>
                                    <th className='py-3 '>First</th>
                                    <th scope="col" className='py-3 text-end'></th>
                                    <th scope="col" className='py-3 text-end pe-4'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <th scope="row" className='py-3 center-text'>1</th>
                                    <td className='py-3 ' colSpan={2} >News</td>

                                    <td className='py-3 text-end pe-4 d-flex justify-content-end'>
                                        <button className='update-button me-1' onClick={() => { navigate('/view-news'); }}>
                                            <i className="fa-solid fa-eye" title='View News'></i>
                                        </button>
                                        <button className='update-button' onClick={() => { navigate('/add-news'); }}>
                                            <i class="fa-solid fa-plus" title='Add news'></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <th className='py-3 center-text' scope="row">2</th>
                                    <td className='py-3 ' colSpan={2}>Cms</td>

                                    <td className='py-3 text-end pe-4 d-flex justify-content-end'>
                                        <button className='update-button me-1' onClick={() => { navigate('/view-CMS'); }}>
                                            <i className="fa-solid fa-eye" title='View CMS'></i>
                                        </button>
                                        <button className='update-button' onClick={() => { navigate('/add-CMS'); }}>
                                            <i className="fa-solid fa-plus" title='Add CMS'></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <th className='py-3 center-text' scope="row">3</th>
                                    <td className='py-3' colSpan={2}>Company details</td>
                                    <td className='py-3 pe-4 text-end'><button className='update-button' onClick={() => { navigate('/update-company-details'); }}> <i class="fa-solid fa-pen-to-square" title='Update compmay details'></i></button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>


        <Footer />
    </>
    )
}

export default ProfileListing