import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from 'react-stepper-horizontal';
import Stack from '@mui/material/Stack';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import parse from 'html-react-parser';
const steps = ['<img src="assets/images/cv-builder/link.png" style="filter: brightness(0);"/> Personal Details', '<img src="assets/images/cv-builder/archive.png" /> Educational Details', '<img src="assets/images/cv-builder/users.png" /> Professional Details', '<img src="assets/images/cv-builder/layers.png" /> Project Details', '<img src="assets/images/cv-builder/users.png" /> Key Skills', '<img src="assets/images/cv-builder/hash.png" /> Certifications', '<img src="assets/images/cv-builder/layers.png" /> Social Links', '<img src="assets/images/cv-builder/archive.png" /> CV'];
const Steps2 = ({ activeStepCount }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        step1Data: '',
        step2Data: '',
        step3Data: '',
        step4Data: ''
    });

    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // ===================================
    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#784af4',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#784af4',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderTopWidth: 3,
            borderRadius: 1,
        },
    }));

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#784af4',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#784af4',
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }));

    function QontoStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }

    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
    };
    useEffect(() => {
        const divToHide = document.querySelectorAll('div[style*="position: absolute; top: 40px; height: 1px; border-top: 1px solid rgb(224, 224, 224);"]');
        const stepperBox = document.querySelectorAll('div[style*="width: 12.5%; display: table-cell; position: relative; padding-top: 24px;"]');
              stepperBox.forEach(div => {
            div.classList.add('stepper-box');
        });
        divToHide.forEach(div => {
            div.classList.add('hidden-div');
        });
    }, []);
    return (
        <div>
         
            <div className="stepper-container custom-stepper">

                <Stepper className="step-dv-child-stepper" 

                    steps={[
                        { title: 'Personal Details' },
                        { title: 'Educational Details' },
                        { title: 'Professional Details' },
                        { title: 'Project Details' },
                        { title: 'Key Skills' },
                        { title: 'Certifications' },
                        { title: 'Social Links' },
                        { title: 'Upload CV' },
                    ]}
                    activeStep={activeStepCount}
                    completeColor="rgb(8 56 92)"
                    activeColor="rgb(0 103 204)"

                />
            </div>
        </div >
    )
}

export default Steps2