import React, { useEffect, useState, useRef } from 'react'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Link, useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import { Blocks } from 'react-loader-spinner';
const host = process.env.REACT_APP_API_SERVER;

const FinalizeResume4 = ({ findResumeData, handleBack, handleReset }) => {
    const [skills, setSkills] = useState([]);
    const [certificate, setCertificate] = useState([]);
    const [language, setLanguage] = useState([]);
    const [links, setLinks] = useState([]);
    const resumeRef = useRef(null);
    const navigate = useNavigate();
    const [showLogin, setShowLogin] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const handleShowLogin = () => setShowLogin(true);
    const handleCloseLogin = () => setShowLogin(false);

    const handleGoHome = () => {
        navigate("/");
    }

    useEffect(() => {
        if (findResumeData?.skills) {const skills = JSON.parse(findResumeData?.skills || '[]');setSkills(skills);} else {setSkills([]);}if (findResumeData?.languages) {const languages = JSON.parse(findResumeData?.languages || '[]');setLanguage(languages);} else {setSkills([]);}if (findResumeData?.certification) {const certifications = findResumeData?.certification;setCertificate(certifications);} else {setCertificate([]);}if (findResumeData?.links) {const links = JSON.parse(findResumeData?.links || '[]');setLinks(links);} else {setLinks([]);}
    }, [findResumeData]);

    function trimString(str, count) {
        if (typeof str !== 'string') {
            throw new Error('First argument must be a string');
        }
        if (typeof count !== 'number' || count < 0) {
            throw new Error('Second argument must be a non-negative number');
        }

       
        return str.length > count ? str.slice(0, count) + "..." : str;
    }

    const urlString = window.location.href;
    const url1 = new URL(urlString);
    const path = url1.pathname.slice(1);
    const searchParams = url1.search;
    const q = path + searchParams;

    const search = window.location.search;
    const searchParams2 = new URLSearchParams(search);
    const step = searchParams2.get('step');
    const dr = searchParams2.get('dr');

    const handleRegisterNavigate = () => {
        try {
            handleCloseLogin();
            navigate(`/create-an-account?q=${encodeURIComponent(q + "?step=6&dr=true")}`);
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {
            if (dr && dr == "true") {
                setShowLoader(true);
                let loaders = document.querySelectorAll(".loaderspinner");
                let disabledBtns = document.querySelectorAll(".disbledBTN");
                loaders.forEach(loader => {
                    loader.style.display = 'inline';
                });
                disabledBtns.forEach(btn => {
                    btn.setAttribute("disabled", "true")
                });

                const pdf = new jsPDF('p', 'mm', 'a4');
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();
                const margin = 20; 

                html2canvas(resumeRef.current).then(canvas => {
                    const imgData = canvas.toDataURL('image/png');
                    const imgProps = pdf.getImageProperties(imgData);
                    const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

                    let heightLeft = imgHeight;
                    let position = 0;

                    pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
                    heightLeft -= pdfHeight - margin;

                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        pdf.addPage();
                        pdf.addImage(imgData, 'PNG', 0, position + margin, pdfWidth, imgHeight);
                        heightLeft -= pdfHeight - margin;
                    }
                    pdf.save('resume.pdf');
                }).then(() => {
                    setShowLoader(false);
                    navigate("/build-resume")
                })
            }
        }
    }, []);

    const downloadPDF = () => {

        if (!localStorage.getItem("seeker_token")) {
            handleShowLogin();
        } else {
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const margin = 20; 

            html2canvas(resumeRef.current).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const imgProps = pdf.getImageProperties(imgData);
                const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

                let heightLeft = imgHeight;
                let position = 0;

                pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
                heightLeft -= pdfHeight - margin;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position + margin, pdfWidth, imgHeight);
                    heightLeft -= pdfHeight - margin;
                }

                pdf.save('resume.pdf');
            });
        }

    };

 
    function formatDate(dateString) {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const dateParts = dateString.split("-");
        const day = dateParts[2];
        const month = months[parseInt(dateParts[1]) - 1];
        const year = dateParts[0];
        return `${day}-${month} ${year}`;
    }
    const accomplishmentsText = parse(`${findResumeData?.accomplishments}`)
    const affiliationsText = parse(`${findResumeData?.affiliations}`)
    return (
        <>
            <div className="overflow-auto">

                <div className="main_cv_detail_section" id='resume' ref={resumeRef}>
                    <div className="name_detail_listing">
                        <div className="profile_main_resum">
                            <div className='main_profile_img'>
                                <img
                                    src={findResumeData?.profile_img ? `${host}/uploaded-files/${findResumeData?.profile_img}` : `./assets/resume_templates/profile-img-dummy.png`}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = "./assets/resume_templates/profile-img-dummy.png";
                                    }}
                                    alt="Profile Image"
                                />
                            </div>
                            <div className='main_profile_detail'>
                                <h2>{findResumeData?.first_name} {findResumeData?.last_name}</h2>
                                <p>{findResumeData?.city}, {findResumeData?.state}, {findResumeData?.country}, {findResumeData?.pincode}</p>
                                <p>{findResumeData?.email}<span>/</span>{findResumeData?.phone} </p>
                            </div>
                        </div>
                        {links?.length > 0 && <div className="main_detail_sosal">
                            <h6>
                                Social Media Links
                            </h6>
                            <ul>
                                {
                                    links.map((link, index) => {
                                        return <li key={index + 1}><a href={link} target='_blank'>{trimString(link, 30)}</a></li>
                                    })
                                }
                               
                            </ul>
                        </div>}
                    </div>
                    <div className="detail_resum_main">

                        <div className="main_detail_skill">
                            <h6>
                                Summary
                            </h6>
                            <p style={{ textAlign: "justify" }}>
                                {findResumeData?.summary}
                            </p>
                        </div>
                        {findResumeData?.cvbuilderprofessionaldetails?.length > 0 &&
                            <div className="main_detail_experiencs">
                                <div className="exp_detail_text">
                                    <h6>
                                        Experience
                                    </h6>
                                </div>
                                {findResumeData?.cvbuilderprofessionaldetails?.map((value, index) => {
                                    return <>
                                        <div className="exp_detail_text">
                                            <h5>
                                                <strong> {value?.current_last_company} </strong> <span>/</span>  {value?.job_title}
                                            </h5>
                                            <h5>
                                                {formatDate(value?.start)} - {!value?.currently_working ? formatDate(value?.end) : "Current"}
                                            </h5>
                                        </div>
                                        {value?.job_description && <div className='text-justify'>{parse(`${value?.job_description}`)}</div>}
                                    </>
                                })
                                }
                            </div>
                        }
                        {findResumeData?.cvbuildereducationaldetails?.length > 0 &&
                            <div className="main_detail_experiencs">
                                <div className="exp_detail_text">
                                    <h6>
                                        Education
                                    </h6>
                                </div>
                                {findResumeData?.cvbuildereducationaldetails.map((value, index) => {
                                    return <div className='mb-2'>
                                        <div className="exp_detail_text">
                                            <h5><strong> {value?.education_level} </strong> <span>/</span>  {value?.specialization}</h5>
                                            <h5>
                                                {formatDate(value?.start)}-{!value?.pursuing ? formatDate(value?.end) : "Current"}
                                            </h5>
                                        </div>
                                        <p>{value?.university_name}, {value?.university_address}</p>
                                        {
                                            value?.description && <div className='text-justify'>{parse(`${value?.description}`)}</div>
                                        }

                                    
                                    </div>
                                })}
                            </div>
                        }
                        {findResumeData?.cvbuilderprojectdetails?.length > 0 && <div className="main_detail_experiencs">
                            <div className="exp_detail_text">
                                <h6>
                                    Project
                                </h6>
                            </div>
                            {findResumeData?.cvbuilderprojectdetails?.map((value, index) => {
                                return <div className='mb-2' key={index + 1}>
                                    <div className="exp_detail_text">
                                        <h5 className='mb-0'><strong> {value?.project_title}</strong> </h5>
                                        <h5 className='mb-0'>
                                            {formatDate(value?.start)} - {value?.in_progress ? "Current" : formatDate(value?.end)}
                                        </h5>
                                    </div>
                                    <p>{value?.client}</p>
                                    {
                                        value?.description && <div className='text-justify'>{parse(`${value?.description}`)}</div>
                                    }
                                  
                                </div>
                            })
                            }
                        </div>
                        }
                        {skills?.length > 0 && <div className="main_detail_skill">
                            <h6>
                                Skills
                            </h6>
                            <ul>
                                {skills?.map((skill, index) => {
                                    return <li key={index + 1}>{skill}</li>
                                })}
                            </ul>
                        </div>
                        }
                        {language?.length > 0 && <div className="main_detail_skill">
                            <h6>
                                Languages
                            </h6>
                            <ul>
                                {language?.map((lang, index) => {
                                    return <li key={index + 1}>{lang}</li>
                                })}
                            </ul>
                        </div>}
                        {certificate?.length > 0 && <div className="main_detail_experiencs">
                            <div className="exp_detail_text">
                                <h6>
                                    Certification
                                </h6>
                            </div>
                            {
                                certificate.map((value, index) => {
                                    return <div className="mb-2">
                                        <div className="exp_detail_text">
                                            <h5><strong> {value?.certificate} </strong></h5>
                                            <h5>
                                                {value?.start} - {value?.end}
                                            </h5>
                                        </div>
                                        <p>{value?.institute}</p>
                                    </div>
                                })

                            }
                        </div>}
                        {findResumeData?.accomplishments && <div className="main_detail_experiencs">
                            <div className="exp_detail_text">
                                <h6>
                                    Accomplishments
                                </h6>
                            </div>
                            <div className="text-justify" style={{ textAlign: "justify" }}>
                                {parse(`${findResumeData?.accomplishments}`)}
                            </div>
                        </div>}
                        {findResumeData?.affiliations && <div className="main_detail_experiencs">
                            <div className="exp_detail_text" style={{ textAlign: "justify" }}>
                                <h6>
                                    Affiliations
                                </h6>
                            </div>
                            <div className="text-justify">
                                {parse(`${findResumeData?.affiliations}`)}
                            </div>
                        </div>}

                    </div>
                </div>
            </div>
            <div className="download-btn" style={{ textAlign: "center", margin: 20 }}>
                <button
                    onClick={handleBack}
                    className='mx-1 disbledBTN'
                    style={{
                        padding: "5px 20px",
                        borderRadius: "40px",
                        border: "1px solid #1B375C",
                        background: "#FFF",
                        textDecoration: "none",
                        color: "#1B375C",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "500",
                    }}
                >
                    <i className="fas fa-spinner fa-spin loaderspinner" style={{ display: "none" }}></i>
                    Previous
                </button>
                <button
                    onClick={downloadPDF}
                    className='mx-1 disbledBTN'
                    id="downloadBTN"
                    style={{
                        padding: "5px 20px",
                        borderRadius: "40px",
                        background: "#1B375C",
                        textDecoration: "none",
                        color: "#FFF",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "500",
                        border: "1px solid #1B375C"
                    }}
                >
                    <i className="fas fa-spinner fa-spin loaderspinner" style={{ display: "none" }}></i>
                    Download PDF
                </button>
                
                <button
                    onClick={handleGoHome}
                    className='mx-1'
                    style={{
                        padding: "5px 20px",
                        borderRadius: "40px",
                        border: "1px solid #1B375C",
                        background: "#FFF",
                        textDecoration: "none",
                        color: "#1B375C",
                        textAlign: "center",
                        fontSize: "16px",
                        fontWeight: "500",
                    }}
                >
                    Home
                </button>
            </div >
            {/* Login Modal */}
            <Modal show={showLogin} onHide={handleCloseLogin} backdrop="static"
                keyboard={false}>
                <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
                    <Modal.Title ><img src="/assets/images/logo.png" /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-body p-3">
                        <h3 className="text-center" style={{
                            fontSize: '20px !important',
                            marginBottom: '20px',
                        }}>
                            {/* Sign in <br /> before proceeding on Karlatoon */}
                            Ready to take next step ?
                        </h3>
                        <p className="text-center" style={{ fontSize: "15px" }}>Sign in to Karlatoon.</p>
                        <p className="text-muted text-justify">
                            <small>
                                You may also apply directly on the company's website. By
                                creating an account or signing in, you understand and agree to
                                Karlatoon's <Link to="/terms-conditions" target='_blank'>Terms <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You also consent to our{" "}
                                <Link to="/privacy-policy" target='_blank'>Privacy policies <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You will receive
                                marketing messages from Karlatoon and may opt out at any time by
                                following the unsubscribe link in our messages, or as detailed in
                                our terms.
                            </small>
                        </p>

                        <div className="text-center mb-3"></div>
                        <div className="d-grid gap-2 mb-3 justify-content-center">
                            <Link
                                className="btn btn-outline-secondary popup-login-btn"
                                type="button"
                                // onClick={handleLoginRedirect}
                                to={`/signin?q=${encodeURIComponent(q + "?step=6&dr=true")}`}
                                style={{
                                    padding: "5px 20px",
                                    borderRadius: "40px",
                                    background: "#1B375C",
                                    textDecoration: "none",
                                    color: "#FFF",
                                    textAlign: "center",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #1B375C",
                                    width: "fit-content"
                                }}
                            >
                                Login
                            </Link>

                        </div>
                        <div className="d-grid gap-2 mb-3 justify-content-center">
                            <p>Don't have an account? <div className="d-inline"
                                style={{
                                    cursor: "pointer",
                                    color: "blue",
                                    textDecoration: "underline"
                                }}
                                onClick={handleRegisterNavigate}
                            >Register</div></p>
                        </div>
                    </div>
                </Modal.Body>
               
            </Modal>

            {/* Loader */}
            {showLoader && <div className="overlay-form-submission">
                <Blocks
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    visible={true}
                />
                <div className="text-center">Downloading Resume...</div>
            </div>}
        </>
    )
}

export default FinalizeResume4