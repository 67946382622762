import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import axios from 'axios';
import EditCertificateDetailModal from './EditCertificateDetailModal';
import { useNavigate } from 'react-router-dom';
import AddCertificateDetailModal from './AddCertificateDetailModal';
const host = process.env.REACT_APP_API_SERVER;

const AddCertification = ({ setCertification, data, refresh, handleNext, handleBack, handlePreviewResume }) => {

  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  let certData = data ? data?.certification : [];
  const handleDelete = (id) => {
    const loadingToastId = toast.info('Submitting form...', { autoClose: false });
    try {
      document.body.style.overflow = "hidden";
      setLoader(true);
      let del_url;
      let config;
      if (localStorage.getItem("seeker_token")) {
        del_url = `${host}/cvbuilder/delete-cvbuilder-seeker-certification?certification_id=${id}`;
        config = {
          headers: {
            'content-type': 'application/json',
            "auth-token": localStorage.getItem("seeker_token")
          }
        }
      } else if (localStorage.getItem("karlatoon_deviceId")) {
        del_url = `${host}/cvbuilder/delete-cvbuilder-seeker-certification-guest?certification_id=${id}`;
        config = {
          headers: {
            'content-type': 'application/json',
            "device-token": localStorage.getItem("karlatoon_deviceId")
          }
        }
      } else {
        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Token expired.' });
        navigate('/build-resume')
      }
      axios.get(del_url, config)
        .then(function (response) {
          if (response.status == 200) {
            refresh();
            document.body.style.overflow = 'auto';
            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
          } else {
            document.body.style.overflow = 'auto';
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
          }
        })
        .catch(function (error) {
          document.body.style.overflow = 'auto';
          toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal Server Error' });
        })
    } catch (error) {
      document.body.style.overflow = 'auto';
      toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal Server Error' });
    }
  }

  return (
    <>
      <div className='certificate-details'>
        <div className="col-lg-12 col-md-12 row justify-content-end">
          <button className="btn next-btn-resume width-fit-content float-end" data-bs-toggle="modal" data-bs-target="#addCertificateDetailModal">
            Add Certification
          </button>
          <AddCertificateDetailModal refresh={refresh} id={data.id} />
        </div>
        {certData.length > 0 ?
          <div className="row d-grid-certificate-details">
            {certData.map((certificate, index) => {
              return <div className="card mt-3" key={index + 1}>
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="font-semibold">{certificate?.certificate}</h5>
                  </div>
                  <p className="card-text mt-2 mb-0">
                    {certificate?.institute} (<i>{certificate?.start} - {certificate?.end}</i>)
                  </p>
                  <div className="d-flex justify-content-end align-items-center gap-2">
                    <div style={{ cursor: 'pointer' }} title='Edit Education Detail'>
                      <EditIcon data-bs-toggle="modal" data-bs-target={`#exampleModalCertificateDetail${certificate?.id}`} />
                      <EditCertificateDetailModal data={certificate} id={certificate?.id} refresh={refresh} />
                    </div>
                    <DeleteIcon onClick={() => { handleDelete(certificate?.id) }} style={{ cursor: "pointer" }} />
                  </div>
                </div>
              </div>
            })
            }
          </div> :
          <div className="text-center my-2">
            <i>No information found.</i>
          </div>
        }
        <div className="d-flex justify-content-center my-3 custom-stepper-button">
          <button className="btn prev-btn-resume mx-1" onClick={handleBack}>Previous</button>
          <button className="btn next-btn-resume mx-1 " onClick={handleNext}>Save & Next</button>
          <button className="btn prev-btn-resume mx-1" onClick={handlePreviewResume}>Preview</button>
        </div>
      </div>
    </>
  )
}

export default AddCertification