import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from "react-slick";
import { getTestimonialData } from '../../../Redux/action/RecruiterAction';
import { Link } from 'react-router-dom';
import TestimonialVideo from './TestimonialVideo';
const host = process.env.REACT_APP_API_SERVER;
const Testimonial = () => {const dispatch = useDispatch();
    useEffect(() => {dispatch(getTestimonialData());}, []);
    const testimonialData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.testimonialDataList?.data) : []);
    const totalStars = 5;const videoId = document.getElementById("myVideoPlayer");
    function pauseVideo() {videoId?.pause();videoId.currentTime = 0;}
    const [currentVideoUrl, setCurrentVideoUrl] = useState("");
    const [title, setTitle] = useState('')
    const updateVideoUrl = (data) => {const video = data?.videoTestimonial;const embedUrl = `${host}/${video}`; setCurrentVideoUrl(embedUrl);setTitle(data?.name)};
    const settings = {dots: true,infinite: testimonialData?.length > 3,autoplaySpeed: 2000, autoplay: false,slidesToShow: 3,slidesToScroll: 1,arrows: false,responsive: [
            {breakpoint: 1024, settings: {slidesToShow: 3,slidesToScroll: 3,infinite: testimonialData?.length > 3,dots: true}},
            {breakpoint: 780,settings: {slidesToShow: 2,slidesToScroll: 2,infinite: testimonialData?.length > 2, dots: true,arrows: false}
            },
            {breakpoint: 480,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: testimonialData?.length > 1, dots: false,arrows: false}
            } ]};
    const renderStars = (rating) => {const stars = [];for (let i = 1; i <= 5; i++) {if (i <= rating) { stars.push(<img src="assets/images/homepage/star1.png" />);} else {stars.push(<img src="assets/images/homepage/star-black.png" />); }} return stars;};
    return (
        <section className='testimonial'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='heading-txt'>
                            <h1>What People are Saying !</h1>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <Slider {...settings} className='mt-5 mb-5'>
                        {testimonialData?.map((data, index) => (
                            <>
                                <div className='' key={index}>
                                    <div className='test-card'>
                                        <div className='star d-flex'>                                            
                                            {renderStars(data?.stars)}
                                        </div>
                                        <p className='pt-3'>
                                            {data?.message}
                                        </p>
                                        <div className='client d-flex'>
                                            <div className='avatar d-flex testimonial-home-play-add'>                                                
                                                <div className='testimonial-home-play-add-img-parent video-add-animation'>
                                                    {data?.videoTestimonial && <><div id="one"></div><div id="two"></div></>}
                                                    <img src={data?.image ? `${host}${data?.image}` : 'assets/images/homepage/avatar1.png'} />


                                                    {data?.videoTestimonial &&
                                                        <a className='testimonial-a-tag-play-icon' data-bs-toggle="modal" data-bs-target="#testimonial-video" onClick={() => updateVideoUrl(data)}><i className="fa-regular fa-circle-play"></i></a>}

                                                </div>
                                                <div className='client-info'>
                                                    <h5>{data?.name}</h5>
                                                    <h6>{data?.designation}</h6>
                                                </div>

                                            </div>
                                            <img src='assets/images/homepage/Quote.png'></img>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </Slider>
                </div>
            </div>
            {/* ========================video modal start======================= */}
            <div className="modal fade" id="testimonial-video" tabIndex={-1} aria-labelledby="exampleModalLabel"
                aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {title}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={pauseVideo} />
                        </div>
                        <div className="modal-body personal-page-modal-body">
                            <div className="popup-video-play">
                                <div className='player-wrapper'>                                    
                                    <video id='myVideoPlayer' className='yt_player_iframe' width="100%" height="400" src={currentVideoUrl} controls autoPlay loop />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ========================video modal end======================= */}
        </section>
    )
}
export default Testimonial