import React, { useEffect, useState } from 'react'
import './scss/ShowJobs.css'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyAllJobs, getCompanybyId } from '../../../../Redux/action/SeekerAction';
import { getIndustryById, getIndustryBySlug, getJobsByIndustrySlug } from '../../../../Redux/action/MasterAction';
import ReactPaginate from 'react-paginate';
import { createImageFromInitials, getRandomColor } from '../../../Utils';
import parse from 'html-react-parser';
import Loader from '../../Loader';
import CareerSearchSection from '../FindRightPlacesCompanyListing/CareerSearchSection';
import { getJobsByIndustry } from '../../../../Redux/action/MasterAction';
const host = process.env.REACT_APP_API_SERVER;

const ShowJobscontent = ({ industry_id }) => {
    let [showCompanyAllJobs, setShowCompanyAllJobs] = useState(false);
    let [jobs, setJobs] = useState([]);
    let [industryName, setIndustryName] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();
    let id = industry_id;
    useEffect(() => {
        dispatch(getJobsByIndustrySlug(industry_id));
        dispatch(getIndustryBySlug(industry_id));
    }, [industry_id]);


    const jobByIndustry = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.jobsByIndustrySlugList : null);
    const industryById = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.industryBySlugList : null);
  
    useEffect(() => {
        if (jobByIndustry) {
            setJobs(jobByIndustry);
        }
    }, [jobByIndustry]);

    useEffect(() => {
        if (industryById) {
            setIndustryName(industryById);
        }
    }, [industryById])
    const [currentJobs, setCurrentJobs] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [jobOffset, setJobOffset] = useState(0);
    const [showPagination, setShowPagination] = useState(false);
    const jobPerPage = 5;
    useEffect(() => {
        const endOffset = jobOffset + jobPerPage;
        setCurrentJobs(jobs.slice(jobOffset, endOffset));
        setPageCount(Math.ceil(jobs?.length / jobPerPage));
        if (pageCount > 1) {setShowPagination(true);
        }
        else {setShowPagination(false);
        }
    }, [jobOffset, jobPerPage, jobs])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * jobPerPage) % jobs?.length;
        setJobOffset(newOffset);
    };
    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };
    return (
        <>
            <section className='find-company-listing-section'>
                <section className="main-section">
                    <div className="container">
                        <div className="card-bg">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card-heading">
                                        <h3>{jobs?.length ?? 0} jobs found {industryName && <span>({industryName?.industry})</span>}</h3>
                                    </div>
                                </div>
                                <div className="col-lg-12 mt-4">
                                    {jobs && jobs?.length > 0 ? currentJobs.map((job, index) => {
                                        return <div key={index + 1} className="card card-custom mb-4">
                                            <div className="d-flex display-card justify-content-between">
                                                <div className='d-flex'>
                                                    <div className='company-logo-dv'>
                                                        <img
                                                            className='img-fluid logo-img'
                                                            src={job?.Company_details?.logo ? `${host}/uploaded-files/${job?.Company_details?.logo}` : `${createImageFromInitials(500, job?.Company_details?.company_name, getRandomColor())}`}
                                                            alt={job?.Company_details?.company_name} title={job?.Company_details?.company_name}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = createImageFromInitials(500, job?.Company_details?.company_name, getRandomColor());
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="footer-heading footer-icon mx-2">
                                                        <div className='company-heading-icon'>
                                                            <div className='company-card'>
                                                                <h3 title={job?.job_title}><Link to={`/job-find-description?j=${job?.job_slug}`}>{job?.job_title}</Link></h3>
                                                            </div>
                                                        </div>
                                                        <p>
                                                           <h3 style={{ display: 'inline' }} title="View Company"><Link to={`/profile/${job?.Company_details?.company_slug}/snap-shot`}>{job?.Company_details?.company_name}</Link></h3>

                                                            {job?.Company_details?.size && <span>
                                                                <span className="mx-5 show-btn" title="Company Size">
                                                                    {job?.Company_details?.company_size?.companysize}
                                                                </span>
                                                            </span>}
                                                            {job?.Company_details?.business_type_detail ? job?.Company_details?.business_type_detail?.businesstype : "-"}
                                                        </p>
                                                    </div>
                                                    <div className="footer-heading footer-icon mx-2"></div>
                                                </div>
                                                <div className="bookmark">
                                                    <Link to={`/job-find-description?j=${job?.job_slug}`} className="btn btn-success">Show job</Link>
                                                </div>
                                            </div>
                                            <div className="footer-list-card mt-2">
                                                <div>
                                                    {job?.description && <>{parse(`${truncateText(job?.description, 30)}`)}</>}
                                                </div>
                                            </div>
                                        </div>
                                    })
                                        :
                                        <div className='text-center'>No jobs found.</div>
                                    }
                                </div>
                            </div>
                            {jobs && jobs?.length > 5 &&
                                <div className='row justify-content-center'>
                                    <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                        <section className='pagination-section'>
                                            <div className='container'>
                                                <nav aria-label="Page navigation example" className='nav-class'>
                                                    <ReactPaginate
                                                        breakLabel="..."
                                                        nextLabel=">>"
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={5}
                                                        pageCount={pageCount}
                                                        previousLabel="<<"
                                                        renderOnZeroPageCount={null}
                                                        containerClassName='pagination'
                                                        pageLinkClassName='page-num'
                                                        previousLinkClassName='page-num'
                                                        nextLinkClassName='page-num'
                                                        activeClassName='active'
                                                    />
                                                </nav>
                                            </div>
                                        </section>

                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </section>


            </section>
        </>
    )
}

export default ShowJobscontent