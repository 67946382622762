import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import { FocusError } from 'focus-formik-error';
import { toast } from 'react-toastify';
import RegisterHeader from './CvBuilderHeader/RegisterHeader'
import Footer from '../Commons/Footer'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Steps from './Steps';
import { getEducationLevelData, getUniversityData, getSeekerEducationalDetailsData, getCourseTypeData } from '../../../Redux/action/SeekerAction';
import Loader from "../Loader";
import HourglassLoader from '../HourglassLoader';
import Steps2 from './Steps2';
let host = process.env.REACT_APP_API_SERVER;

const EducationalDetails2 = () => {

    const [isPursuing, setIsPursuing] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [editedId, setEditedId] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);
    const [other, setother] = useState(false);




    useEffect(() => {

        if (localStorage.getItem("seeker_token")) {

            setIsLoggedIn(true);
            let authToken = localStorage.getItem("seeker_token");
            dispatch(getSeekerEducationalDetailsData(authToken));

        } else {
            toast.error("Please register to update information.")
            navigate("/seeker-register")
        }
        dispatch(getEducationLevelData());
        dispatch(getUniversityData());
        dispatch(getCourseTypeData());


    }, [])

    const educationLevelData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.educationLevelDataList?.data) : "");
    const universityData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.universityDataList?.data) : "");
    const courseTypeData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.courseTypeDataList?.data) : "");

    const [educationalDetailsData, setEducationalDetailsData] = useState("");
    const initialeducationalDetailsData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerEducationalDetailsDataList) : "")


    useEffect(() => {
        if (initialeducationalDetailsData) {
            setEducationalDetailsData(initialeducationalDetailsData);
        }
    }, [initialeducationalDetailsData])


    const handlePursuingChange = (e) => {
        const isChecked = e.target.checked;

        formik.setFieldValue('pursuing', isChecked);

        setIsPursuing(isChecked);
        if (isChecked) {
            formik.setFieldValue('end_year', '');
        }
    };



    const config = {
        headers: {

            'content-type': 'application/json',
            "auth-token": localStorage.getItem("seeker_token")
        }
    };

    let [initialValues, setInitialValues] = useState({
        education_level: "",
        specialization: "",
        university: "",
        university_address: "",
        course_type: "",
        start_year: "",
        end_year: "",
        pursuing: false,
        university_name: "",


    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema:
            Yup.object().shape({
                education_level: Yup.string().required("Please select educational level."),
                specialization: Yup.string().required("Please enter specialization."),
                university: Yup.string().required("Please select university/College."),
                university_name: other ? Yup.string().required("Pleas enter other University/College.") : Yup.string(),
                course_type: Yup.string().required("Please select course type."),

                start_year: Yup.date()
                    .max(new Date(), 'Course duration start year date cannot be in the future')
                    .required('Please enter course duration start year.'),
                end_year: isPursuing ? Yup.string() : Yup.date()
                    .max(new Date(), 'Course duration end year cannot be in the future')
                    .min(
                        Yup.ref('start_year'), 'Course duration end year must be greater than or equal to start year')
                    .required('Please enter course duration end year.'),
            }),
        onSubmit: (values, { setSubmitting }) => {
            const id = editedId;
            setDisableBtn(true);
            setIsSubmitting(true);
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            if (Array.isArray(values.pursuing)) {
                values.pursuing = isPursuing;
            }

            try {

                setSubmitting(true);
                setShowLoader(true);

                document.body.style.overflow = 'hidden';


                if (isEditing) {

                    axios.post(`${host}/mobile/update-educational-details?id=${id}`, values, config)
                        .then(function (response) {
                            if (response.status == 200) {

                                setSubmitting(false)
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                dispatch(getSeekerEducationalDetailsData(localStorage.getItem("seeker_token")));
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Educational details updated successfully!' });


                            } else {

                                setSubmitting(false)
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        })
                        .catch(function (error) {

                            document.body.style.overflow = 'auto';
                            setSubmitting(false)
                            setShowLoader(false);
                            setDisableBtn(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Educational details. Please try again.' });
                        })
                }
                else {
                    axios.post(`${host}/mobile/add-educational-details`, values, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false)
                                document.body.style.overflow = 'auto';
                                setShowLoader(false);
                                setDisableBtn(false);
                                dispatch(getSeekerEducationalDetailsData(localStorage.getItem("seeker_token")));
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Educational details added successfully!' });

                            } else {

                                setSubmitting(false)
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        })
                        .catch(function (error) {

                            document.body.style.overflow = 'auto';
                            setSubmitting(false)
                            setShowLoader(false);
                            setDisableBtn(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Educational details form. Please try again.' });
                        })
                }
                triggerModalClose();


            } catch (error) {

                document.body.style.overflow = 'auto';
                setSubmitting(false)
                setShowLoader(false);
                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Educational details form. Please try again.' });
            }

        }
    });



    const handleDelete = async (id) => {
        try {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            const response = await axios.get(`${host}/mobile/delete-educational-details?id=${id}`, config);
            if (response.status === 200) {
                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Detail deleted successfully.!' });
                const updatedEducationalDetails = educationalDetailsData.filter(item => item.id !== id);

                setEducationalDetailsData(updatedEducationalDetails);
            } else {

                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });

            }
        } catch (error) {
            document.body.style.overflow = 'auto';


        }
    };


    const handleEdit = (id) => {
        setIsEditing(true);
        const selectedData = educationalDetailsData.find(item => item?.id === id);
        if (selectedData) {
            formik.setValues({
                education_level: selectedData?.education_level ?? "",
                specialization: selectedData?.specialization ?? "",
                university: selectedData?.university ?? "",
                course_type: selectedData?.course_type ?? "",
                start_year: selectedData?.start_year ?? "",
                end_year: selectedData?.end_year ?? "",
                pursuing: selectedData?.pursuing ?? false,
                university_name: selectedData?.university_name ?? "",
            });
            setIsPursuing(selectedData?.pursuing === 1);
            setother(selectedData?.university === 0);
        }

        setEditedId(id);

    };

    const triggerModalClose = () => {
        const closeButton = document.querySelector('.modal.fade .btn-close');
        if (closeButton) {
            closeButton.click();
        }
        setIsSubmitting(false);
    };

    const handleAdd = () => {
        setIsEditing(false);
        formik.setValues({
            education_level: "",
            specialization: "",
            university: "",
            course_type: "",
            start_year: "",
            end_year: "",
            pursuing: false,

        });

        setother(false);
    }

    const handleNext = () => {

        if (initialeducationalDetailsData != "") {
            navigate('/professional-details');
        }
        else {
            toast.error("Atleast one detail should be added");
        }
    }

    const handleSubmitWithCustomLogic = async (event) => {
        event.preventDefault();
        await formik.handleSubmit(event);
    };

    const otherUniversity = (e) => {


        setother(e.target.value == "0");


    }


    return (
        <>
            <RegisterHeader />

            <section className='educational-detail'>
                {educationLevelData && <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-2">
                                <Steps2 activeStepCount={1} />
                            </div>
                        </div>
                        <div className="col-lg-12 my-lg-0 my-md-0 my-2">
                            <div className="form-area">
                                <div className="row align-items-center">
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-12 profile-back-btn">
                                        <div className="back-btn">
                                            <Link to='/personal-details'><i className="fa-solid fa-arrow-left mx-2"></i>Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-5 col-6 edu-title">
                                        <h1>Educational Details</h1>
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-6 ">
                                        <div className="">
                                            <button type="button" className="cust-add-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                                onClick={() => {
                                                    setIsEditing(false);
                                                    handleAdd();
                                                }
                                                }>
                                                Add
                                            </button>
                                        </div>
                                    </div>


                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead className='table-light'>
                                                <tr>
                                                    <th scope="col">Sr No.</th>
                                                    <th scope="col" >Educational Level</th>
                                                    <th scope="col" >University/institute</th>
                                                    <th scope="col">Course Duration</th>
                                                    <th scope="col" >Action</th>
                                                </tr>
                                            </thead>
                                            {educationalDetailsData && educationalDetailsData?.length > 0 && (
                                                <tbody className='education-details-t-body'>
                                                    {educationalDetailsData?.map((educationalDetail, index) => (
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td >{educationalDetail?.educationlevel?.educationlevel}</td>
                                                            <td >
                                                                <div className="universiity">
                                                                    {educationalDetail?.university_name ? educationalDetail?.university_name : educationalDetail?.university_details?.university}
                                                                </div>
                                                            </td>
                                                            <td>{educationalDetail?.start_year}{` to ${educationalDetail?.end_year ? educationalDetail?.end_year : "present"}`}</td>


                                                            <td className=''>

                                                                <button type='button' className="btn p-0 me-1 " data-bs-toggle="modal" data-bs-target="#staticBackdrop"

                                                                    onClick={() => {
                                                                        handleEdit(educationalDetail.id);
                                                                        const id = educationalDetail.id;
                                                                    }}
                                                                > <img src="./assets/my-profile/Icon-pen.png" alt="" /></button>
                                                                <button type='button' className="btn p-0 me-1"

                                                                    onClick={() => {
                                                                        handleDelete(educationalDetail.id);
                                                                        const id = educationalDetail.id;

                                                                    }
                                                                    }> <img src="./assets/my-profile/trush-square.png" alt="" /></button>

                                                            </td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            )}
                                        </table>
                                    </div>



                                    <div className="col-md-12 mt-5 mb-5 custm-btn-sec d-flex justify-content-center">
                                        <div className='custm-botton-btn mb-2'>
                                            <Link to='/professional-details'><button className='skip me-2 my-2 my-md-0 my-lg-0' type='button' >Skip</button></Link>
                                            <button className='next my-2 my-md-0 my-lg-0' type='button' onClick={handleNext} >Submit and Next</button>
                                        </div>

                                    </div>
                                    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-xl">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <div className="col-lg-12 col-12 d-flex justify-content-center position-relative">
                                                        <h1 className='edu-title text-center fw-bold'>{isEditing ? "Update" : "Add"} Educational Details</h1>
                                                        <button type="button" className="btn-close position-absolute end-0" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>


                                                </div>
                                                <div className="modal-body">
                                                    <form className='mt-5' method='post' onSubmit={handleSubmitWithCustomLogic}>

                                                        <div className="row align-items-start">


                                                            <div className="col-md-6 mb-3 " >
                                                                <label htmlFor="" className='mb-2'>Education Level<span>*</span></label>
                                                                <select name="education_level" className='form-control form-select' id=""
                                                                    onChange={(e) => {

                                                                        formik.handleChange(e)
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.education_level}>
                                                                    <option value="">Select Education level</option>

                                                                    {educationLevelData?.map(educationLevel => {
                                                                        return <option key={educationLevel?.id} value={educationLevel?.id}>{educationLevel?.educationlevel}</option>;
                                                                    })}

                                                                </select>
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.education_level && formik.errors.education_level ? (
                                                                        <div className='text-danger'>{formik.errors.education_level}</div>
                                                                    ) : null}
                                                                </div>

                                                            </div>
                                                            <div className="col-md-6 mb-3 ">
                                                                <label htmlFor="" className='mb-2'>Specialization<span>*</span></label>
                                                                <input type="text" name='specialization' className='form-control' placeholder='Specialization'
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.specialization} />
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.specialization && formik.errors.specialization ? (
                                                                        <div className='text-danger'>{formik.errors.specialization}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mb-3 ">
                                                                <label htmlFor="" className='mb-2'>University/Institute<span>*</span></label>
                                                                <select name="university" className='form-control  form-select' id=""
                                                                    onChange={(e) => {
                                                                        otherUniversity(e);
                                                                        formik.handleChange(e)
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.university}>
                                                                    <option value="">Select University/Institute</option>
                                                                    {universityData?.map(university => {
                                                                        return <option key={university?.id} value={university?.id}>{university?.university}</option>;
                                                                    })}
                                                                    <option value={0}>Other</option>
                                                                </select>
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.university && formik.errors.university ? (
                                                                        <div className='text-danger'>{formik.errors.university}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            {other &&
                                                                <div className="col-md-6 mb-3 ">
                                                                    <label htmlFor="" className='mb-2'>Other University/College<span>*</span></label>
                                                                    <input type="text" name='university_name' className='form-control' placeholder='Enter your University/College Name'
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        value={formik.values.university_name} />
                                                                    <div className="help-block with-errors">
                                                                        {formik.touched.university_name && formik.errors.university_name ? (
                                                                            <div className='text-danger'>{formik.errors.university_name}</div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>}
                                                            <div className="col-md-6 mb-3 ">
                                                                <label htmlFor="" className='mb-2'>Course Type<span>*</span></label>
                                                                <select name="course_type" className='form-control  form-select' id=""
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.course_type}>
                                                                    <option value="">Select Course Type</option>
                                                                    {courseTypeData?.map(courseType => {
                                                                        return <option key={courseType?.id} value={courseType?.id}> {courseType?.coursetypename} </option>;
                                                                    })}
                                                                </select>
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.course_type && formik.errors.course_type ? (
                                                                        <div className='text-danger'>{formik.errors.course_type}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12 mb-3 d-flex check-box">
                                                                <input
                                                                    type="checkbox"
                                                                    name='pursuing'
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.pursuing}
                                                                    checked={isPursuing}
                                                                    onChange={(e) => {
                                                                        handlePursuingChange(e);
                                                                        formik.handleChange(e);

                                                                    }} /> <span><label htmlFor="" className='mx-2'>Pursuing</label></span>

                                                            </div>
                                                            
                                                            <div className="col-md-6 mb-3 d-grid">
                                                            <label htmlFor="" className='mb-2'>Course Start Date<span>*</span></label>
                                                                <input type='date' name='start_year' className='form-control' placeholder='Start Month & Year'
                                                                  
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.start_year} />
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.start_year && formik.errors.start_year ? (
                                                                        <div className='text-danger'>{formik.errors.start_year}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            {!isPursuing
                                                                && (
                                                                    <div className="col-md-6 mb-3 d-grid">
                                                                        <label htmlFor="" className='mb-2'>Course End Date<span>*</span></label>
                                                                        <input type='date' name='end_year' className='form-control' placeholder='End Month & Year'
                                                                          
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.end_year}

                                                                        />
                                                                        <div className="help-block with-errors">
                                                                            {formik.touched.end_year && formik.errors.end_year ? (
                                                                                <div className='text-danger'>{formik.errors.end_year}</div>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                )}


                                                            <div className="col-md-12 mt-5 mb-5 custm-btn-sec d-flex align-items-center justify-content-center">

                                                                <div className='custm-botton-btn mb-2 text-center'>
                                                                    <button type='submit' className='next mx-2' name="myButton"
                                                                        disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : isEditing ? "Update and Submit" : "Submit"}</button>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >}
            </section >
           
            <Footer />
        </>
    )
}

export default EducationalDetails2;