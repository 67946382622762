import { useFormik } from 'formik';
import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER;

const SendMailForm = ({ seeker }) => {
    let [disable, setDisable] = useState(false);
    let initalValues = {
        message: ""
    }
    const formik = useFormik({
        initialValues: initalValues,
        validationSchema: Yup.object({
            message: Yup.string().required("Please enter a message to send.")
        }),
        onSubmit: (values, { resetForm }) => {
            const loadingToastId = toast.info('Sending mail...', { autoClose: false });
            setDisable(true);
            try {
                values.seeker_mail = seeker?.email;
                const config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } };
                axios.post(`${host}/recruiter/project/send-project-mail`, values, config)
                    .then(response => {
                        if (response.status == 200) {
                            resetForm();
                            setDisable(false);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                        } else {
                            setDisable(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    }).catch(error => {
                        console.log(error.message);
                        setDisable(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error!' });
                    })
            } catch (error) {
                console.log(error.message);
                setDisable(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error!' });
            }

        }
    });
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='col-lg-12 my-2'>
                    <div className='cd-form-f'>
                        <label className='d-block'><b>Send Mail to Seeker:</b></label>
                     
                        <textarea name="message" id="message" className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.message}
                        ></textarea>
                    </div>
                    <div className="help-block with-errors">
                        {formik.touched.message && formik.errors.message ? (
                            <div className='text-danger'>{formik.errors.message}</div>
                        ) : null}
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <button type='submit' className="btn btn-sm btn-primary" disabled={disable}>Send Mail {disable ? <i className="fas fa-spinner fa-spin"></i> : <i className="fa-solid fa-arrow-up"></i>}</button>
                </div>
            </form>
        </>
    )
}

export default SendMailForm