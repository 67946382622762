import { useFormik } from 'formik'
import * as Yup from "yup";
import React, { useState } from 'react'
import axios from 'axios';
import { error } from 'jquery';
import { toast } from 'react-toastify';
import HourglassLoader from '../../../Seeker/HourglassLoader';
import $ from 'jquery';
import { useLocation, useNavigate } from 'react-router-dom';
const host = process.env.REACT_APP_API_SERVER;

const AddAssessmentTItleModal = ({ reloadData }) => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const job_id = queryParams.get('job_id');

    const initialValues = {
        title: "",
        passing_percent:""
    }
    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().required("Please enter title of assessment."),
            passing_percent: Yup.string().required("Please enter passing percent for assessment.")
        }),
        onSubmit: (values, { resetForm }) => {
            setDisabled(true);
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                const config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } }
                axios.post(`${host}/recruiter/create-assessment`, values, config)
                    .then(response => {
                        if (response.status == 200) {
                            setDisabled(false);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Title added successfully" });
                            reloadData();
                            $("#createAssessmentCloseBtn").click();
                            resetForm();
                            navigate(job_id?`/create-assessment-questions?job_id=${job_id}`:'/create-assessment-questions', { state: { assessment_id: response.data.data.id, assessment_title:response.data.data.title } })
                        } else {
                            setDisabled(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    }).catch(error => {
                        setDisabled(false);
                        console.log(error.message)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
                    })
            } catch (error) {
                setDisabled(false);
                console.log(error.message)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
            }

        }
    })

    return (
        <>
            <div className="modal fade" id="createAssessment" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Create Assessment</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="createAssessmentCloseBtn"></button>
                        </div>
                        <div className="modal-body">
                            <form method="post" onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label htmlFor="title">Enter Assessment Title <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            name='title'
                                            placeholder='Enter assessment title'
                                            maxLength={100}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.title} />
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.title && formik.errors.title ? (
                                            <div className='text-danger'> {formik.errors.title}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="passing_percent">Enter Passing Percent <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            name='passing_percent'
                                            placeholder='Enter passing percent(between 1-100%)'
                                            maxLength={100}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.passing_percent} />
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.passing_percent && formik.errors.passing_percent ? (
                                            <div className='text-danger'> {formik.errors.passing_percent}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-6">
                                        {disabled ?
                                            <HourglassLoader />
                                            : <button className="btn btn-primary btn-sm" disabled={disabled}>Submit</button>}
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddAssessmentTItleModal