import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import Error from '../../Common/Error/Error';
import HourglassLoader from '../../Seeker/HourglassLoader';
import Header from '../Commons/Header';
import Footer from '../Commons/Footer';
import { fetchRecruiterProfileEditOnSeeker } from '../../../Redux/action/RecruiterAction';
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar';

let host = process.env.REACT_APP_API_SERVER;

const EditNews = () => {
    const { name } = useParams();

    console.log("name:::", name);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [disableBtn, setDisableBtn] = useState(false);
    const fileInputRef = useRef(null);

    const { loadingRecruiterProfileEditOnSeekerApi, recruiterProfileEditOnSeekerApiData, recruiterProfileEditOnSeekerApiError } = useSelector(
        (state) => state.RecruiterReducer
    );

    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            toast.error("Please login to continue.");
        } else {
            dispatch(fetchRecruiterProfileEditOnSeeker(name, localStorage.getItem("recruiter_token")));
        }
    }, [dispatch, name, navigate]);

    const formik = useFormik({
        initialValues: {
            media_type: "",
            image: null,
            description: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            media_type: Yup.string().required("Please select media type."),
            description: Yup.string().required("Plese enter description."),
            image: Yup.mixed().test(
                "required",
                "Please select a file.",
                function (value) {
                    return value || recruiterProfileEditOnSeekerApiData?.path;
                }
            ),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const id = recruiterProfileEditOnSeekerApiData?.id;
            setSubmitting(true);
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            setDisableBtn(true);
            document.body.style.overflow = 'hidden';

            const formData = new FormData();
            formData.append("media_type", values.media_type);
            if (values.image) {
                formData.append("image", values.image);
            }
            formData.append("description", values.description);

            axios.post(`${host}/recruiter/update-recruiter-profile-news?id=${id}`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                    "auth-token": localStorage.getItem("recruiter_token")
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        setSubmitting(false);
                        setDisableBtn(false);
                        document.body.style.overflow = 'auto';
                        resetForm();
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'News Updated Successfully!' });
                        navigate('/view-news');
                    } else {
                        handleError(response.data.msg, loadingToastId, setSubmitting, setDisableBtn);
                    }
                })
                .catch(error => {
                    handleError('Error submitting News. Please try again.', loadingToastId, setSubmitting, setDisableBtn);
                });
        }
    });

    const handleError = (message, toastId, setSubmitting, setDisableBtn) => {
        setSubmitting(false);
        setDisableBtn(false);
        document.body.style.overflow = 'auto';
        toast.update(toastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: message });
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        formik.setFieldValue("image", file);
    };

    useEffect(() => {
        if (recruiterProfileEditOnSeekerApiData) {
            formik.setValues({
                media_type: recruiterProfileEditOnSeekerApiData?.media_type ?? "",
                image: recruiterProfileEditOnSeekerApiData?.path ? `${host}/${recruiterProfileEditOnSeekerApiData.path}` : null,
                description: recruiterProfileEditOnSeekerApiData?.description ?? "",
            });
        }
    }, [recruiterProfileEditOnSeekerApiData]);

    if (loadingRecruiterProfileEditOnSeekerApi) {
        return (
            <div className="overlay-form-submission">
                <Loader />
            </div>
        );
    }

    if (recruiterProfileEditOnSeekerApiError) {
        return <Error message={recruiterProfileEditOnSeekerApiError} />;
    }

    return (
        <>
            <RecAfterLoginNavbar />
            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10 mb-4">
                        <h4><b>Edit News</b></h4>
                    </div>
                    <div className="col-lg-8 com-md-10 border shadow rounded p-5">
                        <form method='post' onSubmit={formik.handleSubmit} encType='multipart/form-data'>
                            <div className="row mx-auto">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="media_type" className="form-label"><b>Media Type</b><span className="text-danger">*</span></label>
                                        <select
                                            name="media_type"
                                            className='form-control form-select'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.media_type}>
                                            <option>--Select Media Type--</option>
                                            <option value="IMAGE">Image</option>
                                            <option value="VIDEO">Video</option>
                                        </select>
                                        <div className="help-block with-errors">
                                            {formik.touched.media_type && formik.errors.media_type ? (
                                                <div className='text-danger'>{formik.errors.media_type}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="image" className="form-label"><b>Upload File</b><span className="text-danger">*</span></label>
                                        <input
                                            name='image'
                                            className="form-control"
                                            type="file"
                                            id="image"
                                            accept="image/*,video/*"
                                            ref={fileInputRef}
                                            onChange={handleFileChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.image && formik.errors.image ? (
                                                <div className='text-danger'>{formik.errors.image}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div>
                                        <Link to={`${host}/${recruiterProfileEditOnSeekerApiData?.path}`} className="btn btn-sm btn-outline-primary" target='_blank'>View</Link>
                                      
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="description" className="form-label"><b>Description</b><span className="text-danger">*</span></label>
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            rows={3}
                                            name='description'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.description}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.description && formik.errors.description ? (
                                                <div className='text-danger'>{formik.errors.description}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className=" update-button" disabled={disableBtn}>
                                        {disableBtn ? <HourglassLoader /> : "Submit"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default EditNews;
