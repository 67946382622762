import React, { useEffect, useState } from 'react'
import './scss/DescribeTheJob.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getJobDetailsData, getRecruiterregistrationData } from '../../../../Redux/action/RecruiterAction';
import { useFormik } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const host = process.env.REACT_APP_API_SERVER;

const EditDescribeTheJobContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("job_id")) {
            let job_id = localStorage.getItem("job_id")
            dispatch(getJobDetailsData(job_id))
        }
        if (localStorage.getItem("recruiter_token")) {
            setIsLoggedIn(true);
            let authToken = localStorage.getItem("recruiter_token");
            dispatch(getRecruiterregistrationData(authToken));
        }
    }, []);
    const jobDetailsData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.jobdetailsDataList?.jobDetails) : "")


    const [initialValues, setInitialValues] = useState({
        description: ""
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            description: Yup.string().required("Please enter job description.")
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            // console.log(values)
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                setSubmitting(true);
                document.body.style.overflow = 'hidden';
                const config = {
                    headers: {
                        'auth-token': localStorage?.getItem("recruiter_token")
                    }
                };
                formik.values.job_id = localStorage.getItem("job_id");
                await axios.post(`${host}/api/update-job-description`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Details updated successfully!' });
                            navigate('/edit-set-preferences');
                        } else {
                            console.log("in else block of .then method:", response.data.msg);
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        console.log(error.message);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    })
            } catch (error) {
                console.log("catch:", error);
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    })


    useEffect(() => {
        if (jobDetailsData) {
            // console.log(jobDetailsData);
            formik.setValues({
                description: jobDetailsData?.description ?? "",
            });
        }
    }, [jobDetailsData])
    return (
        <>
            <section className='describe-the-job-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2>Describe the Job</h2>
                                    </div>
                                </div>
                            </div>
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <div className='card-outer-dv'>
                                    <div className='inner-input'>
                                        <div className='inner-input mb-2 mb-md-2 mb-lg-4'>
                                            <label htmlFor='no-peaple' className='my-2'>Job Descriptions<span className='text-danger'>*</span></label>
                                            <div className='text-area-custom'>
                                                <div className='textarea-combo-dv'>
                                                    
                                                    <CKEditor
                                                        name="description"
                                                        config={{
                                                            height: 200,
                                                            validation: {
                                                                maxLength: 500 
                                                            },
                                                            
                                                            fontSize: {
                                                                options: [
                                                                    9,
                                                                    11,
                                                                    13,
                                                                    'default',
                                                                    17,
                                                                    19,
                                                                    21
                                                                ]
                                                            },
                                                            toolbar: [
                                                                "undo",
                                                                "redo",
                                                                'bold',
                                                                'italic',
                                                                "fontSize",
                                                                "bulletedList",
                                                                "numberedList"
                                                            ],
                                                        }}
                                                        editor={ClassicEditor}
                                                        data={formik.values.description}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            formik.setFieldValue('description', data);
                                                        }}
                                                    />
                                                  
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.description && formik.errors.description ? (
                                                        <div className='text-danger'>{formik.errors.description}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bottom-button-dv'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-12 text-end'>
                                            <div className='bottom-btn'>
                                                <Link to='/edit-pay-benefits' type='submit' className='a-tag-btn1'>Back</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 text-start'>
                                            <div className='bottom-btn'>                                              
                                                <button className="next-btn mt-0" type='submit'>Save and Next</button>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditDescribeTheJobContent;