import React from 'react'
import Header from '../../Commons/Header'
import Footer from '../../Commons/Footer'
import SearchedResume from './SearchedResume'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'

const SearchedResumeHome = () => {
    return (
        <>
           
            <RecAfterLoginNavbar/>
            <SearchedResume />
            <Footer />
        </>
    )
}

export default SearchedResumeHome