import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import axios from 'axios';
import EditEducationDetailModal from './EditEducationDetailModal';
import { useNavigate } from 'react-router-dom';
import AddEducationDetailModal from './AddEducationDetailModal';
const host = process.env.REACT_APP_API_SERVER;

const AddEducation = ({ setEducation, data, refresh, handleNext, handleBack, handlePreviewResume }) => {
  let [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleDelete = (id) => {
    const loadingToastId = toast.info('Submitting form...', { autoClose: false });
    try {
      document.body.style.overflow = "hidden";
      setLoader(true);
      let del_url;
      let config;
      if (localStorage.getItem("seeker_token")) {
        del_url = `${host}/cvbuilder/delete-cvbuilder-seeker-education?education_detail_id=${id}`;
        config = {
          headers: {
            'content-type': 'application/json',
            "auth-token": localStorage.getItem("seeker_token")
          }
        }
      } else if (localStorage.getItem("karlatoon_deviceId")) {
        del_url = `${host}/cvbuilder/delete-cvbuilder-seeker-education-guest?education_detail_id=${id}`;
        config = {
          headers: {
            'content-type': 'application/json',
            "device-token": localStorage.getItem("karlatoon_deviceId")
          }
        }
      } else {
        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Token expired.' });
        navigate('/build-resume')
      }
      axios.get(del_url, config)
        .then(function (response) {
          if (response.status == 200) {
            refresh();
            document.body.style.overflow = 'auto';
            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
          } else {
            document.body.style.overflow = 'auto';
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
          }
        })
        .catch(function (error) {
          document.body.style.overflow = 'auto';
          toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal Server Error' });
        })
    } catch (error) {
      document.body.style.overflow = 'auto';
      toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal Server Error' });
    }
  }

  function formatDate(dateString) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const dateParts = dateString.split("-");
    const day = dateParts[2];
    const month = months[parseInt(dateParts[1]) - 1];
    const year = dateParts[0];
    return `${day}-${month} ${year}`;
  }

  return (
    <>
      <div className='education-details'>
        <div className="col-lg-12 col-md-12 row justify-content-end">
          <button className="btn next-btn-resume width-fit-content float-end" data-bs-toggle="modal" data-bs-target="#addEducationDetailModal">
            Add Education
          </button>
          <AddEducationDetailModal refresh={refresh} id={data.id} />
        </div>
        {data?.cvbuildereducationaldetails && data?.cvbuildereducationaldetails.length > 0 ?
          <div className="row d-grid-education-details">
            {data?.cvbuildereducationaldetails.map((education, index) => {
              return <div className="card mt-3" key={index + 1}>
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="font-semibold">{education?.education_level}</h5>
                  </div>
                  <p className="card-text mt-2 mb-0">
                    {education?.specialization ? <b><i>{education?.specialization}</i></b> : <i>Enter Specialization</i>}
                  </p>
                  <p className="card-text mt-2 mb-0">
                    {education?.university_name} (<i>{formatDate(education?.start)}{education?.pursuing ? "-Pursuing" : education?.end ? <span>-{formatDate(education?.end)}</span> : ""}</i>)
                  </p>
                 
                  <div className="d-flex justify-content-end align-items-center gap-2">
                    <div style={{ cursor: 'pointer' }} title='Edit Education Detail'>
                      <EditIcon data-bs-toggle="modal" data-bs-target={`#exampleModalEducationDetail${education?.id}`} />
                      <EditEducationDetailModal data={education} id={education?.id} refresh={refresh} />
                    </div>
                    <DeleteIcon onClick={() => { handleDelete(education?.id) }} style={{ cursor: "pointer" }} />
                  </div>
                </div>
              </div>
            })
            }
          </div> :
          <div className="text-center my-2">
            <i>No information found.</i>
          </div>
        }
        
        <div className="d-flex justify-content-center my-3 custom-stepper-button">        
          <button className="btn prev-btn-resume mx-1" onClick={handleBack}>Previous</button>
          <button className="btn next-btn-resume mx-1" onClick={handleNext}>Save & Next</button>
          <button className="btn prev-btn-resume mx-1" onClick={handlePreviewResume}>Preview</button>
        </div>
      </div>
    </>
  )
}

export default AddEducation