import React, { useEffect, useState } from 'react'
import './scss/ListOfJobFair.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER;


const ListOfJobFairContent = ({ postedJobList }) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [showJobList, setShowJobList] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const handleShowList = () => {
        const section = document.getElementById("posted_jobs_list");

        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
        setShowJobList(true);
    }


    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 10;
    const [showPagination, setShowPagination] = useState(false)
    useEffect(() => {

        const endOffset = dataOffset + dataPerPage;
        setCurrentData(postedJobList.slice(dataOffset, endOffset));
        setPageCount(Math.ceil(postedJobList?.length / dataPerPage));
        if (pageCount > 1) {
            setShowPagination(true);
        }
        else {
            setShowPagination(false);
        }
    }, [dataOffset, dataPerPage, postedJobList])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage) % postedJobList?.length;
        setDataOffset(newOffset);
    };

    const removeJobIdandNavigate = () => {
        if (localStorage.getItem("job_id")) {
            localStorage.removeItem("job_id");
        } const loadingToastId = toast.info('Redirecting...', { autoClose: false });
        try {
            document.body.overflow = "hidden";
            setShowLoader(true);

            axios.get(`${host}/recruiter/check-job-post-possible`, { headers: { "auth-token": localStorage.getItem("recruiter_token") } })
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.allowJobPost == true) {
                            document.body.style.overflow = "auto";
                            setShowLoader(false);
                            toast.dismiss(loadingToastId)
                            navigate("/add-job-basics");
                        } else {
                            document.body.style.overflow = "auto";
                            setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: "Please purchase a subscription to continue." });
                            navigate(`/choose-subscription-plan`)
                        }
                    } else {
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                    }
                }).catch((error) => {
                    console.log(error.message);
                    document.body.style.overflow = 'auto';
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
                })
        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = 'auto';
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
        }
    }

    const handlePostSame = (job_id) => {
        const loadingToastId = toast.info('Redirecting...', { autoClose: false });
        try {
            document.body.overflow = "hidden";
            setShowLoader(true);

            axios.get(`${host}/recruiter/check-job-post-possible`, { headers: { "auth-token": localStorage.getItem("recruiter_token") } })
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.allowJobPost == true) {
                            document.body.style.overflow = "auto";
                            setShowLoader(false);
                            toast.dismiss(loadingToastId)
                            navigate(`/rec-review-page?old_job_id=${job_id}`)
                        } else {
                            document.body.style.overflow = "auto";
                            setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: "Please purchase a subscription to continue." });
                            navigate(`/choose-subscription-plan`)
                        }
                    } else {
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                    }
                }).catch((error) => {
                    console.log(error.message);
                    document.body.style.overflow = 'auto';
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
                })
        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = 'auto';
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
        }
    }

    const handlePostSmartRecommendation = (job_id) => {
        const loadingToastId = toast.info('Redirecting...', { autoClose: false });
        try {
            document.body.overflow = "hidden";
            setShowLoader(true);

            axios.get(`${host}/recruiter/check-job-post-possible`, { headers: { "auth-token": localStorage.getItem("recruiter_token") } })
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.allowJobPost == true) {
                            document.body.style.overflow = "auto";
                            setShowLoader(false);
                            toast.dismiss(loadingToastId)
                            navigate(`/rec-recomm-jobtemplate-list`)
                        } else {
                            document.body.style.overflow = "auto";
                            setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: "Please purchase a subscription to continue." });
                            navigate(`/choose-subscription-plan`)
                        }
                    } else {
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                    }
                }).catch((error) => {
                    console.log(error.message);
                    document.body.style.overflow = 'auto';
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
                })

        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = 'auto';
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
        }
    }

    const handleCheckSubscriptionandRedirect = () => {
        const loadingToastId = toast.info('Redirecting...', { autoClose: false });
        try {
            document.body.overflow = "hidden";
            setShowLoader(true);

            axios.get(`${host}/recruiter/check-recruiter-job-fair-plan`, { headers: { "auth-token": localStorage.getItem("recruiter_token") } })
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.applyJobFair == true) {
                            document.body.style.overflow = "auto";
                            setShowLoader(false);
                            toast.dismiss(loadingToastId)
                            navigate(`/create-jobs-for-job-fair`)
                        } else {
                            document.body.style.overflow = "auto";
                            setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: "Please purchase a subscription to continue." });
                            navigate(`/choose-subscription-plan`)
                        }
                    } else {
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                    }
                }).catch((error) => {
                    console.log(error.message);
                    document.body.style.overflow = 'auto';
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
                })

        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = 'auto';
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
        }
    }
    const handleEditJob = (job_id) => {
        const loadingToastId = toast.info('Redirecting...', { autoClose: false });
        document.body.overflow = "hidden";
        setShowLoader(true);
        try {
            document.body.style.overflow = 'auto';
            setShowLoader(false);
            navigate(`/edit-jobs-for-job-fair?job_id=${job_id}`)
            toast.dismiss(loadingToastId);
        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = 'auto';
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
        }
    }

    return (
        <>
            <section className='like-post-your-job-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className='heading'>
                                        <h2>Job Fair</h2>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className='top-image'>
                                        <img src='./assets/recruiter-images/post-a-job/create-a-post.png' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading-inner'>
                                        <h3>Post Jobs in Job Fair<span className='text-danger'>*</span></h3>
                                    </div>
                                    <div className="heading-inner">
                                        <ul>
                                            <li>Jobs posted in Job fair are different from Jobs posted normally.</li>
                                            <li>The applications received on Jobs posted on Job Fair will be sent only over mail provided and will have no record on portal for both Job Seeker and Recruiter ends.</li>
                                            <li>Jobs posted for Job Fair will only be reflected after Admin approval.</li>
                                            <li>Jobs can not be edited after Admin action(approval or rejection).</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='card-outer-dv'>
                                <div className='row justify-content-center align-items-center'>
                                    <div className='col-lg-8 col-md-8 col-12'>
                                        <div className='left-sd-content'>
                                            <h5>Create new job for Job Fair</h5>
                                            <p>Creating a new Job will go to Admin for visibility approval.</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-12 text-lg-end text-md-end text-center'>
                                        <div className='right-sd-btn'>
                                            <button className='continue-btn' onClick={handleCheckSubscriptionandRedirect}>+ Create</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Show Jobs Posted Listing */}
                <div className='container mt-2' id="posted_jobs_list">
                    <div className='create-an-emp-inner mt-2'>
                        <div className='container'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">S. No.</th>
                                        <th scope="col">Job Title</th>
                                        <th scope="col">Date Posted</th>
                                        <th scope="col">Action</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        postedJobList?.length ?
                                            currentData.map((job, index) => {
                                                return <tr key={job?.id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{job?.job_title}</td>
                                                    <td>{new Date(job?.createdAt).toDateString()}</td>
                                                    <td>{job?.current_status == "PENDING" && <button onClick={() => { handleEditJob(job?.id) }} className="btn btn-sm post-similar-btn">Edit</button>}</td>
                                                    <td>
                                                        {job.current_status == "PENDING" && <span>&#9201;</span>}
                                                        {job.current_status == "APPROVED" && <span>&#10004;</span>}
                                                        {job.current_status == "REJECTED" && <span>&#10008;</span>}
                                                        {job.current_status == "EXPIRED" && <span>&#x26D4;</span>}
                                                        {job?.current_status}
                                                    </td>
                                                </tr>
                                            })
                                            : <tr>
                                                <td colSpan="3"><i>No data found.</i></td>
                                            </tr>
                                    }
                                </tbody>
                            </table>

                        </div>
                        {showPagination &&
                            <div className='row justify-content-center'>
                                <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                    <section className='pagination-section'>
                                        <div className='container'>
                                            <nav aria-label="Page navigation example" className='nav-class'>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel=">>"
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel="<<"
                                                    renderOnZeroPageCount={null}
                                                    containerClassName='pagination'
                                                    pageLinkClassName='page-num'
                                                    previousLinkClassName='page-num'
                                                    nextLinkClassName='page-num'
                                                    activeClassName='active'
                                                />
                                            </nav>
                                        </div>
                                    </section>

                                </div>
                            </div>}
                    </div>
                </div>

            </section>
        </>
    )
}

export default ListOfJobFairContent