import React from 'react'
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
const host = process.env.REACT_APP_API_SERVER;


const NewsBanner = ({ data }) => {
    const truncateText = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length > wordLimit) {return words.slice(0, wordLimit).join(' ') + '...';}return text;
    };
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        const formattedDate = `${day}-${months[monthIndex]}-${year}`;
        return formattedDate;
    }
    return (
        <>
            {data && data.length > 0 && <section className="news-banner">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="custm-banner-area my-4" style={{ backgroundImage: `url("${host}/${data[0].image}")` }}>
                            <div className="col-lg-7 banner-info">
                                <div className="holy-btn mt-1">
                                    <Link to={`/karlatoon-news-category/${data[0]?.news_category?.name_slug}`}>{data[0]?.news_category ? data[0]?.news_category?.name : "Karlatoon"}</Link>
                                </div>
                                <div className="banner-text">
                                    <Link className='remove-link-property-news-page' to={`/karlatoon-news-detail/${data[0].news_slug}`}><h2 className='line-clamp-2'>{data[0]?.title}</h2></Link>
                                    <h3 className='line-clamp-2'> {parse(`${truncateText(data[0]?.description, 20)}`)}</h3>
                                </div>
                            </div>
                        </div>
                        {data.length > 1 && <div className="row banner-cards mb-4">

                            {data.map((news, index) => {
                                return (!index == 0) && <div className="col-lg-4 d-flex mb-lg-0 mb-3 pb-1" key={index + 1}>
                                    <div className="card-img c1">
                                        <Link to={`/karlatoon-news-category/${news?.news_category?.name_slug}`}>{news?.news_category?.name}</Link>
                                        <img src={`${host}/${news?.image}`} className='img-fluid' alt="" />
                                    </div>
                                    <div className="upper-title px-2">
                                        <ul className='d-flex'>                                          
                                            <li>{formatDate(news?.createdAt)}</li>
                                        </ul>
                                        <div className="para line-clamp-2">
                                            <Link className='remove-link-property-news-page' to={`/karlatoon-news-detail/${news.news_slug}`}><h3>{news?.title}</h3></Link>
                                        </div>
                                    </div>
                                </div>
                            })}
                           
                            {data.length >= 7 && <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="text-center my-2">
                                    <Link to="/karlatoon-news">View All</Link>
                                </div>
                            </div>}
                        </div>}
                    </div>
                </div>
            </section>
            }
        </>

    )
}

export default NewsBanner