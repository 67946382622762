import React from 'react'

import './scss/YouRegistereredSucJobSearch.css'
import { useNavigate } from 'react-router-dom'
const RegisterPopups = () => {
    const navigate = useNavigate();
    const handleRedirect = () => {
        document.body.style.overflow = "auto";
        window.open("https://play.google.com", '_blank');
    }
    return (
        <>
            <div className="modal fade" id="show-rating" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content popup-bg">
                        <div className="modal-header modal-header-custom ">
                            <button
                                type="button"
                                className="btn-close btn-popup"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setTimeout(() => {document.body.style.overflow = "auto";}, 1500);
                                }}
                            />
                        </div>
                        <div className="modal-body modal-body-custom">
                            <div className="popup-section">
                                <div className="pop-custom">
                                    <p>
                                        Trusted by 1.5 millions <br />
                                        people worldwide
                                    </p>
                                    <button onClick={handleRedirect}>Get App</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterPopups