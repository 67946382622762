import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

let host = process.env.REACT_APP_API_SERVER;
const WorkHistory = ({ setWork, id, refresh }) => {



    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);

    const [working, setworking] = useState(true);
    const formik = useFormik({
        initialValues: {
            job_title: "",

            current_last_company: "",
            currently_working: "",
            start: "",
            end: "",
            job_description: "",
        },
        validationSchema: Yup.object({
            job_title: Yup.string().required("Job title is required"),

            current_last_company: Yup.string().required("Company name is required."),


            start: Yup.date()
                .max(new Date(), "Job start date cannot be in the future")
                .required("Job start date is required"),
            end:
                working ? Yup.date()
                    .max(new Date(), "Job end date cannot be in the future")
                    .min(
                        Yup.ref('start'),
                        "Job end date cannot be earlier than Job start date")
                    .required('Job end date is required') : Yup.string(),

        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });

            try {


                setShowLoader(true);
                setDisableBtn(true);
                if (values.currently_working === true) {
                    values.end = ''
                }


                document.body.style.overflow = 'hidden';

               

                values.cvbuilder_id = id

              

                let post_url;
                let config;

                if (localStorage.getItem("seeker_token")) {
                    post_url = `${host}/cvbuilder/add-cvbuilder-seeker-profession`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "auth-token": localStorage.getItem("seeker_token")
                        }
                    }
                } else if (localStorage.getItem("karlatoon_deviceId")) {
                    post_url = `${host}/cvbuilder/add-cvbuilder-seeker-profession-guest`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "device-token": localStorage.getItem("karlatoon_deviceId")
                        }
                    }
                } else {
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Token expired.' });
                    navigate('/build-resume')
                }

                axios.post(post_url, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false);
                            setShowLoader(false);
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Work details added successfully!' });
                            refresh();
                            setWork(false)
                            refresh();
                        } else {
                            setSubmitting(false);
                            setShowLoader(false);
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        document.body.style.overflow = 'auto';
                        setSubmitting(false);
                        setShowLoader(false);
                        setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    });

            } catch (error) {
                document.body.style.overflow = 'auto';
                console.log(error.message);
                setSubmitting(false);
                setShowLoader(false);
                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    });
    return (
        <div className='mt-3'>
            <form method='post' onSubmit={formik.handleSubmit}>
                <div className='row'>
                    <div className="mb-3 col-md-6">
                        <label htmlFor="Title" className="form-label">
                            <b>Job Title<span className='text-danger'>*</span></b>
                        </label>
                        <input type="text" name='job_title' className='form-control' placeholder='Job Title'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.job_title}
                        />
                        <div className="help-block with-errors">
                            {formik.touched.job_title && formik.errors.job_title ? (
                                <div className='text-danger'>{formik.errors.job_title}</div>
                            ) : null}
                        </div>

                    </div>
                    <div className="mb-3 col-md-6">
                        <label htmlFor="Employer" className="form-label">
                            <b>Company name<span className='text-danger'>*</span></b>
                        </label>
                        <input type="text" name='current_last_company' className='form-control' placeholder='Client'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.current_last_company}
                        />
                        <div className="help-block with-errors">
                            {formik.touched.current_last_company && formik.errors.current_last_company ? (
                                <div className='text-danger'>{formik.errors.current_last_company}</div>
                            ) : null}
                        </div>

                    </div>
                    <div className="col-12"> <input type="checkbox" className="form-check-input" id="exampleCheck1" name='currently_working'
                        onChange={(e) => {
                            setworking(e.target.value == 'true');
                            formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.currently_working}
                    // checked={!inProcess}
                    />
                        <label className="form-check-label ms-3" htmlFor="exampleCheck1">
                            I currently work here
                        </label></div>

                    <div className="mb-3 col-md-6">
                        <label htmlFor="Start Year" className="form-label">
                            <b> Start Year<span className='text-danger'>*</span></b>
                        </label>
                        <input type='date' name='start' className='form-control' placeholder='Start Month & Year'

                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.start}

                        />

                        <div className="help-block with-errors">
                            {formik.touched.start && formik.errors.start ? (
                                <div className='text-danger'>{formik.errors.start}</div>
                            ) : null}
                        </div>

                    </div>

                    {(working) && <div className="mb-3 col-md-6">
                        <label htmlFor="End Year" className="form-label">
                            <b> End Year<span className='text-danger'>*</span></b>
                        </label>
                        <input type='date' name='end' className='form-control' placeholder='End Month & Year'

                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.end}

                        />
                        <div className="help-block with-errors">
                            {formik.touched.end && formik.errors.end ? (
                                <div className='text-danger'>{formik.errors.end}</div>
                            ) : null}
                        </div>


                    </div>}
                    <div className="mb-3 col-md-12">
                        <label htmlFor="job_description" className="form-label">
                            <b>Description</b>
                        </label>
                        <div>
                            <CKEditor
                                name="job_description"
                                config={{ height: 200 }}
                                editor={ClassicEditor}
                                data={formik.values.job_description}
                                // onBlur={formik.handleBlur}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    formik.setFieldValue('job_description', data);
                                }}
                            />
                            <div className="help-block with-errors mt-2">
                                {formik.touched.job_description && formik.errors.job_description ? (
                                    <div className='text-danger'>{formik.errors.job_description}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 col-md-12 text-center">
                        <button type="submit" disabled={disableBtn} className="btn btn-primary btn-sm w-fit me-1">
                            + Add
                        </button>
                        <button className="btn btn-outline-primary btn-sm" onClick={() => { setWork(false) }}>Show all</button>
                    </div>




                </div>
            </form>
        </div>
    )
}

export default WorkHistory