import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
const host = process.env.REACT_APP_API_SERVER;

const SetNoteForm = ({ seeker, projectid, note, reload }) => {
    let [disable, setDisable] = useState(false);
    let initalValues = {
        note: "",
    }
    const formik = useFormik({
        initialValues: initalValues,
        validationSchema: Yup.object({
            note: Yup.string().required("Enter a note to save."),
        }),
        onSubmit: (values, { resetForm }) => {
            const loadingToastId = toast.info('Adding note...', { autoClose: false });
            setDisable(true);
            try {
                values.seekerid = seeker?.id;
                values.projectid = projectid;
                const config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } };
                axios.post(`${host}/recruiter/project/add-note`, values, config)
                    .then(response => {
                        if (response.status == 200) {
                            setDisable(false);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                        } else {
                            setDisable(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    }).catch(error => {
                        console.log(error.message);
                        setDisable(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error!' });
                    })
            } catch (error) {
                console.log(error.message);
                setDisable(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error!' });
            }

        }
    });

    useEffect(() => {
        console.log("note", note);
        if (note) {
            formik.setFieldValue("note", note);
        } else {
            formik.setFieldValue("note", "");
        }
    }, [note])

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='col-lg-12 my-2'>
                    <div className='cd-form-f'>
                        <label className='d-block'><b>Set Note:</b></label>
                        <textarea name="note" id="note" className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.note}
                        ></textarea>
                    </div>
                    <div className="help-block with-errors">
                        {formik.touched.note && formik.errors.note ? (
                            <div className='text-danger'>{formik.errors.note}</div>
                        ) : null}
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <button type='submit' className="btn btn-sm btn-primary" disabled={disable}>Save Note {disable && <i className="fas fa-spinner fa-spin"></i>}</button>
                </div>
            </form>
        </>
    )
}

export default SetNoteForm