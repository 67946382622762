import React, { useEffect, useState } from 'react'
import './scss/PopularJobs.css'
import Slider from "react-slick";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPopularJobsData } from '../../../../../Redux/action/SeekerAction';
import Loader from '../../../Loader';
import { getRandomColor, createImageFromInitials } from '../../../../Utils';
let host = process.env.REACT_APP_API_SERVER;
const PopularJobs = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showPopularJobs, isShowPopularJobs] = useState(false);
    useEffect(() => {dispatch(getPopularJobsData());}, [])
    let popularJobs = useSelector(state => state?.SeekerReducer ? state.SeekerReducer?.popularjobsList?.data : "");
   useEffect(() => {
        if (popularJobs) {isShowPopularJobs(true);}
    }, [popularJobs]);
    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
    }
        const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const previousDate = new Date(timestamp);

        const timeDifference = currentDate - previousDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        if (months > 0) {return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) { return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {return 'Just now';
        }
    };
    const settings = {dots: false,infinite: popularJobs && popularJobs?.length > 3,autoplay: true,autoplaySpeed: 3000,slidesToShow: 3,slidesToScroll: 1,responsive: [{breakpoint: 1024,settings: {slidesToShow: 3,slidesToScroll: 1,infinite: popularJobs && popularJobs?.length > 3,dots: false}},{breakpoint: 780,settings: {slidesToShow: 2,slidesToScroll: 2,infinite: popularJobs && popularJobs?.length > 2,dots: false,arrows: true}},{breakpoint: 480,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: popularJobs && popularJobs?.length > 1,dots: false,arrows: true}}]}
    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');if (words?.length > wordLimit) {return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };
    return (
        <>
            <section className='popular-jobs-section'>
                <div className="main-section">
                    <div className="container">
                        <div className="second-section">
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='similer-post-heading'>
                                        <h2 className='ms-3'>Popular Jobs</h2>
                                    </div>
                                </div>
                            </div>
                            {
                                !showPopularJobs ?
                                    <div className='text-center'><Loader /></div> :
                                    popularJobs && popularJobs?.length > 0 ? <Slider {...settings} className='mb-5'>
                                        {popularJobs?.map(job => {
                                            const JobName = job?.job_data?.Company_details?.company_name || "";
                                            const truncatedJobName = truncateText(JobName, 2);

                                            return <div className="col-lg-4" key={job?.job_data?.id}>
                                                <div className="card">
                                                    <div className="card-sub-dv">
                                                        <div className='company_image'>
                                                            <img
                                                                className='img-fluid'
                                                                src={job?.job_data?.Company_details?.logo ? `${host}/uploaded-files/${job?.job_data?.Company_details?.logo}` : `${createImageFromInitials(500, job?.job_data?.Company_details?.company_name, getRandomColor())}`} alt={job?.job_data?.Company_details?.company_name} title={job?.job_data?.Company_details?.company_name}
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null; 
                                                                    currentTarget.src = createImageFromInitials(500, job?.job_data?.Company_details?.company_name, getRandomColor());

                                                                }}

                                                            />
                                                        </div>
                                                        <div className="footer-heading mx-2">
                                                            <h3><Link to={`/career-result-description?j=${job?.job_data?.job_slug}`}>{job?.job_data?.job_title}</Link></h3>
                                                             <p className='d-md-flex justify-content-between'><Link to={`/profile/${job?.job_data?.Company_details?.company_slug}/snap-shot`} className='remove-link-style' title={job?.job_data?.Company_details?.company_name}><span>{truncatedJobName}</span></Link>
                                                                <span>
                                                                    <Link className='cursor-default'>New post</Link>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="footer-list-card">
                                                        <ul>
                                                            {job?.job_data?.Company_details && <li>
                                                                <img src="/assets/images_another/mappinline.png" alt="" />
                                                                {job?.job_data?.Company_details?.location_city?.city_name}, {job?.job_data?.Company_details?.location_city?.state_of_city?.country_of_state?.name}
                                                            </li>
                                                            }


                                                            <li className='d-grid'>
                                                                <span>
                                                                    {job?.job_data?.job_type_list?.length > 0 && <> < img src="/assets/images_another/Clock.png" alt="" />
                                                                        {job?.job_data?.job_type_list[0]?.job_type_detail?.jobtypename}</>}</span>
                                                              
                                                                {(job.job_data.show_pay_by) && <span>
                                                                    <img src="/assets/images_another/currency-wallet.png" alt="" title='Salary' />
                                                                    {job?.job_data?.show_pay_by == "Range" && <span> {job?.job_data?.min_pay ? `${job?.job_data?.currency} ${job?.job_data?.min_pay} ` : ""}{job?.job_data?.max_pay ? `- ${job?.job_data?.currency} ${job?.job_data?.max_pay} ` : ""} {job?.job_data?.rate_per_hour}</span>}
                                                                    {job?.job_data?.show_pay_by == "Starting Amount" && <span> {job?.job_data?.amount ? ` ${job?.job_data?.currency} ${job?.job_data?.amount}` : ""} {job?.job_data?.rate_per_hour}</span>}
                                                                    {job?.job_data?.show_pay_by == "Maximum Amount" && <span> {job?.job_data?.amount ? ` ${job?.job_data?.currency} ${job?.job_data?.amount}` : ""} {job?.job_data?.rate_per_hour}</span>}
                                                                    {job?.job_data?.show_pay_by == "Exact Amount" && <span> {job?.job_data?.amount ? ` ${job?.job_data?.currency} ${job?.job_data?.amount}` : ""} {job?.job_data?.rate_per_hour}</span>}

                                                                </span>}
                                                            </li>
                                                            <li>
                                                                <div className="d-flex justify-content-between">
                                                                    <span><i className="fa-solid fa-calendar-days me-1" title="Job posted date"></i>{formatDate(job?.job_data?.createdAt)}</span>
                                                                    <span><i className="fa-solid fa-clock me-1"></i>{timeAgoCalculator(job?.job_data?.createdAt)}</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        })}

                                    </Slider>
                                        :
                                        <div className="text-center">
                                            <i>No jobs available.</i>
                                        </div>
                            }


                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}
export default PopularJobs