import React, { useEffect, useState } from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import BeforeFooterPlayStore from '../../Commons/BeforeFooter/BeforeFooterPlayStore'
import Footer from '../../Commons/Footer'
import CareerGuideCategoryNewContent from './CareerGuideCategoryNewContent'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getBlogWithCategoryData, getBlogsByCategory, getSixInterviewQuestions } from '../../../../Redux/action/MasterAction'
import { getBlogCategoryData, getBlogData } from '../../../../Redux/action/RecruiterAction'
import { toast } from 'react-toastify'
import Loader from '../../Loader'
import CareerSearch from '../CareerSearch'

const CareerGuideCategoryNew = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [sixQuestions, setSixQuestions] = useState(null);

    const [blog, setBlog] = useState(null);
    const [blogByCat, setBlogByCat] = useState(null);
    const { name } = useParams();

    useEffect(() => {
        dispatch(getBlogWithCategoryData());
        if (!name) {
            toast.error("Category id is missing.");
            navigate(-1)
        }
    }, [])

    useEffect(() => {
        if (name) {
            dispatch(getBlogsByCategory(name));
        }
    }, [name])


    const blogbyCatData = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.blogByCatListData?.data : null);

    useEffect(() => {
        if (blogbyCatData) {
            setBlogByCat(blogbyCatData)
        }
    }, [blogbyCatData]);

    useEffect(() => {
        dispatch(getSixInterviewQuestions());
        dispatch(getBlogCategoryData());
    }, [])
    const blogCategoryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.blogCategoryDataList?.data) : []);

    return (
        <>{blogbyCatData ? <>
            < AfterNavbar />
            {blogCategoryData && <CareerSearch blogCategoryData={blogCategoryData} />}
            {blogbyCatData && <CareerGuideCategoryNewContent blogData={blogbyCatData} />}
            <BeforeFooterPlayStore />
            <Footer />
        </> :
            <div className="overlay-form-submission">
                <Loader />
            </div>
        }</>
    )
}

export default CareerGuideCategoryNew