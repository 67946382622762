import React, { useEffect, useState } from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../../Commons/Footer'
import InterViewQuestionListingContent from './InterViewQuestionListingContent'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getInterviewQuestions } from '../../../../Redux/action/MasterAction'
import InterviewQuestions from '../InterviewQuestions'

const InterViewQuestionListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sixQuestions, setSixQuestions] = useState(null);
  useEffect(() => {
    dispatch(getInterviewQuestions());}, [])
  const questions = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.interviewQuestionsList : null)
  useEffect(() => {if (questions) {setSixQuestions(questions)}}, [questions])
  return (
    <>
      <AfterNavbar />
      {questions && questions?.length > 0 && <InterViewQuestionListingContent questions={sixQuestions} />}
      <Footer />
    </>
  )
}

export default InterViewQuestionListing