import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getAllFeedbackData } from '../../../Redux/action/SeekerAction';
import { createImageFromInitials, getRandomColor } from '../../Utils';
const host = process.env.REACT_APP_API_SERVER
const AllFeedback = ({ id, company }) => {

    const [length, setLength] = useState(false)
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const company_id = queryParams.get('id');
    const settings = {dots: false,infinite: company?.allFeedbacks && company?.allFeedbacks?.length > 3,autoplaySpeed: 2000,autoplay: true,slidesToShow: 3,slidesToScroll: 1,centerMode: true,centerPadding: '0px',
        responsive: [
            {breakpoint: 1024,settings: {slidesToShow: 3,slidesToScroll: 3,infinite: company?.allFeedbacks && company?.allFeedbacks?.length > 3,dots: true,arrows: true}},

            {breakpoint: 780,settings: {slidesToShow: 2,slidesToScroll: 2,infinite: company?.allFeedbacks && company?.allFeedbacks?.length > 2,dots: true,arrows: true}},
            {breakpoint: 480,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: company?.allFeedbacks && company?.allFeedbacks?.length > 1,dots: false,arrows: true
                }}
        ]
    };
    const totalStars = 5;
    return (
        <section className='testimonial'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='heading-txt'>
                            <h1>What People are Saying !</h1>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <Slider {...settings} className='mt-5 mb-5'>
                        {company && company?.allFeedbacks?.length > 0 ? company?.allFeedbacks?.map((data) => (
                            <div className='col-lg-4' >
                                <div className='test-card' key={data?.id}>
                                    <div className='star d-flex'>
                                        {Array.from({ length: data?.rating }).map((_, index) => (
                                            <i className="fa fa-star rated" style={{ color: 'yellow' }} />
                                        ))}

                                        {Array.from({ length: totalStars - data?.rating }).map((_, index) => (
                                            <i className="fa fa-star" />
                                        ))}
                                    </div>
                                    <p className='pt-3'>
                                        {data?.feedback}
                                    </p>
                                    <div className='client d-flex'>
                                        <div className='avatar d-flex testimonial-home-play-add'>                                           
                                            <div className='testimonial-home-play-add-img-parent'>                                               
                                                <img src={data?.seeker_det?.profile_img ? `${host}/uploaded-files/${data?.seeker_det?.profile_img}` : 'assets/images/homepage/avatar1.png'}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = createImageFromInitials(500, `${data?.seeker_det?.firstName} ${data?.seeker_det?.lastName}`, getRandomColor());
                                                    }}
                                                />
                                            </div>
                                            <div className='client-info'>
                                                <h5>{data?.seeker_det?.firstName} {data?.seeker_det?.lastName}</h5>
                                            </div>
                                        </div>
                                        <img src='assets/images/homepage/Quote.png'></img>
                                    </div>
                                </div>
                            </div>
                        )) :
                            <div className="text-center">
                                <i>No feebacks available.</i>
                            </div>
                        }

                    </Slider>
                </div>
            </div>

        </section>
    )
}

export default AllFeedback