import { React, useState, useEffect } from 'react'
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { FocusError } from 'focus-formik-error';
import * as Yup from "yup";
import './scss/ContactUs.css'
import { getContactUsData } from '../../../Redux/action/SeekerAction';
import { Link } from 'react-router-dom';
let host = process.env.REACT_APP_API_SERVER;
const ContactUsContent = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getContactUsData());
    }, [])

    const contactUsData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.contactUsDataList?.data) : []);

    let [initialValues, setInitialValues] = useState({
        firstName: "",
        message: "",
        email: "",
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            firstName: Yup.string().required("Name is required")
                .matches(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńňñòóôöõøùúûüýÿžźżçčššžąĄćĆęĘłŁńŃóÓśŚźŹżŻ .'-]+$/, "Enter a valid Name"),
        email: Yup.string().required("Email is required")
            .matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid E-mail"),
        message: Yup.string().required("Enquiry message is required"),
    }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {setSubmitting(true);document.body.style.overflow = 'hidden';await axios.post(`${host}/recruiter/contactUs`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Form Submitted Succesfully" });
                            resetForm();
                        } else {
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {

                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    })
            }
            catch (error) {
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
})

return (
    <>
        <section className='contact-us-section'>
            <div className="main-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="main-head">
                                <h2>Contact Us</h2>
                            </div>
                        </div>
                    </div>
                    <div className="banner-section ">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="banner-head mt-5">
                                    <h2>Contact Us</h2>
                                </div>
                                <form method='post' onSubmit={formik.handleSubmit} >
                                    <FocusError formik={formik} />
                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <div className='form-field-class'>
                                                <label htmlFor="name1" className="form-label">
                                                    Name<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    className="form-control form-custom"
                                                    id="name1"
                                                    type="text"
                                                    name="firstName"
                                                    placeholder="Enter your Name"
                                                    maxLength={50}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.firstName}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.firstName && formik.errors.firstName ? (
                                                    <div className='text-danger'>{formik.errors.firstName}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <div className='form-field-class'>
                                                <label htmlFor="mail" className="form-label">
                                                    Email<span className="text-danger">*</span>
                                                </label>
                                                
                                                <input
                                                    className="form-control form-custom"
                                                    id="email"
                                                    type="text"
                                                    name="email"
                                                    placeholder="Enter your Email"
                                                    maxLength={50}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.email && formik.errors.email ? (
                                                    <div className='text-danger'>{formik.errors.email}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <div className='form-field-class'>
                                                <label htmlFor="message" className="form-label">
                                                    What can we help you with?<span className="text-danger">*</span>
                                                </label>
                                                <textarea
                                                    className="form-control form-custom3"
                                                    id="exampleFormControlTextarea1"
                                                    rows={3}
                                                    placeholder="Type your message here"
                                                    name='message'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.message}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.message && formik.errors.message ? (
                                                    <div className='text-danger'>{formik.errors.message}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="join-btn text-center">
                                        <button type='submit'>Submit</button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-6 ">
                                <div className="first-head name">
                                    <div className="display-flex-custom">
                                        <div className="first-box">
                                            <div className="quote-box">
                                                <div className="quote">
                                                    <p>We are always here to help</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="second-box">
                                            <div className="quote-box">
                                                <div className="quote">
                                                    <p>Hello There !</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <img
                                        src="./assets/images_another/operator-img.png"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        {/* ================Contact Card=============== */}
        <section className='call-card py-5'>
            <div className="container">
                <div className="row mx-4">
                    <div className="col-lg-4 col-md-6">
                        <div className="card-area">
                            <div className="d-flex align-items-center">
                                <img src="./assets/images_another/mail.png" alt="" />
                                <div className="textarea">
                                    <h4>Drop a line</h4>
                                    <h2>Mail Us</h2>
                                </div>
                            </div>
                            <p><Link to={`mailto:${contactUsData?.email_one}`}>{contactUsData?.email_one}</Link></p>
                            <p><Link to={`mailto:${contactUsData?.etwol_one}`}>{contactUsData?.email_two}</Link></p>
                        
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="card-area">
                            <div className="d-flex align-items-center">
                                <img src="./assets/images_another/phone-call.png" alt="" />
                                <div className="textarea">
                                    <h4>24/7 Service</h4>
                                    <h2>Call Us</h2>
                                </div>
                            </div>
                            <p><Link to={`tel:${contactUsData?.mobile_one}`}>{contactUsData?.mobile_one}</Link> <span>(Toll free)</span></p>
                            <p><Link to={`tel:${contactUsData?.mobile_two}`}>{contactUsData?.mobile_two}</Link></p>
                           
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="card-area">
                            <div className="d-flex align-items-center">
                                <img src="./assets/images_another/map-pin.png" alt="" />
                                <div className="textarea">
                                    <h4>Location</h4>
                                    <h2>Visit Us</h2>
                                </div>
                            </div>
                            <p>{contactUsData?.address}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
)
}

export default ContactUsContent