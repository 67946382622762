import React, { useEffect } from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'
import Footer from '../Commons/Footer'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ChatContent from './ChatContent'
import { getSeekerChatList } from '../../../Redux/action/SeekerAction'
import Loader from '../Loader'
import Error from '../../Common/Error/Error'
const Chat = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loadingSeekerChatList,SeekerChatListData, SeekerChatListError,} = useSelector(state => state?.SeekerReducer);
    useEffect(() => { let seeker_token = localStorage.getItem("seeker_token");
        if (seeker_token) {dispatch(getSeekerChatList(seeker_token)); } else {toast.error("Please login to continue."); navigate(-1);
        }    }, []);

    const refresh = () => {
        try {let seeker_token = localStorage.getItem("seeker_token");

            if (seeker_token) {dispatch(getSeekerChatList(seeker_token));
            } else {toast.error("Please login to continue.");navigate(-1);}
        } catch (error) {console.log(error.message); toast.error("Internal server error.") }
    }


    if (loadingSeekerChatList) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (SeekerChatListError) {
        return <Error message={SeekerChatListError} />;
    }
    return (
        <>
            <AfterNavbar />
            <ChatContent data={SeekerChatListData} refresh={refresh}/>         
            <Footer />
        </>
    )
}

export default Chat