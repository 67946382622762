import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import './scss/OurPartnerSlider.css';
import $ from 'jquery';
import Slider from "react-slick";
import { getOurPartnerSliderData } from '../../../../Redux/action/RecruiterAction';
const host = process.env.REACT_APP_API_SERVER;
const OurPartnerSlider = () => {
    const dispatch = useDispatch();
    useEffect(() => {dispatch(getOurPartnerSliderData());}, [])
    const [length, setLength] = useState(false)
    const ourPartnerSliderData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.ourPartnerSliderDataList?.data) : []);
     useEffect(() => { if (ourPartnerSliderData?.length > 4) {setLength(true) }
    }, [ourPartnerSliderData])
    const settingsOurpartner = {
dots: false,infinite: length,autoplay: true,autoplaySpeed: 3000,slidesToShow: 4,slidesToScroll: 1,arrows: true,responsive: [{breakpoint: 1024,settings: {slidesToShow: 4,slidesToScroll: 1,infinite: true,dots: true}},{breakpoint: 768,settings: {slidesToShow: 2,slidesToScroll: 1,infinite: true,dots: true,arrows: false}},{breakpoint: 767,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: true,dots: true,arrows: false}},{breakpoint: 480,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: true,dots: true,        arrows: false
                }}]}
    return (
        <>
            <section className='our-partner-sec'>
                <div className="container">
                    <section className="slider-section mt-5">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="about-slider-heading">
                                    <h2>Our Partner</h2>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-4">
                                <Slider {...settingsOurpartner} className="slider-card">
                                    {ourPartnerSliderData?.map((data) => (<div className="card-desgin">
                                        <a href='#'><img src={data?.partner_img ? `${host}/${data?.partner_img}` : "./assets/about-us/about-slider-1.png"} alt="" /></a>
                                    </div>))}

                                </Slider>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </>
    )
}
export default OurPartnerSlider