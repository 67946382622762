import React from 'react'
import AfterNavbar from '../../../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../../../Commons/Footer'
import AssessmentQuestionContent from './AssessmentQuestionContent'
const AssessmentQuestion = () => {
  return (
    <>
    <AfterNavbar />
    <AssessmentQuestionContent/>
    <Footer />
    </>
  )
}
export default AssessmentQuestion