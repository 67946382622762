import React, { useEffect, useState } from 'react'
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import "./CareerPreferencesPopups.css"
import { Formik, useFormik } from 'formik'
import * as Yup from "yup";
import { toast } from 'react-toastify'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select';
import { getJobRoleData } from '../../../Redux/action/SeekerAction'
const host = process.env.REACT_APP_API_SERVER

const CareerPreferencesPopupsJobTitle = ({ data, reload }) => {
    console.log(data);
    const [state, setState] = useState([]);
    const dispatch = useDispatch();
    const [job_titles, setJob_titles] = useState([]);
    useEffect(() => {
        dispatch(getJobRoleData());
    }, []);

    const job_titleSelector = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.job_roleList : []);
    const job_titleOptions = [];
    job_titleSelector && job_titleSelector?.map(job_title => {
        job_titleOptions.push({ value: job_title.jobrole, label: job_title.jobrole })
    })
    
    let initialValues = {job_title: [],   salary: ''}
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({            
            job_title: Yup.array().min(1, 'Please select a job title.').required('Please select a job title.'),
            salary: Yup.string().matches(/^[0-9]+$/, "Salary can only contain numbers.").required("Salary is required.")
        }),
        onSubmit: (values, { resetForm }) => {           
            values.job_role = values.job_title;
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });            
            const config = { headers: { "auth-token": localStorage.getItem("seeker_token") } };
            try {
                axios.post(`${host}/mobile/add-career-prefernces-job-role`, values, config)
                    .then(response => {
                        if (response.status == 200) {
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                            
                            reload();
                            const button = document.getElementById('close-job-title-modal-btn');
                            button.click();
                        } else {
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
            } catch (error) {
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server Error." });
            }
        }
    });

    useEffect(() => {
        if (data) {
            const job_role = data?.job_role ? data?.job_role?.job_role : [];
            formik.setValues({
                job_title: job_role,
                salary: data?.salary
            })
        }
    }, [data]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const value = e.target.value.trim();
            if (value !== '') {
                setState([...state, value]);
                e.target.value = ''; // Clear the input field
            }
        }
    };
    const handleKeyDown2 = (e) => {
        if (e.keyCode === 32) {
            e.preventDefault();
        }
    };
    const handleKeyDownFORM = (e) => {
        if (e.key === 'Enter' && e.target.value.trim() !== '') {
            e.preventDefault();
            const tagsArray = Array.isArray(formik.values.tags) ? formik.values.tags : [];
            formik.setValues({
                ...formik.values,
                tags: [...tagsArray, e.target.value.trim()],
                job_title: ''
            });
        }
    };
    const handleDeleteTag = (index) => {
        const newTags = [...formik.values.tags];
        newTags.splice(index, 1);
        formik.setValues({
            ...formik.values,
            tags: newTags
        });
    };

    return (
        <>
            {/* =========================add-job-titles-body-parent start============================= */}
            <div className="modal fade add-job-titles-body-parent" id="add-job-titles" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form method='post' onSubmit={formik.handleSubmit}>
                            <div className="modal-header">
                                <h1 className="modal-title" id="exampleModalLabel">
                                    Add preferred job role
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className="add-job-title-input-field">
                                    <div style={{ margin: 10 }}>
                                        <label htmlFor="job_title">Enter Preferred Job Role: <span className='text-danger'>*</span></label>
                                        <div className='input-group'>                                            
                                            <Select
                                                id="job_title"
                                                name="job_title"
                                                placeholder="Select Preferred Job Role"
                                                options={job_titleOptions}
                                                value={job_titleOptions.filter(option => formik.values.job_title.includes(option.value))}
                                                onChange={job_title => {
                                                    formik.setFieldValue(
                                                        'job_title',
                                                        job_title ? job_title.map(option => option.value) : []
                                                    );
                                                }}
                                                onBlur={formik.handleBlur}
                                                isMulti
                                            />

                                        </div>
                                        
                                        <div className="help-block with-errors">
                                            {formik.touched.tags && formik.errors.tags ? (
                                                <div className='text-danger'>{formik.errors.tags}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div style={{ margin: 10 }}>
                                        <label htmlFor="salary">Enter Annual Salary: <span className='text-danger'>*</span></label>
                                        <div className='input-group'>

                                            <input
                                                className='form-control'
                                                id="salary"
                                                name='salary'
                                                placeholder='Enter annual salary'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.salary}
                                            />
                                        </div>
                                        <div className="help-block with-errors">
                                            {formik.touched.salary && formik.errors.salary ? (
                                                <div className='text-danger'>{formik.errors.salary}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary cancel-btn" id="close-job-title-modal-btn" data-bs-dismiss="modal">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary save-btn">
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* =========================add-job-types-body-parent end============================= */}
        </>

    )
}

export default CareerPreferencesPopupsJobTitle