const seekerInitialState = {cityList: [],job_roleList: [],QuestionType:[],industryList: null,fairJobsList: null,impaneledCompaniesList: null,highestPayJobsList: null,jobsByIndustryList: null,jobsByIndustrySlugList: null,industryByIdList: null,industryBySlugList: null,sixInterviewQuestionsList: null,interviewQuestionsList: null,jobsInAfganList: null,afganCitiesList: null,departmentList: null,currencyList: null,blogListData: null,blogByCatListData: null,famousCompaniesData: null,searchedBlogsData: null,newsPageData:null,allNewsData:null,newsBySlug:null,newsByCategorySlug:null,findJobCompanyBanner:null,phoneCodes:[]
}
const SeekerReducer = (state = seekerInitialState, action) => {
    switch (action.type) {case 'SET_CITIES_DATA':
            return {...state,cityList: action.payload,};
        case 'SET_JOBROLE_DATA':
            return {...state,job_roleList: action.payload,};
            case 'SET_QUESTION_TYPE':return {    ...state,    QuestionType: action.payload,};
        case 'SET_INDUSTRY_DATA':
            return {...state,industryList: action.payload,};
        case 'SET_FAIR_JOBS_DATA':
            return {...state,fairJobsList: action.payload,};
        case 'SET_IMPANELED_COMPANIES':
            return {...state,impaneledCompaniesList: action.payload,};
        case 'SET_HIGHEST_PAY_JOBS':
            return {...state,highestPayJobsList: action.payload,};
        case 'SET_JOBS_BY_INDUSTRY':
            return {...state,jobsByIndustryList: action.payload,};
        case 'SET_JOBS_BY_INDUSTRY_SLUG':
            return {...state,jobsByIndustrySlugList: action.payload,};
        case 'SET_INDUSTRY_BY_ID':
            return {...state,industryByIdList: action.payload,};
        case 'SET_INDUSTRY_BY_SLUG':
            return {...state,industryBySlugList: action.payload,};
        case 'SET_SIX_INTERVIEW_QUESTIONS':
            return {...state,sixInterviewQuestionsList: action.payload,};
        case 'SET_INTERVIEW_QUESTIONS':
            return {...state,interviewQuestionsList: action.payload,};
        case 'SET_JOBS_IN_AFGAN_DATA':
            return {...state,jobsInAfganList: action.payload,};
        case 'SET_AFGAN_CITIES':
            return {...state,afganCitiesList: action.payload,
            };
        case 'SET_DEPARTMENT_DATA':
            return {...state,departmentList: action.payload,};
        case 'SET_CURRENCY_DATA':
            return {...state,currencyList: action.payload,};
        case 'SET_BLOG_WITH_CATEGORY_DATA':
            return {...state,blogListData: action.payload,};
        case 'SET_BLOG_BY_CATEGORY_DATA':
            return {...state,blogByCatListData: action.payload,};
        case 'SEARCHED_BLOGS':
            return {...state,searchedBlogsData: action.payload, };
        case 'SET_FAMOUS_COMPANIES_DATA':
            return {...state,famousCompaniesData: action.payload, };
        case 'NEWSPAGE':
            return {...state,newsPageData: action.payload,};
        case 'ALL_NEWS':
            return {...state,allNewsData: action.payload,};
        case 'NEWS_BY_SLUG':
            return {...state,newsBySlug: action.payload, };
        case 'NEWS_BY_CATEGORY_SLUG':
            return {...state,newsByCategorySlug: action.payload,};
        case 'SET_FIND_JOB_COMPANY_BANNER_DATA':
            return {...state,findJobCompanyBanner: action.payload,};
        case 'SET_PHONE_CODE_DATA':
            return {...state,phoneCodes: action.payload,};
        default:return state;
    }
}

export default SeekerReducer;