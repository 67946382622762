import React from 'react'
import './scss/ImpactOfTechnology.css'
const ImpactOfTechnologyContent = () => {
    return (
        <>
            <section className='impact-technology-content-section'>
                <div className="main-section">
                    <div className="container">
                        <div className="technology-section">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="technology-back-btn">
                                        <a href="#">
                                            <img src="./assets/images_another/ion_arrow-back-outline.png" alt="" />
                                            Back
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="technology-center-section">
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
                                        <div className="tech-btn">
                                            <button className='techno-btn'>Technology</button>
                                        </div>
                                        <div className="tech-head">
                                            <h2>
                                                The Impact of Technology on the Workplace: How Technology is
                                                Changing
                                            </h2>
                                        </div>
                                        <div className="tech-para d-flex">
                                            <div className="d-flex ">
                                                <div>
                                                    <img src="./assets/images_another/profile-icon.png" className="img-fluid" alt="" />
                                                </div>
                                                <div className="mx-2">
                                                    <p>Tracey Wilson</p>
                                                </div>
                                            </div>
                                            <div className="mx-3">
                                                <p>August 20, 2022</p>
                                            </div>
                                        </div>
                                        <div className="main-center-banner mt-3">
                                            <img src="./assets/images_another/banner-technology.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="para-technology">
                                            <p>
                                                Traveling is an enriching experience that opens up new horizons,
                                                exposes us to different cultures, and creates memories that last
                                                a lifetime. However, traveling can also be stressful and
                                                overwhelming, especially if you don't plan and prepare
                                                adequately. In this blog article, we'll explore tips and tricks
                                                for a memorable journey and how to make the most of your
                                                travels.
                                            </p>
                                            <p>
                                                One of the most rewarding aspects of traveling is immersing
                                                yourself in the local culture and customs. This includes trying
                                                local cuisine, attending cultural events and festivals, and
                                                interacting with locals. Learning a few phrases in the local
                                                language can also go a long way in making connections and
                                                showing respect.
                                            </p>
                                        </div>
                                        <div className="technology-heading">
                                            <div>
                                                <h4>Research Your Destination</h4>
                                                <p>
                                                    Before embarking on your journey, take the time to research
                                                    your destination. This includes understanding the local
                                                    culture, customs, and laws, as well as identifying top
                                                    attractions, restaurants, and accommodations. Doing so will
                                                    help you navigate your destination with confidence and avoid
                                                    any cultural faux pas.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                    do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    In hendrerit gravida rutrum quisque non tellus orci ac auctor.
                                                    Mi ipsum faucibus vitae aliquet nec ullamcorper sit amet.
                                                    Aenean euismod elementum nisi quis eleifend quam adipiscing
                                                    vitae. Viverra adipiscing at in tellus.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="technology-heading">
                                            <div>
                                                <h4>Plan Your Itinerary</h4>
                                                <p>
                                                    While it's essential to leave room for spontaneity and
                                                    unexpected adventures, having a rough itinerary can help you
                                                    make the most of your time and budget. Identify the must-see
                                                    sights and experiences and prioritize them according to your
                                                    interests and preferences. This will help you avoid
                                                    overscheduling and ensure that you have time to relax and
                                                    enjoy your journey.
                                                </p>
                                                <p>
                                                    Vitae sapien pellentesque habitant morbi tristique. Luctus
                                                    venenatis lectus magna fringilla. Nec ullamcorper sit amet
                                                    risus nullam eget felis. Tincidunt arcu non sodales neque
                                                    sodales ut etiam sit amet.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="technology-heading">
                                            <div>
                                                <h4>Conclusion:</h4>
                                                <p>
                                                    Traveling is an art form that requires a blend of planning,
                                                    preparation, and spontaneity. By following these tips and
                                                    tricks, you can make the most of your journey and create
                                                    memories that last a lifetime. So pack your bags, embrace the
                                                    adventure, and enjoy the ride.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ImpactOfTechnologyContent