import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import './scss/PopularCompaniesSlider.css'
import { Link } from 'react-router-dom';
import { getPopularCompanies } from '../../../../../Redux/action/SeekerAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Loader';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { createImageFromInitials, getRandomColor } from '../../../../Utils';
import parse from 'html-react-parser';
const host = process.env.REACT_APP_API_SERVER;
const PopularCompnaiesSlider = () => {
    const totalStars = 5;
    const settingsPopularCompanies = {dots: false,infinite: true,autoplay: true,autoplaySpeed: 3000,slidesToShow: 3,slidesToScroll: 1,responsive: [    {breakpoint: 1024,settings: {    slidesToShow: 2,    slidesToScroll: 1,    infinite: true,    dots: false}    },    {breakpoint: 780,settings: {    slidesToShow: 1,    slidesToScroll: 1,    infinite: true,    dots: false,    arrows: true}    },    {breakpoint: 480,settings: {    slidesToShow: 1,    slidesToScroll: 1,    infinite: true,    dots: false,    arrows: false}    }
]};
    const dispatch = useDispatch();
    const [showPopularCompanies, setShowPopularCompanies] = useState(false);
    useEffect(() => {dispatch(getPopularCompanies());
    }, []);
    let popularCompaniesData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.popularcompaniesDataList : [])
    useEffect(() => {
        if (popularCompaniesData) {setShowPopularCompanies(true);} else { setShowPopularCompanies(false);}}, [popularCompaniesData])
    const truncateText = (text, wordLimit) => {const words = text?.split(' ');
        if (words?.length > wordLimit) {return words.slice(0, wordLimit).join(' ') + '...';}return text;
    };
    const truncateWord = (text, wordLimit) => {
        if (text?.length > wordLimit) {return text.slice(0, wordLimit) + '...';}return text;
    };
    return (
        <>
            <section className='popular-companies-slider-section'>
                <div className="main-section">
                    <div className="container">
                        <div className="second-section">
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='similer-post-heading'>
                                        <h2 className='mb-3'>Popular Companies</h2>
                                    </div>
                                </div>
                            </div>
                            {showPopularCompanies ? <Slider {...settingsPopularCompanies} className='mb-5'>
                                {
                                    popularCompaniesData?.map(company => {
                                        return <div className="col-lg-4" key={company?.id}>
                                            <div className="card ">
                                                <div className="d-flex">
                                                    <div>                                                       
                                                        <Link to={`/profile/${company?.company_slug}/Jobs`}>
                                                            <img
                                                                className='img-fluid logo-img'
                                                                src={company?.logo ? `${host}/uploaded-files/${company?.logo}` : `${createImageFromInitials(500, company?.company_name, getRandomColor())}`}
                                                                alt={company?.company_name} title={company?.company_name}
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null;
                                                                    currentTarget.src = createImageFromInitials(500, company?.company_name, getRandomColor());
                                                                }}
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div className="footer-heading footer-icon mx-2">
                                                        <div className='d-flex mb-2 align-items-start justify-content-between'>                                                          
                                                            <h3 className='line-clamp-comp-name'><Link to={`/profile/${company?.company_slug}/Jobs`}>{company?.company_name}</Link></h3>
                                                        </div>                                                     
                                                           
                                                            <span className='d-flex popular-companies-spans'>
                                                                {Array.from({ length: Math.floor(parseFloat(company?.avg_rating)) }, (_, index) => (
                                                                    <StarIcon key={`full-${index}`} />
                                                                ))}
                                                                {parseFloat(company?.avg_rating) % 1 !== 0 && <StarHalfIcon key="half" />}
                                                                {Array.from({ length: totalStars - Math.floor(parseFloat(company?.avg_rating)) - (parseFloat(company?.avg_rating) % 1 !== 0 ? 1 : 0) }, (_, index) => (
                                                                    <StarBorderIcon key={`empty-${index}`} />
                                                                ))}
                                                               
                                                                <span>{parseFloat(company?.avg_rating) !== 0 && parseFloat(company?.avg_rating).toFixed(1)}</span>
                                                            </span>
                                                        
                                                    </div>
                                                </div>
                                                <div className="footer-list-card-mt-2 d-flex justify-content-start ">
                                                    <Link  className='decoration-none' to={`/profile/${company?.company_slug}/Jobs`}><p className='text-black mb-0' > Show Jobs</p></Link>
                                                </div>
                                              
                                            </div>
                                        </div>
                                    })
                                }                              

                            </Slider> : <div className="text-center"><Loader /></div>}

                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default PopularCompnaiesSlider