import React, { useState } from 'react'
import './scss/FreeJobPostCanOnlyGetApplications.css'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../../Seeker/Loader';
const host = process.env.REACT_APP_API_SERVER;
const FreeJobPostCanOnlyGetApplicationsContent = () => {

    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);

    const handleContinueButton = () => {
        try {
            const job_id = localStorage.getItem("job_id");
            const values = {
                job_id: job_id
            }
            const config = {
                headers: {
                    'auth-token': localStorage?.getItem("recruiter_token")
                }
            };
            const loadingToastId = toast.info('Submitting details...', { autoClose: false });
            document.body.style.overflow = 'hidden';
            setShowLoader(true);
            axios.post(`${host}/api/update-job-completion`, values, config)
                .then(response => {
                    if (response.status == 200) {
                        document.body.style.overflow = 'auto';
                        setShowLoader(false);
                        localStorage.removeItem("job_id");
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Job posted successfully." });
                        navigate("/post-job");
                    } else {
                        document.body.style.overflow = 'auto';
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
                    }
                }).catch(error => {
                    console.log(error.message);
                    document.body.style.overflow = 'auto';
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error' });

                })
        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = 'auto';
            setShowLoader(false);
            toast.error("Internal server error");
        }
    }

    const handleSponsorButton = () => {
        try {
            const job_id = localStorage.getItem("job_id");
            navigate("/rec-choose-your-plan", { state: { job_id: job_id } });
        } catch (error) {
            console.log(error.message);
            toast.error("Internal server error");
        }
    }
    return (
        <>
            <section className='free-job-post-get-application-sec'>
                <div className='container'>
                    <div className='choose-you-plan-internal'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-12 text-center'>
                                <div className='heading-dv'>
                                    <h1>Free Job Post Can Only Get up to 10 Applications</h1>
                                    <div className='row'>
                                        <div className='col-lg-8 col-md-8 offset-lg-2 offset-lg-2'>
                                            <p>Upgrade to a sponsored job plan</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-lg-4 mt-md-4 mt-1 align-items-center'>
                            <div className='col-lg-6 col-md-6 col-12 mt-md-0 mt-lg-0 mt-3'>
                                <div className='left-side-dv'>
                                    <div className='content'>
                                        <h3>Post for Free</h3>
                                        <p className='second-p'>Boost your job post with a daily or monthly budget</p>
                                        {/* <h2>$50</h2>
                                        <p>Suggest per day</p> */}
                                        <ul>
                                            <li>Increases job post visibility Appear more often in of people looking for job like yours.</li>
                                        </ul>
                                    </div>
                                    <div className='content-bottom-btn'>
                                        {/* <Link to='/post-job' className='get-started'>Continue </Link> */}
                                        <button className='get-started' onClick={handleContinueButton}>Continue </button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12 mt-md-0 mt-lg-0 mt-3'>
                                <div className='right-side-dv'>
                                    <div className='content'>
                                        <h3>Sponsor this Job</h3>
                                        <p className='second-p'>Appear more often in of people looking for job like yours.</p>
                                        {/* <h2>$50</h2>
                                        <p>Per application</p> */}
                                        <ul>
                                            <li>No limit on applications</li>
                                            <li>Increased reach in relevant search results on Karlatoon</li>
                                            <li>Match candidate whose resume fit your job description</li>
                                        </ul>
                                    </div>
                                    <div className='content-bottom-btn'>
                                        {/* <Link to="/get-job-front-people" className='get-started'>Sponsor</Link> */}
                                        <button className='get-started' onClick={handleSponsorButton}>Sponsor</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {showLoader && <div className="overlay-form-submission">
                    <Loader />
                </div>}
            </section>
        </>
    )
}

export default FreeJobPostCanOnlyGetApplicationsContent