import React from 'react'
import "./CareerGuideCategoryNew.css";
import parse from 'html-react-parser'
import { Link, useNavigate } from 'react-router-dom';
const host = process.env.REACT_APP_API_SERVER;
const CareerGuideCategoryNewContent = ({ blogData }) => {
    const navigate = useNavigate();
    const handleMoveToNews = (id) => {
        navigate(`#movediv${id}`);
    }
    return (
        <>
            <section className='career-guide-category-new-content-section'>
                <div className="container">
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="career-guide-heading">
                                <h1>{blogData?.name}</h1>
                                <h6>{blogData?.description}</h6>
                            </div>
                        </div>
                    </div>
                    {blogData && blogData?.blog && blogData.blog?.length > 0 ? <>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="feature-article-heading">
                                    <h2>Latest Articles</h2>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="feature-article-left">
                                    <div className="img-parent-dv">                                       
                                        <img src={`${host}/${blogData?.blog[0]?.image}`} alt="" className='img-fluid' />
                                        <div className="img-parent-heading">
                                            <h4 className='line-clamp-3-career-guide-category-new cursor-pointer-career-guide' onClick={() => handleMoveToNews(0)}>{blogData?.blog[0]?.title}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {blogData?.blog[1] && <div className="col-md-6">
                                <div className="feature-article-right">
                                    <div className="img-parent-dv">
                                        <img src={`${host}/${blogData?.blog[1]?.image}`} alt="" className='img-fluid' />
                                        <div className="img-parent-heading">
                                            <h4 className='line-clamp-3-career-guide-category-new cursor-pointer-career-guide' onClick={() => handleMoveToNews(1)}>{blogData?.blog[1]?.title}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="row my-4">
                            <div className="col-md-12">
                                <div className="blog-listing-parent">                                    
                                    {blogData && blogData?.blog && blogData?.blog.map((blog, index) => {

                                        return <div className="blog-list-content-parent my-2" key={index + 1} id={`movediv${index}`}>
                                            <h6><span>{blogData?.name}</span></h6>
                                            <Link to={`/blog-detail/${blog.blog_slug}`}><h3>{blog?.title}</h3></Link>
                                            <p className="py-2"><small>By Admin, Karlatoon</small></p>
                                            <p>{parse(blog?.summmary)}
                                            </p>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </> :
                        <div className="row my-3">
                            <div className="col-md-12">
                                <div className="feature-article-heading">
                                    <h3> <i>No articles found.</i></h3>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section >
        </>
    )
}

export default CareerGuideCategoryNewContent