import React, { useEffect, useState } from 'react'
import AddPersonalDetails from './AddPersonalDetails/AddPersonalDetails'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../Loader';
import { useDispatch, useSelector } from 'react-redux';
import { checkAssessmentQuestionExists } from '../../../Redux/action/SeekerAction';
const YourCareerStartHere = () => {
  const [job_id, setJob_id] = useState(0);const [showJob, setShowJob] = useState(false);const location = useLocation();const navigate = useNavigate(); const dispatch = useDispatch(); const [isAssessmentIncluded, setIsAssessmentIncluded] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('j');
  useEffect(() => {if (!id) {toast.error("Please enter correct URL."); setShowJob(false); document.body.style.overflow = 'hidden';navigate(-1);
    } else {dispatch(checkAssessmentQuestionExists(localStorage.getItem("seeker_token"), id));setShowJob(true);setJob_id(id);document.body.style.overflow = 'auto';};}, [id]);
  const checkAssessmentQuestionExist = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.checkAssessmentQuestionExistData : null);
  useEffect(() => {if (checkAssessmentQuestionExist) {setIsAssessmentIncluded(checkAssessmentQuestionExist?.included_assessment);
    }}, [checkAssessmentQuestionExist])
  return (
    <>     
      {!showJob ? <div className="overlay-form-submission"><Loader /></div> : <>
        <AddPersonalDetails job_id={job_id} isAssessmentIncluded={isAssessmentIncluded}/>
      </>
      }
    </>
  )
}
export default YourCareerStartHere