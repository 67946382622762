import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import Footer from '../Commons/Footer'
import { toast } from 'react-toastify';
import { fetchCompanyProfileDetails } from '../../../Redux/action/RecruiterAction';
import Loader from '../Loader';
import Error from '../../Common/Error/Error';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER;
const UpdateCompanyBanner = () => {
    const [bannerPreview, setBannerPreview] = useState(null);
    const [ceoImagePreview, setCeoImagePreview] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loadingCompanyProfileDetails, CompanyProfileDetailsApiData, CompanyProfileDetailsApiError } = useSelector(
        (state) => state.RecruiterReducer
    );
    const validationSchema = Yup.object({
        banner: Yup.mixed().required('Please select banner image.'),
        ceo_image: Yup.mixed().required('Please select CEO image.'),
        yt_link: Yup.string().url('Invalid URL format').required('Please enter youtube link.'),
    });
    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            toast.error("Please login to continue.");
            navigate("/recruiter-login");
        } else {
            dispatch(fetchCompanyProfileDetails(localStorage.getItem("recruiter_token")));
        }
    }, [dispatch, navigate]);
    useEffect(() => {
        if (CompanyProfileDetailsApiData) {
            if (CompanyProfileDetailsApiData.banner) {
                setBannerPreview(`${host}/uploaded-files/${CompanyProfileDetailsApiData.banner}`);
            }
            if (CompanyProfileDetailsApiData.ceo_image) {
                setCeoImagePreview(`${host}/uploaded-files/${CompanyProfileDetailsApiData.ceo_image}`);
            }
        }
    }, [CompanyProfileDetailsApiData]);
    const formik = useFormik({
        initialValues: {
            banner: bannerPreview || null,
            ceo_image: ceoImagePreview || null,
            yt_link: CompanyProfileDetailsApiData?.yt_link || ''
        },
        validationSchema,
        onSubmit: async (values) => {
            const formData = new FormData();           
            formData.append("banner",values.banner);
            formData.append("ceo_image",values.ceo_image);
            formData.append('yt_link', values.yt_link);
            try {
                const response = await axios.post(
                    `${host}/recruiter/recruiter-banner-ceo-ytlink-update`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            "auth-token": localStorage.getItem("recruiter_token")
                        },
                    }
                );
                if (response.data.code === 200) {
                    toast.success(response?.data?.msg);
                    dispatch(fetchCompanyProfileDetails(localStorage.getItem("recruiter_token")));
                } else {
                    toast.warn(response?.data?.msg);
                }
            } catch (error) {
                toast.error("Internal server error.")
                console.error('Error:', error);
            }
        },
        enableReinitialize: true,
    });


    if (loadingCompanyProfileDetails) {
        return (
            <div className="overlay-form-submission">
                <Loader />
            </div>
        );
    }

    if (CompanyProfileDetailsApiError) {
        return <Error message={CompanyProfileDetailsApiError} />;
    }
    console.log(CompanyProfileDetailsApiData, "CompanyProfileDetailsApiData")

    return (
        <>
            <RecAfterLoginNavbar />
            <div className="container my-5">

                <div className='row justify-content-center'>
                    <div className="col-lg-8 col-md-10 border shadow rounded p-5">

                        <form className="row" onSubmit={formik.handleSubmit}>
                            <div className="col-12 mb-4">
                                <h4><b>Update Company Details</b></h4>
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="banner" className="font-semibold text-sm mb-2">
                                    Banner Image<span className="text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="file"
                                    id="banner"
                                    name="banner"
                                    onChange={(event) => {
                                        formik.setFieldValue('banner', event.target.files[0]);
                                    }}
                                />
                                {bannerPreview && (
                                    <div className="mt-2">
                                        <img src={bannerPreview} alt="Banner Preview" width="200" height='200' style={{ objectFit: "contain" }} />
                                    </div>
                                )}
                                {formik.touched.banner && formik.errors.banner ? (
                                    <div className="text-danger">{formik.errors.banner}</div>
                                ) : null}
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="ceo_image" className="font-semibold text-sm mb-2">
                                    CEO Image<span className="text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="file"
                                    id="ceo_image"
                                    name="ceo_image"
                                    onChange={(event) => {
                                        formik.setFieldValue('ceo_image', event.target.files[0]);
                                    }}
                                />
                                {ceoImagePreview && (
                                    <div className="mt-2">
                                        <img src={ceoImagePreview} alt="CEO Image Preview" width="200" height='200' style={{ objectFit: "contain" }} />
                                    </div>
                                )}
                                {formik.touched.ceo_image && formik.errors.ceo_image ? (
                                    <div className="text-danger">{formik.errors.ceo_image}</div>
                                ) : null}
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="yt_link" className="font-semibold text-sm mb-2">
                                    YouTube Link<span className="text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="yt_link"
                                    name="yt_link"
                                    value={formik.values.yt_link}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.yt_link && formik.errors.yt_link ? (
                                    <div className="text-danger">{formik.errors.yt_link}</div>
                                ) : null}
                            </div>
                            <div className="col-12">
                                <button type="submit" className="update-button mb-3">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default UpdateCompanyBanner