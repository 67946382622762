import React, { useEffect, useState } from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import './scss/careerguide.css'
import Footer from '../Commons/Footer'
import CareerGuideNav from './CareerGuideNav'
import CareerSearch from './CareerSearch'
import CareerAdvice from './CareerAdvice'
import CareerTest from './CareerTest'
import FeatureBlog from './FeatureBlog'
import InterviewQuestions from './InterviewQuestions'
import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'
import Resumes from './Resumes'
import CareerStartHereSearchContent from '../YourCareerStartHere/CareerStartHereSearch/CareerStartHereSearchContent'
import CareerAdviceDynamic from './CareerAdviceDynamic'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getBlogWithCategoryData, getSixInterviewQuestions } from '../../../Redux/action/MasterAction'
import { getBlogData } from '../../../Redux/action/RecruiterAction'
import CareerAdviceDynamic2 from './CareerAdviceDynamic2'
import { toast } from 'react-toastify'
import Loader from '../Loader'
const CareerGuideHome = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [sixQuestions, setSixQuestions] = useState(null);
    const [blog, setBlog] = useState(null);
    useEffect(() => {dispatch(getBlogWithCategoryData());}, []);
    const blogData = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.blogListData : null);
    useEffect(() => {if (blogData) {setBlog(blogData)}}, [blogData]);
    useEffect(() => {dispatch(getSixInterviewQuestions());dispatch(getBlogData())}, [])
    const questions = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.sixInterviewQuestionsList : null)
    useEffect(() => {if (questions) { setSixQuestions(questions)} }, [questions])
    return (
        <>
            <AfterNavbar />           
            {blogData ? <CareerSearch blogCategoryData={blogData} /> : <div className="overlay-form-submission"><Loader /></div>}
            {blogData ? <CareerAdviceDynamic2 blogData={blogData} /> : <div className="overlay-form-submission"><Loader /></div>}
            <BeforeFooterPlayStore />
            <Footer />
        </>
    )
}

export default CareerGuideHome