import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './scss/Questionnaire.css'
import Accordion from 'react-bootstrap/Accordion';
import CreateQuestionPopup from './CreateQuestionPopup/CreateQuestionPopup';
import AddAssessmentTItleModal from './AddAssessmentTItleModal';
import { useDispatch, useSelector } from 'react-redux';
import { getAssessmentList } from '../../../../Redux/action/RecruiterAction';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import Loader from '../../../Seeker/Loader';
import EditAssessmentTItleModal from './EditAssessmentTItleModal';
const host = process.env.REACT_APP_API_SERVER;
const AssessmentList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const job_id = queryParams.get('job_id');
    const [showLoader, setShowLoader] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem("recruiter_token");
        dispatch(getAssessmentList(token));
    }, [])
    const assessmentList = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.assessmentListData : null);

    useEffect(() => {
        if (assessmentList) {
            setShowLoader(false);
            setList(assessmentList);
        } else {
            setShowLoader(true);
        }
    }, [assessmentList])

    const reloadData = () => {
        const token = localStorage.getItem("recruiter_token");
        dispatch(getAssessmentList(token));
    }

    const handleDelete = (id) => {
        const loadingToastId = toast.info('Deleting...', { autoClose: false });
        try {
            setShowLoader(true);
            document.body.style.overflow = "hidden";
            const config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } }
            axios.get(`${host}/recruiter/delete-assessment?assessment_id=${id}`, config)
                .then(response => {
                    if (response.status === 200) {
                        reloadData();
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Assessment deleted successfully." });

                    } else {
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });

                    }
                }).catch(error => {
                    document.body.style.overflow = "auto";
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error" });

                })
        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = "auto";
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error" });
        }
    }

    //pagination
    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 5;
    useEffect(() => {

        const endOffset = dataOffset + dataPerPage;
        setCurrentData(list.slice(dataOffset, endOffset));
        setPageCount(Math.ceil(list?.length / dataPerPage));
    }, [dataOffset, dataPerPage, list])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage) % list?.length;
        setDataOffset(newOffset);
    };


    const handleViewQuestions = (id, title) => {
        navigate('/create-assessment-questions', {
            state: { assessment_id: id, assessment_title: title }
        })
    }

    return (
        <>
            <section className='make-it-deal-breaker-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className='heading'>
                                        <h2>Assessment Listing</h2>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12 text-end'>
                                    <div className='heading2'>
                                        <Link to='' className='maxi-cls'>Minimum 5 and Maximum 15 Questions per assessment</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-lg-4 col-md-4 col-12'>
                                    <div className='heading'>
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#createAssessment">+ Create Assessment</button>
                                    </div>
                                </div>
                                <div className='col-lg-8 col-md-8 col-12'>
                                    <div className='heading text-end'>
                                        <span><b>NOTE:</b>Assessments having minimum 5 questions will be visible while posting jobs.</span>
                                    </div>
                                </div>
                            </div>
                            <div className='question-parent-dv table-responsive'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">S. No.</th>
                                            <th scope="col">Assessment Name</th>
                                            <th scope="col">No. of Questions</th>
                                            <th scope="col">Passing %</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    {list?.length > 0 ? <tbody>
                                        {currentData.map((element, index) => {
                                            return <> <tr key={element?.id}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{element?.title}</td>
                                                <td>{element?.assessmentquestion ? <span className={`text-${element?.assessmentquestion?.length >= 5 ? "success" : "danger"}`} title={`${element?.assessmentquestion.length >= 5 ? "This assessment will be visible while posting job." : "Please add at least 5 questions for assessment to be visible while posting job."}`}>{element?.assessmentquestion?.length}</span> : <span className="text-danger" title='Please add at least 5 questions for assessment to be visible while posting job.'>0</span>}</td>
                                                <td>{element?.passing_percent} %</td>
                                                <td>
                                                    <button className="btn btn-light btn-sm mx-1 assement-button-pad" onClick={() => { handleViewQuestions(element.id, element?.title) }} title='View assessment questions'><i className="fa fa-eye" aria-hidden="true"></i></button>
                                                    <button className="btn btn-success btn-sm mx-1 assement-button-pad" title='Edit assessment title' data-bs-toggle="modal" data-bs-target={`#editAssessment${element?.id}`}><i className="fas fa-edit"></i></button>
                                                   
                                                </td>
                                            </tr >
                                                <EditAssessmentTItleModal reloadData={reloadData} assessment_id={element?.id} />

                                            </>
                                        })}

                                    </tbody> :
                                        <tbody>
                                            <tr>
                                                <td colSpan={5}><i>No data available.</i></td>
                                            </tr>
                                        </tbody>
                                    }
                                </table>
                            </div>
                            {list?.length > 5 && <div className='row justify-content-center'>
                                <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                    <section className='pagination-section'>
                                        <div className='container'>
                                            <nav aria-label="Page navigation example" className='nav-class'>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel=">>"
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel="<<"
                                                    renderOnZeroPageCount={null}
                                                    containerClassName='pagination'
                                                    pageLinkClassName='page-num'
                                                    previousLinkClassName='page-num'
                                                    nextLinkClassName='page-num'
                                                    activeClassName='active'
                                                />
                                            </nav>
                                        </div>
                                    </section>

                                </div>
                            </div>}
                        </div>
                    </div>
                    {
                        showLoader && <div className="overlay-form-submission">
                            <Loader />
                        </div>
                    }

                </div>
                {job_id && <div className="text-center mt-3">
                    <Link to="/edit-select-assessment" className='go-back-to-job-posting'>Go back to job posting</Link>
                </div>}
            </section >
            <AddAssessmentTItleModal reloadData={reloadData} />
            <EditAssessmentTItleModal reloadData={reloadData} />

        </>
    )
}

export default AssessmentList