import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './scss/FindCandidates.css'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getFindCandidateData, getMatchedCandidateData } from '../../../Redux/action/RecruiterAction';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useDispatch, useSelector } from 'react-redux';
import { createImageFromInitials, getRandomColor } from '../../Utils';
const host = process.env.REACT_APP_API_SERVER
const FindCandidatesContent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { jobId } = location.state || {};
    const [stylenone, setStylenone] = useState("none");
    const handlefiterStyle = () => {
        if (stylenone == "none") {setStylenone("block");
        } else if (stylenone == "block") { setStylenone("none");}
    }
    const dispatch = useDispatch();
    let authToken = localStorage.getItem("recruiter_token");
    const job_id = jobId
    useEffect(() => {
        dispatch(getFindCandidateData(authToken, job_id));
        dispatch(getMatchedCandidateData(authToken, job_id));
    }, []);
    const findCandidateData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.findCandidateDataList?.data) : "")
    const matchedCandidateData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.matchedCandidateDataList?.data) : "")
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [itemsPerPage] = useState(12);
    const [filteredFindCandidate, setFilteredFindCandidate] = useState([]);
    const [filteredMatchedCandidate, setFilteredMatchedCandidate] = useState([]);
    const [filter, setFilter] = useState('');
    const [matchedfilter, setmatchedFilter] = useState('');
    const [showPagination, setShowPagination] = useState(false)
    const [showLoader, setShowLoader] = useState(false);
    const appliedCount = findCandidateData?.filter(job => job.job_status === 'APPLIED')?.length;
    const rejectedCount = findCandidateData?.filter(job => job.job_status === 'REJECTED')?.length;
    const shortlistedCount = findCandidateData?.filter(job => job.job_status === 'SHORTLISTED')?.length;
    const interviewedCount = findCandidateData?.filter(job => job.job_status === 'INTERVIEW')?.length;
    const hiredCount = findCandidateData?.filter(job => job.job_status === 'HIRED')?.length;
    const reviewedCount = findCandidateData?.filter(job => job.job_status === 'REVIEWED')?.length;
   const appliedmatchedCount = matchedCandidateData?.filter(job => job.job_status === 'APPLIED')?.length;
    const rejectedmatchedCount = matchedCandidateData?.filter(job => job.job_status === 'REJECTED')?.length;
    const shortlistedmatchedCount = matchedCandidateData?.filter(job => job.job_status === 'SHORTLISTED')?.length;
    const interviewedmatchedCount = matchedCandidateData?.filter(job => job.job_status === 'INTERVIEW')?.length;
    const hiredmatchedCount = matchedCandidateData?.filter(job => job.job_status === 'HIRED')?.length;
    const reviewedmatchedCount = matchedCandidateData?.filter(job => job.job_status === 'REVIEWED')?.length;
    function formatPercentage(number) {
        return (number * 100).toFixed(2) + '%';
    }
    useEffect(() => {
        const filteredData = findCandidateData?.filter(job => {
            switch (filter) {
                case 'APPLIED':return job?.job_status === 'APPLIED';
                case 'REVIEWED':return job?.job_status === 'REVIEWED';
                case 'REJECTED':return job?.job_status === 'REJECTED';
                case 'SHORTLISTED':return job?.job_status === 'SHORTLISTED';
                case 'INTERVIEW':return job?.job_status === 'INTERVIEW';
                case 'HIRED':return job?.job_status === 'HIRED';
                default:return true;
            }
        });
        setFilteredFindCandidate(filteredData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
        setTotalPage(filteredData?.length);
        if (totalPage > 1) {
            setShowPagination(true);
        }
    }, [filter, findCandidateData, currentPage, itemsPerPage]);
    const filteredSkillData = filteredFindCandidate?.[0]?.seeker_details?.Seeker_keyskills
    const filterededucationalData = filteredFindCandidate?.[0]?.seeker_details?.seekerEducationalDetail
    const findEducationByLevel = (level) => filterededucationalData;
    const educationLevels = ['3', '2', '1'];
    let selectedEducation;
    for (const level of educationLevels) {
        selectedEducation = findEducationByLevel(level);
        if (selectedEducation) {
            break;
        }
    }
    const courseType = selectedEducation?.course_type;
    useEffect(() => {
        const filteredData = matchedCandidateData?.filter(job => {
            switch (matchedfilter) {
                case 'APPLIED':return job?.job_status === 'APPLIED';
                case 'REVIEWED':return job?.job_status === 'REVIEWED';
                case 'REJECTED':return job?.job_status === 'REJECTED';
                case 'SHORTLISTED':return job?.job_status === 'SHORTLISTED';
                case 'INTERVIEW':return job?.job_status === 'INTERVIEW';
                case 'HIRED':return job?.job_status === 'HIRED';
                default:return true;
            }
        });
        setFilteredMatchedCandidate(filteredData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
        setTotalPage(filteredData?.length);
        if (totalPage > 1) {
            setShowPagination(true);
        }

    }, [matchedfilter, matchedCandidateData, currentPage, itemsPerPage]);
    const filteredmatchedSkillData = filteredFindCandidate?.[0]?.seeker_details?.Seeker_keyskills
    const filteredmatchededucationalData = filteredFindCandidate?.[0]?.seeker_details?.seekerEducationalDetail
    const findmatchedEducationByLevel = (level) => filteredmatchededucationalData;
  const matchededucationLevels = ['3', '2', '1'];
    let matchedselectedEducation;
for (const level of matchededucationLevels) {
        matchedselectedEducation = findmatchedEducationByLevel(level);
        if (matchedselectedEducation) {break;}}
    const matchedcourseType = matchedselectedEducation?.course_type;
    const totalPages = Math.ceil(totalPage / itemsPerPage);
    const goToNextPage = () => {
        setCurrentPage((prevCurrentPage) => Math.min(prevCurrentPage + 1, totalPages));};
    const goToPreviousPage = () => {
        setCurrentPage((prevCurrentPage) => Math.max(prevCurrentPage - 1, 1)); };
    const changePage = (pageNumber) => {        setCurrentPage(pageNumber);    };
    const getPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {pageNumbers.push(i);
        }return pageNumbers;
    };
    const handleFilterChange = (newFilter) => { setFilter(newFilter);
    };
    const handleMatchedFilterChange = (newmatchedFilter) => { setmatchedFilter(newmatchedFilter);
    };
    const config = {
        headers: {"auth-token": authToken}
    };
    const handleClickFindUpdate = async (seeker_id) => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {
            document.body.style.overflow = 'hidden';
            const values = {job_id: job_id,seeker_id: seeker_id
            }
            await axios.post(`${host}/recruiter/update-matched-candidate`, values, config)
                .then(function (response) {
                    if (response.status == 200) {
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                    } else {
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response.data.msg });
                    }
                })
                .catch(function (error) {

                    document.body.style.overflow = 'auto';

                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                })
            dispatch(getFindCandidateData(authToken, job_id));
            dispatch(getMatchedCandidateData(authToken, job_id));
        }
        catch (error) {
            document.body.style.overflow = 'auto';
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
        }
    }
    const handleClickRemoveUpdate = async (seeker_id) => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {
            document.body.style.overflow = 'hidden';
            const values = {
                job_id: job_id,
                seeker_id: seeker_id
            }
            await axios.post(`${host}/recruiter/remove-matched-candidate`, values, config)
                .then(function (response) {
                    if (response.status == 200) {
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response.data.msg });
                    } else {
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response.data.msg });
                    }
                })
                .catch(function (error) {

                    document.body.style.overflow = 'auto';

                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                })
            dispatch(getFindCandidateData(authToken, job_id));
            dispatch(getMatchedCandidateData(authToken, job_id));


        }
        catch (error) {

            document.body.style.overflow = 'auto';

            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
        }

    }

    const handleClickProfileOpen = (seeker_id) => {
        navigate(`/rec-candidate-profile?seeker_id=${seeker_id}`, { state: { seeker_id } });
    }

    const handleShortlist = (seeker_id, job_id) => {

        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {
            document.body.style.overflow = "hidden";
            setShowLoader(true);
            const values = {
                job_id: job_id, seeker_id: seeker_id, job_status: "SHORTLISTED"
            };
            const config = {
                headers: {
                    "auth-token": localStorage.getItem("recruiter_token")
                }
            }
            axios.post(`${host}/recruiter/change-candidate-job-status`, values, config)
                .then(response => {
                    if (response.status == 200) {
                        dispatch(getFindCandidateData(authToken, job_id));
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Status changed successfully." });
                    } else {
                        dispatch(getMatchedCandidateData(authToken, job_id));
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                    }
                }).catch(error => {

                    document.body.style.overflow = "auto";
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Internal Server Error" });
                })
        } catch (error) {
            document.body.style.overflow = "auto";
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Internal Server Error" });
        }
    }

    const handleReview = (seeker_id, job_id) => {

        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {
            document.body.style.overflow = "hidden";
            setShowLoader(true);
            const values = {
                job_id: job_id, seeker_id: seeker_id, job_status: "REVIEWED"
            };
            const config = {
                headers: {
                    "auth-token": localStorage.getItem("recruiter_token")
                }
            }
            axios.post(`${host}/recruiter/change-candidate-job-status`, values, config)
                .then(response => {
                    if (response.status == 200) {
                        dispatch(getFindCandidateData(authToken, job_id));
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Status changed successfully." });
                    } else {
                        dispatch(getMatchedCandidateData(authToken, job_id));
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                    }
                }).catch(error => {

                    document.body.style.overflow = "auto";
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Internal Server Error" });
                })
        } catch (error) {
            document.body.style.overflow = "auto";
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Internal Server Error" });
        }
    }

    const handleInterview = (seeker_id, jobId) => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {
            document.body.style.overflow = "hidden";
            setShowLoader(true);
            const values = {
                job_id: job_id, seeker_id: seeker_id, job_status: "INTERVIEW"
            };
            const config = {
                headers: {
                    "auth-token": localStorage.getItem("recruiter_token")
                }
            }
            axios.post(`${host}/recruiter/change-candidate-job-status`, values, config)
                .then(response => {
                    if (response.status == 200) {
                        dispatch(getFindCandidateData(authToken, job_id));
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Status changed successfully." });
                    } else {
                        dispatch(getMatchedCandidateData(authToken, job_id));
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                    }
                }).catch(error => {

                    document.body.style.overflow = "auto";
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Internal Server Error" });
                })
        } catch (error) {
            document.body.style.overflow = "auto";
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Internal Server Error" });
        }
    }

    const handleReject = (seeker_id, jobId) => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {
            document.body.style.overflow = "hidden";
            setShowLoader(true);
            const values = {
                job_id: job_id, seeker_id: seeker_id, job_status: "REJECTED"
            };
            const config = {
                headers: {
                    "auth-token": localStorage.getItem("recruiter_token")
                }
            }
            axios.post(`${host}/recruiter/change-candidate-job-status`, values, config)
                .then(response => {
                    if (response.status == 200) {
                        dispatch(getFindCandidateData(authToken, job_id));
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Status changed successfully." });
                    } else {
                        dispatch(getMatchedCandidateData(authToken, job_id));
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                    }
                }).catch(error => {

                    document.body.style.overflow = "auto";
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Internal Server Error" });
                })
        } catch (error) {
            document.body.style.overflow = "auto";
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Internal Server Error" });
        }
    }

    const handleHire = (seeker_id, jobId) => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {
            document.body.style.overflow = "hidden";
            setShowLoader(true);
            const values = {
                job_id: job_id, seeker_id: seeker_id, job_status: "HIRED"
            };
            const config = {
                headers: {
                    "auth-token": localStorage.getItem("recruiter_token")
                }
            }
            axios.post(`${host}/recruiter/change-candidate-job-status`, values, config)
                .then(response => {
                    if (response.status == 200) {
                        dispatch(getFindCandidateData(authToken, job_id));
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Status changed successfully." });
                    } else {
                        dispatch(getMatchedCandidateData(authToken, job_id));
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                    }
                }).catch(error => {

                    document.body.style.overflow = "auto";
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Internal Server Error" });
                })
        } catch (error) {
            document.body.style.overflow = "auto";
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Internal Server Error" });
        }
    }

    const increaseJobViewCount = (seeker_id, resume_id) => {

        try {
            let config = {
                headers: {
                    "auth-token": localStorage.getItem("recruiter_token")
                }
            }
            const values = {
                seeker_id: seeker_id,
                resume_id: resume_id
            }

            axios.post(`${host}/recruiter/increase-resume-download-count`, values, config)
                .then((response) => {
                    console.log(response.data.msg);
                }).catch(error => {
                    console.log(error.message)
                })

        } catch (error) {
            console.log(error.message);
        }

    }

    return (
        <>
            <section className='find-condidate-section'>
                <div className='container'>
                    <div className='candidate-pro-section-inner-dv' >
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-12'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6 col-md-6 col-sm-6 col-6 col-xs-12'>
                                        <div className='condidate-pro-heading'>
                                            <h2>Find Candidates</h2>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-6 col-6 col-xs-12 text-lg-end text-md-end text-end'>
                                        <div className='candidate-pro-btn'>
                                            <Link to='/post-job' className='post-btn'>Post A Job</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <ul className="nav nav-tabs all-match-candidate-cls" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link candidate-btn active" id="all-candidates-tab" data-bs-toggle="tab" data-bs-target="#all-candidates" type="button" role="tab" aria-controls="all-candidates" aria-selected="true">All Candidates</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link candidate-btn" id="match-candidates-tab" data-bs-toggle="tab" data-bs-target="#match-candidates" type="button" role="tab" aria-controls="match-candidates" aria-selected="false">Match Candidates</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* --------------pill tab body show start--------------- */}
                                <div className="tab-content" id="myTabContent" >
                                    <div className="pill-tab-body-show tab-pane fade show active" id="all-candidates" role="tabpanel" aria-labelledby="all-candidates-tab">
                                        <div className='row align-items-center'>
                                            <div className='col-xl-9 col-lg-12 col-md-12 col-12'>
                                                <div className='open-closed-btn mt-2 pt-2 d-grid custom-grid-btn gap-1'>
                                                    <a className={`a-opn-cls-btn ${filter === 'APPLIED' ? 'active' : ''} `} onClick={() => handleFilterChange('APPLIED')}>
                                                        Applied ({appliedCount})
                                                    </a>
                                                    <a className={`a-opn-cls-btn ${filter === 'REVIEWED' ? 'active' : ''} `} onClick={() => handleFilterChange('REVIEWED')}>
                                                        Reviewed ({reviewedCount})
                                                    </a>
                                                    <a className={`a-opn-cls-btn ${filter === 'SHORTLISTED' ? 'active' : ''}`} onClick={() => handleFilterChange('SHORTLISTED')} >
                                                        Shortlisted ({shortlistedCount})
                                                    </a>
                                                    <a className={`a-opn-cls-btn ${filter === 'INTERVIEW' ? 'active' : ''}`} onClick={() => handleFilterChange('INTERVIEW')} >
                                                        Interviewed ({interviewedCount})
                                                    </a>
                                                    <a className={`a-opn-cls-btn ${filter === 'REJECTED' ? 'active' : ''}`} onClick={() => handleFilterChange('REJECTED')} >
                                                        Rejected ({rejectedCount})
                                                    </a>
                                                    <a className={`a-opn-cls-btn ${filter === 'HIRED' ? 'active' : ''}`} onClick={() => handleFilterChange('HIRED')} >
                                                        Hired ({hiredCount})
                                                    </a>
                                                </div>
                                            </div>
                                           
                                        </div>
                                        {/* ---------------filter value start----------------- */}
                                       
                                        {/* ---------------filter value end----------------- */}
                                        <div className='candidates-details-card'>
                                            <div className='row'>

                                                {filteredFindCandidate && filteredFindCandidate?.map((data) => (
                                                    < div className='col-lg-4 col-md-6 col-12 my-lg-3 my-md-3 my-3'>
                                                        <div className='candidates-card'>
                                                            <div className='candidate-img-details'>
                                                                {(data?.seeker_details && <img
                                                                    src={
                                                                        data?.seeker_details?.profile_img
                                                                            ? `${host}/uploaded-files/${data?.seeker_details?.profile_img}`
                                                                            : "assets/images/cv-builder/account.png"
                                                                    }
                                                                    onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null;
                                                                        currentTarget.src = createImageFromInitials(500, `${data?.seeker_details?.firstName} ${data?.seeker_details?.lastName}`, getRandomColor());
                                                                    }}
                                                                    alt="Avatar"
                                                                    style={{ cursor: 'pointer', borderRadius: '50%', objectFit: 'cover' }}
                                                                    width={100} height={100}
                                                                />)}
                                                                <h5><span style={{ cursor: "pointer" }} onClick={() => { handleClickProfileOpen(data?.seeker_id) }} >{data?.seeker_details?.firstName} {data?.seeker_details?.lastName}</span></h5>
                                                                <h6>{data?.seeker_details?.designation?.designation}</h6>
                                                            </div>
                                                            <div className='pro-details'>
                                                                <ul>
                                                                    {data?.seeker_details?.experience_years && <li>
                                                                        <p><span className="heading">Experience:</span>
                                                                            <span>{data?.seeker_details?.experience_years} Years {data?.seeker_details?.experience_months} months</span></p>
                                                                    </li>}
                                                                    {data?.seeker_details?.Seeker_professional_details?.current_last_company && <li>
                                                                        <p> <span>{data?.seeker_details?.Seeker_professional_details?.current_last_company}</span> </p>
                                                                    </li>}
                                                                    <li>
                                                                        {filterededucationalData &&
                                                                            <p><span className="heading">Education:</span>
                                                                                {filterededucationalData?.map((data, index) => (

                                                                                    <span>{data?.educationlevel?.educationlevel} in {data?.specialization}</span>

                                                                                ))}
                                                                            </p>

                                                                        }
                                                                    </li>
                                                                    <li >
                                                                        <p ><span className="heading">Skills:</span>
                                                                            {filteredSkillData && filteredSkillData?.map((skill, index) => (
                                                                                <span key={index}>{skill?.skill_name === '0' ? skill?.other_skill_name : skill?.skill?.skill}</span>
                                                                            ))}
                                                                        </p>
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                            <div className='candidate-accept-reject'>
                                                                <div className='candidate-accept-reject-btn'>
                                                                    
                                                                    <button className='border-0' onClick={() => handleClickProfileOpen(data?.seeker_id)} title='See Candidate Profile'>
                                                                        <img src='./assets/recruiter-images/find-candidates/user.png' className='img-fluid' />
                                                                    </button>
                                                                    {data.is_assessment_included && <>
                                                                        <button className='border-0' data-bs-toggle="modal" data-bs-target={`#assessmentResult${data.id}`} title='View assessment result'>
                                                                            <img src='./assets/images/sidebar-icons/assessment_analysis.png' className='img-fluid' />
                                                                        </button>
                                                                        <div className="modal fade" id={`assessmentResult${data.id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="assessmentResultLabel" aria-hidden="true">
                                                                            <div className="modal-dialog">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">
                                                                                        <h5 className="modal-title" id="assessmentResultLabel">Assessment Result</h5>
                                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                    </div>
                                                                                    <div className="modal-body">
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-6"><b>Candidate Name:</b></div>
                                                                                            <div className="col-md-6">{data?.seeker_details?.firstName} {data?.seeker_details?.lastName}</div>
                                                                                        </div>
                                                                                        <hr />
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-6"><b>Total Questions Count:</b></div>
                                                                                            <div className="col-md-6">{data?.assessment_result?.total_questions_count}</div>
                                                                                        </div>
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-6"><b>Correct answers:</b></div>
                                                                                            <div className="col-md-6">{data?.assessment_result?.correct_count}</div>
                                                                                        </div>
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-6"><b>Wrong answers:</b></div>
                                                                                            <div className="col-md-6">{data?.assessment_result?.wrong_count}</div>
                                                                                        </div>
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-6"><b>Unattempted:</b></div>
                                                                                            <div className="col-md-6">{data?.assessment_result?.unattempted_count ?? 0}</div>
                                                                                        </div>
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-6"><b>Total Marks:</b></div>
                                                                                            <div className="col-md-6">{data?.assessment_result?.total_marks}</div>
                                                                                        </div>
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-6"><b>Obtained Marks:</b></div>
                                                                                            <div className="col-md-6">{data?.assessment_result?.obtained_marks}</div>
                                                                                        </div>
                                                                                        <hr />
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-6"><b>Percent:</b></div>
                                                                                            <div className="col-md-6">{formatPercentage(Number(data?.assessment_result?.obtained_marks) / Number(data?.assessment_result?.total_marks)) ?? "0 %"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="modal-footer">
                                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    }

                                                                    {/* Show resume */}
                                                                    {data.resume_id && <>
                                                                        <button className='border-0' data-bs-toggle="modal" data-bs-target={`#viewResume${data.id}`} title='View resume' onClick={() => increaseJobViewCount(data.seeker_id, data.resume_id)}>
                                                                            <img src='./assets/images/sidebar-icons/viewresume.png' className='img-fluid' />
                                                                        </button>
                                                                        <div className="modal fade" id={`viewResume${data.id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="viewResumeLabel" aria-hidden="true">
                                                                            <div className="modal-dialog modal-xl">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">
                                                                                        <h5 className="modal-title" id="viewResumeLabel">{data?.seeker_details?.firstName} {data?.seeker_details?.lastName}</h5>
                                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                    </div>
                                                                                    <div className="modal-body view-resume-modal-body">
                                                                                        {data?.resume?.resume && ((data?.resume?.resume.slice(((data?.resume?.resume.lastIndexOf(".") - 1) >>> 0) + 2)) == ("pdf" || "PDF")) ?
                                                                                            <iframe
                                                                                                src={`${host}/uploaded-files/${data?.resume?.resume}`}
                                                                                                style={{ width: "100%", height: "100%" }}
                                                                                                frameBorder="0"
                                                                                            ></iframe> :
                                                                                            <>
                                                                                                <p>
                                                                                                    <i>Document other than PDF are not supported for now.</i>
                                                                                                </p>
                                                                                                <p>
                                                                                                    <a href={`${host}/uploaded-files/${data?.resume?.resume}`} target='_blank' className="btn btn-success">Download</a>
                                                                                                </p>
                                                                                                {/* <DocViewer pluginRenderers={DocViewerRenderers} documents={`${host}/uploaded-files/${data?.resume?.resume}`} /> */}
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="modal-footer">
                                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    }
                                                                    <button className='border-0' onClick={() => handleClickFindUpdate(data?.seeker_id)} title='Add candidate to Matched list'
                                                                    >
                                                                        <img src='./assets/recruiter-images/find-candidates/right-check.png' className='img-fluid' />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className='navigation-tooltips navigate-tooltips-custom'>
                                                               
                                                                <div className="dropdown">
                                                                    <a className="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <img src='./assets/recruiter-images/find-candidates/three-dot.png' />
                                                                    </a>

                                                                    <ul className="dropdown-menu border-0" aria-labelledby="dropdownMenuLink">
                                                                        <li><button className="dropdown-item" onClick={() => { handleReview(data?.seeker_id, jobId) }}>Review</button></li>
                                                                        <li><button className="dropdown-item" onClick={() => { handleShortlist(data?.seeker_id, jobId) }}>Shortlist</button></li>
                                                                        <li><button className="dropdown-item" onClick={() => { handleInterview(data?.seeker_id, jobId) }}>Interview</button></li>
                                                                        <li><button className="dropdown-item" onClick={() => { handleReject(data?.seeker_id, jobId) }}>Reject</button></li>
                                                                        <li><button className="dropdown-item" onClick={() => { handleHire(data?.seeker_id, jobId) }}>Hire</button></li>
                                                                    </ul>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>))}




                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* --------------pill tab body show end--------------- */}

                                {/* --------------pill tab body show start--------------- */}
                                <div className="tab-content" id="myTabContent" >
                                    <div className="pill-tab-body-show tab-pane fade show " id="match-candidates" role="tabpanel" aria-labelledby="match-candidates-tab">
                                        <div className='row align-items-center'>
                                            <div className='col-lg-9 col-md-12 col-12'>
                                                <div className='open-closed-btn mt-2 pt-2'>
                                                    <a className={`a-opn-cls-btn ${matchedfilter === 'APPLIED' ? 'active' : ''} `} onClick={() => handleMatchedFilterChange('APPLIED')}>
                                                        Applied ({appliedmatchedCount})
                                                    </a>
                                                    <a className={`a-opn-cls-btn ${matchedfilter === 'REVIEWED' ? 'active' : ''} `} onClick={() => handleMatchedFilterChange('REVIEWED')}>
                                                        Reviewed ({reviewedmatchedCount})
                                                    </a>
                                                    <a className={`a-opn-cls-btn ${matchedfilter === 'SHORTLISTED' ? 'active' : ''}`} onClick={() => handleMatchedFilterChange('SHORTLISTED')} >
                                                        Shortlisted ({shortlistedmatchedCount})
                                                    </a>
                                                    <a className={`a-opn-cls-btn ${matchedfilter === 'INTERVIEW' ? 'active' : ''}`} onClick={() => handleMatchedFilterChange('INTERVIEW')} >
                                                        Interviewed ({interviewedmatchedCount})
                                                    </a>
                                                    <a className={`a-opn-cls-btn ${matchedfilter === 'REJECTED' ? 'active' : ''}`} onClick={() => handleMatchedFilterChange('REJECTED')} >
                                                        Rejected ({rejectedmatchedCount})
                                                    </a>
                                                    <a className={`a-opn-cls-btn ${matchedfilter === 'HIRED' ? 'active' : ''}`} onClick={() => handleMatchedFilterChange('HIRED')} >
                                                        Hired ({hiredmatchedCount})
                                                    </a>
                                                </div>

                                            </div>
                                            
                                        </div>
                                        {/* ---------------filter value start----------------- */}
                                       
                                        {/* ---------------filter value end----------------- */}
                                        <div className='candidates-details-card'>
                                            <div className='row'>

                                                {filteredMatchedCandidate && filteredMatchedCandidate.map((data) => (
                                                    < div className='col-lg-4 col-md-6 col-12 my-lg-3 my-md-3 my-3'>
                                                        <div className='candidates-card'>
                                                            <div className='candidate-img-details'>
                                                                {(data?.seeker_details && <img
                                                                    src={
                                                                        data?.seeker_details?.profile_img
                                                                            ? `${host}/uploaded-files/${data?.seeker_details?.profile_img}`
                                                                            : "assets/images/cv-builder/account.png"
                                                                    }
                                                                    alt="Avatar"
                                                                    style={{ cursor: 'pointer', borderRadius: '50%', objectFit: 'cover' }}
                                                                    width={100} height={100}
                                                                />)}
                                                                <h5><Link to="/rec-candidate-profile">{data?.seeker_details?.firstName} {data?.seeker_details?.lastName}</Link></h5>
                                                                <h6>{data?.seeker_details?.designation?.designation}</h6>
                                                            </div>
                                                            <div className='pro-details'>
                                                                <ul>
                                                                    {data?.seeker_details?.experience_years && <li>
                                                                        <p><span className="heading">Experience:</span>
                                                                            <span>{data?.seeker_details?.experience_years} Years {data?.seeker_details?.experience_months} months</span></p>
                                                                    </li>}
                                                                    {data?.seeker_details?.Seeker_professional_details?.current_last_company && <li>
                                                                        <p> <span>{data?.seeker_details?.Seeker_professional_details?.current_last_company}</span> </p>
                                                                    </li>}
                                                                    <li>
                                                                        {filteredmatchededucationalData &&
                                                                            <p><span className="heading">Education:</span>
                                                                                {filteredmatchededucationalData?.map((data, index) => (

                                                                                    <span>{data?.educationlevel?.educationlevel} in {data?.specialization}</span>

                                                                                ))}
                                                                            </p>

                                                                        }
                                                                    </li>
                                                                    <li >
                                                                        <p ><span className="heading">Skills:</span>
                                                                            {filteredmatchedSkillData && filteredmatchedSkillData?.map((skill, index) => (
                                                                                <span key={index}>{skill?.skill_name === '0' ? skill?.other_skill_name : skill?.skill?.skill}</span>
                                                                            ))}
                                                                        </p>
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                            <div className='candidate-accept-reject'>
                                                                <div className='candidate-accept-reject-btn'>

                                                                    <button className='border-0' onClick={() => handleClickProfileOpen(data?.seeker_id)} >
                                                                        <img src='./assets/recruiter-images/find-candidates/user.png' className='img-fluid' title='See Candidate Profile' />
                                                                    </button>
                                                                    {/* check assessment result matched candidates */}
                                                                    {data.is_assessment_included && <>
                                                                        <button className='border-0' data-bs-toggle="modal" data-bs-target={`#assessmentMatchedResult${data.id}`} title='View assessment result'>
                                                                            <img src='./assets/images/sidebar-icons/assessment_analysis.png' className='img-fluid' />
                                                                        </button>
                                                                        <div className="modal fade" id={`assessmentMatchedResult${data.id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="assessmentMatchedResultLabel" aria-hidden="true">
                                                                            <div className="modal-dialog">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">
                                                                                        <h5 className="modal-title" id="assessmentMatchedResultLabel">Assessment Result</h5>
                                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                    </div>
                                                                                    <div className="modal-body">
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-6"><b>Candidate Name:</b></div>
                                                                                            <div className="col-md-6">{data?.seeker_details?.firstName} {data?.seeker_details?.lastName}</div>
                                                                                        </div>
                                                                                        <hr />
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-6"><b>Total Questions Count:</b></div>
                                                                                            <div className="col-md-6">{data?.assessment_result?.total_questions_count}</div>
                                                                                        </div>
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-6"><b>Correct answers:</b></div>
                                                                                            <div className="col-md-6">{data?.assessment_result?.correct_count}</div>
                                                                                        </div>
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-6"><b>Wrong answers:</b></div>
                                                                                            <div className="col-md-6">{data?.assessment_result?.wrong_count}</div>
                                                                                        </div>
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-6"><b>Unattempted:</b></div>
                                                                                            <div className="col-md-6">{data?.assessment_result?.unattempted_count ?? 0}</div>
                                                                                        </div>
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-6"><b>Total Marks:</b></div>
                                                                                            <div className="col-md-6">{data?.assessment_result?.total_marks}</div>
                                                                                        </div>
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-6"><b>Obtained Marks:</b></div>
                                                                                            <div className="col-md-6">{data?.assessment_result?.obtained_marks}</div>
                                                                                        </div>
                                                                                        <hr />
                                                                                        <div className="row mt-2">
                                                                                            <div className="col-md-6"><b>Percent:</b></div>
                                                                                            <div className="col-md-6">{formatPercentage(Number(data?.assessment_result?.obtained_marks) / Number(data?.assessment_result?.total_marks)) ?? "0 %"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="modal-footer">
                                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    }

                                                                    {/* Show resume Matched candidates*/}
                                                                    {data.resume_id && <>
                                                                        <button className='border-0' data-bs-toggle="modal" data-bs-target={`#viewMatchedResume${data.id}`} title='View resume' onClick={() => increaseJobViewCount(data.seeker_id, data.resume_id)}>
                                                                            <img src='./assets/images/sidebar-icons/viewresume.png' className='img-fluid' />
                                                                        </button>
                                                                        <div className="modal fade" id={`viewMatchedResume${data.id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="viewMatchedResumeLabel" aria-hidden="true">
                                                                            <div className="modal-dialog modal-xl">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">
                                                                                        <h5 className="modal-title" id="viewMatchedResumeLabel">{data?.seeker_details?.firstName} {data?.seeker_details?.lastName}</h5>
                                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                    </div>
                                                                                    <div className="modal-body view-resume-modal-body">
                                                                                        {data?.resume?.resume && ((data?.resume?.resume.slice(((data?.resume?.resume.lastIndexOf(".") - 1) >>> 0) + 2)) == ("pdf" || "PDF")) ?
                                                                                            <iframe
                                                                                                src={`${host}/uploaded-files/${data?.resume?.resume}`}
                                                                                                style={{ width: "100%", height: "100%" }}
                                                                                                frameBorder="0"
                                                                                            ></iframe> :
                                                                                            <>
                                                                                                <p>
                                                                                                    <i>Document other than PDF are not supported for now.</i>
                                                                                                </p>
                                                                                                <p>
                                                                                                    <a href={`${host}/uploaded-files/${data?.resume?.resume}`} target='_blank' className="btn btn-success">Download</a>
                                                                                                </p>
                                                                                                {/* <DocViewer pluginRenderers={DocViewerRenderers} documents={`${host}/uploaded-files/${data?.resume?.resume}`} /> */}
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="modal-footer">
                                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    }
                                                                    <button className='border-0' onClick={() => handleClickRemoveUpdate(data?.seeker_id)}>
                                                                        <img src='./assets/recruiter-images/find-candidates/cross-icon.png' className='img-fluid' title='Remove candidate from matched list.' />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>



                        {/* -------------------pagination start--------------- */}
                        {showPagination && <div className='row justify-content-center mt-lg-5 mt-md-3 mt-4'>
                            <div className='col-lg-12 col-md-12 col-12'>
                                <nav aria-label="Page navigation example text-center">
                                    <ul className="pagination justify-content-center">
                                        <li className="page-item">
                                            <a className="page-link" href="#" onClick={goToPreviousPage} disabled={currentPage === 1} aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        {getPageNumbers().map((number) => (
                                            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`} >
                                                <a
                                                    onClick={() => changePage(number)} href="#" className="page-link" >
                                                    {number}
                                                </a>
                                            </li>
                                        ))}
                                        <li className="page-item">
                                            <a className="page-link" href="#" onClick={goToNextPage} disabled={currentPage === totalPages} aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>}
                        {/* -------------------pagination end--------------- */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default FindCandidatesContent