import React, { useEffect, useState } from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import BeforeFooterPlayStore from '../../Commons/BeforeFooter/BeforeFooterPlayStore'
import Footer from '../../Commons/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getBlogWithCategoryData, getBlogsByCategory, getSearchedBlog, getSixInterviewQuestions } from '../../../../Redux/action/MasterAction'
import { getBlogData } from '../../../../Redux/action/RecruiterAction'
import { toast } from 'react-toastify'
import Loader from '../../Loader'
import CareerSearch from '../CareerSearch'
import CareerGuideSearchContent from './CareerGuideSearchContent'

const CareerGuideSearch = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [sixQuestions, setSixQuestions] = useState(null);

    const [blog, setBlog] = useState(null);
    const [blogByCat, setBlogByCat] = useState(null);
    const queryParams = new URLSearchParams(location.search);
    const searched_text = queryParams.get('s');
    useEffect(() => { dispatch(getBlogWithCategoryData());}, [])
    useEffect(() => { dispatch(getSearchedBlog(searched_text));
        if (!searched_text) { toast.error("Please enter some text to search.");navigate(-1)}}, [searched_text])
    const blogData = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.blogListData : null);
    useEffect(() => {
        if (blogData) {setBlog(blogData)}}, [blogData]);
    const searchedBlog = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.searchedBlogsData?.data : null);
    useEffect(() => {
        if (searchedBlog) { }}, [searchedBlog]);
    useEffect(() => {dispatch(getSixInterviewQuestions());dispatch(getBlogData())}, [])
    return (
        <>{searchedBlog ? <>
            < AfterNavbar />
            {blogData && <CareerSearch blogData={blogData} />}
            {searchedBlog && <CareerGuideSearchContent blogData={searchedBlog} />}
            <BeforeFooterPlayStore />
            <Footer />
        </> :
            <div className="overlay-form-submission">
                <Loader />
            </div>
        }</>
    )
}

export default CareerGuideSearch