import React from 'react'
import './scss/MostCommonOccupations.css'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { createImageFromInitials, getRandomColor } from '../../../../Utils';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { toast } from 'react-toastify';
const host = process.env.REACT_APP_API_SERVER;
const MostCommonOccupations = ({ jobs }) => {
const totalStars = 5;const settingsMostCommon = {dots: false,infinite: jobs && jobs?.length > 3,autoplay: false,autoplaySpeed: 3000,slidesToShow: 3,slidesToScroll: 1,responsive: [{breakpoint: 1024,settings: {slidesToShow: 2,slidesToScroll: 1,infinite: jobs && jobs?.length > 3,dots: true}},
{breakpoint: 780,settings: {slidesToShow: 2,slidesToScroll: 2,infinite: jobs && jobs?.length > 2,dots: true,arrows: true}
},{breakpoint: 480,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: jobs && jobs?.length > 1,dots: true,arrows: false}}]
}
    const urlString = window.location.href;
    const url = new URL(urlString);
    const path = url.pathname.slice(1);
    const searchParams = url.search;
    const q = path + searchParams;
    const handleClick = () => {
        if (!localStorage.getItem("seeker_token")) {
            toast.error("Please login to continue.")
        }
    }
    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
    }
    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const previousDate = new Date(timestamp);
        const timeDifference = currentDate - previousDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        if (months > 0) {return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) {return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) { return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {return 'Just now';
        }
    };


    const truncateWord = (text, wordLimit) => {       
        if (text?.length > wordLimit) {return text.slice(0, wordLimit) + '...';}return text;
    };

    return (
        <>
            <section className='most-common-occupations-section'>
                <div className="main-section">
                    <div className="container">
                        <div className="second-section">
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='similer-post-heading'>
                                        <h2 className='ms-3 mb-3'>Most Common Occupations in Afghanistan</h2>
                                    </div>
                                </div>
                            </div>
                            <Slider {...settingsMostCommon} className='mb-5 pb-5'>

                                {jobs && jobs?.length > 0 && jobs.map((job, index) => {
                                    return <div className="col-lg-4" key={index + 1}>
                                        <div className="card ">
                                            <div className="d-flex">
                                                <div>
                                                    <img src={`${host}/uploaded-files/${job?.Company_details?.logo}`} alt={job?.Company_details?.company_name}
                                                        className='img-fluid company-logo'
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null;
                                                            currentTarget.src = createImageFromInitials(500, `${job?.Company_details?.company_name}`, getRandomColor());
                                                        }}
                                                        loading='lazy'
                                                    />
                                                </div>
                                                <div className="footer-heading footer-icon ms-2">
                                                    <div className='d-flex mb-2 align-items-start justify-content-between'>                                                      
                                                        <h3 className='company-name-css me-0' ><Link className='remove-link-style' to={`/profile/${job?.Company_details?.company_slug}/snap-shot`} title='View Company'>{job?.Company_details?.company_name}</Link></h3>
                                                                                                            </div>
                                                   
                                                    <span className='d-flex popular-companies-spans justify-content-start'>
                                                        {Array.from({ length: Math.floor(parseFloat(job?.avg_rating)) }, (_, index) => (
                                                            <StarIcon key={`full-${index}`} />
                                                        ))}
                                                        {parseFloat(job?.avg_rating) % 1 !== 0 && <StarHalfIcon key="half" />}
                                                        {Array.from({ length: totalStars - Math.floor(parseFloat(job?.avg_rating)) - (parseFloat(job?.avg_rating) % 1 !== 0 ? 1 : 0) }, (_, index) => (
                                                            <StarBorderIcon key={`empty-${index}`} />
                                                        ))}
                                                        <span>{Number(job?.avg_rating) || Number(job?.avg_rating) !== 0 && job?.avg_rating}</span>
                                                    </span>
                                                   
                                                </div>
                                            </div>
                                            <div className="footer-list-card mt-2">
                                                <div className='d-flex justify-content-between times-div'>
                                                    <h6><Link className='remove-link-style' to={`/job-find-description?j=${job?.job_slug}`} title='View Job'>{job?.job_title}</Link></h6>
                                                    <span className='times-span'><i className="fa-solid fa-clock me-1"></i>{timeAgoCalculator(job?.createdAt)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                                

                            </Slider>

                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default MostCommonOccupations