import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getHomepageData } from '../../../Redux/action/SeekerAction';
const host = process.env.REACT_APP_API_SERVER;
const Benifits = () => {const dispatch = useDispatch();useEffect(() => { dispatch(getHomepageData());}, []);
    const homepageFifthSectionData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.homepageDataList?.data?.homeSection) : "");
    const homepageFifthSectionCardData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.homepageDataList?.data?.benefits) : "");
    return (
        <section className='benifits'>
            <div className='container'>
                {<div className='row'>
                    <div className='col-lg-6'>
                        <div className='benifit-img'>
                            < img src={homepageFifthSectionData?.img_five ? `${host}/${homepageFifthSectionData?.img_five}` : "assets/images/homepage/benifits.png"}
                                className='img-fluid'
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = "assets/images/homepage/benifits.png";
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='key-benifits'>
                            <div className='heading-txt'>
                                <h1>{homepageFifthSectionData?.heading_five ? homepageFifthSectionData?.heading_five : "Benefits"}</h1>
                            </div>
                            <div className='para-txt'>
                                <p>{homepageFifthSectionData?.description_five ? homepageFifthSectionData?.description_five : "Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida"}</p>
                            </div>
                            <div className='row'>
                                {homepageFifthSectionCardData?.map((data) => {
                                    const imageURL = data?.img_file ? `${host}/${data?.img_file}` : '';
                                    return (
                                        <div className='col-lg-6'>
                                            <div className='benifit-sec-lft'>
                                                <div className='key-points'>
                                                    <div className='cust-image'>
                                                        <img src={imageURL} className='img-fluid' alt="" />
                                                    </div>
                                                    <div className='text'>
                                                        <p>{data?.title ? data?.title : "Quality Job"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </section>
    )
}
export default Benifits