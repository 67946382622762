import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import { FocusError } from 'focus-formik-error';
import { toast } from 'react-toastify';
import RegisterHeader from './CvBuilderHeader/RegisterHeader'
import Footer from '../Commons/Footer'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Steps from './Steps';
import { getSeekeResumeData, resetSeekerCVData } from '../../../Redux/action/SeekerAction';
import Loader from "../Loader";
import HourglassLoader from '../HourglassLoader';
import CvModal from './CvModal';
import Steps2 from './Steps2';
let host = process.env.REACT_APP_API_SERVER;

const Cv = () => {

    const [refresh, setRefersh] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [editedId, setEditedId] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);
    const [other, setother] = useState(false);



    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;
        dispatch(resetSeekerCVData());
        if (localStorage.getItem("seeker_token")) {

            setIsLoggedIn(true);
            let authToken = localStorage.getItem("seeker_token");
            dispatch(getSeekeResumeData(authToken));

        } else {
            toast.error("Please register to update information.")
            navigate("/seeker-register")
        }

        return () => {
            abortController.abort();
        };
    }, [])

    const resumeList = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.seekerResumesDataList : []);

    useEffect(() => {
        if (resumeList) {
        }
    }, [resumeList]);


    const config = {
        headers: {
            'content-type': 'application/json',
            "auth-token": localStorage.getItem("seeker_token")
        }
    };

    useEffect(() => {
        let authToken = localStorage.getItem("seeker_token");
        dispatch(getSeekeResumeData(authToken));
    }, [refresh]);

    const changeRefresh = () => {
        setRefersh(!refresh);
    }


    const setDefaultResume = (resume_id) => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {
            document.body.style.overflow = "hidden";
            setShowLoader(true);
            const config = {
                headers: {
                    "auth-token": localStorage.getItem("seeker_token")
                }
            };
            const values = { resume_id: resume_id }
            axios.post(`${host}/mobile/set-default-resume`, values, config)
                .then(response => {
                    if (response.status == 200) {
                        let authToken = localStorage.getItem("seeker_token");
                        dispatch(getSeekeResumeData(authToken));
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Default resume updated.' });
                    } else {
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                    }
                }).catch(error => {
                    document.body.style.overflow = "auto";
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error!' });
                })
        } catch (error) {
            document.body.style.overflow = "auto";
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error!' });
        }
    }

    const deleteReusme = (resume_id) => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {
            document.body.style.overflow = "hidden";
            setShowLoader(true);
            const config = {
                headers: {
                    "auth-token": localStorage.getItem("seeker_token")
                }
            };
            axios.get(`${host}/mobile/delete-resume?resume_id=${resume_id}`, config)
                .then(response => {
                    if (response.status == 200) {
                        let authToken = localStorage.getItem("seeker_token");
                        dispatch(getSeekeResumeData(authToken));
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Resume deleted successfully.' });
                    } else {
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                    }
                }).catch(error => {
                    document.body.style.overflow = "auto";
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error!' });
                })
        } catch (error) {
            document.body.style.overflow = "auto";
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error!' });
        }
    }

    const handleNext = () => {
        navigate("/you-have-registred-successfully");
    }

    return (
        <>
            <RegisterHeader />

            <section className='educational-detail'>
                {<div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-2">
                                <Steps2 activeStepCount={7} />
                            </div>
                        </div>
                        <div className="col-lg-12 my-lg-0 my-md-0 my-2">
                            <div className="form-area">
                                <div className="row align-items-center">
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-12 profile-back-btn">
                                        <div className="back-btn">
                                            <Link to='/social-media'><i className="fa-solid fa-arrow-left mx-2"></i>Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-5 col-6 edu-title">
                                        <h1>CV</h1>
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-6 ">
                                        <div className="">
                                            <button type="button" className="cust-add-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                                onClick={() => {
                                                }
                                                }>
                                                Add
                                            </button>
                                        </div>
                                    </div>


                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead className='table-light'>
                                                <tr>
                                                    <th scope="col">Sr No.</th>
                                                    <th scope="col" >CV</th>
                                                    <th scope='col'>Default</th>
                                                    <th scope="col" >Action</th>
                                                </tr>
                                            </thead>
                                            {resumeList?.length > 0 ?
                                                <tbody>
                                                    {
                                                        resumeList.map((resume, index) => {
                                                            return <> <tr key={index + 1}>
                                                                <th>{index + 1}</th>
                                                                <td>
                                                                    <button type="button" className="btn btn-primary btn-sm mx-2 view_resume_btn" data-bs-toggle="modal" data-bs-target={`#CVresumeModal_${resume?.id}`} title="View Resume">{resume?.resume_name ?? "View"}</button></td>
                                                                <td>{resume?.set_default ? <span className="text-success">Default resume</span> : <button title="Set default resume" className="defaultResumeBtn" onClick={() => setDefaultResume(resume?.id)}>Set default</button>}</td>
                                                                <td> <button type='button' className="btn ms-1" onClick={() => { deleteReusme(resume?.id) }}> <img src="./assets/my-profile/trush-square.png" alt="delete button" title='Delete resume' /></button>
                                                                </td>
                                                            </tr>
                                                                <div className="modal fade" id={`CVresumeModal_${resume?.id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                    <div className="modal-dialog modal-xl">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                <h1 className="modal-title fs-5" id="staticBackdropLabel">{resume?.resume_name}</h1>
                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                            </div>
                                                                            <div className="modal-body">
                                                                                {resume?.resume && ((resume?.resume.slice(((resume?.resume.lastIndexOf(".") - 1) >>> 0) + 2)) == ("pdf" || "PDF")) ?
                                                                                    <iframe
                                                                                        src={`${host}/uploaded-files/${resume?.resume}`}
                                                                                        style={{ width: "100%", height: "100%" }}
                                                                                        frameBorder="0"
                                                                                    ></iframe> :
                                                                                    <>
                                                                                        <p>
                                                                                            <i>Document other than PDF are not supported for now.</i>
                                                                                        </p>
                                                                                        <p>
                                                                                            <a href={`${host}/uploaded-files/${resume?.resume}`} target='_blank' className="btn btn-success">Download</a>
                                                                                        </p>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                            <div className="modal-footer">
                                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                              
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        })
                                                    }
                                                </tbody>

                                                :

                                                <tbody>
                                                    <tr>
                                                        <td colSpan={4}>No data available.</td>
                                                    </tr>
                                                </tbody>
                                            }
                                        </table>
                                    </div>



                                    <div className="col-md-12 mt-5 mb-5 custm-btn-sec d-flex justify-content-center">

                                        <div className='custm-botton-btn mb-2 text-center'>
                                            <Link to='/you-have-registred-successfully'><button className='skip me-2' type='button' >Skip</button></Link>

                                            <button className='next' type='button'
                                                onClick={handleNext}
                                            >Save and Continue</button>

                                        </div>

                                    </div>
                                    <CvModal refresh={refresh} changeRefresh={changeRefresh} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >}
            </section >
            
            <Footer />
        </>
    )
}

export default Cv;