import React, { useEffect, useState, useCallback } from 'react'
import './scss/CreateAnAccount.css'
import $ from 'jquery';
import CreateAnAccountPopup from './CreateAnAccountPopup/CreateAnAccountPopup';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, useFormik } from "formik";
import { FocusError } from 'focus-formik-error';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { getGenderData, getMaritalData, getExperienceLevelData, getDesignationData, getJobRoleData, getlanguageKnownData, getCountryData, getIndustryData, getTypeOfJobData, getStatebyCountryData, getCitybyStateData } from '../../../Redux/action/SeekerAction';
import Loader from '../Loader';
import HourglassLoader from '../HourglassLoader';
import Select from 'react-select'
import { useAuth0 } from "@auth0/auth0-react";
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import {
    LoginSocialGoogle, LoginSocialLinkedin,
} from 'reactjs-social-login'
import {
    GoogleLoginButton, LinkedInLoginButton,
} from 'react-social-login-buttons'

const client_id = process.env.REACT_APP_GG_APP_CLIENT_ID;
const host = process.env.REACT_APP_API_SERVER
const REDIRECT_URI = 'https://karlatoon.alobhadev.com/create-an-account'


const CreateAnAccountContent = () => {
    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState(null)
    const [lkData, setlkData] = useState(null);
    const onLoginStart = useCallback(() => {
        alert('login start')
    }, [])

    const onLogoutSuccess = useCallback(() => {
        setProfile(null)
        setProvider('')
        alert('logout success')
    }, [])


    const [faIcon, setfaIcon] = useState('fa-eye-slash');
    const [timerStarted, setTimerStarted] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const [disabled, setDisabled] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const handleStartTimer = () => {
        setTimerStarted(true);
    };

    useEffect(() => {
        let intervalId;
        if (timerStarted && seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [timerStarted, seconds]);

    const togglePasswordType = () => {
        let input_pass1 = document.getElementById('input_pass1');
        const toggle_pass = document.getElementById("toggle_pass");
        let input_type = input_pass1.getAttribute('type');
        if (input_type == "password") {
            setfaIcon('fa-eye');
            input_pass1.type = "text";
        } else {
            setfaIcon('fa-eye-slash')
            input_pass1.type = "password"
        }
    }
    const [faIcon2, setfaIcon2] = useState('fa-eye-slash');
    const togglePasswordType2 = () => {
        let input_pass1 = document.getElementById('input_pass12');
        const toggle_pass = document.getElementById("toggle_pass2");
        let input_type = input_pass1.getAttribute('type');
        if (input_type == "password") {
            setfaIcon2('fa-eye');
            input_pass1.type = "text";
        } else {
            setfaIcon2('fa-eye-slash')
            input_pass1.type = "password"
        }
    }
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [country_id, setCountry_id] = useState("");
    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    useEffect(() => {
    }, [dispatch]);
    const handleChangeState = (country_id) => {
        dispatch(getStatebyCountryData(country_id)); }
    const handleChangeCity = (state_id) => {
        dispatch(getCitybyStateData(state_id))

    }
    const citybystateData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.citybystateDataList?.data) : "")
    const statebycountryData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.statebycountryDataList?.data) : "");
    const genderData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.genderDataList?.data) : "");
    const maritalData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.maritalDataList?.data) : "");
    const experienceLevelData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.experienceLevelDataList?.data) : "");
    const designationData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.designationDataList?.data) : "");
    const jobRoleData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.jobRoleDataList?.data) : "");
    const languageKnownData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.languageKnownList?.data) : "");
    const countryData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.countryDataList?.data) : '');
    const industryData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.industryTypeDataList?.data) : '');
    const typeOfJobData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.typeOfJobDataList?.data) : '');
    const languageOptions = [];
    languageKnownData?.map(language => {
        let key = language.id;
        languageOptions.push({ value: language.id, label: language.languagename })
    })
    let [initialValues, setInitialValues] = useState({
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
        password: "",
        confirmpassword: "",        
        share_data: false,
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            firstname: Yup.string().required("Please enter First Name."),

            mobile: Yup.string().required("Please enter Mobile no.").matches(/^\+?[0-9]{1,4}?[-\s]?[0-9]{8,15}$/, "Please enter a valid mobile number with optional country code."),
            email: Yup.string().required("Please enter Email.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid E-mail"),
            password: Yup.string().required('Please enter Password')
                .min(6, "Password should be 6 to 20 characters long."),
            confirmpassword: Yup.string().required("Please enter Confirm password.")
                .oneOf([Yup.ref('password'), null], 'Passwords must match'),           
            share_data: Yup.boolean().oneOf([true], 'Check T&C and Privacy policy.') }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                setDisableBtn(true);
                setSubmitting(true);
                setShowLoader(true);

                document.body.style.overflow = 'hidden';

                const config = {headers: { 'content-type': 'application/json',                    }               };               
                await axios.post(`${host}/api/seeker-register`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setDisableBtn(false);


                            setSubmitting(false)

                            document.body.style.overflow = 'auto';
                            setShowLoader(false);
                            const otpResponse = response.data.otp;

                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `Otp is ${otpResponse}. Please verify.` });
                            const modal = document.getElementById('signup-otp');
                            if (modal) {
                                modal.classList.add('show');
                                modal.style.display = 'block';

                                handleStartTimer();
                            }

                        } else {
                            setDisableBtn(false);
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })


            }

            catch (error) {
                setDisableBtn(false);
                document.body.style.overflow = 'auto';
                setShowLoader(false);
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    });

    if (profile?.email_verified) {
        try {
            const formdata = {
                firstName: profile?.given_name,
                lastName: profile?.family_name,
                email: profile?.email,
            }
            axios.post(`${host}/mobile/seeker-google-login-web`, formdata).
                then((response) => {
                    if (response.status == 200) {
                        if (localStorage.getItem("recruiter_token")) {
                            localStorage.removeItem("recruiter_token")
                            toast.warn("Recruiter session logged out.")
                        }
                        localStorage.setItem("seeker_token", response.data.authToken);
                        toast({ type: toast.TYPE.SUCCESS, autoClose: 3000, render: response?.data?.msg });
                        navigate('/personal-details')
                    }
                    else {

                        toast({ type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
                    }
                })
        }
        catch (error) {

            toast({ type: toast.TYPE.ERROR, autoClose: 3000, render: "Error loggin In" });

        }
    }

    if (lkData?.access_token) {


        try {
            const value = {

                accessToken: lkData.access_token, 

            }
            axios.post(`${host}/mobile/seeker-linkedIn-login-web`, value).
                then((response) => {
                    if (response.status == 200) {
                        if (localStorage.getItem("recruiter_token")) {
                            localStorage.removeItem("recruiter_token")
                            toast.warn("Recruiter session logged out.")
                        }
                        localStorage.setItem("seeker_token", response.data.authToken);
                        toast({ type: toast.TYPE.SUCCESS, autoClose: 3000, render: response?.data?.msg });
                        if (response.data.newUser) {
                            navigate('/personal-details')

                        }
                        else {
                            navigate('/seeker-login-profile')
                        }
                    }
                    else {

                        toast({ type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
                    }
                })
        }
        catch (error) {

            toast({ type: toast.TYPE.ERROR, autoClose: 3000, render: "Error loggin In" });

        }
    }

    return (
        <>
            <section className='create-an-account-section'>
                <section className="main-section">
                    <div className="container">
                        <div className="second-section py-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="first-head">
                                        <h2>Create an account</h2>
                                        <p>It only takes a couple of minutes to get started</p>
                                    </div>
                                   
                                </div>
                                <div className="col-md-12">
                                    <div className='before-form-dv'>
                                        <form className="craete-form" method='post' onSubmit={formik.handleSubmit}>
                                            <FocusError formik={formik} />
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="name1" className="form-label">
                                                        First Name<span className="text-danger">*</span>
                                                    </label>
                                                    <input className="form-control form-custom" id="name1" type="text" name="firstname" placeholder="Enter First Name"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.firstname} />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.firstname && formik.errors.firstname ? (
                                                            <div className='text-danger'>{formik.errors.firstname}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="name2" className="form-label">
                                                        Last Name<span ></span>
                                                    </label>
                                                    <input className="form-control form-custom" id="name2" type="text" name="lastname" placeholder="Enter Last Name" onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.lastname} />

                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 mt-3 verify-icon">
                                                    <label htmlFor="phone" className="form-label">
                                                        Mobile Number<span className="text-danger">*</span>
                                                    </label>
                                                    <input type="tel" className="form-control form-custom2" id="phone" name="mobile" placeholder="Enter Mobile Number"
                                                        // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.mobile}
                                                        maxLength={15}
                                                    />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.mobile && formik.errors.mobile ? (
                                                            <div className='text-danger'>{formik.errors.mobile}</div>
                                                        ) : null}
                                                    </div>
                                                </div>



                                                <div className="col-lg-6 col-md-6 mt-3 verify-icon">
                                                    <label htmlFor="mail" className="form-label">
                                                        Email<span className="text-danger">*</span>
                                                    </label>
                                                    <input type="mail" className="form-control form-custom2" id="email" name="email" placeholder="Enter your Email address"
                                                       
                                                        onChange={(e) => { formik.handleChange(e); handleEmailChange(e); }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.email}
                                                    />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.email && formik.errors.email ? (
                                                            <div className='text-danger'>{formik.errors.email}</div>
                                                        ) : null}
                                                    </div>
                                                </div>



                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mt-3 ">
                                                    <div className='password-custom cus-pass-section'>
                                                        <label htmlFor="password" className="form-label">
                                                            Password<span className="text-danger">*</span>
                                                        </label>
                                                      
                                                        <span toggle="#password-field" className={`fa fa-fw ${faIcon} field_icon slash-icon toggle-password`} id="toggle_pass" onClick={togglePasswordType}></span>
                                                    </div>
                                                    <div className='input-position-ab'>
                                                        <input className="form-control form-custom" type="password" name="password" placeholder="Enter your Password" id="input_pass1" maxLength={20}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.password} />
                                                    </div>
                                                    <div className="help-block with-errors">
                                                        {formik.touched.password && formik.errors.password ? (
                                                            <div className='text-danger'>{formik.errors.password}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <div className='password-custom'>

                                                        <label htmlFor="password2" className="form-label">
                                                            Confirm Password<span className="text-danger">*</span>
                                                        </label>
                                                      
                                                        <span toggle="#password-field" className={`fa fa-fw ${faIcon2} field_icon slash-icon toggle-password`} id="toggle_pass" onClick={togglePasswordType2}></span>
                                                    </div>
                                                    <div className='input-position-ab'>
                                                        <input className="form-control form-custom" type="password" id="input_pass12" name="confirmpassword" placeholder="Enter your Password" maxLength={20}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.confirmpassword} />
                                                    </div>
                                                    <div className="help-block with-errors">
                                                        {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                                                            <div className='text-danger'>{formik.errors.confirmpassword}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className='checkbox-dv'>
                                                        <input
                                                            type="checkbox"
                                                            id="share_data"
                                                            name="share_data"
                                                            className='checkbox-input'
                                                            checked={formik.values.share_data ? true : false}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.share_data}
                                                        />
                                                        <label htmlFor="share-check"><span className="text-danger">*</span>Share my registration data with our content providers for marketing purposes. </label>
                                                    </div>
                                                    <div className="help-block with-errors">
                                                        {formik.touched.share_data && formik.errors.share_data ? (
                                                            <div className='text-danger'>{formik.errors.share_data}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className='by-policy-dv'>
                                                        <p> By creating an account, you agree to the
                                                            <Link to="/terms-conditions"> Terms of Conditions </Link> and <Link to="/privacy-policy"> Privacy Policy </Link>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="top-btn">
                                                 
                                                    <button className="form-btn2" type='submit' disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : "Sign Up"}</button>
                                                </div>
                                                <p className="text-center already-account-dv">
                                                    Already have an account?
                                                    <Link to='/signin' className='login-a-tag' >Sign In</Link>
                                                </p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showLoader && <div className="overlay-form-submission">
                            <Loader />
                        </div>}
                    </div>

                </section>
                <CreateAnAccountPopup email={email} timerStarted={timerStarted} setTimerStarted={setTimerStarted} handleStartTimer={handleStartTimer} seconds={seconds} setSeconds={setSeconds} />

            </section >
        </>
    )
}

export default CreateAnAccountContent