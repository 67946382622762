import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../Loader';
import Error from '../../../Common/Error/Error';
import parse from 'html-react-parser';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getJobDetailsOnCompanySlug, getJobsAccToCompany, getJobsOnCompany, getSearchDataOnCompanyProfile } from '../../../../Redux/action/SeekerAction';
import JobsDetail from './JobsDetail';
import './scss/Jobs.css';
const host = process.env.REACT_APP_API_SERVER;
const Jobs = (data) => {
    const id = data?.id;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [showLoader, setShowLoader] = useState(false);
    useEffect(() => {
        if (!localStorage.getItem('seeker_token')) {
          } else {      
        }
    }, [dispatch, id, navigate]);

    const { loadingJobsAccToCompanyDataListApi, JobDetailsOnCompanyDataList } = useSelector(
        (state) => state.SeekerReducer
    );
    const JobsOnCompanyData = loadingJobsAccToCompanyDataListApi?.data;

    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const previousDate = new Date(timestamp);
        const timeDifference = currentDate - previousDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {
            return 'Just now';
        }
    };

    // Pagination
    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 10;

    useEffect(() => {
        const endOffset = dataOffset + dataPerPage;
        setCurrentData(JobsOnCompanyData?.slice(dataOffset, endOffset));
        setPageCount(Math.ceil(JobsOnCompanyData?.length / dataPerPage));
    }, [dataOffset, dataPerPage, JobsOnCompanyData]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage) % JobsOnCompanyData?.length;
        setDataOffset(newOffset);
        window.scrollTo(0, 0);
    };

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('seeker_token')) {
            setIsLoggedIn(true);
        }
        return (() => {
            let loginPopUpModalJobsCloseBtn = document.getElementById("loginPopUpModalJobsCloseBtn");
            if (loginPopUpModalJobsCloseBtn) {
                loginPopUpModalJobsCloseBtn.click();
            }
        })
    }, []);

    const handleApply = (id) => {
        if (!isLoggedIn) {
          
            let loginModalJobsBtn = document.getElementById("loginModalJobsBtn");
            if (loginModalJobsBtn) {
                loginModalJobsBtn.click();
            }
        } else {
            navigate(`/your-career-start-here?j=${id}`);
        }
    };

    const urlString = window.location.href;
    const url1 = new URL(urlString);
    const path = url1.pathname.slice(1);
    const searchParams = url1.search;
    const q = path + searchParams;
    const handleLoginRedirect = () => {
        let loginPopUpModalJobsCloseBtn = document.getElementById("loginPopUpModalJobsCloseBtn");
        if (!localStorage.getItem("seeker_token")) {
            if (loginPopUpModalJobsCloseBtn) {
                loginPopUpModalJobsCloseBtn.click();
                navigate(`/signin?q=${encodeURIComponent(q)}`);
            }
        } else {
            if (loginPopUpModalJobsCloseBtn) {
                loginPopUpModalJobsCloseBtn.click();
            }
        }
    }

    const [cdata, setCdata] = useState(currentData?.[0]);
    const queryParams = new URLSearchParams(location.search);
    const slug = queryParams.get('s');

    useEffect(() => {
        if (slug) {
            dispatch(getJobDetailsOnCompanySlug(slug, localStorage.getItem('seeker_token')));
        } else if (currentData?.length > 0) {
            setCdata(currentData[0]);
        }
    }, [slug, dispatch, currentData]);

    useEffect(() => {
        if (slug && JobDetailsOnCompanyDataList) {
            setCdata(JobDetailsOnCompanyDataList.data);
        }
    }, [JobDetailsOnCompanyDataList, slug]);

    const formik = useFormik({
        initialValues: {
            title: '',
            location: ''
        },
        validationSchema: Yup.object({
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const removeEmptyStringValues = (obj) => {
                for (const key in obj) {
                    if (obj[key] === '') {
                        delete obj[key];
                    }
                }
                return obj;
            };

            const objectWithoutEmptyStrings = removeEmptyStringValues(values);
            let create_search_string = "";
            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                    create_search_string += `${key}=${objectWithoutEmptyStrings[key]}&`;
                }
            }
            create_search_string = create_search_string.slice(0, -1);
            navigate(`/profile/${data?.name}/Jobs?${create_search_string}`);
            resetForm();
        }
    });

    const locationSearch = queryParams.get('location');
    const titleSearch = queryParams.get('title');

    useEffect(() => {
        if (locationSearch || titleSearch) {
            const paramsSearch = {};
            if (locationSearch) {
                paramsSearch.location = locationSearch;
            }
            if (titleSearch) {
                paramsSearch.title = titleSearch;
            }
            const removeEmptyStringValues = (obj) => {
                for (const key in obj) {
                    if (obj[key] === '') {
                        delete obj[key];
                    }
                }
                return obj;
            };

            const objectWithoutEmptyStrings = removeEmptyStringValues(paramsSearch);
            let create_search_string = `id=${id}&`;
            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                    create_search_string += `${key}=${objectWithoutEmptyStrings[key]}&`;
                }
            }
            create_search_string = create_search_string.slice(0, -1);

            if (localStorage.getItem("seeker_token")) {
                dispatch(getSearchDataOnCompanyProfile(create_search_string, localStorage.getItem("seeker_token")));
            } else {
                dispatch(getSearchDataOnCompanyProfile(create_search_string));
            }
        }
        else {
            let create_search_string = `id=${id}`;
            dispatch(getSearchDataOnCompanyProfile(create_search_string, localStorage.getItem("seeker_token")));
        }
    }, [locationSearch, titleSearch]);

    const handleClick = (slug) => {
        if (locationSearch || titleSearch) {
            const paramsSearch = {};
            if (locationSearch) {
                paramsSearch.location = locationSearch;
            }
            if (titleSearch) {
                paramsSearch.title = titleSearch;
            }
            const removeEmptyStringValues = (obj) => {
                for (const key in obj) {
                    if (obj[key] === '') {
                        delete obj[key];
                    }
                }
                return obj;
            };

            const objectWithoutEmptyStrings = removeEmptyStringValues(paramsSearch);
            let create_search_string = `id=${id}&`;
            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                    create_search_string += `${key}=${objectWithoutEmptyStrings[key]}&`;
                }
            }
            create_search_string = create_search_string.slice(0, -1);

            navigate(`/profile/${data?.name}/Jobs?s=${slug}&${create_search_string}`);
        }
        else {
            navigate(`/profile/${data?.name}/Jobs?s=${slug}`);

        }

    };



    const handleSave = (slug) => {
        if (!isLoggedIn) {
            let loginModalJobsBtn = document.getElementById("loginModalJobsBtn");
            if (loginModalJobsBtn) {
                loginModalJobsBtn.click();
            }
        } else {
            let token = localStorage.getItem("seeker_token");

            let values = {
                job_slug: slug,
            };
            const loadingToastId = toast.info('Saving...', { autoClose: false });
            setShowLoader(true);
            try {
                document.body.style.overflow = 'hidden';
                let config = {
                    headers: {
                        'content-type': 'application/json',
                        'auth-token': localStorage.getItem("seeker_token")
                    }
                };
                axios.post(`${host}/mobile/save-job-slug`, values, config)
                    .then((response) => {
                        if (response.status === 200) {
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Saved successfully!' });
                            setShowLoader(false);
                            setCurrentData((prevData) => {
                                return prevData.map((job) => {
                                    if (job.job_slug === slug) {
                                        return { ...job, saved: true };
                                    }
                                    return job;
                                });
                            });
                        } else {
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response?.data?.msg });
                            setShowLoader(false);
                        }
                    })
            } catch (error) {
                console.log(error.message);
                document.body.style.overflow = 'auto';
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Some Error occured. Please try again later.' });
                setShowLoader(false);
            }

        }
    };

    const handleUnsave = (slug) => {
        if (!isLoggedIn) {
            let loginModalJobsBtn = document.getElementById("loginModalJobsBtn");
            if (loginModalJobsBtn) {
                loginModalJobsBtn.click();
            }
        } else {
            let token = localStorage.getItem("seeker_token");

            let values = {
                job_slug: slug,
            };
            const loadingToastId = toast.info('Removing from saved jobs...', { autoClose: false });
            setShowLoader(true);
            try {
                document.body.style.overflow = 'hidden';
                let config = {
                    headers: {
                        'content-type': 'application/json',
                        'auth-token': localStorage.getItem("seeker_token")}
                };
                axios.post(`${host}/mobile/unsave-job-slug`, values, config)
                    .then((response) => {
                        if (response.status === 200) {
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Removed from saved jobs successfully!' });
                            setShowLoader(false);
                            setCurrentData((prevData) => {
                                return prevData.map((job) => {if (job.job_slug === slug) {return { ...job, saved: false };}return job; });
                            });
                        } else {
                            document.body.style.overflow = 'auto';toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response?.data?.msg });setShowLoader(false);
                        }
                    })
            } catch (error) {console.log(error.message);document.body.style.overflow = 'auto';toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Some Error occured. Please try again later.' });setShowLoader(false);}

        }
    };

    return (
        <>
            <div className=" jobs-section ">
                <div className="pb-1">
                    <div className="">
                        <h2>{data?.data} {titleSearch} Jobs {locationSearch ? `in ${locationSearch}` : 'and Carrers'}</h2>
                    </div>

                </div>
                <form onSubmit={formik.handleSubmit} className="form-section rounded-t">
                    <div className="row py-2 align-items-end">

                        <div className="col-md-5">
                            <label htmlFor="jobTitle">
                                what
                            </label>
                            <div className="relative">

                                <input
                                    type="text"
                                    name="title"
                                    className="form-control pe-5"
                                    placeholder="Job title"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <SearchIcon />
                            </div>
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="location">
                                where

                            </label>
                            <div className="relative">

                                <input
                                    type="text"
                                    name="location"
                                    className="form-control pe-5"
                                    placeholder="Location"
                                    value={formik.values.location}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <LocationOnIcon />
                            </div>
                        </div>
                        <div className='col-md-2'>
                        <button type="submit" className="submit-btn find_jobs_btn">
                            Find Jobs
                        </button>
                        </div>
                    </div>
                </form>
                <div className="jobs">
                    <h4 className="my-3">
                        {JobsOnCompanyData?.length} jobs at {data?.data}
                    </h4>
                    <h6><Link to={`/profile/${data?.name}/Jobs`}>See all jobs for the company <i className='fa fa-long-arrow-right'></i> </Link></h6>

                    {JobsOnCompanyData?.length > 0 ?
                        <div className="row">

                            <div className="col-lg-4">
                                <div className="job-status-div">

                                    {
                                        currentData?.map((data) => {
                                            return (

                                                <div className="box-rounded mb-3" key={data?.id} style={{ cursor: "pointer" }} onClick={() => { handleClick(data?.job_slug) }}>
                                                    <div className="d-flex mb-1 justify-content-between align-items-center" >
                                                        <h4 className=''>{data?.job_title}</h4>
                                                        <div className="dropdown">
                                                            <button
                                                                className="job-dropdown-btn"
                                                                type="button"
                                                                id="dropdownMenuButton1"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                <MoreVertIcon />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li>
                                                                    {localStorage.getItem("seeker_token") && data?.saved ?
                                                                        <div style={{ cursor: "pointer" }} onClick={() => { handleUnsave(data?.job_slug) }}>
                                                                            <img
                                                                                src="/assets/career-start/savedJob.png"
                                                                                className="img-fluid ps-2"
                                                                                alt="Unsave Job"
                                                                                title='Unsave Job'

                                                                            /> Unsave Job
                                                                        </div>
                                                                        :
                                                                        <div style={{ cursor: "pointer" }} onClick={() => handleSave(data?.job_slug)}>
                                                                            <img
                                                                                src="/assets/career-start/unsavedJob.png"
                                                                                className="img-fluid ps-2"
                                                                                alt="Save Job"
                                                                                title='Save Job'

                                                                            /> Save Job
                                                                        </div>
                                                                    }
                                                                </li>

                                                            </ul>
                                                        </div>

                                                    </div>
                                                    {data?.location_to_report &&
                                                        <p className="mb-2">{data?.job_city?.city_name},{data?.job_city?.state_of_city?.state_name},{data?.job_city?.state_of_city?.country_of_state?.name}</p>
                                                    }
                                                    <div className="job-update-btn mb-3">
                                                        <span>{data?.job_type_list[0]?.job_type_detail?.jobtypename}</span>
                                                    </div>
                                                    <span>{timeAgoCalculator(data?.createdAt)}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <section className='pagination-section'>
                                    <nav aria-label="Page navigation example" className='nav-class'>
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=">>"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={pageCount}
                                            previousLabel="<<"
                                            renderOnZeroPageCount={null}
                                            containerClassName='pagination'
                                            pageLinkClassName='page-num'
                                            previousLinkClassName='page-num'
                                            nextLinkClassName='page-num'
                                            activeClassName='active'
                                        />
                                    </nav>
                                </section>
                            </div>
                            <div className="col-lg-8">
                                <div className="rounded-box-container">

                                    <div className="box-rounded-div">
                                        <div>

                                            <div className="d-flex justify-content: flex-start apply-job-section align-items-center">
                                                <div className="img d-flex justify-content-center align-items-center">
                                                    <img src={data?.logo ? `${host}/uploaded-files/${data?.logo}` : ''} style={{ width: "50px", height: '100px' }} alt="" />
                                                </div>
                                                <div>
                                                    <h3 className="mb-0">
                                                        {cdata?.job_title}
                                                    </h3>
                                                    {cdata?.location_to_report && <span>{cdata?.job_city?.city_name},{cdata?.job_city?.state_of_city?.state_name},{cdata?.job_city?.state_of_city?.country_of_state?.name}</span>}
                                                </div>
                                            </div>
                                            <button type="button" className="submit-btn col-md-3" onClick={() => handleApply(cdata?.job_slug)}>
                                                Apply Now
                                            </button>
                                        </div>

                                    </div>
                                    <div className="role-box">
                                        <div className="card-body py-4">
                                            <h5 className="card-subtitle mb-3">Overview:</h5>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-3 col-6">
                                                    <div className="card-pad">
                                                        <img
                                                            src="/assets/career-start/calendar-card.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p>Job Posted:</p>
                                                        <h6>{new Date(cdata?.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-3 col-6">
                                                    <div className="card-pad">
                                                        <img
                                                            src="/assets/career-start/timer.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p>Job expire:</p>
                                                        <h6>{cdata?.is_application_deadline ? `${new Date(cdata?.application_deadline).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}` : "-"}</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-3 col-6">
                                                    <div className="card-pad">
                                                        <img
                                                            src="/assets/career-start/wallet.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p>No. of Vaccancies</p>
                                                        <h6>{cdata?.no_of_people_to_hire ? cdata?.no_of_people_to_hire : "-"}</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-3 col-6">
                                                    <div className="card-pad">
                                                        <img
                                                            src="/assets/career-start/calendar-card.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <p>Education</p>
                                                        <h6>{cdata?.education_level_list?.length ? cdata?.education_level_list?.map((education) => education?.education_level_detail?.educationlevel).join(", ")
                                                            : "-"}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3 className="card-title mb-3">Job Description</h3>

                                            {cdata?.description ? parse(`${cdata?.description}`) : parse(`<i>No description available.</i>`)}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        :
                        <>
                            {
                                (locationSearch || titleSearch) ?
                                    <div className='text-center'>
                                        <h5>No jobs for this search</h5>
                                        <img
                                            src="/assets/career-start/jobsZrp.svg"
                                            className="img-fluid"
                                            alt=""
                                        />
                                        <h6><Link to={`/profile/${data?.name}/Jobs`}>See all jobs for the company</Link></h6>
                                    </div>
                                    :
                                    <div className='text-center'>
                                        <h5>  No Jobs Posted</h5>
                                        <img
                                            src="/assets/career-start/jobsZrp.svg"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>

                            }
                        </>
                    }
                </div>

            </div>
            <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#loginPopUpModalJobs" id="loginModalJobsBtn">
                Launch demo modal
            </button>
            <div className="modal fade" id="loginPopUpModalJobs" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h5 className="modal-title" id="exampleModalLabel"><img src="/assets/images/logo.png" /></h5>
                            <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" id="loginPopUpModalJobsCloseBtn"></button>
                        </div>
                        <div className="modal-body">
                            <div className="card-body p-3">
                                <h3 className="text-center" style={{
                                    fontSize: '20px !important',
                                    marginBottom: '20px',
                                }}>
                                    Ready to take next step ?
                                </h3>
                                <p className="text-center" style={{ fontSize: "15px" }}>Sign in to Karlatoon.</p>
                                <p className="text-muted text-justify">
                                    <small>
                                        You may also apply directly on the company's website. By
                                        creating an account or signing in, you understand and agree to
                                        Karlatoon's <Link to="/terms-conditions" target='_blank'>Terms <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You also consent to our{" "}
                                        <Link to="/privacy-policy" target='_blank'>Privacy policies <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You will receive
                                        marketing messages from Karlatoon and may opt out at any time by
                                        following the unsubscribe link in our messages, or as detailed in
                                        our terms.
                                    </small>
                                </p>

                                <div className="text-center mb-3"></div>
                                <div className="d-grid gap-2 mb-3 justify-content-center">
                                    <button className="btn btn-outline-secondary popup-login-btn" type="button" onClick={handleLoginRedirect}
                                        style={{
                                            padding: "5px 20px",
                                            borderRadius: "40px",
                                            background: "#1B375C",
                                            textDecoration: "none",
                                            color: "#FFF",
                                            textAlign: "center",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            border: "1px solid #1B375C",
                                            width: "fit-content"
                                        }}
                                    >
                                        Login
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showLoader && <div className="overlay-form-submission"><Loader /></div>}
        </>
    )
}

export default Jobs;
