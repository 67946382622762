import React, { useEffect, useState } from 'react'
import './scss/RecommendedJobsSlider.css'
import Slider from "react-slick";
import { getRecommendedJobsData } from '../../../../../Redux/action/SeekerAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Loader';
import { Link } from 'react-router-dom';
import { getRandomColor, createImageFromInitials } from '../../../../Utils';
let host = process.env.REACT_APP_API_SERVER;
const RecommendedJobsSlider = () => {
    const dispatch = useDispatch();
    const [loggedIn, setLoggedIn] = useState(false);
    const [showRecommendedJobs, setShowRecommendedJobs] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("seeker_token")) { setLoggedIn(true);dispatch(getRecommendedJobsData(localStorage.getItem("seeker_token")))
        } else {setLoggedIn(false);}}, [])
    let recommendedJob = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.recommendedjobDataList : "");
    useEffect(() => {if (recommendedJob) { setShowRecommendedJobs(true)}}, [recommendedJob])
    const settingsRecommended = { dots: false,infinite: recommendedJob?.length > 3,autoplay: true,autoplaySpeed: 3000,slidesToShow: 3,slidesToScroll: 1,responsive: [    {breakpoint: 1024,settings: {    slidesToShow: 3,    slidesToScroll: 1,    infinite: recommendedJob?.length > 3,}    },    {breakpoint: 780,settings: {    slidesToShow: 2,    slidesToScroll: 2,    infinite: recommendedJob?.length > 2,}    },    {breakpoint: 480,settings: {    slidesToShow: 1,    slidesToScroll: 1,    infinite: recommendedJob?.length > 1,}    }
]    }
    function formatDate(timestamp) {const date = new Date(timestamp);const options = { day: '2-digit', month: 'short', year: 'numeric' };return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
    }
    const timeAgoCalculator = (timestamp) => {const currentDate = new Date();const previousDate = new Date(timestamp);
const timeDifference = currentDate - previousDate;const seconds = Math.floor(timeDifference / 1000);const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        if (months > 0) {return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) {return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) { return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) { return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else { return 'Just now';
        }
    };
    return (
        <>
            {loggedIn && <section className='recommended-jobs-slider-job-section'>
                <div className="main-section">
                    <div className="container">
                        <div className="second-section">
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='similer-post-heading'>
                                        <h2 className='ms-3'>Recommended Jobs</h2>
                                    </div>
                                </div>
                            </div>
                            {showRecommendedJobs ? <Slider {...settingsRecommended} className='mb-5'>
                                {recommendedJob?.data?.map(job => {
                                    return <div className="col-lg-4" key={job?.job_data?.id}>
                                        <div className="card">
                                            <div className="card-sub-dv">
                                                <div className='company_image'>
                                                    <img className='img-fluid' src={job?.job_data?.Company_details?.logo ? `${host}/uploaded-files/${job?.job_data?.Company_details?.logo}` : `${createImageFromInitials(500, job?.job_data?.Company_details?.company_name, getRandomColor())}`} alt={job?.job_data?.Company_details?.company_name} title={job?.job_data?.Company_details?.company_name}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; 
                                                            currentTarget.src = createImageFromInitials(500, job?.job_data?.Company_details?.company_name, getRandomColor());
                                                        }}
                                                    />
                                                </div>
                                                <div className="footer-heading mx-2">
                                                    <h3><Link to={`/career-result-description?j=${job?.job_data?.job_slug}`}>{job?.job_data?.job_title}</Link></h3>
                                                   
                                                    <p><Link to={`/profile/${job?.job_data?.Company_details?.company_slug}/snap-shot`} className='remove-link-style'><span>{job?.job_data?.Company_details?.company_name}</span></Link>
                                                        <span>
                                                            <span className='cursor-default'>New post</span>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="footer-list-card">
                                                <ul>
                                                    {job?.job_data?.Company_details && <li>
                                                        <img src="./assets/images_another/mappinline.png" alt="" />
                                                        {job?.job_data?.Company_details?.location_city?.city_name}, {job?.job_data?.Company_details?.location_city?.state_of_city?.country_of_state?.name}
                                                    </li>
                                                    }
                                                    <li>
                                                        {job?.job_data?.job_type_list?.length > 0 && <> < img src="./assets/images_another/clock.png" alt="" />
                                                            {job?.job_data?.job_type_list[0]?.job_type_detail?.jobtypename}</>}
                                                        {(job.job_data.show_pay_by) && <span>
                                                            <img src="./assets/images_another/currency-wallet.png" alt="" />
                                                            {job?.job_data?.show_pay_by == "Range" && <span>{job?.job_data?.currency} {job?.job_data?.min_pay} - {job?.job_data?.currency} {job?.job_data?.max_pay} {job?.job_data?.rate_per_hour}</span>}
                                                            {job?.job_data?.show_pay_by == "Starting Amount" && <span> {job?.job_data?.currency} {job?.job_data?.amount}  {job?.job_data?.rate_per_hour}</span>}
                                                            {job?.job_data?.show_pay_by == "Maximum Amount" && <span> {job?.job_data?.currency} {job?.job_data?.amount}  {job?.job_data?.rate_per_hour}</span>}
                                                            {job?.job_data?.show_pay_by == "Exact Amount" && <span> {job?.job_data?.currency} {job?.job_data?.amount}  {job?.job_data?.rate_per_hour}</span>}

                                                        </span>}
                                                    </li>
                                                    <li>
                                                        <div className="d-flex justify-content-between p-2">
                                                            <span className='times-span'><i className="fa-solid fa-calendar-days me-1" title="Job posted date"></i>{formatDate(job?.job_data?.createdAt)}</span>
                                                            <span className='times-span'><i className="fa-solid fa-clock me-1"></i>{timeAgoCalculator(job?.job_data?.createdAt)}</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                })}
                               
                            </Slider> :
                                <div className="text-center">
                                    <Loader />
                                </div>
                            }

                        </div>
                    </div>
                </div>

            </section>}
        </>
    )
}
export default RecommendedJobsSlider