import React, { useEffect, useState } from 'react'
import './scss/PlannedStartDateThisJob.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { getJobDetailsData } from '../../../../Redux/action/RecruiterAction';
import { toast } from 'react-toastify';
import axios from 'axios';
import { FallingLines } from 'react-loader-spinner';
const host = process.env.REACT_APP_API_SERVER

const EditPlannedStartDateThisJobContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showDateDiv, setShowDateDiv] = useState(false);

    let [initialValues, setInitialValues] = useState({
        is_planned_start_date: false,
        planned_start_date: "",
        min_exp: "",
        max_exp: "",
    })

    useEffect(() => {
        if (localStorage.getItem("job_id")) {
            let job_id = localStorage.getItem("job_id")
            dispatch(getJobDetailsData(job_id))
        }
    }, []);
    const jobDetailsData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.jobdetailsDataList?.jobDetails) : "")

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            is_planned_start_date: Yup.string().required("Please select one option."),
            planned_start_date: showDateDiv ? Yup.string().required("Please select start date.") : Yup.string(),
            min_exp: Yup.string().required("Please select min experience.").matches(/^\d+$/, "Input must be a number"),
          max_exp: Yup.string().required("Please select max experience.").matches(/^\d+$/, "Input must be a number")
            }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            const config = {
                headers: {
                    'content-type': 'application/json',
                }
            };
            config.headers["auth-token"] = localStorage.getItem("recruiter_token");
            if (localStorage.getItem("job_id")) {
                formik.values.job_id = localStorage.getItem("job_id");
                await axios.post(`${host}/api/update-job-start-date`, values, config)
                    .then(response => {
                        if (response.status == 200) {
                            setSubmitting(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Details updated successfully!' });
                            navigate("/edit-job-details");
                        } else {
                            setSubmitting(false)
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    }).catch((error) => {
                        console.log("catch:", error);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    })
            } else {
                await axios.post(`${host}/api/post-job-basics`, values, config)
                    .then((response) => {
                        if (response.status == 200) {
                            setSubmitting(false);
                            document.body.style.overflow = 'auto';
                            localStorage.setItem("job_id", response.data.addjobbasics.id);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Details saved successfully!' });
                            navigate("/edit-job-details");
                        } else {
                            setSubmitting(false)
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    }).catch((error) => {
                        console.log("catch:", error);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    })
            }
        }
    });
    useEffect(() => {
        if (jobDetailsData) {
            formik.setValues({
                is_planned_start_date: jobDetailsData?.is_planned_start_date ?? false,
                planned_start_date: jobDetailsData?.planned_start_date ?? "",
                min_exp: jobDetailsData?.min_exp ?? "",
                max_exp: jobDetailsData?.max_exp ?? "",
            });
            if (Number(jobDetailsData?.is_planned_start_date) == true) {
                setShowDateDiv(true);
            } else {
                setShowDateDiv(false)
            }
        }
    }, [jobDetailsData])

    const handleShowDateDiv = () => {
        if (Number(formik.values.is_planned_start_date) == false) {
            setShowDateDiv(true);
        } else {
            setShowDateDiv(false);
        }
    }

    let min_arr = [];
    let max_arr = []
    for (let index = 0; index <= 29; index++) {
        min_arr.push(index);
        max_arr.push(index + 1);
    }

    return (
        <>
            <section className='planned-start-date-this-job-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                          

                            <form method='post' onSubmit={formik.handleSubmit}>
                                <div className='card-outer-dv card-outer-dv1'>
                                    <div className='row justify-content-center align-items-center'>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='left-sd-content'>
                                                <h5>Is there a planned start date for this job?</h5>
                                            </div>
                                            <div className='radio-btn-dv'>
                                                <div className='radio-btn'>
                                                    <input
                                                        type='radio'
                                                        id='rdo-yes'
                                                        className='rdo-inpt'
                                                        name="is_planned_start_date"
                                                        value={true}
                                                        checked={formik.values.is_planned_start_date == true}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("is_planned_start_date", true);
                                                            handleShowDateDiv();
                                                        }
                                                        }
                                                    />
                                                    <label htmlFor='rdo-yes'>Yes</label>
                                                </div>
                                                <div className='radio-btn'>
                                                    <input
                                                        type='radio'
                                                        id='rdo-no'
                                                        className='rdo-inpt'
                                                        name='is_planned_start_date'
                                                        value={false}
                                                        checked={formik.values.is_planned_start_date == false}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("is_planned_start_date", false);
                                                            handleShowDateDiv();
                                                        }
                                                        }
                                                    />
                                                    <label htmlFor='rdo-no'>No</label>
                                                </div>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.is_planned_start_date && formik.errors.is_planned_start_date ? (
                                                    <div className='text-danger'>{formik.errors.is_planned_start_date}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {showDateDiv && <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='date-inpt-dv my-lg-4 my-md-4 my-1'>
                                                <input
                                                    type='date'
                                                    min={new Date().toISOString()?.split('T')[0]}
                                                    className='date-input-field form-control'
                                                    id="planned_start_date"
                                                    name='planned_start_date'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.planned_start_date}
                                                />
                                                <div className="help-block with-errors">
                                                    {formik.touched.planned_start_date && formik.errors.planned_start_date ? (
                                                        <div className='text-danger'>{formik.errors.planned_start_date}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                        </div>}

                                    </div>

                                </div>
                                <div className='card-outer-dv card-outer-dv1'>
                                    <div className='row justify-content-center align-items-center'>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='left-sd-content'>
                                                <h5>Experience Required:</h5>
                                            </div>
                                            <div className='min-exp-dv row'>
                                                <div className='col-md-6'>
                                                    <label htmlFor='min_exp'>Min Experience (in Years)<span className="text-danger">*</span></label>
                                                    
                                                    <select name="min_exp" id="min_exp" className='form-control'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.min_exp}
                                                    >
                                                        {min_arr.map((value, index) => {
                                                            return <option value={value} key={index + 1}>{value} years</option>
                                                        })}
                                                    </select>

                                                    <div className="help-block with-errors">
                                                        {formik.touched.min_exp && formik.errors.min_exp ? (
                                                            <div className='text-danger'>{formik.errors.min_exp}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label htmlFor='max_exp'>Max Experience (in Years)<span className="text-danger">*</span></label>
                                                    <select name="max_exp" id="max_exp" className='form-control'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.max_exp}
                                                    >
                                                        {max_arr.map((value, index) => {
                                                            return <option value={value} key={index + 1}>{value} years</option>
                                                        })}
                                                    </select>

                                                    <div className="help-block with-errors">
                                                        {formik.touched.max_exp && formik.errors.max_exp ? (
                                                            <div className='text-danger'>{formik.errors.max_exp}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='bottom-button-dv'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-12 text-end'>
                                            <div className='bottom-btn'>
                                                <Link to='/edit-job-basics' type='submit' className='a-tag-btn1'>Back</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 text-start'>
                                            <div className='bottom-btn'>
                                              
                                                <button type='submit' className='a-tag-btn2'>Continue</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                {/* Loader */}
                {formik.isSubmitting && <div className="overlay-form-submission">
                    <FallingLines
                        color="#1b375c"
                        width="100"
                        visible={true}
                        ariaLabel='falling-lines-loading'
                    />
                </div>
                }
            </section>
        </>
    )
}

export default EditPlannedStartDateThisJobContent