import React, { useState, useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { useDispatch, useSelector } from 'react-redux';
import './scss/AnalysisChart.css';
import { getAnalyticsChartOverviewData } from '../../../Redux/action/RecruiterAction';

const AnalyticsOverview = () => {
    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState("Week");
    const [renderKey, setRenderKey] = useState(0);
    const authToken = localStorage.getItem("recruiter_token");
    useEffect(() => {
        dispatch(getAnalyticsChartOverviewData(authToken, selectedValue));
    }, [selectedValue, dispatch, authToken]);
    const handleSelect = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
    };
    useEffect(() => {
        const handleTabChange = () => {
            if (document.getElementById('analysischart-tab')?.classList.contains('active')) {
                setRenderKey((prevKey) => prevKey + 1);
            }
        };
        const tabElement = document.getElementById('analysischart-tab');
        tabElement?.addEventListener('shown.bs.tab', handleTabChange);
        return () => {
            tabElement?.removeEventListener('shown.bs.tab', handleTabChange);
        };
    }, []);
    const analyticsChartData = useSelector(state => state?.RecruiterReducer?.analyticsOverviewChartDataList || {});
    const jobCountdata = analyticsChartData.data || [];
    const cvData = analyticsChartData.cvdata || [];
    const interviewData = analyticsChartData.interviewdata || [];
    const hireData = analyticsChartData.hiredata || [];
    const data = analyticsChartData.other_details || {};
    const mapData = (data, period) => {
        return data.map((dp, index) => ({
            x: period === 'Year' ? 12 - index : dp.DayMonth,
            y: dp.job_posted_count,
            label: dp.month
        }));
    };
    const arr = mapData(jobCountdata, selectedValue);
    const cvarr = mapData(cvData, selectedValue);
    const interviewarr = mapData(interviewData, selectedValue);
    const hirecvarr = mapData(hireData, selectedValue);
    const jitterAmount = 0.1; 
    const jitterDataPoints = (data) => data.map(dp => ({
        ...dp,
        x: dp.x + Math.random() * jitterAmount - jitterAmount / 2
    }));
    const options = {
        animationEnabled: true,
        colorSet: "dataColor",
        axisX: {
            minimum: 0.5,
            interval: 1,
            labelFormatter: (e) => {
                if (selectedValue === "Month") {
                    const index = arr.findIndex((dp) => dp.x === e.value);
                    return index % 3 === 0 ? e.label : "";
                }
                return e.label;
            },
        },
        axisY: {
            gridThickness: 1,
            gridColor: "#e8e7fe",
            minimum: 0,
            labelFormatter: e => {
                if (Number.isInteger(e.value)) {
                    return e.value;
                } else {
                    return "";
                }
            }
        },
        data: [
            {type: "spline",name: "Jobs Posted",dataPoints: jitterDataPoints(arr),yValueFormatString: "#,##0.## Jobs Posted",showInLegend: true,lineColor: "#08aafd",lineThickness: 4,markerType: "circle",markerColor: "#08aafd",markerBorderColor: "#08aafd",markerBorderThickness: 2,dataPoints: arr
            },
            {type: "spline",name: "CV Downloaded",dataPoints: jitterDataPoints(cvarr),yValueFormatString: "#,##0.## CVs Downloaded",showInLegend: true,lineColor: "#FF0000",lineThickness: 4,markerType: "square",markerColor: "#FF0000",markerBorderColor: "#FF0000",markerBorderThickness: 2,dataPoints: cvarr
            },
            {type: "spline",name: "Interviewed Candidates",dataPoints: jitterDataPoints(interviewarr),yValueFormatString: "#,##0.## Interviewed",showInLegend: true,lineColor: "#00FF00",lineThickness: 4,markerType: "triangle",markerColor: "#00FF00",markerBorderColor: "#00FF00",markerBorderThickness: 2,dataPoints: interviewarr
            },
            {type: "spline",name: "Hired Candidates",dataPoints: jitterDataPoints(hirecvarr),yValueFormatString: "#,##0.## Hired",showInLegend: true,lineColor: "#A020F0",lineThickness: 4,markerType: "cross",markerColor: "#A020F0",markerBorderColor: "#A020F0",markerBorderThickness: 2,dataPoints: hirecvarr
            },
        ]
    };

    return (
        <>
            <section className="custm-chart">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="overview-head mb-4 d-flex justify-content-between">
                            <div className="textarea">
                                <h4>Analytics overview</h4>
                                <p>Monitor and analyse performance to optimise your spend across products</p>
                            </div>
                            <div className='dropdown'>
                                <select onChange={handleSelect} value={selectedValue}>
                                    <option value="Week">Last Week</option>
                                    <option value="Month">Last Month</option>
                                    <option value="Year">Last Year</option>
                                </select>
                            </div>
                        </div>
                        <div className="review d-flex justify-content-between">
                            <h5>Analytics overview</h5>
                        </div>
                        <div style={{ overflowX: 'auto' }}>
                            <div style={{ minWidth: '100%' }}>
                                <CanvasJSChart key={renderKey} options={options} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="performance py-5">
                <div className="container pb-5">
                    <div className="row justify-content-between">
                        <div className="heading my-4">
                            <h4>Performance summary</h4>
                        </div>
                        <div className="col-lg-12 my-5">
                            <div className="row campaign-card py-5 align-items-center">
                                <div className="col-lg-3 col-md-4 text-center">
                                    <h4>Total Jobs Posted</h4>
                                    <h1>{data.posted_job_count}</h1>
                                </div>
                                <div className="col-lg-3 col-md-4 text-center">
                                    <h4>Total CVs Downloaded</h4>
                                    <h1>{data.total_downloaded_cv}</h1>
                                </div>
                                <div className="col-lg-3 col-md-4 text-center">
                                    <h4>Total Interviews Taken</h4>
                                    <h1>{data.total_interveiwed_jobs}</h1>
                                </div>
                                <div className="col-lg-3 col-md-4 text-center">
                                    <h4>Total Hiring Done</h4>
                                    <h1>{data.total_hired_jobs}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AnalyticsOverview;
