import React, { useEffect, useState } from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'
import Footer from '../Commons/Footer'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getSeekerChat, getSeekerChatList } from '../../../Redux/action/SeekerAction'
import Loader from '../Loader'
import Error from '../../Common/Error/Error'
import ReadChatContent from './ReadChatContent';
const host = process.env.REACT_APP_API_SERVER;
const Readchat = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { company_id } = location.state || {};

    let seeker_token = localStorage.getItem("seeker_token");

    let [chatData, SetChatData] = useState([]);
    let [company, setCompany] = useState({});
    const [socketConnected, setSocketConnected] = useState(false);
    const {loadingSeekerChat,SeekerChatData,SeekerChatError,} = useSelector(state => state?.SeekerReducer);
    useEffect(() => {
        if (!seeker_token) {toast.error("Please login to continue."); navigate(-1);
        } else if (!company_id) { toast.error("Company not found with URL.");
            navigate(-1);
        } else {dispatch(getSeekerChat(company_id, seeker_token)); }}, [company_id]);

    useEffect(() => {
        if (SeekerChatData) {
            if (SeekerChatData?.chatData?.length > 0) {SetChatData(SeekerChatData?.chatData || []);}
            if (SeekerChatData?.company_details) {setCompany(SeekerChatData?.company_details);}
        } }, [SeekerChatData]);

    const refresh = () => {
        if (!seeker_token) {toast.error("Please login to continue.");navigate(-1);
        } else if (!company_id) {toast.error("Company not found with URL.");navigate(-1);
        } else { dispatch(getSeekerChat(company_id, seeker_token));}};

    const addMessageToChatData = (message) => { SetChatData(prevData => [...prevData, message]);};
    useEffect(() => {
        const fetchData = () => {
            dispatch(getSeekerChat(company_id, seeker_token))
                .catch(error => {console.error('Error fetching chat list:', error);});
        };
        fetchData();const intervalId = setInterval(fetchData, 2000);
        return () => clearInterval(intervalId);
    }, []);
    return (
        <>
            <AfterNavbar />
            <ReadChatContent data={chatData} company={company} refresh={refresh} addMessageToChatData={addMessageToChatData} />
            </>
    )
}

export default Readchat