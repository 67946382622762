import React, { useEffect, useState } from 'react'
import './scss/ChooseYourSubscriptionPlan.css'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../Seeker/Loader';
const host = process.env.REACT_APP_API_SERVER;

const ChooseYourSubscriptionPlanContent = ({ plans }) => {
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const urlString = window.location.href;
    const url = new URL(urlString);
    const path = url.pathname.slice(1);
    const searchParams = url.search;
    const q = path + searchParams;
    const handlePurchasePlan = (plan_id, selling_price) => {
        if (localStorage.getItem("recruiter_token")) {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                if (!localStorage.getItem("recruiter_token")) {
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Please login to continue." });
                    navigate("/recruiter-login");
                } else {


                    document.body.style.overflow = 'hidden';
                    setShowLoader(true);
                    const values = { plan_id: plan_id };
                    const config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } };
                    if (Number(selling_price) == 0) {
                        axios.post(`${host}/recruiter/purchase-plan`, values, config)
                            .then(response => {
                                if (response.status === 200) {
                                    document.body.style.overflow = 'auto';
                                    setShowLoader(false);
                                    toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                                    navigate("/post-job");
                                } else {
                                    document.body.style.overflow = 'auto';
                                    setShowLoader(false);
                                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                                }
                            }).catch(error => {
                                console.log(error.message);
                                document.body.style.overflow = 'auto';
                                setShowLoader(false);
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error." });
                            })
                    } else {
                        axios.post(`${host}/recruiter/payment/create-payment-session`, { subscription_plan_id: plan_id }, config)
                            .then(response => {
                                if (response.status === 200) {
                                    document.body.style.overflow = 'auto';
                                    setShowLoader(false);
                                    toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Redirecting to payment page." });
                                    window.location.href = response?.data?.data?.url;
                                } else {
                                    document.body.style.overflow = 'auto';
                                    setShowLoader(false);
                                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                                }
                            }).catch(error => {
                                console.log(error.message);
                                document.body.style.overflow = 'auto';
                                setShowLoader(false);
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error." });
                            })
                    }

                }
            } catch (error) {
                console.log(error.message);
                document.body.style.overflow = 'auto';
                setShowLoader(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error." });
            }
        } else {
            toast.error("Please login to continue.");
            navigate(`/recruiter-login?q=${q}`)
        }

    };



    return (
        <>
            <section className='choose-your-subscription-plan-sec'>
                <section className="my-5 main-subs-plan">
                    <div className="main-section mt-5">
                        <div className="container">
                            <div className="Subscrip-card">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-center">
                                            <h1>Choose Your Subscription Plan</h1>
                                            <p>
                                                Whether you want to get organized, keep your professional life
                                                on track, or boost workplace productivity, Karlatoon has
                                                the right plan for you.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cus-subs-2">
                                    {plans?.length > 0 ? <div className="row mt-4">
                                        {/* ======== Dynamic Data ========= */}
                                        {plans.map((plan) => {
                                            return <div className={`col-lg-${plans?.length <= 4 ? (12 / plans?.length) : 3} col-md-6 mt-2`} >
                                                <div className="card cus-sec">
                                                    {plan.tag && <div className="cus-ribbon">
                                                        <span>{plan.tag}</span>
                                                    </div>}
                                                    <div className="basic">
                                                        <h3>{plan.name}</h3>
                                                        {plan.discount > 0 ? <p>{plan.discount}% discount on ؋{plan.price}/{plan.price_type == "YEARLY" ? "yr" : "mo"}</p> : <p>No Discount Available</p>}
                                                        <div className="cus-gap" />
                                                        <div>
                                                            <p className="cus-price">
                                                             ؋{plan.selling_price} <span className="cus-mon">/{plan.price_type == "YEARLY" ? "yr" : "mo"}</span>
                                                            </p>
                                                            <p className="new-p">Excl. Vat</p>
                                                            <div className="cus-bottom" />
                                                            <div className="cus-inner-p">
                                                                <p>
                                                                    {plan?.short_description}
                                                                </p>
                                                            </div>
                                                            <div className="cus-btn">
                                                                {localStorage.getItem("recruiter_token") ? Number(plan.selling_price) == 0 ? <button className="btn mt-3 mb-3 btn-inner w-100 free-plan">
                                                                    Free Plan
                                                                </button> : <button className="btn mt-3 mb-3 btn-inner w-100" onClick={() => (handlePurchasePlan(plan.id, plan.selling_price))}>
                                                                    Buy Now
                                                                </button> : <button className="btn mt-3 mb-3 btn-inner w-100" onClick={() => (handlePurchasePlan(plan.id, plan.selling_price))}>
                                                                    Buy Now
                                                                </button>}
                                                            </div>
                                                            <div className="cus-list mt-2">
                                                                <div className="cus-head">
                                                                    <h2>Features Included :</h2>
                                                                </div>
                                                                <ul className="list-unstyled cus-inner-list">
                                                                  
                                                                    <li className="mb-3">
                                                                        <i className="fas fa-check-circle icon-color" />
                                                                        <span className="small">Upto {plan.jobpost_count ?? 0} Job Posts</span>
                                                                    </li>
                                                                  
                                                                    <li className="mb-3">
                                                                        <i className={plan.resumedownload_count == 0 ? `fa-solid fa-circle-xmark icon-color` : `fas fa-check-circle icon-color`} />
                                                                        <span className="small">Download resumes</span>
                                                                      
                                                                    </li>
                                                                    <li className="mb-3">
                                                                        <i className={plan.add_jobfair == 0 ? `fa-solid fa-circle-xmark icon-color` : `fas fa-check-circle icon-color`} />
                                                                        <span className="small">Post Jobs for Job fair</span>
                                                                       
                                                                    </li>
                                                                   
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                        {/* ============================card1 */}                                       
                                    </div> :
                                        <div className='row-mt-4'>
                                            <div className="text-center">
                                                No subscription plans available.
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section >
            {showLoader && <div className="overlay-form-submission">
                <Loader />
            </div>}
        </>
    )
}

export default ChooseYourSubscriptionPlanContent