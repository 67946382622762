import React, { useEffect, useState } from 'react'
import { getRecruiterregistrationData } from '../../../Redux/action/RecruiterAction';
import { useDispatch, useSelector } from 'react-redux';
import './scss/RecMyProfile.css'
import Loader from '../../Seeker/Loader';
import { Link } from 'react-router-dom';
const host = process.env.REACT_APP_API_SERVER
const RecMyProfileContent = () => {

    const dispatch = useDispatch();
    useEffect(() => {

        let authToken = localStorage.getItem("recruiter_token");
        dispatch(getRecruiterregistrationData(authToken));


    }, []);

    const recruiterRegisterData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.recruiterRegisterDataList?.data?.[0]) : "");

    const [showLoader, setShowLoader] = useState(true);
    useEffect(() => {
        if (recruiterRegisterData) {
            setShowLoader(false);
        }
    }, [recruiterRegisterData]);
    return (
        <>

            <section className='rec-myprofile-section'>
                <div className="main-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main-head">
                                    <h2>My Profile</h2>
                                </div>
                            </div>
                        </div>
                        <div className="banner-section">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="bg-img profile-banner-head">
                                        <img src="./assets/my-profile/background.png" className="img-fluid" alt="" />
                                        <div className='profile-heading-top'>
                                            <h2>Profile Details</h2>
                                        </div>
                                    </div>
                                    <div className="banner-bg">
                                        <div className="banner-bg-child-img-content-parent align-items-center justify-content-beetween">
                                            <div className='profile-images'>
                                                {(recruiterRegisterData && <img
                                                    src={
                                                        recruiterRegisterData?.logo
                                                            ? `${host}/uploaded-files/${recruiterRegisterData?.logo}`
                                                            : "assets/images/cv-builder/avatar.png"
                                                    }
                                                    alt="Avatar"
                                                    style={{ cursor: 'pointer', borderRadius: '50%', objectFit: 'cover' }}
                                                    width={200} height={200} className="img-fluid"
                                                />)}
                                            </div>
                                            <div className="first-head-bg ml-md-2 ml-0">
                                                <h4>{recruiterRegisterData?.company_name ? recruiterRegisterData?.company_name : ''}</h4>
                                                <p>{recruiterRegisterData?.website ? recruiterRegisterData?.website : ''}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="user-section mt-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="top-user-section">
                                            <div className="d-flex my-3">

                                                <div className=" top-user-head">
                                                    <h4 className='fw-bold'>Basic Information</h4>
                                                   
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <Link to='/company-details-update' className="edit-btn">Edit</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="user-details">
                                            <div>
                                                <h6>Name of CEO</h6>
                                                <p>{recruiterRegisterData?.company_ceo ? recruiterRegisterData?.company_ceo : ''}</p>
                                            </div>
                                           
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>Landline Number</h6>
                                                <p>{recruiterRegisterData?.company_landline ? recruiterRegisterData?.company_landline : ''}</p>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="user-details">
                                            <div>
                                                <h6>Phone Number</h6>
                                                <p>{recruiterRegisterData?.company_mobile ? recruiterRegisterData?.company_mobile : ''}</p>
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>Founded In</h6>
                                                <p>{recruiterRegisterData?.founded ? recruiterRegisterData?.founded : ''}</p>
                                            </div>
                                        </div>



                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="user-details">
                                            <div>
                                                <h6>Net Worth</h6>
                                                <p>{recruiterRegisterData?.net_worth ? `${recruiterRegisterData?.currency} ${recruiterRegisterData?.net_worth}` : ''}</p>
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <div>
                                                    <h6>Company Size</h6>
                                                    <p>{recruiterRegisterData?.company_size?.companysize ? recruiterRegisterData?.company_size?.companysize : ''}</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="user-details">
                                            <div>
                                                <h6>Department</h6>
                                                <p>{recruiterRegisterData?.company_department ? recruiterRegisterData?.company_department.map(value=>value.department).toString() : ''}</p>
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>Location</h6>
                                                <p>{`${recruiterRegisterData?.city?.state?.state_name}${`,${recruiterRegisterData?.city?.state?.country?.name}`}`}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="user-section mt-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="top-user-section">
                                            <div className="d-flex my-3">

                                                <div className=" top-user-head">
                                                    <h4 className='fw-bold'>Documents Verification Details</h4>
                                                   
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <Link to='/document-verification-update' className="edit-btn">Edit</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="user-details">
                                            <div>
                                                <h6>Registraion Number</h6>
                                                <p>{recruiterRegisterData?.registration_number ? recruiterRegisterData?.registration_number : ''}</p>
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>GST Number</h6>
                                                <p>{recruiterRegisterData?.gst_no ? recruiterRegisterData?.gst_no : ''}</p>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="user-details">
                                            <div>
                                                <h6>TIN Number</h6>
                                                <p>{recruiterRegisterData?.tin_no ? recruiterRegisterData?.tin_no : ''}</p>
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>Official Address</h6>
                                                <p>{recruiterRegisterData?.official_address ? recruiterRegisterData?.official_address : ''}</p>
                                            </div>
                                        </div>


                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="user-section mt-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="top-user-section">
                                            <div className="d-flex my-3">

                                                <div className=" top-user-head">
                                                    <h4 className='fw-bold'>Contact Details</h4>
                                                  
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <Link to='/company-details-update' className="edit-btn">Edit</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-12">
                                        {recruiterRegisterData?.company_contactperson_fname &&
                                            <div className="user-details">
                                                <div>
                                                    <h6>Name</h6>
                                                    <p>{recruiterRegisterData?.company_contactperson_fname ? recruiterRegisterData?.company_contactperson_fname : ''} {recruiterRegisterData?.company_contactperson_lname ? recruiterRegisterData?.company_contactperson_lname : ''}</p>
                                                </div>
                                            </div>}
                                        {recruiterRegisterData?.company_contactperson_designation &&
                                            <div className="user-details">
                                                <div>
                                                    <h6>Designation</h6>
                                                    <p>{recruiterRegisterData?.company_contactperson_designation ? recruiterRegisterData?.company_contactperson_designation : ''}</p>
                                                </div>

                                            </div>}

                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12">
                                        {recruiterRegisterData?.company_contactperson_email &&
                                            <div className="user-details">
                                                <div>
                                                    <h6>Email</h6>
                                                    <p>{recruiterRegisterData?.company_contactperson_email ? recruiterRegisterData?.company_contactperson_email : ''}</p>
                                                </div>
                                            </div>}
                                        {recruiterRegisterData?.company_contactperson_mobile &&
                                            <div className="user-details">
                                                <div>
                                                    <h6>Phone No.</h6>
                                                    <p>{recruiterRegisterData?.company_contactperson_mobile ? recruiterRegisterData?.company_contactperson_mobile : ''}</p>
                                                </div>
                                            </div>}


                                    </div>


                                </div>
                            </div>
                        </div>


                        {(recruiterRegisterData?.fb_link || recruiterRegisterData?.linkedin_link || recruiterRegisterData?.instagram_link || recruiterRegisterData?.twitter_link) &&
                            <div className="user-section mt-4">
                                <div className="container">
                                    <div className="row">
                                        <div className="top-user-section">
                                            <div className="d-flex my-3">

                                                <div className=" top-user-head">
                                                    <h4 className='fw-bold'>Social Links</h4>
                                                  
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <Link to='/privacy-settings-update' className="edit-btn">Edit</Link>
                                            </div>
                                        </div>

                                        {recruiterRegisterData?.fb_link && <div className="col-lg-6 col-xl-6 col-md-12 mt-3">
                                            <div className="card icon-card-custom icon-crd-cus">
                                                <div className="icon-card-section">
                                                    <img src="./assets/my-profile/facebook-icon.png" className="img-fluid social-img" alt="" />
                                                    <div className='icon-card-dv'>
                                                        <p>{recruiterRegisterData?.fb_link ? recruiterRegisterData?.fb_link : ''}</p>
                                                        <img src="./assets/my-profile/right-icon.png" className="" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        {recruiterRegisterData?.linkedin_link && <div className="col-lg-6 col-xl-6 col-md-12 mt-3">
                                            <div className="card icon-card-custom icon-crd-cus">
                                                <div className="icon-card-section">
                                                    <img src="./assets/my-profile/linkedin.png" className="img-fluid social-img" alt="" />
                                                    <div className='icon-card-dv'>
                                                        <p>{recruiterRegisterData?.linkedin_link ? recruiterRegisterData?.linkedin_link : ''}</p>
                                                        <img src="./assets/my-profile/right-icon.png" className="" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}


                                        {recruiterRegisterData?.instagram_link &&
                                            <div className="col-lg-6 col-xl-6 col-md-12 mt-3">
                                                <div className="card icon-card-custom icon-crd-cus">
                                                    <div className="icon-card-section">
                                                        <img src="./assets/images/cv-builder/i.png" className="img-fluid social-img" alt="" />
                                                        <div className='icon-card-dv'>
                                                            <p>{recruiterRegisterData?.instagram_link ? recruiterRegisterData?.instagram_link : ''}</p>
                                                            <img src="./assets/my-profile/right-icon.png" className="" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                        {recruiterRegisterData?.twitter_link &&
                                            <div className="col-lg-6 col-xl-6 col-md-12 mt-3">
                                                <div className="card icon-card-custom icon-crd-cus">
                                                    <div className="icon-card-section">
                                                        <img src="./assets/images/cv-builder/t.png" className="img-fluid social-img" alt="" />
                                                        <div className='icon-card-dv'>
                                                            <p>{recruiterRegisterData?.twitter_link ? recruiterRegisterData?.twitter_link : ''}</p>
                                                            <img src="./assets/my-profile/right-icon.png" className="" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        {recruiterRegisterData?.yt_link &&
                                            <div className="col-lg-6 col-xl-6 col-md-12 mt-3">
                                                <div className="card icon-card-custom icon-crd-cus">
                                                    <div className="icon-card-section">
                                                        <img src="./assets/images/cv-builder/yt.png" className="img-fluid social-img" alt="" />
                                                        <div className='icon-card-dv'>
                                                            <p>{recruiterRegisterData?.yt_link ? recruiterRegisterData?.yt_link : ''}</p>
                                                            <img src="./assets/my-profile/right-icon.png" className="" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}


                                    </div>
                                </div>
                            </div>
                        }



                    
                    </div>
                </div>
                {
                    showLoader && <div className="overlay-form-submission">
                        <Loader />
                    </div>
                }
            </section >
        </>
    )
}

export default RecMyProfileContent