import React, { useState, useEffect } from 'react'
import "./scss/stepper.css";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';
const host = process.env.REACT_APP_API_SERVER;

const ResumeModal5 = ({ preview, findResumeData }) => {
    const [skills, setSkills] = useState([]);
    const [certificate, setCertificate] = useState([]);
    const [links, setLinks] = useState([]);
    const [language, setLanguage] = useState([]);

    useEffect(() => {
        if (findResumeData?.skills) {
            const skills = JSON.parse(findResumeData?.skills || '[]');
            setSkills(skills);
        } else {
            setSkills([]);
        }
        if (findResumeData?.languages) {
            const languages = JSON.parse(findResumeData?.languages || '[]');
            setLanguage(languages);
        } else {
            setSkills([]);
        }
        if (findResumeData?.certification) {
            const certifications = findResumeData?.certification;
            setCertificate(certifications);
        } else {
            setCertificate([]);
        }
        if (findResumeData?.links) {
            const links = JSON.parse(findResumeData?.links || '[]');
            setLinks(links);
        } else {
            setLinks([]);
        }
    }, [findResumeData]);

    function trimString(str, count) {
        if (typeof str !== 'string') {
            throw new Error('First argument must be a string');
        }
        if (typeof count !== 'number' || count < 0) {
            throw new Error('Second argument must be a non-negative number');
        }

        // Check if the string needs to be trimmed and add "..." if it does
        return str.length > count ? str.slice(0, count) + "..." : str;
    }


    function formatDate(dateString) {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const dateParts = dateString.split("-");
        const day = dateParts[2];
        const month = months[parseInt(dateParts[1]) - 1];
        const year = dateParts[0];
        return `${day}-${month} ${year}`;
    }
    const accomplishmentsText = parse(`${findResumeData?.accomplishments}`)
    const affiliationsText = parse(`${findResumeData?.affiliations}`)


    return (
        <>
            <div
                className="modal fade"
                id={preview}
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content cv_detail_new_modul">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Preview Resume
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body ">
                            <>
                                <div className="overflow-auto">
                                    <div className="main_cv_detail_section main_cv_detail_section_2">
                                        <div className="bg_none_main_new">
                                            <div className='main_profile_detail'>
                                                <h2>{findResumeData?.first_name} {findResumeData?.last_name}</h2>
                                                <p>{findResumeData?.city}, {findResumeData?.state}, {findResumeData?.country}, {findResumeData?.pincode} <span>/</span> {findResumeData?.email}<span>/</span>{findResumeData?.phone}</p>

                                            </div>

                                        </div>
                                        <div className="detail_resum_main">

                                            <div className="main_detail_skill">
                                                <h6>
                                                    Summary
                                                </h6>
                                                <p style={{ textAlign: "justify" }}>
                                                    {findResumeData?.summary}
                                                </p>
                                            </div>
                                            {
                                                findResumeData?.cvbuilderprofessionaldetails?.length > 0 && <div className="main_detail_experiencs">
                                                    <div className="exp_detail_text">
                                                        <h6>
                                                            Experiencs
                                                        </h6>
                                                    </div>
                                                    {
                                                        findResumeData?.cvbuilderprofessionaldetails?.map((value, index) => {
                                                            return <div className="text-justify mb-2" key={index + 1}>
                                                                <div className="exp_detail_text">
                                                                    <h5 className='mb-0'>
                                                                        <strong> {value?.job_title} </strong> <span>/</span>  {value?.current_last_company}
                                                                    </h5>
                                                                    <h5 className='mb-0'>
                                                                        {formatDate(value?.start)} - {!value?.currently_working ? formatDate(value?.end) : "Current"}
                                                                    </h5>
                                                                </div>
                                                                {value?.job_description && <div className='text-justify' style={{ textAlign: "justify" }}>{parse(`${value?.job_description}`)}</div>}
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            }
                                            {findResumeData?.cvbuildereducationaldetails?.length > 0 && <div className="main_detail_experiencs">
                                                <div className="exp_detail_text">
                                                    <h6>
                                                        Education
                                                    </h6>
                                                </div>
                                                {findResumeData?.cvbuildereducationaldetails?.map((value, index) => {
                                                    return <div className="mb-2" key={index + 1}>
                                                        <div className="exp_detail_text">
                                                            <h5 className='mb-0'><strong> {value?.education_level}  </strong> <span>/</span>  {value?.specialization}</h5>
                                                            <h5 className='mb-0'>
                                                                {formatDate(value?.start)}-{!value?.pursuing ? formatDate(value?.end) : "Current"}
                                                            </h5>
                                                        </div>
                                                        <p>{value?.university_name}, {value?.university_address}</p>
                                                        {
                                                            value?.description && <div className='text-justify' style={{ textAlign: "justify" }}>{parse(`${value?.description}`)}</div>
                                                        }
                                                    </div>
                                                })}

                                            </div>}

                                            {findResumeData?.cvbuilderprojectdetails?.length > 0 && <div className="main_detail_experiencs">
                                                <div className="exp_detail_text">
                                                    <h6>
                                                        Project
                                                    </h6>
                                                </div>
                                                {findResumeData?.cvbuilderprojectdetails?.map((value, index) => {
                                                    return <div className="mb-2" key={index + 1}>
                                                        <div className="exp_detail_text">
                                                            <h5 className='mb-0'><strong> {value?.project_title}</strong> </h5>
                                                            <h5 className='mb-0'>
                                                                {formatDate(value?.start)} - {value?.in_progress ? "Current" : formatDate(value?.end)}
                                                            </h5>
                                                        </div>
                                                        <p>{value?.client}</p>
                                                        {
                                                            value?.description && <div className='text-justify'>{parse(`${value?.description}`)}</div>
                                                        }
                                                    </div>
                                                })}
                                            </div>
                                            }

                                            {skills.length > 0 && <div className="main_detail_skill">
                                                <h6>
                                                    Skills
                                                </h6>
                                                <ul>
                                                    {skills?.map((skill, index) => {
                                                        return <li key={index + 1}>{skill}</li>
                                                    })}
                                                </ul>
                                            </div>}
                                            {language?.length > 0 && <div className="main_detail_skill">
                                                <h6>
                                                    Languages
                                                </h6>
                                                <ul>
                                                    {language?.map((lang, index) => {
                                                        return <li key={index + 1}>{lang}</li>
                                                    })}
                                                </ul>
                                            </div>}
                                            {links.length > 0 && <div className="main_detail_experiencs">
                                                <div className="exp_detail_text">
                                                    <h6>
                                                        Social Media Links
                                                    </h6>
                                                </div>

                                                <ul className='main_link_sosal'>
                                                    {
                                                        links.map((link, index) => {
                                                            return <li key={index + 1}><a href={link} target='_blank'>{trimString(link, 30)}</a></li>
                                                        })
                                                    }
                                                </ul>
                                            </div>}
                                            {certificate?.length > 0 && <div className="main_detail_experiencs">
                                                <div className="exp_detail_text">
                                                    <h6>
                                                        Certification
                                                    </h6>
                                                </div>
                                                {certificate?.map((value, index) => {
                                                    return <div className="mb-2">
                                                        <div className="exp_detail_text">
                                                            <h5 className='mb-0'><strong>  {value?.certificate} </strong></h5>
                                                            <h5 className='mb-0'>
                                                                {value?.start} - {value?.end}
                                                            </h5>
                                                        </div>
                                                        <p>{value?.institute}</p>
                                                    </div>
                                                })}
                                            </div>}
                                            {findResumeData?.accomplishments && <div className="main_detail_experiencs">
                                                <div className="exp_detail_text">
                                                    <h6>
                                                        Accomplishments
                                                    </h6>
                                                </div>
                                                <div className="text-justify" style={{ textAlign: "justify" }}>
                                                    {parse(`${findResumeData?.accomplishments}`)}
                                                </div>
                                            </div>}
                                            {findResumeData?.affiliations && <div className="main_detail_experiencs">
                                                <div className="exp_detail_text">
                                                    <h6>
                                                        Affiliations
                                                    </h6>
                                                </div>
                                                <div className="text-justify" style={{ textAlign: "justify" }}>
                                                    {parse(`${findResumeData?.affiliations}`)}
                                                </div>
                                            </div>}

                                        </div>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResumeModal5