import React, { useEffect, useState } from 'react'
import SearchandFilterBar from './SearchandFilterBar';
import Loader from '../Loader';
import "./scss/SearchResults.css"
import { createImageFromInitials, getRandomColor } from '../../Utils';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getEducationLevelData, getExperienceLevelData, getIndustryData, getMostViewedJobsData, getTypeOfJobData, getbusinesstypeData, getcityData, getfilterJob, getjobCountData, getpositionlevelData, getscheduleData, getworkplaceData } from '../../../Redux/action/SeekerAction';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
const host = process.env.REACT_APP_API_SERVER;
const SearchResultPage = () => {
    let [showPage, setShowPage] = useState(false);
    let [reload, setReload] = useState(false);
    let [job_data, setJob_data] = useState({});
    let [showFilterJob, setShowFilterJob] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showWorkplace, setShowWorkplace] = useState(false);
    const [showCity, setShowCity] = useState(false);
    const [showEducationLevel, setShowEducationLevel] = useState(false);
    const [showExperience, setShowExperience] = useState(false);
    const [showSchedule, setShowSchedule] = useState(false);
    const [showJobtype, setShowJobtype] = useState(false);
    const [showPositionlevel, setShowPositionlevel] = useState(false);
    const [showIndustry, setShowIndustry] = useState(false);
    const [showBusinessType, setShowBusinessType] = useState(false);
    const [jobCount, setJobCount] = useState(0);

    useEffect(() => {dispatch(getMostViewedJobsData());dispatch(getworkplaceData());dispatch(getcityData());dispatch(getEducationLevelData());dispatch(getExperienceLevelData());dispatch(getscheduleData());dispatch(getTypeOfJobData());dispatch(getpositionlevelData());dispatch(getIndustryData());dispatch(getbusinesstypeData());dispatch(getjobCountData());
    }, [])

    const workplaceData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.workplaceDataList : []);
    const cityData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.cityDataList : []);
    const educationLevelData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.educationLevelDataList : []);
    const experienceData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.experienceLevelDataList : []);
    const scheduleData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.scheduleDataList : []);
    const jobtypeData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.typeOfJobDataList : []);
    const positionlevelData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.positionlevelDataList : []);
    const industryData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.industryTypeDataList : []);
    const businesstypeData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.businesstypeDataList : []);
    let job_count_data = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.jobCountDataList : "0");
    useEffect(() => {
        if (workplaceData) {
            setShowWorkplace(true);
        }
    }, [workplaceData])
    useEffect(() => {
        if (cityData) {
            setShowCity(true);
        }
    }, [cityData])
    useEffect(() => {
        if (educationLevelData?.data) {
            setShowEducationLevel(true);
        }
    }, [educationLevelData])
    useEffect(() => {
        if (experienceData?.data) {
            setShowExperience(true);
        }
    }, [experienceData])
    useEffect(() => {
        if (scheduleData) {
            setShowSchedule(true);
        }
    }, [scheduleData])
    useEffect(() => {
        if (jobtypeData?.data) {
            setShowJobtype(true);
        }
    }, [jobtypeData])
    useEffect(() => {
        if (positionlevelData) {
            setShowPositionlevel(true);
        }
    }, [positionlevelData])
    useEffect(() => {
        if (industryData?.data) {
            setShowIndustry(true);
        }
    }, [industryData])
    useEffect(() => {
        if (businesstypeData) {
            setShowBusinessType(true);
        }
    }, [businesstypeData])
    useEffect(() => {
        if (job_count_data) {
            setJobCount(job_count_data);
        }
    }, [job_count_data])
    const initialValues = {
        serach_text: "",
        workplace: "",
        job_type: "",
        pay_range: "",
        industry: "",
        address_city: "",
        location: "",
        company_type: "",
        education_level: "",
        min_pay: "",
        max_pay: "",
        experience_level: "",
        miles: "",
        benefit: "",
        position_level: "",
        department: "",
        natureofbusiness: "",
        schedule: "",
        date_posted: "",
        exp: ""
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({}),
        onSubmit: (values) => {
            function removeEmptyStringValues(obj) {
                for (const key in obj) {
                    if (obj[key] === '') {
                        delete obj[key];
                    }
                }
                return obj;
            }
            const objectWithoutEmptyStrings = removeEmptyStringValues(values);
            let create_search_string = ""
            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                    create_search_string = create_search_string + key + "=" + objectWithoutEmptyStrings[key] + "&";
                }
            }
            navigate(`/search-result?${create_search_string}`)
        }
    });

    const url = new URL(window.location.href);
    const URLhref = url.href;

    useEffect(() => {
        const queryParams = new URLSearchParams(url.search);
        const queryParamsOject = {};
        for (const [key, value] of queryParams) {
            queryParamsOject[key] = value;
        }
        setJob_data(queryParamsOject);
        setReload(!reload);
    }, [URLhref]);

    useEffect(() => {
        const queryParams = new URLSearchParams(url.search);
        const queryParamsOject = {};

        for (const [key, value] of queryParams) {
            queryParamsOject[key] = value;
        }
        formik.setValues({
            serach_text: queryParamsOject.hasOwnProperty("serach_text") ? queryParamsOject.serach_text : "",
            workplace: queryParamsOject.hasOwnProperty("workplace") ? queryParamsOject.workplace : "",
            job_type: queryParamsOject.hasOwnProperty("job_type") ? queryParamsOject.job_type : "",
            pay_range: queryParamsOject.hasOwnProperty("pay_range") ? queryParamsOject.pay_range : "",
            industry: queryParamsOject.hasOwnProperty("industry") ? queryParamsOject.industry : "",
            address_city: queryParamsOject.hasOwnProperty("address_city") ? queryParamsOject.address_city : "",
            location: queryParamsOject.hasOwnProperty("location") ? queryParamsOject.location : "",
            company_type: queryParamsOject.hasOwnProperty("company_type") ? queryParamsOject.company_type : "",
            education_level: queryParamsOject.hasOwnProperty("education_level") ? queryParamsOject.education_level : "",
            min_pay: queryParamsOject.hasOwnProperty("min_pay") ? queryParamsOject.min_pay : "",
            max_pay: queryParamsOject.hasOwnProperty("max_pay") ? queryParamsOject.max_pay : "",
            experience_level: queryParamsOject.hasOwnProperty("experience_level") ? queryParamsOject.experience_level : "",
            miles: queryParamsOject.hasOwnProperty("miles") ? queryParamsOject.miles : "",
            benefit: queryParamsOject.hasOwnProperty("benefit") ? queryParamsOject.benefit : "",
            position_level: queryParamsOject.hasOwnProperty("position_level") ? queryParamsOject.position_level : "",
            department: queryParamsOject.hasOwnProperty("department") ? queryParamsOject.department : "",
            natureofbusiness: queryParamsOject.hasOwnProperty("natureofbusiness") ? queryParamsOject.natureofbusiness : "",
            schedule: queryParamsOject.hasOwnProperty("schedule") ? queryParamsOject.schedule : "",
            date_posted: queryParamsOject.hasOwnProperty("date_posted") ? queryParamsOject.date_posted : "",
            exp: queryParamsOject.hasOwnProperty("exp") ? queryParamsOject.exp : "",

        });
    }, [reload]);

    let filterJobData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.filterJobDataList : []);
    useEffect(() => {
        if (Object.keys(job_data)?.length != 0) {
            dispatch(getfilterJob(job_data));
        }
    }, [job_data])
    useEffect(() => {
        if (filterJobData) {
            setShowFilterJob(true);
        }
    }, [filterJobData])

    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };

    const handleRemoveFilter = () => {
        formik.setValues({serach_text: "",workplace: "",job_type: "",pay_range: "",industry: "",address_city: "",location: "",company_type: "",education_level: "",min_pay: "",max_pay: "",experience_level: "",miles: "",benefit: "",position_level: "",department: "",natureofbusiness: "",schedule: "",date_posted: "",exp: ""
        });
    }
    const handleShowAllJobs = () => {
        formik.setValues({serach_text: "",workplace: "",job_type: "",pay_range: "",industry: "",address_city: "",location: "",company_type: "",education_level: "",min_pay: "",max_pay: "",experience_level: "",miles: "",benefit: "",position_level: "",department: "",natureofbusiness: "",schedule: "",date_posted: "",exp: ""
        });
        dispatch(getfilterJob({}));
    };


    const experienceOptionsData = [];

    for (let index = 0; index < 30; index++) {
        experienceOptionsData.push({ value: index + 1, label: `${index + 1} years` })
    }

    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
    }

    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const previousDate = new Date(timestamp);

        const timeDifference = currentDate - previousDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {
            return 'Just now';
        }
    };


    let min_arr = [];
    for (let index = 0; index <= 29; index++) {
        min_arr.push(index);
    }
    return (
        <>
            <section className='search-result-details-section'>
                {/* Search bar */}
                <section className="career-section">
                    <div className="career-start-section">
                        <div className="container">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="career-top-heading">
                                            <h2>
                                                Your <span> career </span> starts here
                                            </h2>
                                        </div>
                                        <div className="career-second-heading">
                                            <h6>
                                                Search <span> {jobCount} </span> Job Postings in Afghanistan
                                            </h6>
                                        </div>
                                        <div>
                                            <div className="custom-form-section">
                                                <div className="search-custom" >
                                                    <img
                                                        src="./assets/career-start/magnifyingglass.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                    <input
                                                        // style={{ paddingLeft: "4%" }}
                                                        type="text"
                                                        className="form-control form-custom"
                                                        placeholder="Search with keywords"
                                                        name="serach_text"
                                                        value={formik.values.serach_text}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                                <div className="search-custom">
                                                    <img
                                                        src="./assets/career-start/mappinline.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                    <input
                                                        type="text"
                                                        className="form-control form-custom"
                                                        placeholder="Location"
                                                        name="location"
                                                        value={formik.values.location}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                                <div className="search-custom">
                                                    <img
                                                        src="./assets/career-start/uim_bag.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                    <select
                                                        id='exp'
                                                        name='exp'
                                                        className='form-control form-custom'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.exp}
                                                        placeholder="Experience"
                                                    >
                                                        <option>Experience</option>
                                                        {experienceOptionsData.map((value, index) => {
                                                            return <option key={index + 1} value={value.value}>{value.label}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="form-custom-btn">
                                                    <button type="submit">Search job</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" dropdown-custom-btn">
                                            <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="date_posted"
                                                    id="date_posted"
                                                    value={formik.values.date_posted}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Date Posted'>
                                                    <option value="">Date Posted</option>
                                                    <option value="RECENT">Recent</option>
                                                    <option value="LAST_WEEK">Last Week</option>
                                                    <option value="LAST_MONTH">Last Month</option>
                                                    <option value="ANYTIME">Any Time</option>
                                                </select>
                                            </div>
                                            <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="miles"
                                                    id="miles"
                                                    value={formik.values.miles}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Miles'>
                                                    <option value="">Miles</option>
                                                    <option value="5">within 5 miles</option>
                                                    <option value="10">within 10 miles</option>
                                                    <option value="20">within 20 miles</option>
                                                    <option value="25">within 25 miles</option>
                                                </select>
                                            </div>
                                            <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="pay_range"
                                                    id="pay_range"
                                                    value={formik.values.pay_range}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Pay/salary'>
                                                    <option value="">Pay/salary</option>
                                                    <option value="0-3">0-3 Lakhs</option>
                                                    <option value="3-6">3-6 Lakhs</option>
                                                    <option value="6-10">6-10 Lakhs</option>
                                                    <option value="10-15">10-15 Lakhs</option>
                                                    <option value="15-25">15-25 Lakhs</option>
                                                </select>
                                            </div>
                                            {showJobtype && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="job_type"
                                                    id="job_type"
                                                    value={formik.values.job_type}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Job Type'>
                                                    <option value="">Job Type</option>
                                                    {
                                                        jobtypeData?.data?.map(jobtype => {
                                                            return <option key={jobtype?.id} value={jobtype?.id}>{jobtype?.jobtypename}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>}
                                            {showSchedule && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="schedule"
                                                    id="schedule"
                                                    value={formik.values.schedule}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Shift'>
                                                    <option value="">Shift & Schedule</option>
                                                    {
                                                        scheduleData?.map(schedule => {
                                                            return <option key={schedule?.id} value={schedule?.id}>{schedule?.schedulename}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>}
                                            
                                            {showCity && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="location"
                                                    id="location"
                                                    value={formik.values.location}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Location'>
                                                    <option value="">Location</option>
                                                    {
                                                        cityData?.map((city) => {
                                                            return <option key={city.id} value={city.city_name}>{city.city_name}, {city?.state?.state_name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>}

                                            {showEducationLevel && <div className="dropdown">
                                                <select className='btn-custom p-2'
                                                    name="education_level"
                                                    id="education_level"
                                                    title='Education'
                                                    value={formik.values.education_level}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                >
                                                    <option value="">Education</option>
                                                    {
                                                        educationLevelData?.data?.map(education => {
                                                            return <option key={education?.id} value={education?.id}>{education?.educationlevel}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>}
                                           
                                            <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="exp"
                                                    id="exp"
                                                    value={formik.values.exp}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Experience'>
                                                    <option value="">Experience</option>
                                                    {min_arr.map((value, index) => {
                                                        return <option value={value} key={index + 1}>{value} years</option>
                                                    })}
                                                </select>
                                            </div>
                                            {showIndustry && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="industry"
                                                    id="industry"
                                                    value={formik.values.industry}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Industry type'>
                                                    <option value="">Industry Type</option>
                                                    {
                                                        industryData?.data?.map(industry => {
                                                            return <option key={industry?.id} value={industry?.id}>{industry?.industry}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>}

                                            
                                        </div>
                                    </div>
                                </div>
                            </form>

                            {Object.keys(job_data)?.length != 0 && <button
                                className='mt-2 p-2'
                                style={{
                                    "borderRadius": "5px",
                                    "background": "#1B375C",
                                    "color": "#FFF",
                                    "fontSize": "15px",
                                    "fontWeight": "500",
                                }}
                                onClick={handleRemoveFilter}>Remove Filter</button>}

                            <button className="mt-2 p-2 mx-2"
                                style={{
                                    "borderRadius": "5px",
                                    "background": "#1B375C",
                                    "color": "#FFF",
                                    "fontSize": "15px",
                                    "fontWeight": "500",
                                }}
                                onClick={handleShowAllJobs}>
                                Show All Jobs
                            </button>
                        </div>
                    </div>
                </section>
                {/* Result bar */}
                <section className="career-second-section my-5">
                    <div className="container">
                        <div className="row mb-3">
                            <div className="col-lg-12">

                                {!showFilterJob ? <div className="overlay-form-submission"><Loader /></div> :
                                    <>
                                        {filterJobData?.length ?
                                            filterJobData?.map((job) => {
                                                return <div key={job?.job_data?.id} className="info-section mb-3">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="top-header d-flex justify-content-between">
                                                                <div className="top-header-child">
                                                                    <div className='top-header-company-logo company_image'>
                                                                        <img className="img-fluid"
                                                                            src={job?.job_data?.Company_details?.logo ? `${host}/uploaded-files/${job?.job_data?.Company_details?.logo}` : `${createImageFromInitials(500, job?.job_data?.Company_details?.company_name, getRandomColor())}`}
                                                                            alt={`${job?.job_data?.Company_details?.company_name}`}
                                                                            title={`${job?.job_data?.Company_details?.company_name}`}
                                                                            onError={({ currentTarget }) => {
                                                                                currentTarget.onerror = null; // prevents looping
                                                                                currentTarget.src = createImageFromInitials(500, job?.job_data?.Company_details?.company_name, getRandomColor());
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="top-head">
                                                                        <h3><Link to={`/job-find-description?j=${job?.job_data?.job_slug}`}>{job?.job_data?.job_title}</Link></h3>
                                                                        <p>
                                                                            <span>at {job?.job_data?.Company_details?.company_name}</span>

                                                                            <span>Featured</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="top-header-child">
                                                                    <div className="top-head">
                                                                        <div>
                                                                            <p className='times-span'><i className="fa-solid fa-calendar-days me-1" title="Job posted date"></i>{formatDate(job?.job_data?.createdAt)}</p>
                                                                            <p className='times-span'><i className="fa-solid fa-clock me-1"></i>{timeAgoCalculator(job?.job_data?.createdAt)}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="footer-list-card">
                                                                <ul>
                                                                    {job?.job_data?.Company_details && <li>
                                                                        <img className='me-1' src="./assets/images_another/mappinline.png" alt="" />
                                                                        {job?.job_data?.Company_details?.location_city?.city_name}, {job?.job_data?.Company_details?.location_city?.state_of_city?.country_of_state?.name}
                                                                    </li>
                                                                    }
                                                                    <li>
                                                                        {job?.job_data?.job_type_list?.length > 0 && <> <img className='me-1' src="./assets/images_another/clock.png" alt="" />
                                                                            {job?.job_data?.job_type_list[0]?.job_type_detail?.jobtypename}</>}
                                                                        {(job.job_data.show_pay_by) && <span className="mx-2">
                                                                            <img src="./assets/images_another/currency-wallet.png" alt="" />
                                                                            {job?.job_data?.show_pay_by == "Range" && <span>  {job?.job_data?.currency} {job?.job_data?.min_pay}  -  {job?.job_data?.currency} {job?.job_data?.max_pay}  {job?.job_data?.rate_per_hour}</span>}
                                                                            {job?.job_data?.show_pay_by == "Starting Amount" && <span>  {job?.job_data?.currency} {job?.job_data?.amount}   {job?.job_data?.rate_per_hour}</span>}
                                                                            {job?.job_data?.show_pay_by == "Maximum Amount" && <span>  {job?.job_data?.currency} {job?.job_data?.amount}   {job?.job_data?.rate_per_hour}</span>}
                                                                            {job?.job_data?.show_pay_by == "Exact Amount" && <span>  {job?.job_data?.currency} {job?.job_data?.amount}   {job?.job_data?.rate_per_hour}</span>}
                                                                        </span>}
                                                                    </li>
                                                                    {
                                                                        job?.job_data?.description && <li>
                                                                            {parse(`${truncateText(job?.job_data?.description, 50)}`)}
                                                                        </li>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div >
                                            }) : <div className="info-section">No results found.</div>}
                                    </>
                                }
                            </div >

                        </div >
                    </div >
                </section >
            </section >
        </>
    )
}

export default SearchResultPage