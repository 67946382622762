import React, { useEffect, useState } from 'react'
import ShowCompanyJobsListingcontent from './ShowCompanyJobsListingcontent'
import Footer from '../../Commons/Footer'
import AfterNavbarwithoutLogin from '../../Commons/AfterLoginHeader/AfterNavbarwithoutLogin'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
const ShowCompanyJobsListing = () => {
  const navigate = useNavigate();
  const [loadPage, setLoadPage] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const company_id = queryParams.get('c');
  useEffect(()=>{
      if(!company_id){toast.error("Please enter correct URL.");navigate(-1);}else{}
  },[])
  return (
    <>
      <AfterNavbar />
      <ShowCompanyJobsListingcontent company_id={company_id} />
      <Footer />
    </>
  )
}

export default ShowCompanyJobsListing