import React from 'react'
import { Link } from 'react-router-dom'
import './scss/BuildYourResume.css'
const BuildResumeSnapShotEditor = () => {
    return (
        <>
            <section className='build-resume-sec'>
                <>
                    {/*------------- Snapshot-section-start ------------*/}
                    <section className="Snapshot-section mt-5">
                        <div className="container">
                            <div className="Snapshot-section-bg">
                                <div className="row">
                                    <div className="col-lg-6 col-md-5">
                                        <div className="Snapshot-top-heading">
                                            <h3>Snapshot of our simple-to-use editor</h3>
                                        </div>
                                        <div className="mt-5">
                                            <img
                                                src="./assets/build-resume/third-section-left-img.png"
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-7">
                                        <div className="mx-0 mx-lg-5 mx-md-0">
                                            <div className="Snapshot-para mt-4">
                                                <p>
                                                    All the flexibility &amp; intuition you need to build a resume
                                                    that stands out
                                                </p>
                                            </div>
                                            <div className="Snapshot-right-list">
                                                <ul>
                                                    <li>
                                                        Multi-theme &amp; type face
                                                        <br />
                                                        <span>for personalization.</span>
                                                    </li>
                                                    <li>
                                                        Placeholder resume content
                                                        <br />
                                                        <span> to guide your filling.</span>
                                                    </li>
                                                    <li>
                                                        Multiple layouts &amp; templates
                                                        <br />
                                                        <span> to choose from.</span>
                                                    </li>
                                                </ul>
                                            </div>                                        
                                            <div className="Snapshot-para">
                                                <h4>Our stats:</h4>
                                            </div>
                                            <div className="d-flex">
                                                <div className="Snapshot-right-para">
                                                    <h2>200+</h2>
                                                    <p>users</p>
                                                </div>
                                                <div className="border-bottom mx-3"></div>
                                                <div className="Snapshot-right-para mx-3">
                                                    <h2>3 mins</h2>
                                                    <p>Average resume building time</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>

            </section>
        </>
    )
}

export default BuildResumeSnapShotEditor