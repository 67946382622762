import React from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import YouRegisterSucHomeReturnContent from './YouRegisterSucHomeReturnContent'
import Footer from '../Commons/Footer'
const YouRegisterSucHomeReturn = () => {
    return (
        <>
            <AfterNavbar/>
            <YouRegisterSucHomeReturnContent/>
            <Footer/>
        </>
    )
}
export default YouRegisterSucHomeReturn