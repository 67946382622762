import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './scss/AfterNavbar.css';
import './scss/AfterNavbarResponsive.css';
import './scss/ProfileCounterNumbers.css'
import $ from 'jquery';
import { getContactUsData, getNotificationData, getProfilePercent, getSeekerData, setProfilePercent } from '../../../../Redux/action/SeekerAction'
import { getRecruiterregistrationData } from '../../../../Redux/action/RecruiterAction';
import { createImageFromInitials, getRandomColor } from '../../../Utils';
import HeaderWithoutNav from '../HeaderWithoutNav';
import Header from '../Header';
const host = process.env.REACT_APP_API_SERVER;
const AfterNavbar = (props) => {
    let [completionPercent, setCompletionPercent] = useState(0);
    const [mobileOne, setMobileOne] = useState("+1-202-555-0178");
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showCompanyLogo, setShowCompanyLogo] = useState(false);
    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
    const [notifications, setNotfications] = useState([]);

    useEffect(() => {
        $('.menu-tab-btn').on("click", function () {
            $('.sidebar-menu').addClass('sidebar-menu-toggle');
            $('body').addClass('noscroll');
        });
        $('.sidebar-menu-btn').on("click", function () {
            $('.sidebar-menu').removeClass('sidebar-menu-toggle');
            $('body').removeClass('noscroll');
        });
        $('div.sidebar-link').on("click", function () {
            $('.sidebar-menu').removeClass('sidebar-menu-toggle');
            $('body').removeClass('noscroll');
        })

        dispatch(getContactUsData());
        dispatch(getNotificationData(localStorage.getItem("seeker_token")));

    }, [])
    const contactUsData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.contactUsDataList?.data) : []);
    const img = contactUsData?.logo ? `${host}/${contactUsData.logo}` : '/assets/images/logo.png'
    const notificationData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.notificationDataList?.data) : [])
    useEffect(() => {
        if (contactUsData?.mobileOne) {
            setMobileOne(contactUsData?.mobileOne);
        }
    }, [contactUsData])

    useEffect(() => {
        if (notificationData) {
            setNotfications(notificationData);
        }
    }, [notificationData])

    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {
            setIsLoggedIn(true);
            let authToken = localStorage.getItem("seeker_token");
            dispatch(getSeekerData(authToken));
            dispatch(getProfilePercent(authToken));

        }
        if (localStorage.getItem("recruiter_token")) {
            dispatch(getRecruiterregistrationData(localStorage.getItem("recruiter_token")))
        };       

    }, []);
    const profileData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerDataList?.data?.[0]) : "");
    const firstName = (profileData?.firstName) ? (profileData?.firstName) : '';
    const lastName = (profileData?.lastName) ? (profileData?.lastName) : '';
    const profilePercentData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.profilepercentDataList : {});
    const companyDetails = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.recruiterRegisterDataList : []);
    const logoutBtn = () => {
        let path = `/`;
        toast.success("Successfully logged out.");
        localStorage.removeItem("seeker_token");
        navigate(path);
    }
    useEffect(() => {
        if (companyDetails?.data) {
            setShowCompanyLogo(true);
        } else {
            setShowCompanyLogo(false);
        }
    }, [companyDetails]);


    useEffect(() => {
        if (profilePercentData) {            
            setCompletionPercent(profilePercentData?.profile_completion_percent);
        }
    }, [profilePercentData]);


    return (
        <>
            <section className='after-login-sec'>                
                <HeaderWithoutNav />                
                <section className='header-second'>
                    <div className='container'>
                        <nav className="navbar navbar-expand-lg">
                            <div className="container-fluid">
                                {localStorage.getItem("seeker_token") && <span className='menu-tab-btn l'>
                                    <img src='../assets/images/menu.png' className='menu-tab' />
                                </span>}
                                <Link className="navbar-brand mx-0" to={localStorage.getItem("recruiter_token") ? "/post-job" : "/"}><img src={img} /></Link>
                                <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample08">
                                    <ul className="navbar-nav align-items-center ms-auto">
                                        <li className="nav-item">
                                            <Link className="nav-link active" aria-current="page" to="/career-start-search">Find Jobs</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/browse-company-by-industry">Find Company</Link>
                                        </li>
                                        {localStorage.getItem("seeker_token") &&
                                            <li className="nav-item"><Link className="nav-link" to="/matching-hiring">Features</Link></li>
                                        }
                                        {localStorage.getItem("recruiter_token") &&
                                            <li className="nav-item"><Link className="nav-link" to="/rec-matching-hiring-sec">Features</Link></li>}
                                        {(!localStorage.getItem("recruiter_token")) && (!localStorage.getItem("seeker_token")) && <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Features
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><Link to='/matching-hiring' className="dropdown-item"><i className="fa-solid fa-user me-2"></i>Feature for Job Seeker</Link></li>
                                                <li>
                                                    <Link className="dropdown-item" to="/rec-matching-hiring-sec"><i className="fa-solid fa-user me-2"></i>Feature for Recruiter</Link></li>
                                            </ul>
                                        </li>}
                                       
                                        <li className='nav-item mx-2'>
                                            <Link className="nav-link" to="/build-resume">CV Builder</Link>
                                        </li>
                                        <li className='nav-item mx-2'>
                                            <Link className="nav-link" to="/career-guide">Career Guide</Link>
                                        </li>                                      

                                        <div className="btn-group">
                                            <div className=" dropdown-toggle blog-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Blog and News
                                            </div>
                                            <ul className="dropdown-menu">
                                                <li className="nav-item ">

                                                    <span><Link to='/blogs' className="nav-link  py-0">Blog</Link></span>
                                                </li>
                                                <li className="nav-item ">

                                                    <span><Link to='/news' className="nav-link py-0">News</Link></span>
                                                </li>
                                            </ul>
                                        </div>
                                        {localStorage.getItem("seeker_token") && <li className='header-sec-btn2 mx-2'>
                                            <Link to='/notification-view' className='nav-link'>
                                                <img src={notifications?.length > 0 ? '../assets/images/notification-bel.png' : '../assets/images/notification-bel2.png'} />
                                            </Link>
                                        </li>}
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </section>
                {/* side bar  */}
                <section className='sidebar-menu-parent'>
                    <div className='sidebar-menu'>
                        <div className='sidebar-menu-btn'>
                            <img src='../assets/images/sidebar-icons/cross.png' alt='' />
                            <p>Close</p>
                        </div>
                        <div className='profile-images profile-image-parent'>
                            {/* =============================================== */}
                            <div className="box">
                                <div className="shadow"></div>
                                {localStorage.getItem("seeker_token") && <div className="content">
                                    <div className="percent" data-text="HTML" >
                                        <div className="dot" style={{ transform: `rotate(calc(3.6deg * ${completionPercent}))` }}>
                                            <span className='profile-com-number' style={{ transform: `rotate(${360 - 3.6 * completionPercent}deg)` }}>{completionPercent}%</span>
                                        </div>                                        
                                        <svg>
                                            <circle cx="70" cy="70" r="70"></circle>
                                            <circle cx="70" cy="70" r="70" style={{ strokeDashoffset: `calc(440 - (440 * ${completionPercent}) / 100)` }}></circle>
                                        </svg>
                                        <div className='circle-img'>
                                            {(profileData && <img
                                                src={
                                                    profileData?.profile_img
                                                        ? `${host}/uploaded-files/${profileData?.profile_img}`
                                                        : "assets/images/cv-builder/account.png"
                                                }
                                                alt="Avatar"
                                                style={{ cursor: 'pointer', borderRadius: '50%', objectFit: 'cover', height: "100%", width: "100%" }}
                                                width={100} height={100}

                                            />)}
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            {/* =============================================== */}
                        </div>
                        <div className='user-name'>
                            <h4>{`${firstName} ${lastName}`}</h4>
                        </div>
                        <div className='sidebar-link'>
                            <ul className='sidebar-unlist'>
                                <li>
                                    <img src='/assets/images/sidebar-icons/my-jobs.png' className='img-fluid' />
                                    <span><Link to='/find-jobs'>My Jobs</Link></span>
                                </li>
                                <li>
                                    <img src='/assets/images/sidebar-icons/my-profile.png' className='img-fluid' />
                                    <span><Link to='/my-profile'>My Profile</Link></span>
                                </li>                              
                                
                                <li>
                                    <img src='/assets/images/sidebar-icons/cv-builder.png' className='img-fluid' />
                                    <span><Link to='/build-resume'>CV Builder</Link></span>
                                </li>
                                <li>
                                    <img src='/assets/images/sidebar-icons/my-profile.png' className='img-fluid' />
                                    <span><Link to='/profile-performance'>Profile Performance</Link></span>
                                </li>
                                <li>
                                    <img src='/assets/images/sidebar-icons/chat.png' className='img-fluid' />
                                    <span><Link to='/chat'>Message</Link></span>
                                </li>
                                <li>
                                    <img src='/assets/images/sidebar-icons/my-profile.png' className='img-fluid' />
                                    <span><Link to='/career-preferences'>Career Preferences</Link></span>
                                </li>

                                <li>
                                    <img src='/assets/images/sidebar-icons/video.png' className='img-fluid' />
                                    <span><Link to='/learning-video'>Learning Video</Link></span>
                                </li>
                                <li>
                                    <img src='/assets/images/sidebar-icons/faq.png' className='img-fluid' />
                                    <span><Link to='/faq'>FAQ</Link></span>
                                </li>
                                <li>
                                    <img src='/assets/images/sidebar-icons/blog.png' className='img-fluid' />
                                    <span><Link to='/blogs'>Blog</Link></span>
                                </li>
                                <li>
                                    <img src='/assets/images/sidebar-icons/setting.png' className='img-fluid' />
                                    <span><Link to='/settings'>Settings</Link></span>
                                </li>
                                <li>
                                    <img src='/assets/images/sidebar-icons/logout.png' className='img-fluid' />
                                    <span><a href='#' data-bs-toggle="modal" data-bs-target="#logout-modal">Logout</a></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className='logout-modal'>
                    <div className="modal fade" id="logout-modal" tabIndex={-1} aria-labelledby="exampleModalLabel"
                        aria-hidden="true" >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='modal-body-content'>
                                        <div className='modal-body-content-body'>
                                            <h3>Are you sure you want to Logout</h3>
                                        </div>
                                        <div className='modal-body-btn'>
                                            <button className='logout-cancel-btn' data-bs-dismiss="modal">Cancel</button>
                                            <Link to="/" data-bs-dismiss="modal" className='btn logout-btn' onClick={logoutBtn}>Logout</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section >
        </>
    )
}

export default AfterNavbar