import React from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import AboutUsContent from './AboutUsContent'
import Footer from '../Commons/Footer'
const AboutUs = () => {
  return (
    <>
        <AfterNavbar />
        <AboutUsContent />
        <Footer />
    </>
  )
}
export default AboutUs