import React, { Fragment, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select';
import './scss/Form.css';
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { getJobRoleData } from '../../../../Redux/action/SeekerAction';
import { useDispatch, useSelector } from 'react-redux';
const host = process.env.REACT_APP_API_SERVER;
const CompanyForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const company_name = queryParams.get('c');
  const slug = queryParams.get('s');

  useEffect(() => {
    dispatch(getJobRoleData());
  }, []);

  const { job_roleList } = useSelector(state => state?.MasterReducer);
  const mapData = data => {
    return data?.map(dp => ({
      value: dp?.jobrole,
      label: dp?.jobrole,
    }));
  };

  const role = mapData(job_roleList);

  const how_did_you_get_interview = [
    { value: '1', label: 'Through Karlatoon.com' },
    { value: '2', label: 'Other online application' },
    { value: '3', label: 'Recruiter contacted me' },
    { value: '4', label: 'University recruiting/job fair' },
    { value: '5', label: 'Employee referral' },
    { value: '6', label: 'Paper application' },
    { value: '7', label: 'I walked in' },
    { value: '8', label: 'I called them' },
    { value: '9', label: 'Nothing is applicable' },
  ];

  const recommended_dressing = [
    { value: '1', label: 'Formal (business suit)' },
    { value: '2', label: 'Business Casual (e.g. dress slacks)' },
    { value: '3', label: 'Casual (t-shirt and jeans)' },
    { value: '4', label: 'They didn’t have a dress code' },
    { value: '5', label: 'Special outfit (e.g. protective gear)' },
  ];

  const time_till_offer = [
    { value: '1', label: 'About a day or two' },
    { value: '2', label: 'About a week' },
    { value: '3', label: 'About two weeks' },
    { value: '4', label: 'About a month' },
    { value: '5', label: 'More than one month' },
  ];

  const work_exp = [
    { value: '1', label: 'Less than 1 year' },
    { value: '2', label: '1-3 years' },
    { value: '3', label: '3-5 years' },
    { value: '4', label: '5-8 years' },
    { value: '5', label: 'More than 8 years' },
  ];

  const interview_activities = [
    { value: '1', label: 'Phone call/ screening' },
    { value: '2', label: 'Written test' },
    { value: '3', label: 'Take-home/ sample work' },
    { value: '4', label: 'Problem solving exercises' },
    { value: '5', label: 'Group interview' },
    { value: '6', label: 'On-site interview' },
    { value: '7', label: 'Presentation' },
    { value: '8', label: 'Background check' },
    { value: '9', label: 'Drug test' },
    { value: '10', label: 'They have no interview' },
    { value: '11', label: 'Other' },
  ];

  const feel_after_interview = [
    { value: '1', label: 'Really excited to work there' },
    { value: '2', label: 'A bit more excited to work there' },
    { value: '3', label: 'Felt about the same' },
    { value: '4', label: 'Less excited about working there' },
    { value: '5', label: "Didn't want to work there anymore" },
  ];

  const year = [
    { value: '2024', label: '2024' },
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
    { value: '2021', label: '2021' },
    { value: '2020', label: '2020' },
    { value: '2019', label: '2019' },
    { value: '2018', label: '2018' },
    { value: '2017', label: '2017' },
    { value: '2016', label: '2016' },
    { value: 'Before 2016', label: 'Before 2016' },
  ];

  let parsedJSON = JSON.parse(localStorage.getItem("company_form_values"));
  let parsedCompany = localStorage.getItem("company_form_company");

  const formik = useFormik({
    initialValues: {
      how_did_you_get_interview: (parsedCompany && parsedCompany === slug) ? parsedJSON ? parsedJSON?.how_did_you_get_interview : "" : "",
      work_exp: (parsedCompany && parsedCompany === slug) ? parsedJSON ? parsedJSON?.work_exp : "" : "",
      recommended_dressing: (parsedCompany && parsedCompany === slug) ? parsedJSON ? parsedJSON?.recommended_dressing : "" : "",
      interview_activities: (parsedCompany && parsedCompany === slug) ? parsedJSON ? parsedJSON?.interview_activities : [] : [],
      questions_asked: (parsedCompany && parsedCompany === slug) ? parsedJSON ? parsedJSON?.questions_asked : "" : "",
      level_of_difficulty: (parsedCompany && parsedCompany === slug) ? parsedJSON ? parsedJSON?.level_of_difficulty : "" : "",
      feel_after_interview: (parsedCompany && parsedCompany === slug) ? parsedJSON ? parsedJSON?.feel_after_interview : "" : "",
      time_till_offer: (parsedCompany && parsedCompany === slug) ? parsedJSON ? parsedJSON?.time_till_offer : "" : "",
      fair_assessment: (parsedCompany && parsedCompany === slug) ? parsedJSON ? parsedJSON?.fair_assessment : "" : "",
      overall_interview_exp: (parsedCompany && parsedCompany === slug) ? parsedJSON ? parsedJSON?.overall_interview_exp : "" : "",
      interview_process: (parsedCompany && parsedCompany === slug) ? parsedJSON ? parsedJSON?.interview_process : "" : "",
      role: (parsedCompany && parsedCompany === slug) ? parsedJSON ? parsedJSON?.role : "" : "",
      year: (parsedCompany && parsedCompany === slug) ? parsedJSON ? parsedJSON?.year : "" : "",
      location: (parsedCompany && parsedCompany === slug) ? parsedJSON ? parsedJSON?.location : "" : "",
    },
    validationSchema: Yup.object({
      level_of_difficulty: Yup.string().required('This field is required'),
      time_till_offer: Yup.string().required('This field is required'),
      overall_interview_exp: Yup.string().required('This field is required'),
      role: Yup.string().required('This field is required'),
      location: Yup.string().required('This field is required'),
      year: Yup.string().required('This field is required'),
      fair_assessment: Yup.string().required('This field is required'),
    }),
    onSubmit: (values, { resetForm }) => {
      const token = localStorage.getItem('seeker_token');

      if (!token) {
        let loginModalCompanyFormBtn = document.getElementById("loginModalCompanyFormBtn");
        if (loginModalCompanyFormBtn) {
          loginModalCompanyFormBtn.click();
        }
        localStorage.setItem("company_form_company", slug)
        localStorage.setItem("company_form_values", JSON.stringify(values));
      } else {
        const config = {
          headers: {
            'content-type': 'application/json',
            'auth-token': token,
          },
        };
        const loadingToastId = toast.info('Submitting...', { autoClose: false });
        try {
          values.company_slug = slug;


          axios.post(`${host}/web/recruiter-interview-add-interview-experience`, values, config)
            .then(response => {
              if (response.status === 200) {
                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Interview Experience submitted successfully!' });
                resetForm();
                localStorage.removeItem("company_form_values")
                localStorage.removeItem("company_form_company")
                navigate(`/profile/${slug}/Interviews`);
              } else {
                toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response.data.msg });
                if (response.data.msg === 'You have already shared your interview experience.') {
                  resetForm();
                  navigate(`/profile/${slug}/Interviews`);
                }
              }
            })
            .catch(error => {
              console.error(error.message);
              toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'An error occurred. Please try again later.' });
            });
        } catch (error) {
          toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: error?.response?.data?.error });
        }
      }
    },
  });

  const urlString = window.location.href;
  const url1 = new URL(urlString);
  const path = url1.pathname.slice(1);
  const searchParams = url1.search;
  const q = path + searchParams;
  const handleLoginRedirect = () => {
    let loginPopUpModalCompanyFormCloseBtn = document.getElementById("loginPopUpModalCompanyFormCloseBtn");
    if (!localStorage.getItem("seeker_token")) {
      if (loginPopUpModalCompanyFormCloseBtn) {
        loginPopUpModalCompanyFormCloseBtn.click();
        navigate(`/signin?q=${encodeURIComponent(q)}`);
      }
    } else {
      if (loginPopUpModalCompanyFormCloseBtn) {
        loginPopUpModalCompanyFormCloseBtn.click();
      }
    }
  }

  const handleSelectChange = (field, value) => {
    formik.setFieldValue(field, value?.value || '');
  };

  const handleMultiSelectChange = (field, value) => {
    formik.setFieldValue(field, value ? value.map(v => v.value) : []);
  };

  return (
    <>
      <Header />
      <div className="company-review-form">
        <div className="container">
          <div className="row py-4 ">
            <h1 className="text-black text-center">
              Company Reviews
            </h1>
          </div>
          <div className="p-5 mb-5 rounded-md">
            <form className="row align-items-baseline" onSubmit={formik.handleSubmit}>
              <div className="col-md-6 mb-4">
                <label htmlFor="how_did_you_get_interview" className="form-label"> How did you get the interview at {company_name}?</label>
                <Select
                  id="how_did_you_get_interview"
                  name="how_did_you_get_interview"
                  options={how_did_you_get_interview}
                  value={how_did_you_get_interview?.find(option => option.value === formik.values.how_did_you_get_interview)}
                  onChange={option => handleSelectChange('how_did_you_get_interview', option)}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-md-6 mb-4">
                <label htmlFor="work_exp" className="form-label"> How much related work experience did you have at the time of the interview at {company_name}?</label>
                <Select
                  id="work_exp"
                  name="work_exp"
                  options={work_exp}
                  value={work_exp?.find(option => option.value === formik.values.work_exp)}
                  onChange={option => handleSelectChange('work_exp', option)}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-md-6 mb-4">
                <label htmlFor="recommended_dressing" className="form-label">How would you recommend dressing for an interview at {company_name}?</label>
                <Select
                  id="recommended_dressing"
                  name="recommended_dressing"
                  options={recommended_dressing}
                  value={recommended_dressing?.find(option => option.value === formik.values.recommended_dressing)}
                  onChange={option => handleSelectChange('recommended_dressing', option)}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="mb-3  col-md-6 ">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                  Please rate the level of difficulty of your interview at {company_name}<span className='text-danger'>*</span>
                </label>
                <div className="radio-group">
                  {[1, 2, 3, 4, 5]?.map(value => (
                    <Fragment key={value}>
                      <input
                        type="radio"
                        id={`level_of_difficulty_${value}`}
                        name="level_of_difficulty"
                        value={value}
                        checked={formik.values.level_of_difficulty === String(value)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor={`level_of_difficulty_${value}`}>{value}</label>
                    </Fragment>
                  ))}
                </div>
                <div className="help-block with-errors">
                  {formik.touched.level_of_difficulty && formik.errors.level_of_difficulty ? (
                    <div className='text-danger'>{formik.errors.level_of_difficulty}</div>
                  ) : null}
                </div>

              </div>
              <div className="col-md-6 mb-4">
                <label htmlFor="interview_activities" className="form-label">What kinds of interview activities did you have at {company_name}?</label>
                <Select
                  id="interview_activities"
                  name="interview_activities"
                  options={interview_activities}
                  value={interview_activities.filter(option => formik.values.interview_activities.includes(option.value))}
                  onChange={option => handleMultiSelectChange('interview_activities', option)}
                  onBlur={formik.handleBlur}
                  isMulti
                />
              </div>
              <div className="col-md-6 mb-4">
                <label htmlFor="questions_asked" className="form-label"> What interview questions did they ask at {company_name}?</label>
                <textarea
                  id="questions_asked"
                  name="questions_asked"
                  className="form-control"
                  rows="4"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.questions_asked}
                />
              </div>
              <div className="mb-3  col-md-6 ">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                  Please rate your overall interview experience at {company_name}<span className='text-danger'>*</span>  </label>
                <div className="radio-group">
                  {[1, 2, 3, 4, 5]?.map(value => (
                    <Fragment key={value}>
                      <input
                        type="radio"
                        id={`overall_interview_exp_${value}`}
                        name="overall_interview_exp"
                        value={value}
                        checked={formik.values.overall_interview_exp === String(value)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor={`overall_interview_exp_${value}`}>{value}</label>
                    </Fragment>
                  ))}
                </div>
                <div className="help-block with-errors">
                  {formik.touched.overall_interview_exp && formik.errors.overall_interview_exp ? (
                    <div className='text-danger'>{formik.errors.overall_interview_exp}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <label htmlFor="feel_after_interview" className="form-label"> How did you feel about {company_name} after interviewing there?</label>
                <Select
                  id="feel_after_interview"
                  name="feel_after_interview"
                  options={feel_after_interview}
                  value={feel_after_interview?.find(option => option.value === formik.values.feel_after_interview)}
                  onChange={option => handleSelectChange('feel_after_interview', option)}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-md-6 mb-4">
                <label htmlFor="time_till_offer" className="form-label"> How long did it take from the beginning of the interview process at {company_name} until you received your job offer?<span className='text-danger'>*</span></label>
                <Select
                  id="time_till_offer"
                  name="time_till_offer"
                  options={time_till_offer}
                  value={time_till_offer?.find(option => option.value === formik.values.time_till_offer)}
                  onChange={option => handleSelectChange('time_till_offer', option)}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.time_till_offer && formik.errors.time_till_offer ? (
                  <div className="text-danger">{formik.errors.time_till_offer}</div>
                ) : null}
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label" > Do you think your interview at the company was a fair assessment of your skills?<span className='text-danger'>*</span></label>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="fair_assessment_yes"
                    name="fair_assessment"
                    value="YES"
                    checked={formik.values.fair_assessment === "YES"}
                    onChange={(e) => {
                      formik.handleChange(e);

                    }}
                  />
                  <label className="form-label" htmlFor="fair_assessment_yes">Yes</label>
                  <input
                    type="radio"
                    id="fair_assessment_no"
                    name="fair_assessment"
                    value="NO"
                    checked={formik.values.fair_assessment === "NO"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label className="form-label" htmlFor="fair_assessment_no">No</label>
                </div>
                <div className="help-block with-errors">
                  {formik.touched.fair_assessment && formik.errors.fair_assessment && (
                    <div className='text-danger'>{formik.errors.fair_assessment}</div>
                  )}
                </div>
              </div>



              <div className="col-md-6 mb-4">
                <label htmlFor="interview_process" className="form-label"> What is the interview process like at {company_name}?</label>
                <textarea
                  id="interview_process"
                  name="interview_process"
                  className="form-control"
                  rows="4"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.interview_process}
                />
              </div>
              <div className="col-md-6 mb-4">
                <label htmlFor="role" className="form-label">  Which role did you interview for?<span className='text-danger'>*</span></label>
                <Select
                  id="role"
                  name="role"
                  options={role}
                  value={role?.find(option => option.value === formik.values.role)}
                  onChange={option => handleSelectChange('role', option)}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.role && formik.errors.role ? (
                  <div className="text-danger">{formik.errors.role}</div>
                ) : null}
              </div>
              <div className="col-md-6 mb-4">
                <label htmlFor="year" className="form-label">Year</label>
                <Select
                  id="year"
                  name="year"
                  options={year}
                  value={year?.find(option => option.value === formik.values.year)}
                  onChange={option => handleSelectChange('year', option)}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.year && formik.errors.year ? (
                  <div className="text-danger">{formik.errors.year}</div>
                ) : null}
              </div>
              <div className="col-md-6 mb-4">
                <label htmlFor="location" className="form-label">Location</label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.location}
                />
                {formik.touched.location && formik.errors.location ? (
                  <div className="text-danger">{formik.errors.location}</div>
                ) : null}
              </div>
              <div className="col-md-12 text-center">
                <button type="submit" className="btn btn-primary px-4">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Login Modal */}
      <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#loginPopUpModalCompanyForm" id="loginModalCompanyFormBtn">
        Launch demo modal
      </button>
      <div className="modal fade" id="loginPopUpModalCompanyForm" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title" id="exampleModalLabel"><img src="/assets/images/logo.png" /></h5>
              <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" id="loginPopUpModalCompanyFormCloseBtn"></button>
            </div>
            <div className="modal-body">
              <div className="card-body p-3">
                <h3 className="text-center" style={{
                  fontSize: '20px !important',
                  marginBottom: '20px',
                }}>
                  {/* Sign in <br /> before proceeding on Karlatoon */}
                  Ready to take next step ?
                </h3>
                <p className="text-center" style={{ fontSize: "15px" }}>Sign in to Karlatoon.</p>
                <p className="text-muted text-justify">
                  <small>
                    You may also apply directly on the company's website. By
                    creating an account or signing in, you understand and agree to
                    Karlatoon's <Link to="/terms-conditions" target='_blank'>Terms <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You also consent to our{" "}
                    <Link to="/privacy-policy" target='_blank'>Privacy policies <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You will receive
                    marketing messages from Karlatoon and may opt out at any time by
                    following the unsubscribe link in our messages, or as detailed in
                    our terms.
                  </small>
                </p>

                <div className="text-center mb-3"></div>
                <div className="d-grid gap-2 mb-3 justify-content-center">
                  <button className="btn btn-outline-secondary popup-login-btn" type="button" onClick={handleLoginRedirect}
                    style={{
                      padding: "5px 20px",
                      borderRadius: "40px",
                      background: "#1B375C",
                      textDecoration: "none",
                      color: "#FFF",
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "500",
                      border: "1px solid #1B375C",
                      width: "fit-content"
                    }}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CompanyForm;
