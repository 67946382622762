import { useFormik } from 'formik'
import * as Yup from "yup";
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { error } from 'jquery';
import { toast } from 'react-toastify';
import HourglassLoader from '../../../Seeker/HourglassLoader';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAssessmentList, getAssessmentQuestionList } from '../../../../Redux/action/RecruiterAction';
const host = process.env.REACT_APP_API_SERVER;

const EditAssessmentTItleModal = ({ reloadData, assessment_id }) => {
    const initialValues = {
        title: "",
        passing_percent:''
    }
    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        const token = localStorage.getItem("recruiter_token");
        if (assessment_id) {
            dispatch(getAssessmentList(token));
        }
    }, [assessment_id])
    const assessmentList = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.assessmentListData : null);
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().required("Please enter title of assessment."),
            passing_percent: Yup.string().required("Please enter passing percent for assessment..")
        }),
        onSubmit: (values, { resetForm }) => {
            values.assessment_id = assessment_id
            setDisabled(true);
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                const config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } }
                axios.post(`${host}/recruiter/update-assessment`, values, config)
                    .then(response => {
                        if (response.status == 200) {
                            setDisabled(false);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Title updated successfully" });
                            reloadData();
                            $(`#editAssessmentCloseBtn${assessment_id}`).click();
                            resetForm();
                        } else {
                            setDisabled(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    }).catch(error => {
                        setDisabled(false);
                        console.log(error.message)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
                    })
            } catch (error) {
                setDisabled(false);
                console.log(error.message)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
            }

        }
    });

    useEffect(() => {
        if (assessmentList) {
            const findQuestion = assessmentList.find(item => item.id === assessment_id)
            // console.log(findQuestion);
            formik.setValues({
                title: findQuestion?.title ?? "",
                passing_percent: findQuestion?.passing_percent ?? "",
            })
        }
    }, [assessmentList])

    return (
        <>
            <div className="modal fade" id={`editAssessment${assessment_id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Edit Assessment</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id={`editAssessmentCloseBtn${assessment_id}`}></button>
                        </div>
                        <div className="modal-body">
                            <form method="post" onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label htmlFor="title">Enter Assessment Title <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            name='title'
                                            placeholder='Enter assessment title'
                                            maxLength={100}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.title} />
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.title && formik.errors.title ? (
                                            <div className='text-danger'> {formik.errors.title}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="passing_percent">Enter Passing Percent <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            name='passing_percent'
                                            placeholder='Enter passing percent(between 1-100%)'
                                            maxLength={100}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.passing_percent} />
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.passing_percent && formik.errors.passing_percent ? (
                                            <div className='text-danger'> {formik.errors.passing_percent}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-6">
                                        {disabled ?
                                            <HourglassLoader />
                                            : <button className="btn btn-primary btn-sm" disabled={disabled}>Submit</button>}
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default EditAssessmentTItleModal