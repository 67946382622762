import React, { useEffect, useState } from 'react'
import './scss/RecMySubsCriptionPlan.css'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate';

const RecMySubsCriptionPlanHistoryContent = ({ allPlans }) => {
    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const futureDate = new Date(timestamp);

        const timeDifference = futureDate - currentDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months !== 1 ? 's' : ''} left`;
        } else if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} left`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} left`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} left`;
        } else {
            return 'Just now';
        }
    };

    //pagination
    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 5;
    useEffect(() => {

        const endOffset = dataOffset + dataPerPage;
        setCurrentData(allPlans.slice(dataOffset, endOffset));
        setPageCount(Math.ceil(allPlans?.length / dataPerPage));
    }, [dataOffset, dataPerPage, allPlans])

    const handlePageClick = (event) => {
        window.scroll(0, 0);
        const newOffset = (event.selected * dataPerPage) % allPlans?.length;
        setDataOffset(newOffset);
    };

    return (
        <>
            <section className='rec-my-subscription-plan'>
                <section className="my-5 main-subs-plan-2">
                    <div className="main-sec-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="cus-head">
                                        <h5 className="text-center">Billing Summary</h5>
                                    </div>

                                    {allPlans?.length > 0 ? currentData.map(plan => {
                                        return <>
                                            <div className="subscrip-card mt-2">
                                                {/* <div className="cus-btn">
                                            <button className="btn">Post a Job</button>
                                        </div> */}
                                                <div className="sub-inner-sec">
                                                    <div className="d-flex justify-content-between">
                                                        <p className="cus-par-1">{plan?.subscription_plan?.name}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <p className="cus-par-1">Plan price: ${plan?.price}/{plan.price_type === "YEARLY" ? "yr" : "mo"} </p>
                                                        <p className="cus-par-1">Discount: {plan?.discount}%</p>

                                                    </div>
                                                    <div className="d-flex justify-content-between">

                                                        <p className="cus-par-1">Purchased At: {new Date(plan?.plan_purchase_time).toLocaleDateString("en-US", { day: '2-digit', month: 'long', year: 'numeric' })}</p>
                                                    </div>

                                                    <div>
                                                        <p className='inner-p'>Final price:</p>
                                                        <h4>${plan.selling_price}/{plan.price_type === "YEARLY" ? "yr" : "mo"} </h4>
                                                        <p className="inner-p">Plan Benefits:</p>
                                                    </div>
                                                    <div className="cus-list mt-2">
                                                        <ul className="list-unstyled">
                                                            <li className="mb-2 right">Upto {plan.jobpost_count ?? 0} Job Posts</li>
                                                            <li className={`mb-2 ${Number(plan?.resumedownload_count) == 0 ? "cross" : "right"}`}>Search Candidate across the globe</li>
                                                            <li className={`mb-2 ${Number(plan?.resumedownload_count) == 0 ? "cross" : "right"}`}>Download resumes</li>
                                                            <li className={`mb-2 ${Number(plan?.add_jobfair) == 0 ? "cross" : "right"}`}>Post Jobs for Job fair</li>
                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    }) :
                                        <div className="text-center my-2">
                                            No subscription plan purchased.
                                            <div className='m-2'>
                                                <Link className='btn btn-sm btn-primary' to="/choose-subscription-plan">Buy Subscription</Link>
                                            </div>
                                        </div>
                                    }

                                    {allPlans?.length > 5 && <div className='row justify-content-center'>
                                        <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                            <section className='pagination-section'>
                                                <div className='container'>
                                                    <nav aria-label="Page navigation example" className='nav-class'>
                                                        <ReactPaginate
                                                            breakLabel="..."
                                                            nextLabel=">>"
                                                            onPageChange={handlePageClick}
                                                            pageRangeDisplayed={5}
                                                            pageCount={pageCount}
                                                            previousLabel="<<"
                                                            renderOnZeroPageCount={null}
                                                            containerClassName='pagination'
                                                            pageLinkClassName='page-num'
                                                            previousLinkClassName='page-num'
                                                            nextLinkClassName='page-num'
                                                            activeClassName='active'
                                                        />
                                                    </nav>
                                                </div>
                                            </section>
                                        </div>
                                    </div>}

                                    <div className="cus-list mt-2">
                                        <div className="text-center">
                                            <Link to="/rec-my-subscription-plan">My Subscription Plan</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </section >

            </section >
        </>
    )
}

export default RecMySubsCriptionPlanHistoryContent