import React from 'react'
import './scss/YouHaveRegisteredSuccessfully.css'
import $ from "jquery";
import RegisterPopup from './RegisterPopup';
import RecYouRegisterButton from './RecYouRegisterButton';
const YouHaveRegisteredSuccessfullyContent = () => {
    $(document).ready(function () {setTimeout(function () {document.getElementById("rec-pop-btn-register") && document.getElementById("rec-pop-btn-register").click(); }, 1000);});
    return (
        <>
            <section className='rec-you-register-suc-job-search'>
                <div className="main-section">
                    <div className="container">
                        <div className="second-section">
                            <div className="row align-items-center">
                                <div className="col-md-7 col-lg-7 order-lg-1 order-md-1 order-2">
                                    <div className='main-head'>
                                        <div className="#">
                                            <div className='register-heading'>
                                                <h2 className="head">You have registered Successfully</h2>
                                            </div>
                                            <div>
                                                <h5 className="head2">it will be verified by the admin first then you can proceed</h5>
                                            </div>
                                            <p className="para">
                                                Thank you for completing the registration process successfully. Your account is now active, and you are all set to explore our platform. Welcome aboard, and we wish you the best in your endeavors!
                                            </p>
                                            <RecYouRegisterButton />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-lg-5 order-lg-2 order-md-2 order-1">
                                    <div>
                                        <img src="./assets/images_another/success.png" className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RegisterPopup />
                <a data-bs-toggle="modal" id="rec-pop-btn-register" data-bs-target="#show-rating"></a>
            </section>
        </>
    )
}

export default YouHaveRegisteredSuccessfullyContent