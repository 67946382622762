import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getMostViewedJobsData } from '../../../../Redux/action/SeekerAction';
import Loader from '../../Loader';
import parse from 'html-react-parser';
import { Link, useNavigate } from 'react-router-dom';
import { getRandomColor, createImageFromInitials } from '../../../Utils';
const host = process.env.REACT_APP_API_SERVER;
const SearchResultJobsList = ({ mostviewedJobs }) => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let [mostViewedJob, setMostViewedJob] = useState([]);
    useEffect(() => {setMostViewedJob(mostviewedJobs);
    }, [mostviewedJobs])
    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const previousDate = new Date(timestamp);

        const timeDifference = currentDate - previousDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        if (months > 0) {return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) {return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) { return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {return 'Just now';}
    };
    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {return words.slice(0, wordLimit).join(' ') + '...';
        } return text;
    };
    const redirectPage = (id) => { navigate(`/career-result-description?job_id=${id}`);}
   
    return (
        <>
            {mostViewedJob.map(job => {
                return <div className="form-desgin mt-2" key={job?.job_data?.id}>
                    <div className="row">
                        <div className="col-lg-3 text-center">
                            <div >
                               
                                <img className="img-fluid search-result-section-img"
                                    src={job?.job_data?.Company_details?.logo ? `${host}/uploaded-files/${job?.job_data?.Company_details?.logo}` : `${createImageFromInitials(500, job?.job_data?.Company_details?.company_name, getRandomColor())}`}
                                    alt={`${job?.job_data?.Company_details?.company_name}`}
                                    title={`${job?.job_data?.Company_details?.company_name}`}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = createImageFromInitials(500, job?.job_data?.Company_details?.company_name, getRandomColor());
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="ptn">
                               <p className="top-para"><Link to={`/profile/${job?.job_data?.Company_details?.company_slug}/snap-shot`} className='remove-link-style' title='View company profile'>{job?.job_data?.Company_details?.company_name}</Link></p>
                                <div className="heading">
                                 <h2><Link to={`/career-result-description?j=${job?.job_data?.job_slug}`}> {job?.job_data?.job_title}</Link><span> <a className="tile" href="#">New Post</a> </span>
                                    </h2>
                                </div>
                                <div className="list-section">
                                    <ul className="pad-custom ">
                                        {job?.job_data?.job_type_list?.length > 0 && <li>
                                            <img src="./assets/career-start/clock.png" alt="" />
                                            <span>{job?.job_data?.job_type_list[0]?.job_type_detail?.jobtypename}</span>
                                        </li>}
                                        {job?.job_data?.show_pay_by && <li>
                                            <img src="./assets/images_another/currency-wallet.png" alt="" />
                                            {job?.job_data?.show_pay_by == "Range" && <span>  {job?.job_data?.currency} {job?.job_data?.min_pay} - {job?.job_data?.currency} {job?.job_data?.max_pay}   {job?.job_data?.rate_per_hour}</span>}
                                            {job?.job_data?.show_pay_by == "Starting Amount" && <span>  {job?.job_data?.currency} {job?.job_data?.amount} {job?.job_data?.rate_per_hour}</span>}
                                            {job?.job_data?.show_pay_by == "Maximum Amount" && <span>  {job?.job_data?.currency} {job?.job_data?.amount} {job?.job_data?.rate_per_hour}</span>}
                                            {job?.job_data?.show_pay_by == "Exact Amount" && <span>  {job?.job_data?.currency} {job?.job_data?.amount} {job?.job_data?.rate_per_hour}</span>}
                                        </li>}
                                        <li>
                                            <img src="./assets/career-start/calendarblank.png" alt="" />
                                            <span> {timeAgoCalculator(job?.job_data?.createdAt)}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="para">
                                   
                                    {job?.job_data?.description ? parse(`${truncateText(job?.job_data?.description, 30)}`) : <i>Description unavailable</i>}
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            })}
        </>
    )
}

export default SearchResultJobsList