import React, { useEffect, useState } from 'react';
import { useNavigate, } from 'react-router-dom';
import Loader from '../Loader';
import Error from '../../Common/Error/Error';
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    Paper,
} from '@mui/material';
import "./scss/stepper.css";

import Footer from '../Commons/Footer';
import PersonalDetails from './PersonalDetails';
import WorkHistory from './WorkHistory/WorkHistory';
import Education from './Education/Education';
import AddWorkDetails from './WorkHistory/AddWorkDetails';
import AddEducation from './Education/AddEducation';

import ResumeModal from './ResumeModal';
import ResumeModal2 from './ResumeModal2';
import ResumeModal3 from './ResumeModal3';
import ResumeModal4 from './ResumeModal4';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { getCVbuilderBasicDetails } from '../../../Redux/action/SeekerAction';
import { useLocation } from 'react-router-dom';
import Certification from './Certification/Certification';
import AddCertification from './Certification/AddCertification';
import FinalizeResume1 from './Finalize/FinalizeResume1';
import FinalizeResume2 from './Finalize/FinalizeResume2';
import FinalizeResume3 from './Finalize/FinalizeResume3';
import FinalizeResume4 from './Finalize/FinalizeResume4';
import FinalizeResume5 from './Finalize/FinalizeResume5';
import Accomplishment from './Accomplishment';
import ProjectHistory from './ProjectHistory/ProjectHistory';
import AddProjectDetails from './ProjectHistory/AddProjectDetails';
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar';
import { v4 as uuidv4 } from 'uuid';
import ResumeModal5 from './ResumeModal5';


const ResumeForm = () => {
    const currentUrl = new URL(window.location.href);
    let stepper_step = currentUrl.searchParams.get('step');
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (stepper_step) {
            if (stepper_step == 6) {
                console.log("in here");
                setActiveStep(6);
            } else {
                console.log(stepper_step);
            }
        } else {
            console.log("Not found.")
        }
    }, [stepper_step]);

    const resumeId = localStorage.getItem("karlatoon_resume_template");
    useEffect(() => {
        if (!resumeId) {
            localStorage.setItem("karlatoon_resume_template", 1)
        }
    }, [resumeId]);
    const navigate = useNavigate();
    useEffect(() => {
        if (!resumeId) {
            toast.error("Please select a resume template.")
            navigate(-1);
        }
    }, [resumeId])

    const [education, setEducation] = useState(false);
    const [work, setWork] = useState(false)
    const [certification, setCertification] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        let authToken = localStorage.getItem("seeker_token");
        let device_token = localStorage.getItem("karlatoon_deviceId");
        const search = window.location.search;
        const searchParams = new URLSearchParams(search)
        const step = searchParams.get('step');
        const dr = searchParams.get('dr');

        if (device_token && step == "6" && dr && dr == "true") {
            dispatch(getCVbuilderBasicDetails(device_token, "DOWNLOAD"));
        } else if (localStorage.getItem("seeker_token")) {
            let authToken = localStorage.getItem("seeker_token");
            dispatch(getCVbuilderBasicDetails(authToken));
        } else {
            if (localStorage.getItem("karlatoon_deviceId")) {
                let token = localStorage.getItem("karlatoon_deviceId");
                dispatch(getCVbuilderBasicDetails(token));
            } else {
                navigate('/build-resume');
            }
        }
    }, [dispatch, navigate]);


    const refresh = () => {
        let authToken = localStorage.getItem("seeker_token");
        let device_token = localStorage.getItem("karlatoon_deviceId");

        if (authToken) {
            dispatch(getCVbuilderBasicDetails(authToken));
        } else if (device_token) {
            dispatch(getCVbuilderBasicDetails(device_token));
        } else {
            toast.error("Token expired.");
            navigate("/build-resume")
        }
    }
    const { loadingCvBuilderBasicDetailsDataListApi, CvBuilderBasicDetailsDataListApiData, CvBuilderBasicDetailsDataListApiError } = useSelector(
        (state) => state.SeekerReducer
    );
    const handleNext = () => {
        window.scroll(0, 0);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        window.scroll(0, 0);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handlePreviewResume = () => {
        if (!localStorage.getItem("karlatoon_resume_template")) {
            localStorage.setItem("karlatoon_resume_template", 1);
        }
        if (![1, 2, 3, 4, 5].includes(Number(localStorage.getItem("karlatoon_resume_template")))) {
            localStorage.setItem("karlatoon_resume_template", 1);
        }
        let resume_id = localStorage.getItem("karlatoon_resume_template");
        let karlatoon_resume_id_1 = document.getElementById("karlatoon_resume_id_1");
        let karlatoon_resume_id_2 = document.getElementById("karlatoon_resume_id_2");
        let karlatoon_resume_id_3 = document.getElementById("karlatoon_resume_id_3");
        let karlatoon_resume_id_4 = document.getElementById("karlatoon_resume_id_4");
        let karlatoon_resume_id_5 = document.getElementById("karlatoon_resume_id_5");
        if (resume_id == 1) {
            if (karlatoon_resume_id_1) {
                karlatoon_resume_id_1.click();
            }
        }
        if (resume_id == 2) {
            if (karlatoon_resume_id_2) {
                karlatoon_resume_id_2.click();
            }
        }
        if (resume_id == 3) {
            if (karlatoon_resume_id_3) {
                karlatoon_resume_id_3.click();
            }
        }
        if (resume_id == 4) {
            if (karlatoon_resume_id_4) {
                karlatoon_resume_id_4.click();
            }
        }
        if (resume_id == 5) {
            if (karlatoon_resume_id_5) {
                karlatoon_resume_id_5.click();
            }
        }
    }


    const handleSetTemple = (id) => {
        try {
            if (!id) {
                toast.error("Please select a template.")
            } else if (![1, 2, 3, 4, 5].includes(Number(id))) {
                toast.error("Please select a valid template.");
            } else {
                localStorage.setItem("karlatoon_resume_template", Number(id));
                let closeBtn = document.getElementById("changeCVBuilderTemplateCloseBtn");
                if (closeBtn) {
                    closeBtn.click();
                }
                refresh();
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const [project, setProject] = useState(CvBuilderBasicDetailsDataListApiData?.cvbuilderprofessionaldetails?.length > 0);
    const steps = [
        {
            label: 'Personal Details',
            content: (
                <>
                    <PersonalDetails data={CvBuilderBasicDetailsDataListApiData} refresh={refresh} handleNext={handleNext} handlePreviewResume={handlePreviewResume} />
                </>
            ),
        },
        {
            label: 'Work History',
            content: (<>
              
                <AddWorkDetails setWork={setWork} data={CvBuilderBasicDetailsDataListApiData} refresh={refresh} handleNext={handleNext} handlePreviewResume={handlePreviewResume} handleBack={handleBack} />
            </>),
        },
        {
            label: 'Education',
            content: (<>
                
                <AddEducation setEducation={setEducation} data={CvBuilderBasicDetailsDataListApiData} refresh={refresh} handleNext={handleNext} handlePreviewResume={handlePreviewResume} handleBack={handleBack} />
            </>),
        },
        {
            label: 'Project History',
            content: (<>
              
                 <AddProjectDetails setProject={setProject} data={CvBuilderBasicDetailsDataListApiData} refresh={refresh} handleNext={handleNext} handlePreviewResume={handlePreviewResume} handleBack={handleBack} />
            </>),
        },

       
        {
            label: 'Certification',
            content: (<>
              
                <AddCertification setCertification={setCertification} data={CvBuilderBasicDetailsDataListApiData} refresh={refresh} handleNext={handleNext} handlePreviewResume={handlePreviewResume} handleBack={handleBack} />
            </>),
        },
        {
            label: 'Accomplishments',
            content: (
                <>
                    <Accomplishment data={CvBuilderBasicDetailsDataListApiData} refresh={refresh} handleNext={handleNext} handlePreviewResume={handlePreviewResume} handleBack={handleBack} />
                </>
            ),
        },
        {
            label: 'Finalize',
            content: (
                <>
                    {resumeId === "1" && <FinalizeResume1 findResumeData={CvBuilderBasicDetailsDataListApiData} handleBack={handleBack} handleReset={handleReset} />}
                    {resumeId === "2" && <FinalizeResume2 findResumeData={CvBuilderBasicDetailsDataListApiData} handleBack={handleBack} handleReset={handleReset} />}
                    {resumeId === "3" && <FinalizeResume3 findResumeData={CvBuilderBasicDetailsDataListApiData} handleBack={handleBack} handleReset={handleReset} />}
                    {resumeId === "4" && <FinalizeResume4 findResumeData={CvBuilderBasicDetailsDataListApiData} handleBack={handleBack} handleReset={handleReset} />}
                    {resumeId === "5" && <FinalizeResume5 findResumeData={CvBuilderBasicDetailsDataListApiData} handleBack={handleBack} handleReset={handleReset} />}
                </>
            )
            ,
        },
    ];




    if (loadingCvBuilderBasicDetailsDataListApi) {
        return <div className="overlay-form-submission"><Loader /></div>;
    }

    if (CvBuilderBasicDetailsDataListApiError) {
        return <Error />;
    }

    return (
        <>
            <AfterNavbar />
            <div className="container">
                <div className='custom-resume-stepper' >
                    <Stepper activeStep={activeStep} orientation="vertical" sx={{ width: '200px' }}>
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >{step.label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div className='overflow-auto' style={{ marginLeft: '20px', flexGrow: 1, border: "1px solid #c7c7c7", background: "#fff" }}>


                        {steps[activeStep] && (
                            <Paper className='h-100 d-flex flex-col justify-content-between' square elevation={0} sx={{ p: 3 }}>
                                <div>
                                    <div className='d-flex custom-stepper-1 justify-content-between align-items-center mb-5'>
                                        <Typography variant="h6" className=' font-semibold'>{steps[activeStep].label}</Typography>
                                        <div>

                                            {CvBuilderBasicDetailsDataListApiData && CvBuilderBasicDetailsDataListApiData?.first_name && <button className="btn-outline-primary" data-bs-toggle="modal" data-bs-target="#changeCVBuilderTemplate"
                                                style={{
                                                    padding: "5px 20px",
                                                    borderRadius: "40px",
                                                    border: "1px solid #1B375C",
                                                    background: "#FFF",
                                                    textDecoration: "none",
                                                    color: "#1B375C",
                                                    textAlign: "center",
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                }}
                                            >
                                                Change Template
                                            </button>}
                                            <div className="modal fade" id="changeCVBuilderTemplate" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="changeCVBuilderTemplateLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered modal-xl">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="changeCVBuilderTemplateLabel">Select a template</h5>
                                                            <button type="button" className="btn-close" id="changeCVBuilderTemplateCloseBtn" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 mb-2">
                                                                    <div className="custm-bud-img-cv-builder" onClick={() => handleSetTemple(1)}>
                                                                        <img src="./assets/build-resume/karlatoon_cv1.png" className={localStorage.getItem("karlatoon_resume_template") == 1 ? "selected-cv img-fluid" : "not-selected img-fluid"} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 mb-2">
                                                                    <div className="custm-bud-img-cv-builder" onClick={() => handleSetTemple(2)}>
                                                                        <img src="./assets/build-resume/karlatoon_cv2.png" className={localStorage.getItem("karlatoon_resume_template") == 2 ? "selected-cv img-fluid" : "not-selected img-fluid"} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 mb-2">
                                                                    <div className="custm-bud-img-cv-builder" onClick={() => handleSetTemple(3)}>
                                                                        <img src="./assets/build-resume/karlatoon_cv3.png" className={localStorage.getItem("karlatoon_resume_template") == 3 ? "selected-cv img-fluid" : "not-selected img-fluid"} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 mb-2">
                                                                    <div className="custm-bud-img-cv-builder" onClick={() => handleSetTemple(4)}>
                                                                        <img src="./assets/build-resume/karlatoon_cv4.png" className={localStorage.getItem("karlatoon_resume_template") == 4 ? "selected-cv img-fluid" : "not-selected img-fluid"} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 mb-2">
                                                                    <div className="custm-bud-img-cv-builder" onClick={() => handleSetTemple(5)}>
                                                                        <img src="./assets/build-resume/karlatoon_cv5.png" className={localStorage.getItem("karlatoon_resume_template") == 5 ? "selected-cv img-fluid" : "not-selected img-fluid"} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                               
                                                <button
                                                    type="button"
                                                    id="karlatoon_resume_id_1"
                                                    className="btn btn-primary btn-sm"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#preview-1"
                                                    style={{ display: "none" }}
                                                >
                                                    Preview
                                                </button>
                                            }
                                            {
                                               
                                                <button
                                                    type="button"
                                                    id="karlatoon_resume_id_2"
                                                    className="btn btn-primary btn-sm"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#preview-2"
                                                    style={{ display: "none" }}
                                                >
                                                    Preview
                                                </button>
                                            }
                                            {
                                              
                                                <button
                                                    type="button"
                                                    id="karlatoon_resume_id_3"
                                                    className="btn btn-primary btn-sm"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#preview-3"
                                                    style={{ display: "none" }}
                                                >
                                                    Preview
                                                </button>
                                            }
                                            {
                                              
                                                <button
                                                    type="button"
                                                    id="karlatoon_resume_id_4"
                                                    className="btn btn-primary btn-sm"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#preview-4"
                                                    style={{ display: "none" }}
                                                >
                                                    Preview
                                                </button>
                                            }
                                            {
                                               
                                                <button
                                                    type="button"
                                                    id="karlatoon_resume_id_5"
                                                    className="btn btn-primary btn-sm"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#preview-5"
                                                    style={{ display: "none" }}
                                                >
                                                    Preview
                                                </button>
                                            }
                                        </div>
                                    </div>

                                    <div>{steps[activeStep].content}</div>

                                </div>
                               
                            </Paper>
                        )}

                        {activeStep === steps.length && (
                            <Paper square elevation={0} sx={{ p: 3 }}>
                                <Typography>All steps completed - you&apos;re finished</Typography>
                                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                    Restart
                                </Button>
                            </Paper>
                        )}
                    </div>
                </div>
            </div >
            <Footer />




            <ResumeModal preview={"preview-1"} findResumeData={CvBuilderBasicDetailsDataListApiData} />
            <ResumeModal2 preview={"preview-2"} findResumeData={CvBuilderBasicDetailsDataListApiData} />
            <ResumeModal3 preview={"preview-3"} findResumeData={CvBuilderBasicDetailsDataListApiData} />
            <ResumeModal4 preview={"preview-4"} findResumeData={CvBuilderBasicDetailsDataListApiData} />
            <ResumeModal5 preview={"preview-5"} findResumeData={CvBuilderBasicDetailsDataListApiData} />



        </>
    )
}

export default ResumeForm