import React from 'react';
import "./scss/Chat.css";
import { createImageFromInitials, getRandomColor } from '../../Utils';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
const host = process.env.REACT_APP_API_SERVER;
const ChatContent = ({ data, refresh }) => {
    const navigate = useNavigate();
    const OpenChat = (id) => {
        try {
            let values = { recruiter_id: id };
            let config = { headers: { "auth-token": localStorage.getItem("seeker_token") } }
            axios.post(`${host}/chat/change-chat-status-seeker`, values, config)
                .then(response => {
                    if (response.status === 200) { navigate(`/read-chat`, { state: { company_id: id } });
                    } else {toast.error(response.data.msg); }
                }).catch(error => {console.log(error.message);toast.error("Internal server error.");})
        } catch (error) {console.log(error.message); toast.error("Internal server error."); }        
    };
    return (
        <div className="container seeker-chat-container">
            <div className="row my-2">
                <div className="col-md-6"><h1>Karlatoon Chat</h1></div>
                <div className="col-md-6 d-flex justify-content-end align-items-center"><button className="btn btn-outline-dark" onClick={refresh}><i className="fa-solid fa-arrows-rotate"></i></button></div>
            </div>
            <div className="row seeker-chat-list ms-1">
                <ul className="list-group list-group-numbered mb-2 list-bef-none">
                    {data && data?.length > 0 ? data.map((chat, index) => {
                        return <li className="list-group-item d-flex justify-content-between align-items-start seeker-chat-list-item" onClick={() => { OpenChat(chat?.company_details?.id) }} key={index + 1}>
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    <div className="d-flex">
                                        <img className='seeker-chat-list-company-logo img-fluid' src={`${host}/uploaded-files/${chat?.company_details?.logo}`}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = createImageFromInitials(500, `${chat?.company_details?.company_name}`, getRandomColor());
                                            }}
                                        />
                                        <div className="chat-username-msg">
                                            {chat?.company_details?.company_name}
                                            <span className='chat-message-line-clamp-1'>{chat?.lastMessage?.message}</span>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            {chat?.unread_count > 0 && <span className="badge bg-primary rounded-pill">{chat?.unread_count}</span>}
                        </li>
                    }) :
                        <div className="text-center mb-2">
                            <i>No messages found.</i>
                        </div>
                    }
                </ul>

            </div>
        </div>
    )
}

export default ChatContent