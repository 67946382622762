import React, { useState } from 'react'
import './scss/AssessmentScore.css'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import Loader from '../../../Loader';
import { SemiCircleProgress } from 'react-semicircle-progressbar';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER;
const AssessmentScoreContent = ({ job_id, passed_status, result }) => {
    const percentage = (result?.score / result?.sumscore) * 100;
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const handleClick = () => {
        if (passed_status === true) {
            navigate(`/cv-for-employer?j=${job_id}`, { state: { result: result } })
        } else {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            document.body.style.overflow = "hidden";
            setShowLoader(true);
            try {
                const job_id_value = {job_slug: job_id,total_questions_count: result.correctAnswers + result.wrongAnswers + result.notattemptedAnswers,correct_count: result.correctAnswers,correct_questions: JSON.stringify(result.correct_attempted),wrong_count: result.wrongAnswers,wrong_questions: JSON.stringify(result.wrong_attempted),unattempted_count: result.notattemptedAnswers,unattempted_questions: JSON.stringify(result.not_attempted), total_marks: result.sumscore,obtained_marks: result.score,
                }
                let config = {
                    headers: {
                        "content-type": "application/json",
                        "auth-token": localStorage.getItem("seeker_token")
                    }
                }
                axios.post(`${host}/mobile/apply-job-with-assessment-failed-slug`, job_id_value, config)
                    .then((response) => {
                        if (response.status === 200) {document.body.style.overflow = 'auto';setShowLoader(false);toast.dismiss(loadingToastId);navigate(`/career-start-search`)
                        } else {
                            document.body.style.overflow = 'auto';setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response?.data?.msg });
                            }
                    }).catch((error) => {
                        document.body.style.overflow = 'auto';
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error." });
                      })
            } catch (error) {
                document.body.style.overflow = 'auto';
                setShowLoader(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error." });
              }
        }
    }
    return (
        <>
            <section className='assessment-score-sec'>
                <div className="container my-5">
                    <div className="boxshadow_head">
                        <div className="row m-lg-3 m-md-3 m-0">
                            <div className="col-md">
                                <div className="row m-1">
                                    <div className="col-md">
                                        <div>
                                        
                                        </div>
                                        <div className="text-center">
                                           
                                            <SemiCircleProgress
                                                bgStrokeColor="#e9e9e9"
                                                percentage={Math.floor(percentage)}
                                                size={{
                                                    width: 200,
                                                    height: 200,
                                                }}
                                                hasBackground={true}
                                                strokeWidth={7}
                                                strokeColor="#1b375c"
                                            
                                            />
                                            {passed_status === true ? <h3 className="heading_color mt-0">
                                                You are eligible to apply for this job
                                            </h3> : <h3 className="heading_color_fail mt-0">
                                                You are not eligible to apply for this job
                                            </h3>}
                                        </div>
                                       
                                    </div>
                                </div>
                                <div className="boxshadow_inner">
                                    <div className="row m-4">
                                       
                                        <h3 className='text-center'>{passed_status === true ? "Congratulations! You're one step closer to your goals." : "Your efforts were commendable, but you missed by a step. Keep pushing forward, success is waiting for you."}</h3>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                      
                    </div>
                    <div className="row m-lg-5 m-md-5 m-2">
                        <div className="col text-center">
                           
                            <button
                                type="button"
                                className="my-1 btn rounded-pill button_width1 text-white"
                                onClick={handleClick}
                            >
                                Continue
                            </button>
                           
                        </div>
                    </div>
                </div>

            </section>
            {showLoader && <div className="overlay-form-submission">
                <Loader />
            </div>}
        </>
    )
}

export default AssessmentScoreContent