import React, { useEffect, useState } from 'react'
import './scss/HiredCard.css'
import { Link, useNavigate } from 'react-router-dom'
import { getHiredJobsData } from '../../../../../../Redux/action/SeekerAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../Loader';
import JobCard from '../JobCard/JobCard';

const HiredCard = ({ jobs }) => {
   
    return (
        <>
           
            <JobCard jobs={jobs} />
        </>
    )
}

export default HiredCard