import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import { FocusError } from 'focus-formik-error';
import { toast } from 'react-toastify';
import '../CvBuilder/scss/cv.css';
import '../CvBuilder/scss/cvresponsive.css';
import { Link, useNavigate } from 'react-router-dom';
import RegisterHeader from './CvBuilderHeader/RegisterHeader';
import Footer from '../Commons/Footer';
import Steps from './Steps';
import { useDispatch, useSelector } from 'react-redux';
import { getGenderData, getMaritalData, getExperienceLevelData, getDesignationData, getJobRoleData, getlanguageKnownData, getCountryData, getCityStateData, getSeekerData, getStatebyCountryData, getCitybyStateData, getSeekerPersonalDetailsData } from '../../../Redux/action/SeekerAction';
import { style } from '@mui/system';
import Loader from '../Loader';
import Select from 'react-select'
import HourglassLoader from '../HourglassLoader';
import Steps2 from './Steps2';
let host = process.env.REACT_APP_API_SERVER;
const PersonalDetail2 = () => {
    const [isEditing, setIsEditing] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [displayLanguage, setDisplayLanguage] = useState("")
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {
            setIsLoggedIn(true);
            let authToken = localStorage.getItem("seeker_token");

            dispatch(getSeekerPersonalDetailsData(authToken));

        } else {
            toast.error("Please register to update information.")
            navigate("/seeker-register")
        }
        dispatch(getGenderData());
        dispatch(getMaritalData());
        dispatch(getExperienceLevelData());
        dispatch(getDesignationData());
        dispatch(getJobRoleData());
        dispatch(getlanguageKnownData());
        dispatch(getCountryData());
        dispatch(getCityStateData());
    }, []);

    const handleChangeState = (country_id) => {

        dispatch(getStatebyCountryData(country_id));
    }
    const handleChangeCity = (state_id) => {

        dispatch(getCitybyStateData(state_id))

    }
    const citybystateData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.citybystateDataList?.data) : "")
    const statebycountryData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.statebycountryDataList?.data) : "");

    const seekerRegistrationData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerPersonalDetailsDataList?.data) : "");

    const genderData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.genderDataList?.data) : "");
    const maritalData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.maritalDataList?.data) : "");
    const experienceLevelData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.experienceLevelDataList?.data) : "");
    const designationData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.designationDataList?.data) : "");
    const jobRoleData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.jobRoleDataList.data) : "");
    const languageKnownData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.languageKnownList.data) : "");
    const countryData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.countryDataList.data) : '');
    const cityStateData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.cityStateDataList.data) : '');
    const hasResume = seekerRegistrationData?.[0]?.resume;
    let hasResumeValue;
    if (hasResume == null) {
        hasResumeValue = false;

    } else {
        hasResumeValue = true;
    }
       let seeker_language_arr = [];
    const stepButtons = document.querySelectorAll('.step-button');
    const progress = document.querySelector('#progress');

    Array.from(stepButtons).forEach((button, index) => {
        button.addEventListener('click', () => {
            progress.setAttribute('value', index * 100 / (stepButtons?.length - 1));

            stepButtons.forEach((item, secindex) => {
                if (index > secindex) {
                    item.classList.add('done');
                }
                if (index < secindex) {
                    item.classList.remove('done');
                }
            })
        })
    })
    const languageOptions = [];
    languageKnownData?.map(language => {
        let key = language.id;
        languageOptions.push({ value: language.id, label: language.languagename })
    });
    let [initialValues, setInitialValues] = useState({
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
        password: "",
        confirmpassword: "",
        dob: "",
        gender: "",
        marital_status: "",
        experience_level: "",
        current_designation: "",
        experience_years: "",
        experience_months: "",
        video_profile: "",
        industry: "",
        typeofjob: "",
        jobrole: "",
        language: "",
        address: "",
        country: "",
        state: "",
        city: "",
        postal_code: "",
        resume: "",
        profile_img: "",

    });
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            firstname: Yup.string().required("Please enter first name."),

            dob: Yup.date()
                .max(new Date(), 'Date of birth cannot be in the future')
                .test('is-at-least-18', 'Must be at least 18 years old', function (value) {
                    const today = new Date();
                    const userBirthDate = new Date(value);
                    const age = today.getFullYear() - userBirthDate.getFullYear();
                    return age >= 18;
                })
                .required('Please select date of birth.'),
            gender: Yup.string().required("Please select gender."),

            experience_level: Yup.string().required("Please select experience level."),
            current_designation: Yup.string().required("Please select current designation"),
            experience_years: Yup.string().required("Please select experience in years."),
            experience_months: Yup.string().required("Please select experience in months."),
            language: Yup.array()
                .min(1, 'Please select language')
                .required('Please select language'),
            address: Yup.string().required("Please enter address."),
            country: Yup.string().required("Please select country."),
            state: Yup.string().required("Please select province."),
            city: Yup.string().required("Please select city."),
            postal_code: Yup.string()
                .matches(/^\d{4,6}$/, 'Enter a valid postal code.')
                .required('Please enter postal code.'),
           
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            const loadingToastId = toast.info('Submitting form...', { autoClose: false });

            try {
                var allowed_video_extensions = ["mp4", "webm", "ogg"];

                setShowLoader(true);
                setDisableBtn(true);
              
                if (((values?.resume?.size) / (1024 * 1024)) > 6) {
                    setShowLoader(false);
                    setDisableBtn(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "File size should be less than 6 MB." });
                } else if (values?.video_profile && !allowed_video_extensions.includes(values?.video_profile?.name?.split('.').pop().toLowerCase())) {
                    setShowLoader(false);
                    setDisableBtn(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Please enter a valid file. (.mp4,.webm,.ogg allowed.)" });
                } else if (values?.video_profile && ((values.video_profile.size) / (1024 * 1024) > 50)) {
                    setShowLoader(false);
                    setDisableBtn(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Video profile file size should be less than 50 MB." });
                }
                else {
                    document.body.style.overflow = 'hidden';

                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data',
                            "auth-token": localStorage.getItem("seeker_token")
                        }
                    };
                    await axios.post(`${host}/mobile/add-personal-details`, values, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false);
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Personal details updated successfully!' });
                                navigate('/educational-details');
                            } else {

                                setSubmitting(false);
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        })
                        .catch(function (error) {

                            document.body.style.overflow = 'auto';
                            setSubmitting(false);
                            setShowLoader(false);
                            setDisableBtn(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                        })
                    setIsEditing(false);
                }
            } catch (error) {

                document.body.style.overflow = 'auto';
                console.log(error.message);
                setSubmitting(false);
                setShowLoader(false);
                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    });



    useEffect(() => {

        if (seekerRegistrationData) {
            let seeker_language_arr = [];
            let showLanguages = []
            seekerRegistrationData?.[0].seeker_language && seekerRegistrationData?.[0].seeker_language?.map((lang) => {
              seeker_language_arr.push(lang.language_id);
                showLanguages.push(lang.language.languagename);
            });

            setDisplayLanguage(showLanguages.toString())
            formik.setValues({
                firstname: seekerRegistrationData?.[0]?.firstName ?? "",
                lastname: seekerRegistrationData?.[0]?.lastName ?? "",
                mobile: seekerRegistrationData?.[0]?.mobile ?? "",
                email: seekerRegistrationData?.[0]?.email ?? "",
                password: seekerRegistrationData?.[0]?.password ?? "",
                confirmpassword: seekerRegistrationData?.[0]?.confirmpassword ?? "",
                dob: seekerRegistrationData?.[0]?.dob ?? "",
                gender: seekerRegistrationData?.[0]?.gender ?? "",
                marital_status: seekerRegistrationData?.[0]?.marital_status ?? "",
                experience_level: seekerRegistrationData?.[0]?.experience_level ?? "",
                current_designation: seekerRegistrationData?.[0]?.current_designation ?? "",
                experience_years: seekerRegistrationData?.[0]?.experience_years ?? "",
                experience_months: seekerRegistrationData?.[0]?.experience_months ?? "",
                industry: seekerRegistrationData?.[0]?.industry ?? "",
                typeofjob: seekerRegistrationData?.[0]?.typeofjob ?? "",
                jobrole: seekerRegistrationData?.[0]?.jobrole ?? "",
                language: seekerRegistrationData?.[0]?.seeker_language ? seekerRegistrationData?.[0]?.seeker_language?.map(lang => Number(lang.language_id)) : [],
               
                address: seekerRegistrationData?.[0]?.address ?? "",
                country: seekerRegistrationData?.[0]?.country ?? "",
                city: seekerRegistrationData?.[0]?.city ?? "",
                state: seekerRegistrationData?.[0]?.state ?? "",
                postal_code: seekerRegistrationData?.[0]?.postal_code ?? "",
                profile_img: "",
                video_profile: ""
            });

            handleChangeCity(seekerRegistrationData?.[0]?.state);
            handleChangeState(seekerRegistrationData?.[0]?.country);

        }
    }, [seekerRegistrationData]);




    const handleEditClick = () => {
        setIsEditing(true);

        if (seekerRegistrationData) {

            formik.setValues({
                firstname: seekerRegistrationData?.[0]?.firstName ?? "",
                lastname: seekerRegistrationData?.[0]?.lastName ?? "",
                mobile: seekerRegistrationData?.[0]?.mobile ?? "",
                email: seekerRegistrationData?.[0]?.email ?? "",
                password: seekerRegistrationData?.[0]?.password ?? "",
                confirmpassword: seekerRegistrationData?.[0]?.confirmpassword ?? "",
                dob: seekerRegistrationData?.[0]?.dob ?? "",
                gender: seekerRegistrationData?.[0]?.gender ?? "",
                marital_status: seekerRegistrationData?.[0]?.marital_status ?? "",
                experience_level: seekerRegistrationData?.[0]?.experience_level ?? "",
                current_designation: seekerRegistrationData?.[0]?.current_designation ?? "",
                experience_years: seekerRegistrationData?.[0]?.experience_years ?? "",
                experience_months: seekerRegistrationData?.[0]?.experience_months ?? "",
                industry: seekerRegistrationData?.[0]?.industry ?? "",
                typeofjob: seekerRegistrationData?.[0]?.typeofjob ?? "",
                jobrole: seekerRegistrationData?.[0]?.jobrole ?? "",
                language: seekerRegistrationData?.[0]?.seeker_language ? seekerRegistrationData?.[0]?.seeker_language?.map(lang => Number(lang.language_id)) : [],
                address: seekerRegistrationData?.[0]?.address ?? "",
                country: seekerRegistrationData?.[0]?.country ?? "",
                city: seekerRegistrationData?.[0]?.city ?? "",
                state: seekerRegistrationData?.[0]?.state ?? "",
                postal_code: seekerRegistrationData?.[0]?.postal_code ?? "",
                profile_img: "",
                video_profile: ""
            });
        }
        handleChangeCity(seekerRegistrationData?.[0]?.state);
        handleChangeState(seekerRegistrationData?.[0]?.country);
    };

    const [avatar, setAvatar] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setAvatar(file);
    };
    const [videoname, setVideoName] = useState("");
    const handleVideoChange = (event) => {
        const file = event.target.files[0];
        setVideoName(file.name);
    };
    const videoId = document.getElementById("myVideoPlayer");
    function pauseVideo() {
        if (seekerRegistrationData && seekerRegistrationData?.[0] && seekerRegistrationData?.[0]?.video_profile) {
            videoId.pause();
            videoId.currentTime = 0;
        }
    }


    const handleVideoError = () => {
        alert("There is a problem in loading this video.")
    }

    const closeModal = () => {
        let closeBtn = document.getElementById("cross-close");
        if (closeBtn) {
            closeBtn.click();
        }
    }

    return (

        <>
            <RegisterHeader />
            <section className='personal-detail'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-2">
                                <Steps2 activeStepCount={0} />
                            </div>
                        </div>
                        <div className="col-lg-12 my-lg-0 my-md-0 my-2">
                            {seekerRegistrationData && seekerRegistrationData.length > 0 ? <div className="form-area">
                                <div className="row justify-content-md-center justify-content-center text-center">
                                  
                                    <div className="col-lg-12 col-md-12 avatar">
                                        <input
                                            type='file'
                                            accept='image/*'
                                            onChange={(e) => {
                                                handleFileChange(e);
                                                formik.setFieldValue("profile_img", e.target.files[0])
                                            }}
                                            style={{ display: 'none' }}
                                            id='avatarInput'
                                            name='profile_img'

                                            onBlur={formik.handleBlur}
                                       
                                        />
                                        <label htmlFor='avatarInput'>
                                            {(seekerRegistrationData && <img
                                                src={avatar
                                                    ? URL.createObjectURL(avatar)
                                                    : (seekerRegistrationData[0]?.profile_img
                                                        ? `${host}/uploaded-files/${seekerRegistrationData[0].profile_img}`
                                                        : "assets/images/cv-builder/avatar.png"
                                                    )}
                                                alt="Avatar"
                                                style={{ cursor: 'pointer', borderRadius: '50%', objectFit: 'cover' }}
                                                width={200} height={200}
                                            />)}
                                        </label>

                                    </div>
                                    <div className="col-lg-9 col-md-9"></div>
                                    <div className="col-lg-3 col-md-3 profile-edit-btn justify-content-center text-center">
                                        <div className="vodeo-btn d-flex justify-content-md-end">
                                            <Link href={undefined} data-bs-toggle="modal" data-bs-target="#create-video-profile" >Upload Video Profile</Link>
                                            {/* ========================video modal start======================= */}
                                            <div className="modal fade" id="create-video-profile" tabIndex={-1} aria-labelledby="exampleModalLabel"
                                                aria-hidden="true" data-bs-backdrop='static' >
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="exampleModalLabel">
                                                                Video profile
                                                            </h5>
                                                            <button type="button" className="btn-close" id="cross-close" data-bs-dismiss="modal" aria-label="Close" onClick={pauseVideo} />
                                                        </div>
                                                        <div className="modal-body personal-page-modal-body">
                                                            {seekerRegistrationData?.[0] && seekerRegistrationData?.[0]?.video_profile && <div className="popup-video-play">
                                                                <div className='player-wrapper'>
                                                                    <video controls id='myVideoPlayer'
                                                                        onError={handleVideoError}
                                                                    >
                                                                        <source src={`${host}/uploaded-files/${seekerRegistrationData[0].video_profile}`} type="video/mp4" />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                </div>
                                                            </div>}
                                                            <div>
                                                                <b>Note:</b><span>The video will be uploaded as you submit Personal details.</span>
                                                            </div>
                                                            {/* ================= video upload start================ */}
                                                            <div className="file-name-custom-dv">
                                                                <span>
                                                                    {videoname}
                                                                </span>
                                                            </div>
                                                            <div className='d-flex justify-content-between pt-3'>
                                                                <div>
                                                                    <input type='file' accept='video/*,image/gif' name='video_profile'
                                                                        onChange={(e) => {
                                                                            handleVideoChange(e);
                                                                           
                                                                            formik.setFieldValue("video_profile", e.target.files[0])
                                                                        }}
                                                                        onBlur={formik.handleBlur}
                                                                        
                                                                        style={{ display: 'none' }}
                                                                        id='videoInput' />
                                                                    <label htmlFor='videoInput'>
                                                                        <button className='btn btn-outline-primary upload-with-icon' type='button' onClick={() => document.getElementById('videoInput').click()} >
                                                                            <i className="fa-solid fa-upload"></i> {seekerRegistrationData?.[0]?.video_profile ? "Upload another video" : "Upload Video"}
                                                                        </button>
                                                                    </label>
                                                                </div>
                                                                {videoname && <button type="button" className="btn save-btn-cstm" onClick={closeModal}> +Upload </button>}
                                                            </div>
                                                            <div className="help-block with-errors">
                                                                {formik.touched.video_profile && formik.errors.video_profile ? (
                                                                    <div className='text-danger'>{formik.errors.video_profile}</div>
                                                                ) : null}
                                                            </div>
                                                            {/* ================= video upload end================= */}

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {/* ========================video modal end======================= */}
                                        </div>
                                    </div>
                                </div>
                                <form method='post' onSubmit={formik.handleSubmit} encType='multipart/form-data' className='mt-5'>
                                    <FocusError formik={formik} />
                                    <div className="row">
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>First Name<span>*</span></label>
                                            {isEditing ? (
                                                <input
                                                    type="text"
                                                    name='firstname'
                                                    className='form-control'
                                                    placeholder='Enter first name'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.firstname}
                                               
                                                />
                                            ) : (
                                                <div style={inputBoxStyle}>{formik.values.firstname}</div>
                                            )}
                                            <div className="help-block with-errors">
                                                {formik.touched.firstname && formik.errors.firstname ? (
                                                    <div className='text-danger'>{formik.errors.firstname}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Last Name</label>
                                            {isEditing ? (
                                                <input
                                                    type="text"
                                                    name='lastname'
                                                    className='form-control'
                                                    placeholder='Enter last name'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.lastname}
                                                // style={{ color: "rgba(102, 102, 102, 0.6)" }}
                                                />
                                            ) : (
                                                <div style={inputBoxStyle}>{formik.values.lastname}</div>
                                            )}
                                        </div>
                                        {seekerRegistrationData?.[0]?.mobile &&
                                            <div className="col-md-6 mb-3 d-grid cus-verify">
                                                <label htmlFor="" className='mb-2'>Mobile Number</label>
                                              
                                                <div className='d-flex'>
                                                    <div className='col-12' style={inputBoxStyle}>{formik.values.mobile}</div>

                                                </div>
                                               
                                            </div>}
                                      
                                        <div className="col-md-6 mb-3 d-grid cus-verify">
                                            <label htmlFor="" className='mb-2'>Email<span>*</span></label>
                                          
                                            <div className='d-flex'>
                                                <div className='col-12' style={inputBoxStyle}>{formik.values.email}</div>

                                            </div>
                                          
                                        </div>


                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>D.O.B.<span>*</span></label>
                                            {isEditing ? (
                                                <input
                                                    type="date"
                                                    name='dob'
                                                    max={new Date().toISOString()?.split('T')[0]}
                                                    className='form-control'
                                                    placeholder='Enter your Date of birth'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.dob}
                                              
                                                />
                                            ) : (
                                                <div style={inputBoxStyle}>{formik.values.dob}</div>
                                            )}
                                            <div className="help-block with-errors">
                                                {formik.touched.dob && formik.errors.dob ? (
                                                    <div className='text-danger'>{formik.errors.dob}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Gender<span>*</span></label>
                                            {isEditing ? (
                                                <select
                                                    className="form-control cus-titlesal form-select w-100 form-custom text-dark"
                                                    id="gender"
                                                    placeholder="Select your gender"
                                                    name="gender"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.gender}

                                                >
                                                    <option value="" className='text-dark'>Gender</option>
                                                    {genderData?.map((gender) => {
                                                        let key = gender?.id;
                                                        return (
                                                            <option key={key} value={gender?.id} className='text-dark'>
                                                                {gender?.gender}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            ) : (
                                                <div style={inputBoxStyle}>
                                                    {seekerRegistrationData?.[0]?.Gender ? seekerRegistrationData?.[0]?.Gender.gender : ""}
                                                </div>
                                            )}
                                            <div className="help-block with-errors">
                                                {formik.touched.gender && formik.errors.gender ? (
                                                    <div className='text-danger'>{formik.errors.gender}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Experience Level<span>*</span></label>
                                            {isEditing ? (
                                                <select
                                                    className="form-control cus-titlesal form-select w-100 form-custom text-dark"
                                                    id="City3"
                                                    placeholder="Select your experience level"
                                                    name="experience_level"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.experience_level}
                                                >
                                                    <option value="" >Select Experience Level</option>
                                                    {experienceLevelData?.map((experience_level) => {
                                                        let key = experience_level?.id;
                                                        return (
                                                            <option key={key} value={experience_level.id} >
                                                                {experience_level?.experience_level}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            ) : (
                                                <div style={inputBoxStyle}>
                                                    {seekerRegistrationData?.[0]?.ExperienceLevel?.experience_level}
                                                </div>
                                            )}

                                            <div className="help-block with-errors">
                                                {formik.touched.experience_level && formik.errors.experience_level ? (
                                                    <div className='text-danger'>{formik.errors.experience_level}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Current designation<span>*</span></label>
                                            {isEditing ? (
                                                <select className="form-control cus-titlesal form-select w-100 form-custom text-dark" id="City4" placeholder="city" name="current_designation"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.current_designation}>
                                                    <option value="">Select Current Designation</option>
                                                    {designationData?.map(designation => {
                                                        let key = designation.id;
                                                        return <option key={key} value={designation.id}>{designation?.designation}</option>
                                                    })}
                                                </select>
                                            ) : (
                                                <div style={inputBoxStyle}>
                                                    {seekerRegistrationData?.[0]?.designation?.designation}
                                                </div>
                                            )}
                                            <div className="help-block with-errors">
                                                {formik.touched.current_designation && formik.errors.current_designation ? (
                                                    <div className='text-danger'>{formik.errors.current_designation}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-3  exp-input align-items-end">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label htmlFor="" className='mb-2'>Total Experience<span>*</span></label>
                                                </div>
                                                <div className="col-md-6 my-1">

                                                    {isEditing ? (
                                                        <select name="experience_years" className='form-control  form-select form-custom text-dark' id="experience_years"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.experience_years}>
                                                            <option value="">Years</option>
                                                            {[...Array(30)].map((_, index) => {
                                                                const value = index + 1;
                                                                return (
                                                                    <option key={value} value={value}>
                                                                        {value === 1 ? `${value} Year` : `${value} Years`}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    ) : (
                                                        <div style={inputBoxStyle}>
                                                            {formik.values.experience_years} years
                                                        </div>
                                                    )}
                                                    <div className="help-block with-errors">
                                                        {formik.touched.experience_years && formik.errors.experience_years ? (
                                                            <div className='text-danger'>{formik.errors.experience_years}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 my-1 ">
                                                    {isEditing ? (
                                                        <select name="experience_months" className='form-control  form-select form-custom text-dark' id="experience_months"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.experience_months}>
                                                            <option value="">Months</option>
                                                            {[...Array(12)].map((_, index) => {
                                                                const value = index + 1;
                                                                return (
                                                                    <option key={value} value={value}>
                                                                        {value === 1 ? `${value} Month` : `${value} Months`}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    ) : (
                                                        <div style={inputBoxStyle}>
                                                            {formik.values.experience_months} months
                                                        </div>
                                                    )}
                                                    <div className="help-block with-errors">
                                                        {formik.touched.experience_months && formik.errors.experience_months ? (
                                                            <div className='text-danger'>{formik.errors.experience_months}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Language Known<span>*</span></label>
                                            {isEditing ?
                                                <Select
                                                    id="language"
                                                    name="language"
                                                    placeholder="Select language"
                                                    options={languageOptions}
                                                    value={languageOptions.filter(option => formik.values.language.includes(option.value))}
                                                  
                                                    onChange={language => {
                                                        formik.setFieldValue(
                                                            'language',
                                                            language ? language.map(option => option.value) : []
                                                        );
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    isMulti
                                                />
                                                : (
                                                    <div style={inputBoxStyle}>
                                                      
                                                        {displayLanguage}
                                                    </div>
                                                )}
                                            <div className="help-block with-errors">
                                                {formik.touched.language && formik.errors.language ? (
                                                    <div className='text-danger'>{formik.errors.language}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Address<span>*</span></label>
                                            {isEditing ? (
                                                <input type="text" name='address' className='form-control text-dark' placeholder='Enter your Address'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.address}
                                               
                                                />
                                            ) : (
                                                <div style={inputBoxStyle}>
                                                    {formik.values.address}
                                                </div>
                                            )}
                                            <div className="help-block with-errors">
                                                {formik.touched.address && formik.errors.address ? (
                                                    <div className='text-danger'>{formik.errors.address}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Country<span>*</span></label>
                                            {isEditing ? (
                                                <select
                                                    className="form-control cus-titlesal form-select w-100 form-custom text-dark"
                                                    id="country"
                                                    placeholder="Select your country"
                                                    name="country"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);

                                                        handleChangeState(e.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.country}
                                                >
                                                    <option value="">Country </option>
                                                    {countryData?.map(country => {
                                                        return <option key={country.id} value={country.id}>{country?.name}</option>
                                                    })}
                                                </select>
                                            ) : (
                                                <div style={inputBoxStyle}>
                                                    {seekerRegistrationData?.[0]?.Country?.name}
                                                </div>
                                            )}
                                            <div className="help-block with-errors">
                                                {formik.touched.country && formik.errors.country ? (
                                                    <div className='text-danger'>{formik.errors.country}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Province<span>*</span></label>
                                            {isEditing ? (
                                                <select
                                                    className="form-control cus-titlesal form-select w-100 form-custom text-dark"
                                                    id="state"
                                                    placeholder="Select your province"
                                                    name="state"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);

                                                        handleChangeCity(e.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.state}
                                                >
                                                    <option value="">--Select a Province--</option>
                                                    {statebycountryData?.map(state => {
                                                        return <option key={state?.id} value={state?.id}>{state?.state_name}</option>
                                                    })}
                                                </select>
                                            ) : (
                                                <div style={inputBoxStyle}>
                                                    {seekerRegistrationData?.[0]?.State?.state_name}
                                                </div>
                                            )}
                                            <div className="help-block with-errors">
                                                {formik.touched.state && formik.errors.state ? (
                                                    <div className='text-danger'>{formik.errors.state}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>City<span>*</span></label>
                                            {isEditing ? (
                                                <select
                                                    className="form-control cus-titlesal form-select w-100 form-custom text-dark"
                                                    id="City8"
                                                    placeholder="Select your city"
                                                    name="city"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.city}
                                                >
                                                    <option value="">--Select a City--</option>
                                                    {citybystateData?.map(city => {
                                                        return <option key={city?.id} value={city?.id}>{city?.city_name}</option>
                                                    })}
                                                </select>
                                            ) : (
                                                <div style={inputBoxStyle}>
                                                    {seekerRegistrationData?.[0]?.City?.city_name}
                                                </div>
                                            )}
                                            <div className="help-block with-errors">
                                                {formik.touched.city && formik.errors.city ? (
                                                    <div className='text-danger'>{formik.errors.city}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 d-grid">
                                            <label htmlFor="" className='mb-2'>Postal Code<span>*</span></label>
                                            {isEditing ? (
                                                <input type="text" name='postal_code' className='form-control' placeholder='Enter your Postal code'
                                                    maxLength={6}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.postal_code}
                                               
                                                />
                                            ) : (
                                                <div style={inputBoxStyle}>
                                                    {formik.values.postal_code}
                                                </div>
                                            )}
                                            <div className="help-block with-errors">
                                                {formik.touched.postal_code && formik.errors.postal_code ? (
                                                    <div className='text-danger'>{formik.errors.postal_code}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className=" custm-btn-sec d-flex justify-content-center align-items-center">
                                            <div className='custm-botton-btn mb-2 '>
                                                <Link to='/educational-details' className='skip mx-2' >Skip</Link>
                                            </div>
                                            <div className='custm-botton-btn mb-2'>
                                                <button className=" next mx-2" type='submit' disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : "Submit & Next"}</button>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div> :
                                <div className="overlay-form-submission">
                                    <Loader />
                                </div>
                            }
                        </div>
                    </div>
                </div >
                {
                    showLoader && <div className="overlay-form-submission">
                        <Loader />
                    </div>
                }

                {/* ========================video modal start======================= */}
                
            </section >

            <Footer />
        </>
    )
}

const inputBoxStyle = {
    display: 'block',
    padding: '6px 12px', 
    border: '1px solid #dee2e6', 
    borderRadius: '4px',
    color: "rgba(102, 102, 102, 0.6)",
    height: "38px",

};


export default PersonalDetail2