import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useFormik } from "formik";
import { FocusError } from 'focus-formik-error';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { getFeaturesData, getContactUsData } from '../../../Redux/action/SeekerAction';
const host = process.env.REACT_APP_API_SERVER

const RecSearchCandidateSec = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getContactUsData())
        dispatch(getFeaturesData());

    }, []);


    const featurerecruiterData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.featuresDataList?.data?.[0]?.featurerecruiter?.[0]) : []);

    const sixTitle = featurerecruiterData?.section_six_heading ? featurerecruiterData?.section_six_heading : 'Search for candidates yourself with Karlatoon Resume'
    const sixSubtitle = featurerecruiterData?.section_six_sub_heading ? featurerecruiterData?.section_six_sub_heading : 'Easily filter resumes by the qualifications you want, then subscribe to contact the candidates you like with Indeed Resume.'

    const img = (featurerecruiterData?.section_six_img) ? `${host}/${featurerecruiterData?.section_six_img}` : './assets/images/matching-hiring/form.png'

    let [initialValues, setInitialValues] = useState({
        firstName: "",
        message: "",
        email: "",
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            firstName: Yup.string().required("Please enter name."),
            email: Yup.string().required("Please enter email.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid E-mail"),
            message: Yup.string().required("Please enter message."),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });

            try {

                setSubmitting(true);


                document.body.style.overflow = 'hidden';
                // return false;


                await axios.post(`${host}/recruiter/contactUs`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Form Submitted Succesfully" });

                        } else {

                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {

                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    })

            }
            catch (error) {

                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }

        }
    })


    return (
        <section className='rec-search-candidates-sec py-5'>
            <div className="container">
                {featurerecruiterData && <div className="row">
                    <div className="head-area mb-4 text-center">
                        <h2>{sixTitle}</h2>
                        <h4>{sixSubtitle}</h4>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="form-area">
                          
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <FocusError formik={formik} />
                                <div className='mb-3'>
                                    <label htmlFor="firstName" className='mb-2'>Name<span className='text-danger'>*</span></label>
                                    <input type="text" className='form-control  text-light' name="firstName"
                                        placeholder="Enter your Name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.firstName} />
                                    <div className="help-block with-errors">
                                        {formik.touched.firstName && formik.errors.firstName ? (
                                            <div className='text-danger'>{formik.errors.firstName}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor="email" className='mb-2'>Email<span className='text-danger'>*</span></label>
                                    <input type="text" className='form-control text-light' id="mail"
                                        name="email"
                                        placeholder="Enter your email address"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email} />

                                    <div className="help-block with-errors">
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className='text-danger'>{formik.errors.email}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor="" className='mb-2'>What can we help you with?<span className='text-danger'>*</span></label>
                                    <textarea id="message" className='form-control text-light' rows={3}
                                        placeholder="Type your message here"
                                        defaultValue={""}
                                        name='message'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.message} ></textarea>
                                    <div className="help-block with-errors">
                                        {formik.touched.message && formik.errors.message ? (
                                            <div className='text-danger'>{formik.errors.message}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <button className='login-btn' type='submit'>Submit</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12">
                        <div className="form-img text-end">
                            <img src={img} className='img-fluid' alt="" />
                        </div>
                    </div>
                </div>}
            </div>
        </section>
    )
}

export default RecSearchCandidateSec