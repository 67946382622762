import React from 'react'
import AfterNavbar from '../../../Commons/AfterLoginHeader/AfterNavbar'
import AssessmentAllQuestionTimeContent from './AssessmentAllQuestionTimeContent'
import Footer from '../../../Commons/Footer'

const AssessmentAllQuestionTime = () => {
    return (
        <>
            <AfterNavbar />
            <AssessmentAllQuestionTimeContent />
            <Footer />
        </>
    )
}
export default AssessmentAllQuestionTime