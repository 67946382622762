import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import './scss/SearchedResume.css'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { getResumeData } from '../../../../Redux/action/RecruiterAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Seeker/Loader';
import { createImageFromInitials, getRandomColor } from '../../../Utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCitiesData } from '../../../../Redux/action/MasterAction';
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
const host = process.env.REACT_APP_API_SERVER;

const SearchedResume = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [reload, setReload] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showData, setShowData] = useState(false);
    const [cities, setCities] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    const url = new URL(window.location.href);
    const URLhref = url.href;
    const queryParams = new URLSearchParams(url.search);
    const queryParamsObject = {};
    for (const [key, value] of queryParams) {
        queryParamsObject[key] = value;
    }
    const initialValues = {
        search_text: queryParamsObject["search_text"] ?? "",
        city_text: queryParamsObject["city_text"] ?? ""
    }

    useEffect(() => {
        dispatch(getCitiesData());
    }, [])

    const cityData = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.cityList : null);
    useEffect(() => {
        setReload(!reload);
    }, [URLhref]);
    useEffect(() => {
        if (cityData) {
            // console.log(cityData);
            setCities(cityData);
            setFilteredCities(cityData);
        }
    }, [cityData])
    useEffect(() => {
        let token = localStorage.getItem("recruiter_token");
        const query = window.location.search;
        dispatch(getResumeData(token, query));
    }, [reload]);

    const resumes = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.resumeDataList : null);

    useEffect(() => {
        if (Array.isArray(resumes)) {
            setShowLoader(false);
            setShowData(true);
            // console.log(resumes);
        } else {
            setShowLoader(true);
            setShowData(false);
        }
    }, [resumes])


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({}),
        onSubmit: values => {
            function removeEmptyStringValues(obj) {
                for (const key in obj) {
                    if (obj[key] === '') {
                        delete obj[key];
                    }
                }
                return obj;
            }
            const objectWithoutEmptyStrings = removeEmptyStringValues(values);
            let create_search_string = ""
            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                    create_search_string = create_search_string + key + "=" + objectWithoutEmptyStrings[key] + "&";
                }
            };

            navigate(`/searched-resumes?${create_search_string}`);
        }
    });

    const showProfile = (seeker_id) => {
        navigate(`/rec-candidate-profile?seeker_id=${seeker_id}`, { state: { seeker_id: seeker_id } })
    }
    const saveResume = async (seeker_id) => {
        const loadingToastId = toast.info('Saving...', { autoClose: false });
        try {
            setShowLoader(true);
            document.body.style.overflow = "hidden";
            let token = localStorage.getItem("recruiter_token");
            const config = { headers: { "auth-token": token } }
            const values = { seeker_id: seeker_id };
            await axios.post(`${host}/recruiter/save-candidate`, values, config)
                .then((response) => {
                    if (response.status == 200) {
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                        setShowLoader(false);
                    } else {

                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response.data.msg });
                        setShowLoader(false);
                    }
                }).catch((error) => {
                    console.log(error.message);
                    document.body.style.overflow = 'auto';
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
                    setShowLoader(false);
                })
        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = 'auto';
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error" + error.message });
            setShowLoader(false);
        }
    }

    const handleInputChange = event => {
        const inputValue = event.target.value.toLowerCase();
        formik.handleChange(event);

        if (inputValue === '' || inputValue?.length === 1) {
            setFilteredCities(cities);
        } else {
            const filtered = cities.filter(city =>
                city.city_name.toLowerCase().includes(inputValue)
            );
            setFilteredCities(filtered);
        }
        // console.log(filteredCities);
    };

    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const previousDate = new Date(timestamp);

        const timeDifference = currentDate - previousDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {
            return 'Just now';
        }
    };


    //React Paginate
    //pagination
    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 10;

    useEffect(() => {
        const endOffset = dataOffset + dataPerPage;
        setCurrentData(resumes.slice(dataOffset, endOffset));
        setPageCount(Math.ceil(resumes?.length / dataPerPage));
    }, [dataOffset, dataPerPage, resumes])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage) % resumes?.length;
        setDataOffset(newOffset);
    };
    const handleCopyClipboard = async (id) => {
        try {
            await navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}/rec-candidate-profile?seeker_id=${id}`);
            toast.success('Link copied to clipboard');
        } catch (error) {
            console.error('Unable to copy to clipboard', error);
            toast.error('Failed to copy link to clipboard');
        }
    }

    return (
        <>
            {!showLoader ?
                <section className="searched-resume-section">
                    <section className='project-search-section'>
                        <section className="search-resumes-section">
                            <div className="container">
                                <div className="search-resumes-bg">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="search-resumes-display">
                                                <div className="search-heading">
                                                    <h3>
                                                        Searched Resume
                                                    </h3>
                                                </div>
                                                <div>
                                                    {/* <Link to="/search-resumes" className="search-btn1 mx-2">Search Resume</Link> */}
                                                    <Link to="/saved-search" className="search-btn2 mx-2">Saved Candidates</Link>
                                                    <Link className="search-btn3 mx-2" to="/post-job">Post A Job</Link>
                                                </div>
                                            </div>
                                            <div>
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div className="mt-4">
                                                        <div className=" d-flex search-area">
                                                            <div className="custom-form-section">
                                                                <div className="search-custom">
                                                                    <img src="./assets/images/search-resume/MagnifyingGlass.png" className="img-fluid" alt="" />
                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-custom form-cus-one"
                                                                        placeholder="Job title, skill, company, or search"
                                                                        name="search_text"
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        value={formik.values.search_text}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <div className="search-custom">
                                                                        <img src="./assets/images/search-resume/MapPinLine .png" className="img-fluid" alt="" />
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-custom form-cus-two"
                                                                            name="city_text"
                                                                            // list="cities-list"
                                                                            autoComplete='off'
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.city_text}
                                                                            placeholder="Enter city name"
                                                                        />
                                                                    </div>
                                                                </div>
                                                              
                                                                <div>
                                                                    <div className='text-center'>
                                                                        <button type='submit' className="form-custom-btn mb-2">Search Resume</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                           
                                            <div className="tab-view-custom mt-4 d-flex justify-content-between align-items-center">
                                              
                                            </div>
                                            <div className="tab-content " id="myTabContent">
                                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                    <section className="mb-5">
                                                        {resumes?.length ? <div className="container">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    {currentData.map(resume => {
                                                                        return <div className="mt-4" key={resume?.id}>
                                                                            <div className="row  cust_row">
                                                                                <div className="col-lg-2 mt-2">
                                                                                    <div className="photo-cus text-center ">
                                                                                        <div onClick={() => { showProfile(resume?.id) }} style={{ cursor: "pointer" }} className='searched-resume-page-profile-img'>
                                                                                           
                                                                                            <img
                                                                                                className='img-fluid logo-img createimagefrominitials mt-2'
                                                                                                src={resume?.profile_img ? `${host}/uploaded-files/${resume?.profile_img}` : `${createImageFromInitials(500, `${resume?.firstName} ${resume?.lastName}`, getRandomColor())}`}
                                                                                                alt={resume?.firstName} title={`${resume?.firstName} ${resume?.lastName}`}
                                                                                                onError={({ currentTarget }) => {
                                                                                                    currentTarget.onerror = null;
                                                                                                    currentTarget.src = createImageFromInitials(500, `${resume?.firstName} ${resume?.lastName}`, getRandomColor());
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                                                            <div className="text-left">
                                                                                                <span onClick={() => { showProfile(resume?.id) }} style={{ cursor: "pointer" }}><h5>{resume.firstName} {resume.lastName}</h5></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-auto col-lg-8">
                                                                                            <div className="cust_up float-end">
                                                                                                <ul>
                                                                                                    {
                                                                                                        resume.mobile && <li className="list-inline-item">
                                                                                                            <Link to={`tel:${resume.mobile}`}>
                                                                                                                <img src="./assets/images/search-resume/Vector.png" alt="karlatoon_call_image" title={resume.mobile} />
                                                                                                            </Link>
                                                                                                        </li>
                                                                                                    }

                                                                                                    {
                                                                                                        resume.email && <li className="list-inline-item">
                                                                                                            <Link to={`mailto:${resume.email}`}>
                                                                                                                <img src="./assets/images/search-resume/email.png" alt="karlatoon_email_image" title={resume.email} />
                                                                                                            </Link>
                                                                                                        </li>
                                                                                                    }
                                                                                                    <li className="list-inline-item">
                                                                                                        <button className='shareprofilebtn' data-bs-toggle="modal" data-bs-target={`#sharebutton${resume?.id}`}><img src="./assets/images/search-resume/share.png" alt="karlatoon_share_image" title="Share Profile" /></button>
                                                                                                    </li>
                                                                                                    <div className="modal fade" id={`sharebutton${resume?.id}`} tabindex="-1" aria-labelledby="sharebuttonLabel" aria-hidden="true">
                                                                                                        <div className="modal-dialog">
                                                                                                            <div className="modal-content">
                                                                                                                <div className="modal-header">
                                                                                                                    <h5 className="modal-title" id="sharebuttonLabel">Share Profile</h5>
                                                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                                                </div>
                                                                                                                <div className="modal-body">
                                                                                                                    <div className="row">
                                                                                                                        <div className="social-media-parent col-md-3 text-center">
                                                                                                                            <img src="./assets/career-start/icon/share-link.png" alt="Copy link"
                                                                                                                                onClick={() => handleCopyClipboard(resume?.id)}
                                                                                                                                title='Copy link to clipboard' style={{ cursor: "pointer" }} />
                                                                                                                        </div>
                                                                                                                        <div className="social-media-parent col-md-3 text-center">
                                                                                                                            <LinkedinShareButton url={`${window.location.protocol}//${window.location.host}/rec-candidate-profile?seeker_id=${resume?.id}`} title="Share on LinkedIn">
                                                                                                                                <LinkedinIcon size={32} round={true} logoFillColor="white" />
                                                                                                                            </LinkedinShareButton>
                                                                                                                        </div>
                                                                                                                        <div className="social-media-parent col-md-3 text-center">
                                                                                                                            <FacebookShareButton url={`${window.location.protocol}//${window.location.host}/rec-candidate-profile?seeker_id=${resume?.id}`} title="Share on Facebook">
                                                                                                                                <FacebookIcon size={32} round={true} logoFillColor="white" />
                                                                                                                            </FacebookShareButton>
                                                                                                                        </div>
                                                                                                                        <div className="social-media-parent col-md-3 text-center">
                                                                                                                            <TwitterShareButton url={`${window.location.protocol}//${window.location.host}/rec-candidate-profile?seeker_id=${resume?.id}`} title="Share on X">
                                                                                                                                <TwitterIcon size={32} round={true} logoFillColor="white" />
                                                                                                                            </TwitterShareButton>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <li className="list-inline-item"><span style={{ cursor: "pointer" }} onClick={() => saveResume(resume?.id)}><strong>Save Resume</strong></span></li>
                                                                                                    
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col">
                                                                                            <div className="text-left">
                                                                                                <span className="cust_main">{resume?.designation && resume?.designation.designation}</span>
                                                                                              
                                                                                            </div>
                                                                                            <section className='project-search-sec'>
                                                                                                <ul className="text-left cust_lis">
                                                                                                    {resume?.City && <li className="list-inline-item "><img src="./assets/images/search-resume/mappinline.png" alt="" /> {resume?.City.city_name} {resume?.City?.state_id && `, ${resume?.City?.state_of_city?.state_name}`} </li>}
                                                                                                   <li className="list-inline-item "><img src="./assets/images/search-resume/calendarblank.png" alt="" /> {timeAgoCalculator(resume.updatedAt)}</li>
                                                                                                </ul>
                                                                                            </section>
                                                                                        </div>
                                                                                    </div>
                                                                                  
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    })}

                                                                </div>
                                                            </div>
                                                        </div> :
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div>
                                                                        <div className="row text-center">
                                                                            <i>No data found.</i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </section>
                                                </div>
                                               
                                                {resumes?.length > 10 && <div className='row justify-content-center'>
                                                    <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                                        <section className='pagination-section'>
                                                            <div className='container'>
                                                                <nav aria-label="Page navigation example" className='nav-class'>
                                                                    <ReactPaginate
                                                                        breakLabel="..."
                                                                        nextLabel=">>"
                                                                        onPageChange={handlePageClick}
                                                                        pageRangeDisplayed={5}
                                                                        pageCount={pageCount}
                                                                        previousLabel="<<"
                                                                        renderOnZeroPageCount={null}
                                                                        containerClassName='pagination'
                                                                        pageLinkClassName='page-num'
                                                                        previousLinkClassName='page-num'
                                                                        nextLinkClassName='page-num'
                                                                        activeClassName='active'
                                                                    />
                                                                </nav>
                                                            </div>
                                                        </section>

                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                        {/* <!-- ------------------------popup start here-------------------------- --> */}
                        
                        {/* <!-- ------------------------popup end here---------------------------- --> */}
                    </section>
                </section> :
                <div className="overlay-form-submission">
                    <Loader />
                </div>
            }
        </>
    )
}

export default SearchedResume