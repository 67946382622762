import React, { useEffect, useState } from 'react'
import './scss/JobCard.css'
import { Link, useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import parse from 'html-react-parser';
import { createImageFromInitials, getRandomColor } from '../../../../../Utils';
import { toast } from 'react-toastify';
const host = process.env.REACT_APP_API_SERVER;

const JobCard = ({ jobs }) => {
    const [currentJobs, setCurrentJobs] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [jobOffset, setJobOffset] = useState(0);
    const jobsPerPage = 5;
    const navigate = useNavigate();
    useEffect(() => {
        const endOffset = jobOffset + jobsPerPage;
        setCurrentJobs(jobs.slice(jobOffset, endOffset));
        setPageCount(Math.ceil(jobs?.length / jobsPerPage));
    }, [jobOffset, jobsPerPage, jobs])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * jobsPerPage) % jobs?.length;
        setJobOffset(newOffset);
    };

    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const previousDate = new Date(timestamp);

        const timeDifference = currentDate - previousDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {
            return 'Just now';
        }
    };
    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };

    const handleChat = (id) => {
        try {
            let seeker_token = localStorage.getItem("seeker_token");
            let recruiter_id = id;
            if (!seeker_token) {
                toast.error("Please login to continue.")
            } else if (!id) {
                toast.error("Company not found.")
            }else{
                navigate(`/read-chat`, { state: { company_id: id } });
            }
        } catch (error) {
            console.log(error.message);
            toast.error("Internal server error!")
        }
    }

    return (
        <>
            {
                jobs?.length > 0 ? currentJobs?.map((job) => {
                    return <section key={job?.job_id} className='applied-job-section'>
                        <div className='container'>
                            <div className='job-from-rec'>
                                <div className='right-logo-flex l'>
                                    <div className='c-logo '>
                                        <img className='img-fluid' src={job?.job_data?.Company_details?.logo ? `${host}/uploaded-files/${job?.job_data?.Company_details?.logo}` : `${createImageFromInitials(500, job?.job_data?.Company_details?.company_name, getRandomColor())}`} alt={job?.job_data?.Company_details?.company_name} title={job?.job_data?.Company_details?.company_name}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src = createImageFromInitials(500, job?.job_data?.Company_details?.company_name, getRandomColor());
                                            }}
                                        />
                                    </div>
                                    <div className='company-content col-md-10'>
                                        
                                        <p><Link className='remove-link-style' to={`/profile/${job?.job_data?.Company_details?.company_slug}/snap-shot`}>{job?.job_data?.Company_details?.company_name}</Link></p>
                                        <div className='post-cls'>
                                            <h4><Link to={`/job-find-description?j=${job?.job_data?.job_slug}`}>{job?.job_data?.job_title}</Link></h4>
                                            {job?.job_data?.is_assessment_attempted == true && <h6>Assessment Attempted </h6>}
                                        </div>
                                        <div className='icon-working-show'>
                                            <ul>
                                                {(job?.job_data?.job_city) && (Number(job?.job_data?.location_to_report) != false) && <li><img src='./assets/images/find-jobs/mappinline.png' className='img-fluid icon-working-img' /><span>{job?.job_data?.job_city?.city_name}, {job?.job_data?.job_city?.state_of_city?.state_name
                                                }, {job?.job_data?.job_city?.state_of_city?.country_of_state?.name}</span></li>}
                                                {job?.job_data?.job_type_list?.length > 0 && <li><img src='./assets/images/find-jobs/clock.png' className='img-fluid icon-working-img' /><span>{job?.job_data?.job_type_list[0]?.job_type_detail?.jobtypename ?? <i>-</i>}</span></li>}
                                                {(job.job_data.show_pay_by) && <li>
                                                    <img src="./assets/images_another/currency-wallet.png" alt="" />
                                                    {job?.job_data?.show_pay_by == "Range" && <span>{job?.job_data?.currency} {job?.job_data?.min_pay} - {job?.job_data?.currency} {job?.job_data?.max_pay}   {job?.job_data?.rate_per_hour}</span>}
                                                    {job?.job_data?.show_pay_by == "Starting Amount" && <span>{job?.job_data?.currency} {job?.job_data?.amount}  {job?.job_data?.rate_per_hour}</span>}
                                                    {job?.job_data?.show_pay_by == "Maximum Amount" && <span>{job?.job_data?.currency} {job?.job_data?.amount}  {job?.job_data?.rate_per_hour}</span>}
                                                    {job?.job_data?.show_pay_by == "Exact Amount" && <span>{job?.job_data?.currency} {job?.job_data?.amount}  {job?.job_data?.rate_per_hour}</span>}
                                                </li>}
                                                <li><img src='./assets/images/find-jobs/calendarblank.png' className='img-fluid icon-working-img' /><span> {timeAgoCalculator(job?.job_data?.createdAt)}</span></li>
                                            </ul>
                                        </div>
                                        <div className='last-para-content'>
                                            {job?.job_data?.description ? parse(`${truncateText(job?.job_data?.description, 30)}`) : <i>Description unavailable</i>}
                                        </div>
                                    </div>
                                </div>
                                <div className='left-btn-flex'>
                                    <button className='message-btn mb-2' onClick={()=>handleChat(job?.job_data?.Company_details?.id)}>
                                        <img src='./assets/images/find-jobs/chat.png' /> Message
                                    </button>
                                    {job?.job_data?.applied_job_details?.length > 0 &&
                                        job?.job_data?.applied_job_details[0]?.job_status == "APPLIED" &&
                                        <button className='left-btn mb-2'>
                                            <img src='./assets/images/find-jobs/check.png' /> APPLIED</button>

                                    }
                                    {job?.job_data?.applied_job_details?.length > 0 &&
                                        job?.job_data?.applied_job_details[0]?.job_status == "HIRED" &&
                                        <button className='left-btn mb-2'>
                                            <img src='./assets/images/find-jobs/check.png' />HIRED</button>

                                    }
                                    {job?.job_data?.applied_job_details?.length > 0 &&
                                        job?.job_data?.applied_job_details[0]?.job_status == "SHORTLISTED" &&
                                        <button className='shortlisted-btn mb-2'>
                                            <img src='./assets/images/find-jobs/check.png' /> SHORTLISTED</button>

                                    }
                                    {job?.job_data?.applied_job_details?.length > 0 &&
                                        job?.job_data?.applied_job_details[0]?.job_status == "REVIEWED" &&
                                        <button className='shortlisted-btn mb-2'>
                                            <img src='./assets/images/find-jobs/reviewed-eye.png' /> REVIEWED</button>

                                    }
                                    {job?.job_data?.applied_job_details?.length > 0 &&
                                        job?.job_data?.applied_job_details[0]?.job_status == "REJECTED" &&
                                        <button className='rejected-btn mb-2'>
                                            <img src='./assets/images/find-jobs/cross.png' /> REJECTED</button>

                                    }
                                    {job?.job_data?.applied_job_details?.length > 0 &&
                                        job?.job_data?.applied_job_details[0]?.job_status == "INTERVIEW" &&
                                        <button className='left-btn mb-2'>
                                            <img src='./assets/images/find-jobs/check.png' /> INTERVIEW</button>

                                    }
                                    {job?.job_data?.saved_job_detail?.length > 0 &&
                                        <button className='saved-btn'>
                                            <img src='./assets/images/find-jobs/tag.png' /> SAVED</button>
                                    }
                                </div>


                            </div>
                        </div>
                    </section>
                }) :
                    <div className="text-center my-3">
                        No jobs found.
                    </div>
            }
            {jobs && jobs?.length > 5 && <section className='pagination-section'>
                <div className='container'>
                    <nav aria-label="Page navigation example" className='nav-class'>
                        <ReactPaginate
                                breakLabel="..."
                                nextLabel=">>"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="<<"
                                renderOnZeroPageCount={null}
                                containerClassName='pagination'
                                pageLinkClassName='page-num'
                                previousLinkClassName='page-num'
                                nextLinkClassName='page-num'
                                activeClassName='active'
                        />
                    </nav>
                </div>
            </section>}
        </>
    )
}

export default JobCard