import React from 'react'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser';
import "./scss/news.css";
import { InlineShareButtons } from 'sharethis-reactjs';
const host = process.env.REACT_APP_API_SERVER;
const NewsDetailContent = ({ data }) => {
    return (
        <>
            <section className="blog-list mt-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="blog-head text-center">
                            <Link to={-1}>
                                <span>
                                    <i className="fa-solid fa-arrow-left"></i>Back
                                </span>
                            </Link>
                            <h1>News</h1>
                        </div>
                        <div className="row blog-info pt-5">
                            <div className="col-lg-12 col-md-12">
                                <div className="blog-card mb-5">
                                    <img
                                        src={`${host}/${data?.image}`}
                                        className="img-fluid"
                                        alt=""
                                    />
                                    <div className="blog-category mt-3 d-flex justify-content-between">
                                        <div className="d-flex">
                                          
                                            <div className="date mx-3 d-flex align-items-center justify-content-center flex-wrap">
                                                <img
                                                    src="/assets/images/blogs/calen.png"
                                                    className="img-fluid mb-2"
                                                    alt=""
                                                />
                                                <h5 className="mb-2">
                                                    {new Date(data?.createdAt).toDateString()}
                                                </h5>
                                                <span className="mx-2 mb-2 make-span-colored-tag px-2">
                                                    {' '}
                                                    {data?.news_category?.name}{' '}
                                                </span>
                                                {data?.is_trending && (
                                                    <span className="mx-2 mb-2 make-span-colored-tag-trending px-2">
                                                        {' '}
                                                        &#128293;Trending{' '}
                                                    </span>
                                                )}
                                              
                                                <span className="mx-2 mb-2 px-2 share-buttons-news">
                                                    <InlineShareButtons
                                                        config={{
                                                            alignment: 'center', 
                                                            color: 'social',      
                                                            enabled: true,      
                                                            font_size: 12,       
                                                            labels: null,        
                                                            language: 'en',       
                                                            networks: [          
                                                                'whatsapp',
                                                                'linkedin',
                                                                'facebook',
                                                                'twitter'
                                                            ],
                                                            padding: 10,         
                                                            radius: 4,           
                                                            show_total: false,
                                                            size: 30,            

                                                            url: `${window.location.href}`, 
                                                            image: `${host}/${data?.image}`, 
                                                            description: `${parse(data?.description)}`,     
                                                            title: `${data?.title}`,        
                                                        }}
                                                    />
                                                </span>

                                            </div>
                                        </div>
                                      
                                    </div>
                                    <div className="blog-title py-3">
                                        <h2>{data?.title}</h2>
                                    </div>
                                   
                                    {data?.description ? (
                                        <div className='news-detail-page-description'> {parse(data?.description)} </div>
                                    ) : (
                                        <>
                                            <p>
                                                <i>News desription not available.</i>
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NewsDetailContent