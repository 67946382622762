import React, { useEffect, useState } from 'react'
import './scss/JobFindDescription.css'
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAppliedSavedJobStatusData } from '../../../Redux/action/SeekerAction';
import { createImageFromInitials, getRandomColor } from '../../Utils';
const host = process.env.REACT_APP_API_SERVER;
const JobFindDescriptionContent = ({ job_data }) => {
    const [educationList, setEducationList] = useState("");
    const [shareURL, setShareURL] = useState("");
    const [applied, setApplied] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let education_level_array = job_data?.education_level_list?.map((education) => education?.education_level_detail?.educationlevel);
    useEffect(() => {
        setEducationList(education_level_array.join(", "));
        setShareURL(`${window.location.protocol}//${window.location.host}/job-find-description?job_id=${job_data?.id}`);
        if (localStorage.getItem("seeker_token")) {
            dispatch(getAppliedSavedJobStatusData(localStorage.getItem("seeker_token"), job_data?.job_slug));
        } else {
            setApplied(false);
        }
    }, [job_data]);
    const applysavejobStatusDetail = useSelector((state) => state?.SeekerReducer ? state?.SeekerReducer?.appliedsavedjobstausDataList : "");

    useEffect(() => {
        if (applysavejobStatusDetail) {
            if (applysavejobStatusDetail?.applied_job_status == true) {
                setApplied(true);
            } else {
                setApplied(false);
            }
        } else {
            setApplied(false);
        }
    }, [applysavejobStatusDetail])

    const handleCopyClipboard = async (link) => {
        try {
            await navigator.clipboard.writeText(link);
            toast.success('Link copied to clipboard');
        } catch (error) {
            console.error('Unable to copy to clipboard', error);
            toast.error('Failed to copy link to clipboard');
        }
    }

    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const previousDate = new Date(timestamp);

        const timeDifference = currentDate - previousDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        if (months > 0) {return `${months} month${months !== 1 ? 's' : ''} ago`;} else if (days > 0) { return `${days} day${days !== 1 ? 's' : ''} ago`;} else if (hours > 0) {return `${hours} hour${hours !== 1 ? 's' : ''} ago`;} else if (minutes > 0) {return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;} else {return 'Just now';}
    };

    const urlString = window.location.href;
    const url = new URL(urlString);
    const path = url.pathname.slice(1);
    const searchParams = url.search;
    const q = path + searchParams;
    const handleApply = () => {
        if (!localStorage.getItem("seeker_token")) {toast.error("Please login to apply.");navigate(`/signin?q=${encodeURIComponent(q)}`);
        } else {navigate(`/your-career-start-here?j=${job_data?.job_slug}`)}
    }
    return (
        <>
            <section className='jobfind-description-section'>
                <div className="main-section">
                    <div className="container">
                        <div className="second-section">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="top-header">
                                        <div className="d-flex">
                                            <div className='company-logo'>                                              
                                                <img className="img-fluid"
                                                    src={job_data?.Company_details?.logo ? `${host}/uploaded-files/${job_data?.Company_details?.logo}` : `${createImageFromInitials(500, job_data?.Company_details?.company_name, getRandomColor())}`}
                                                    alt={`${job_data?.Company_details?.company_name}`}
                                                    title={`${job_data?.Company_details?.company_name}`}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; 
                                                        currentTarget.src = createImageFromInitials(500, job_data?.Company_details?.company_name, getRandomColor());
                                                    }}
                                                />
                                            </div>
                                            <div className="top-head mx-3">
                                                <h3>{job_data?.job_title}</h3>
                                                <p>
                                                    <span>At {job_data?.Company_details?.company_name}</span> {job_data?.job_type_list?.length > 0 && <span className='mx-1'>{job_data?.job_type_list[0]?.job_type_detail?.jobtypename}</span>}
                                                    <span className='mx-1'>Featured</span>{" "}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="top-btn">
                                            {!applied ?
                                                <button className='top-btn-tag-applied' onClick={handleApply}>
                                                    Apply now<img src="/assets/career-start/right-arrow.png" alt="" />
                                                </button>
                                                : <button className='top-btn-tag'>
                                                    <img src="./assets/images_another/mdi_check-bold.png" alt="" /> Applied
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                {job_data?.description && <div className="col-lg-7 scroll-description">
                                    <div className="banner-section">
                                        {parse(`${job_data?.description}`)}
                                    </div>
                                </div>}
                                <div className="col-lg-5">
                                    <div className="left-card-section">
                                        <div className="row">
                                            {job_data.show_pay_by && <div className="col-lg-6 col-md-6 border-right">
                                                <div className="left-section">
                                                    <h6>Salary</h6>
                                                    {job_data?.show_pay_by == "Range" && <h5>  {job_data?.currency} {job_data?.min_pay}  - {job_data?.currency} {job_data?.max_pay}   </h5>}
                                                    {job_data?.show_pay_by == "Starting Amount" && <h5>  {job_data?.currency} {job_data?.amount}   </h5>}
                                                    {job_data?.show_pay_by == "Maximum Amount" && <h5>  {job_data?.currency} {job_data?.amount}   </h5>}
                                                    {job_data?.show_pay_by == "Exact Amount" && <h5>  {job_data?.currency} {job_data?.amount}   </h5>}
                                                    <p>{job_data?.rate_per_hour}</p>
                                                </div>
                                            </div>}
                                            <div className="col-lg-6 col-md-6">
                                                <div className="left-section2 text-center">
                                                    <img src="./assets/images_another/map-tri-fold.png" alt="" />
                                                    <h5>Job Location</h5>
                                                    {Number(job_data?.location_to_report) != false ? <p>{job_data?.job_city?.city_name}, {job_data?.job_city?.state_of_city?.state_name}, {job_data?.job_city?.state_of_city?.country_of_state?.name}</p> : <p>-:-</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="left-card-second mt-5">
                                        <div className="mt-5 mx-4">
                                            <div className="col-lg-12">
                                                <div className="list-head">
                                                    <h3>Job Overview</h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-3 col-6">
                                                    {job_data?.createdAt &&
                                                        <div className="card-pad">
                                                            <img
                                                                src="./assets/images_another/calendar-card.png"
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                            <p>Job Posted:</p>
                                                            <h6>{timeAgoCalculator(job_data?.createdAt)}</h6>
                                                        </div>}
                                                </div>

                                                <div className="col-lg-4 col-md-3 col-6">
                                                    <div className="card-pad">
                                                        <img src="./assets/images_another/timer.png" className="img-fluid" alt="" />
                                                        <p>Job expire:</p>
                                                        {job_data?.is_application_deadline ? <h6>{job_data?.application_deadline}</h6> : <h6>No</h6>}
                                                    </div>
                                                </div>                                               
                                            </div>
                                            <div className="row">
                                                {job_data?.no_of_people_to_hire &&
                                                    <div className="col-lg-4 col-md-3 col-6">
                                                        <div className="card-pad">
                                                            <img src="./assets/images_another/wallet.png" className="img-fluid" alt="" />
                                                            <p>Vaccancies</p>
                                                            <h6>{job_data?.no_of_people_to_hire}</h6>
                                                        </div>
                                                    </div>}
                                                {job_data?.education_level_list?.length &&
                                                    <div className="col-lg-4 col-md-3 col-6">
                                                        <div className="card-pad">
                                                            <img
                                                                src="./assets/images_another/calendar-card.png"
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                            <p>Education</p>
                                                            <h6>{job_data?.education_level_list?.length ? educationList : "-"}</h6>
                                                        </div>
                                                    </div>}
                                            </div>
                                        </div>
                                        <div className="border-bottom"></div>
                                        <div className="col-lg-12">
                                            <div className="list-top-heading">
                                                <div className="list-head">
                                                    <h3>Share this job:</h3>
                                                </div>
                                                <div className="card-list mx-2">
                                                    <div className="card-list-child-dv mx-2" onClick={() => handleCopyClipboard(shareURL)} title='Copy link to clipboard'>
                                                        <img src="./assets/images_another/icon/copy-link.png" alt="" />
                                                    </div>                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default JobFindDescriptionContent