import React, { useEffect, useState } from 'react'
import './scss/news.css'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../Commons/Footer'
import NewsBanner from './NewsBanner'
import Entertainment from './Entertainment'
import NewsHeader from './NewsHeader'
import NewsSports from './NewsSports'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getNewsPageData } from '../../../Redux/action/MasterAction'
import { dividerClasses } from '@mui/material'
import Loader from '../Loader';
import NewsSportsDynamic from './NewsSportsDynamic'
const host = process.env.REACT_APP_API_SERVER;
const NewsHome = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    useEffect(() => {dispatch(getNewsPageData());}, []);
    const data = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.newsPageData : null);
    useEffect(() => {
        if (data) {setLoader(false);} else {setLoader(true);}}, [data])
    return (
        <>{!loader ? <>
            <AfterNavbar />
            <NewsHeader />
            {data ? <NewsBanner data={data.top} /> : <div className="overlay-form-submission"><div className="text-center">Loading...</div></div>}
            {data ? <Entertainment top={data.top} dataByCat={data.newsByCategory} trending={data.trending} mostViewed={data.mostViewed} /> : <div className="overlay-form-submission"><div className="text-center">Loading...</div></div>}
            {data ? <NewsSportsDynamic dataByCat={data.newsByCategory} /> : <div className="overlay-form-submission"><div className="text-center">Loading...</div></div>}
            <Footer />
        </> :
            <div className="overlay-form-submission">
                <Loader />
            </div>
        }</>
    )
}

export default NewsHome