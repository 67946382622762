import React, { useEffect, useState } from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import CareerStepper from '../CareerStepper/CareerStepper'
import YourCareerCompanyDetailsCard from '../YourCareerCompanyDetailsCard/YourCareerCompanyDetailsCard'
import Footer from '../../Commons/Footer'
import CvForEmployerContent from './CvForEmployerContent'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loader from '../../Loader'
import { useDispatch, useSelector } from 'react-redux'
import CareerStepperAssessment from '../CareerStepper/CareerStepperAssessment'
import { checkAssessmentQuestionExists } from '../../../../Redux/action/SeekerAction'

const CvForEmployer = () => {
  const [job_id, setJob_id] = useState(0);
  const [showJob, setShowJob] = useState(false);
  const [isAssessmentIncluded, setIsAssessmentIncluded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const  result  = location.state ? location.state.result : null;
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('j');
  useEffect(() => {
    if (!id) {
      toast.error("Please enter correct URL.");
      setShowJob(false);
      document.body.style.overflow = 'hidden';
      navigate(-1)
    } else {
      setShowJob(true);
      setJob_id(id);
      document.body.style.overflow = 'auto';
    }
  });
  useEffect(()=>{
    dispatch(checkAssessmentQuestionExists(localStorage.getItem("seeker_token"), id));
  },[])
  const checkAssessmentQuestionExist = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.checkAssessmentQuestionExistData : null);
  useEffect(() => {
    if (checkAssessmentQuestionExist) {
      setIsAssessmentIncluded(checkAssessmentQuestionExist?.included_assessment);
    }
  }, [checkAssessmentQuestionExist])
  return (
    <>
      {!showJob ? <div className="overlay-form-submission"><Loader /></div> : <>
        <AfterNavbar />
        {isAssessmentIncluded ? <CareerStepperAssessment activeStep={2} /> : <CareerStepper activeStep={1} />}
        <CvForEmployerContent job_id={job_id} result={result}/>
        <Footer />
      </>}
    </>
  )
}

export default CvForEmployer