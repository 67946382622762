import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Slider from "react-slick";
import { getIndustryData, getLatestJobData } from '../../../Redux/action/RecruiterAction';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'bootstrap';
import { Link } from 'react-router-dom';
import { createImageFromInitials, getRandomColor } from '../../Utils';
const host = process.env.REACT_APP_API_SERVER;
const Jobopening = () => {const [value, setValue] = useState("1");const handleChange = (event, newValue) => {setValue(newValue);};
    const dispatch = useDispatch();
    useEffect(() => {dispatch(getLatestJobData());dispatch(getIndustryData());}, []);
    const industryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.industryDataList?.data) : "");
    const latestJobData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.latestJobDataList?.data) : "");
    const settings = {dots: false,infinite: false,autoplay: true,autoplaySpeed: 3000,slidesToShow: 4,slidesToScroll: 1,
        responsive: [
            {breakpoint: 1024,settings: {slidesToShow: 3,slidesToScroll: 3,infinite: true,dots: false}},
            {breakpoint: 780,settings: {slidesToShow: 2,slidesToScroll: 2,infinite: true,dots: false,arrows: false}},
            {breakpoint: 480,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: true,dots: false,arrows: false}}
        ]}
    function formatDate(timestamp) {const date = new Date(timestamp);const options = { day: '2-digit', month: 'short', year: 'numeric' };return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');}
    const timeAgoCalculator = (timestamp) => {const currentDate = new Date();const previousDate = new Date(timestamp);
        const timeDifference = currentDate - previousDate;const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);const months = Math.floor(days / 30);
        if (months > 0) {return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) { return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {return 'Just now';}
    };
    return (
        <section className='job-opening'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 '>
                        <div className='heading-txt mt-4 mb-5 text-center'>
                            <h1>Latest Jobs Opening</h1>
                        </div>
                    </div>
                    <div className="col-lg-12 latest-job-opening-tab-parent">
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="All" value="1" className='latest-job-opening-tab-btn' />
                                        {industryData && industryData.map((data, index) => (
                                            < Tab label={data.industry} value={`${index + 2}`} key={data.id} className='latest-job-opening-tab-btn1' />
                                        ))}
                                    </TabList>
                                </Box>
                                <TabPanel value="1" className=''>
                                    <Slider {...settings} className='mb-5'>
                                        {latestJobData && latestJobData.map((job, index) => {
                                            return <div className="col-lg-3" key={index + 1} >
                                                <Link to={`/job-find-description?j=${job.job_slug}`} className='job-find-card-link'>
                                                    <div className="latest-job-card-two">
                                                        <div className="company-group d-flex justify-content-between">
                                                            <img className='comp-logo'
                                                                src={`${host}/uploaded-files/${job?.Company_details?.logo}`}
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null;
                                                                    currentTarget.src = createImageFromInitials(500, job?.Company_details?.company_name, getRandomColor());
                                                                }}
                                                            />
                                                            <div className="job-role">
                                                                <h5 className='line-clamp-company-name'>{job?.Company_details?.company_name ? job?.Company_details?.company_name : ""}</h5>
                                                                {job?.show_pay_by !== "Range" &&
                                                                    <h6> {job?.min_pay ? ` ${job?.currency} ${job?.min_pay}` : ''} {job?.max_pay ? `- ${job?.currency} ${job?.max_pay}` : ''}</h6>
                                                                }
                                                                {job?.show_pay_by === "Range" &&
                                                                    <h6> {job?.currency} {job?.amount ? job?.amount : ""} {job?.rate_per_hour ? job?.rate_per_hour : ""}</h6>
                                                                }
                                                            </div>
                                                            <img src="assets/images/homepage/g1.png" />
                                                        </div>
                                                        <div className="job-title">
                                                            <h5>{job?.job_title ? job?.job_title : ""}</h5>
                                                            <h6> {job?.jobTypeList?.[0]?.jobType?.jobtypename} {job?.jobCountry?.name ? `-${job?.jobCountry?.name}` : ''}</h6>
                                                        </div>
                                                        <div className="job-loc d-flex">
                                                            {job?.addWorkPlaceList?.map((workplace) => (<button><a href={undefined}>{workplace?.workplaceList?.workplace ? workplace?.workplaceList?.workplace : ""} </a></button>))}
                                                        </div>
                                                        <div className="job-times d-flex justify-content-space-between mt-1 ">
                                                            <span><i className="fa-solid fa-calendar-days me-1" title="Job posted date"></i>{formatDate(job?.createdAt)}</span>
                                                            <span><i className="fa-solid fa-clock me-1"></i>{timeAgoCalculator(job?.createdAt)}</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        })}
                                    </Slider>
                                </TabPanel>
                                {industryData && industryData.map((industry, index) => (
                                    <TabPanel value={`${index + 2}`} key={industry.id} className=''>
                                        <Slider {...settings} className='mb-5'>
                                            {latestJobData &&

                                                latestJobData
                                                    .filter(job => String(job.industry) === String(industry.id)) // Ensure both are of the same type for comparison

                                                    .map((job, jobIndex) => {

                                                        return <div className="col-lg-3" key={index + 1} >
                                                        <Link to={`/job-find-description?j=${job.job_slug}`} className='job-find-card-link'>
                                                            <div className="latest-job-card-two">
                                                                <div className="company-group d-flex justify-content-between">
                                                                    <img className='comp-logo'
                                                                        src={`${host}/uploaded-files/${job?.Company_details?.logo}`}
                                                                        onError={({ currentTarget }) => {
                                                                            currentTarget.onerror = null;
                                                                            currentTarget.src = createImageFromInitials(500, job?.Company_details?.company_name, getRandomColor());
                                                                        }}
                                                                    />
                                                                    <div className="job-role">
                                                                        <h5 className='line-clamp-company-name'>{job?.Company_details?.company_name ? job?.Company_details?.company_name : ""}</h5>
                                                                        {job?.show_pay_by !== "Range" &&
                                                                            <h6> {job?.min_pay ? ` ${job?.currency} ${job?.min_pay}` : ''} {job?.max_pay ? `- ${job?.currency} ${job?.max_pay}` : ''}</h6>
                                                                        }
                                                                        {job?.show_pay_by === "Range" &&
                                                                            <h6> {job?.currency} {job?.amount ? job?.amount : ""} {job?.rate_per_hour ? job?.rate_per_hour : ""}</h6>
                                                                        }
                                                                    </div>
                                                                    <img src="assets/images/homepage/g1.png" />
                                                                </div>
                                                                <div className="job-title">
                                                                    <h5>{job?.job_title ? job?.job_title : ""}</h5>
                                                                    <h6> {job?.jobTypeList?.[0]?.jobType?.jobtypename} {job?.jobCountry?.name ? `-${job?.jobCountry?.name}` : ''}</h6>
                                                                </div>
                                                                <div className="job-loc d-flex">
                                                                    {job?.addWorkPlaceList?.map((workplace) => (<button><a href={undefined}>{workplace?.workplaceList?.workplace ? workplace?.workplaceList?.workplace : ""} </a></button>))}
                                                                </div>
                                                                <div className="job-times d-flex justify-content-space-between mt-1 ">
                                                                    <span><i className="fa-solid fa-calendar-days me-1" title="Job posted date"></i>{formatDate(job?.createdAt)}</span>
                                                                    <span><i className="fa-solid fa-clock me-1"></i>{timeAgoCalculator(job?.createdAt)}</span>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    })}
                                        </Slider>
                                    </TabPanel>
                                ))}
                            </TabContext>

                        </Box>
                    </div>
                    {/* -------------------new slider start here------------------------ */}
                    
                    {/* -------------------new slider end here------------------------ */}
                </div>

            </div>
        </section >
    )
}
export default Jobopening