import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getRecruiterAdviceData } from '../../../Redux/action/SeekerAction';
const host = process.env.REACT_APP_API_SERVER;
const FindYourNextGreatHireBanner = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getRecruiterAdviceData());
    }, []);
    const recruiterAdviceData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.recruiterAdviceDataList?.data) : "");
    const firstTitle = recruiterAdviceData?.section_one_title ? recruiterAdviceData?.section_one_title : 'Find Your Next Great Hire'
    const firstSubtitle = recruiterAdviceData?.section_one_sub_title ? recruiterAdviceData?.section_one_sub_title : 'No matter the skills, experience, or qualifications you’re looking for, you’ll find the right people on Karlatoon’s matching and hiring platform.'
    const img = recruiterAdviceData?.section_one_banner_img ? `${host}/${recruiterAdviceData?.section_one_banner_img}` : './assets/images/matching-hiring/ban.png';
    return (    
        <>
            <section className="bannre-area" style={{ backgroundImage: `url(${img})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="banner-txt">
                                <h2>{firstTitle}</h2>
                                <p>{firstSubtitle}</p>
                            </div>
                        </div>
                        <div className="col-lg-6"></div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FindYourNextGreatHireBanner