import React from 'react'
import './scss/ShortListed.css'
import { Link } from 'react-router-dom'
import JobCard from '../JobCard/JobCard'
const ShortListed = ({jobs}) => {
    return (
        <>
          
            <JobCard jobs={jobs} />
        </>
    )
}

export default ShortListed