import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getIndustryData, getbusinesstypeData, getcityData, getcompanyCountData } from '../../../../Redux/action/SeekerAction';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { getCompanysizeData } from '../../../../Redux/action/RecruiterAction';
import { useNavigate } from 'react-router';
import { getAfganCities, getFindJobCompanyBanner } from '../../../../Redux/action/MasterAction';
import { toast } from 'react-toastify';
const host = process.env.REACT_APP_API_SERVER;

const CareerSearchSection = () => {
    let [companyCount, setCompanyCount] = useState(0);
    let [showCity, setShowCity] = useState(false);
    let [showIndustry, setShowIndustry] = useState(false);
    let [showSize, setShowSize] = useState(false);
    let [showBusinessType, setShowBusinessType] = useState(false);
    const [cities, setCities] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    let dispatch = useDispatch();
    let navigate = useNavigate();
    useEffect(() => {
        dispatch(getcompanyCountData());
        dispatch(getcityData());
        dispatch(getIndustryData());
        dispatch(getCompanysizeData());
        dispatch(getbusinesstypeData());
        dispatch(getAfganCities());
        dispatch(getFindJobCompanyBanner());
    }, []);
    let companyCountData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.companyCountDataList : 0);
    const cityData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.cityDataList : []);
    const industryData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.industryTypeDataList : []);
    const sizeData = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.companysizeDataList : []);
    const businessTypeData = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.businesstypeDataList : []);
    let afganCity = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.afganCitiesList : []);

    useEffect(() => {
        if (afganCity) {
            setCities(afganCity);
            setFilteredCities(afganCity);
        }
    }, [afganCity]);

    const handleInputChange = event => {
        const inputValue = event.target.value.toLowerCase();
        formik.handleChange(event);

        if (inputValue === '' || inputValue?.length === 1) {
            setFilteredCities(cities);
        } else {
            const filtered = cities.filter(city =>
                city.city_name.toLowerCase().includes(inputValue)
            );
            setFilteredCities(filtered);
        }
    };
    useEffect(() => {
        if (companyCountData) {
            setCompanyCount(companyCountData);

        }
    }, [companyCountData]);
    useEffect(() => {
        if (cityData) {
            setShowCity(true);
        } else {
            setShowCity(false);
        }
    }, [cityData]);

    useEffect(() => {
        if (industryData?.data) {
            setShowIndustry(true);
        } else {
            setShowIndustry(false);
        }
    }, [industryData]);

    useEffect(() => {
        if (sizeData?.data) {
            setShowSize(true);
        } else {
            setShowSize(false);
        }
    }, [sizeData]);

    useEffect(() => {
        if (businessTypeData) {
            setShowBusinessType(true);
        } else {
            setShowBusinessType(false);
        }
    }, [businessTypeData]);

    let initialValues = {
        company_name: "",
        loc: "",
        industry: "",
        size: "",
        business_type: "",
        departments_list_text: "",
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({}),
        onSubmit: async (values) => {
            function removeEmptyStringValues(obj) {
                for (const key in obj) {
                    if (obj[key] === '') {
                        delete obj[key];
                    }
                }
                return obj;
            }
            const objectWithoutEmptyStrings = removeEmptyStringValues(values);
            let create_search_string = ""
            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                    create_search_string = create_search_string + key + "=" + objectWithoutEmptyStrings[key] + "&";
                }
            }
            if(!create_search_string || create_search_string==""){
                toast.error("Enter company name or location to start a search.")
            }else{
                navigate(`/search-company-result?${create_search_string}`);
            }
        }
    });

    //dynamic Banner data
    let [imageUrl, setImageUrl] = useState("/assets/career-start/career-start-banner.png");
    const [imageFailed, setImageFailed] = useState(false);
    let [defaultImageUrl, setDefaultImageUrl] = useState("/assets/career-start/career-start-banner.png");

    let findBanners = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.findJobCompanyBanner : null);

    useEffect(() => {
        if (findBanners) {
            setImageUrl(`${host}/${findBanners?.findcompanybanner}`);
        }
    }, [findBanners])

    const handleImageError = () => {
        setImageFailed(true);
    };

    const imageStyle = {
        backgroundImage: `url(${imageFailed ? defaultImageUrl : imageUrl})`
    };

    return (
        <>
            <section className="career-section" style={imageStyle}>
                <div className="career-start-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="career-top-heading">
                                    <h2>Explore the career you love </h2>
                                </div>
                                <div className="career-top-heading mt-4">
                                    <h1>
                                        Find Right <span> Places/Companies </span> to Work
                                    </h1>
                                </div>
                                <div className="career-second-heading mt-4">
                                    <h6><span>  {companyCount} </span> Companies <b>NOW</b> Hiring in Afghanistan
                                    </h6>
                                </div>
                                <form onSubmit={formik.handleSubmit}>
                                    <div>
                                        <div className="custom-form-section mt-4">
                                            <div className="search-custom">
                                                <img src=" ./assets/find-right-places-companies/magnifyingglass.png" className="img-fluid" alt="Search Companies" title='Search Companies' />
                                                <input
                                                    type="text"
                                                    className="form-control form-custom"
                                                    placeholder="Company name"
                                                    name='company_name'
                                                    id='company_name'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.company_name}
                                                    style={{ paddingLeft: "45px" }}
                                                />
                                            </div>
                                            <div className="search-custom">
                                                <img src="./assets/find-right-places-companies/mappinline.png" className="img-fluid" alt="" />
                                                <input
                                                    type="text"
                                                    className="form-control form-custom"
                                                    placeholder="Location"
                                                    id="loc"
                                                    name='loc'
                                                    
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.loc}
                                                    style={{ paddingLeft: "45px" }}
                                                />
                                            </div>
                                        
                                            <div>
                                                <div>
                                                    <button className="form-custom-btn">Find Company</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                  
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CareerSearchSection