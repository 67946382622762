import { useFormik } from 'formik'
import * as Yup from "yup";
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { error } from 'jquery';
import { toast } from 'react-toastify';
import HourglassLoader from '../../../Seeker/HourglassLoader';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAssessmentList, getAssessmentQuestionList, getUserList } from '../../../../Redux/action/RecruiterAction';
const host = process.env.REACT_APP_API_SERVER;

const EditUserModal = ({ reloadData, user_id }) => {

    const [password, setPassword] = useState("password");

    const initialValues = {
        user_email: "",
        passing_percent: ""
    }
    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        const token = localStorage.getItem("recruiter_token");
        if (user_id) {
            dispatch(getUserList(token));
        }
    }, [user_id])
    const usersList = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.usersList : null);
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            user_email: Yup.string().required("Please enter user email.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid E-mail"),
            password: Yup.string().required("Please enter password.").min(6, "Password should be 6 to 20 characters long."),
        }),
        onSubmit: (values, { resetForm }) => {
            values.id = user_id
            setDisabled(true);
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                const config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } }
                axios.post(`${host}/recruiter/update-user`, values, config)
                    .then(response => {
                        if (response.status == 200) {
                            setDisabled(false);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Title updated successfully" });
                            reloadData();
                            $(`#editAssessmentCloseBtn${user_id}`).click();
                            resetForm();
                          
                        } else {
                            setDisabled(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    }).catch(error => {
                        setDisabled(false);
                        console.log(error.message)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
                    })
            } catch (error) {
                setDisabled(false);
                console.log(error.message)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
            }

        }
    });

    useEffect(() => {
        if (usersList) {
            const findUser = usersList.find(item => item.id === user_id)            
            formik.setValues({
                user_email: findUser?.company_email ?? "",
                password: findUser?.raw_password ?? "",
            })
        }
    }, [usersList])

    const handlePassword = () => {
        if (password == "password") {
            setPassword("text");
        } else {
            setPassword("password");
        }
    }

    return (
        <>
            <div className="modal fade" id={`editAssessment${user_id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Edit User</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id={`editAssessmentCloseBtn${user_id}`}></button>
                        </div>
                        <div className="modal-body">
                            <form method="post" onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label htmlFor="user_email">Enter User Email <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            name='user_email'
                                            placeholder='Enter user email'
                                            maxLength={50}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.user_email} />
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.user_email && formik.errors.user_email ? (
                                            <div className='text-danger'> {formik.errors.user_email}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-12 password-cus-modal">
                                        <label htmlFor="password">Enter Password <span className='text-danger'>*</span></label>
                                        <div className='ps-inpt'>
                                            <input
                                                type={password}
                                                className='form-control'
                                                name='password'
                                                placeholder='Enter Password'
                                                maxLength={20}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.password} />
                                            <i className={`fa fa-eye${password == "password" ? "-slash" : ""} cursor-pointer`} onClick={handlePassword} aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.password && formik.errors.password ? (
                                            <div className='text-danger'> {formik.errors.password}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-6">
                                        {disabled ?
                                            <HourglassLoader />
                                            : <button className="btn btn-primary btn-sm" disabled={disabled} type='submit'>Submit</button>}
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default EditUserModal