import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import HourglassLoader from '../HourglassLoader';
import axios from 'axios';
import $ from 'jquery';
import "bootstrap";
let host = process.env.REACT_APP_API_SERVER;

const CvModal = ({ refresh, changeRefresh }) => {
    const [disableBtn, setDisableBtn] = useState(false);
    const initialValues = {
        resume_name: "",
        resume_size: "",
        resume: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            resume: Yup.mixed().required("Please select a file.")
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const loadingToastId = toast.info('Uploading...', { autoClose: false });
            setDisableBtn(true);
            try {
                if (((values?.resume?.size) / (1024 * 1024)) > 6) {
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "File size should be less than 6 MB." });
                    setDisableBtn(false);
                } else {
                    document.body.style.overflow = 'hidden';
                    function roundToTwo(num) {
                        return +(Math.round(num + "e+2") + "e-2");
                    }
                    formik.setFieldValue("resume_name", values?.resume?.name);
                    formik.setFieldValue("resume_size", `${roundToTwo((values?.resume?.size) / (1024 * 1024))}MB`);
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data',
                            "auth-token": localStorage.getItem("seeker_token")
                        }
                    };
                    const formData = new FormData();
                    formData.append("resume", values.resume);
                    formData.append("resume_name", values.resume.name)
                    formData.append("resume_size", `${roundToTwo((values?.resume?.size) / (1024 * 1024))}MB`)
                  axios.post(`${host}/mobile/add-resume`, formData, config)
                        .then((response) => {
                            if (response.status == 200) {
                               document.body.style.overflow = 'auto';
                                formik.setValues({ initialValues });
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Resume uploaded!' });
                                setDisableBtn(false);
                                changeRefresh();
                                const closeBtn = document.getElementById("closeButtonCVViewwer");
                                if (closeBtn) {
                                    closeBtn.click();
                                }
                                const resume_id = document.getElementById("resume");
                                resume_id.value = "";
                                formik.setValues({
                                    resume_name: "",
                                    resume_size: "",
                                    resume: "",
                                });
                               } else {
                                console.log("in else block of .then method:", response.data.msg);
                                document.body.style.overflow = 'auto';
                                 toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                                setDisableBtn(false);
                            }
                        })
                }

            } catch (error) {
                console.log(error.message);
                document.body.style.overflow = 'auto';
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                setDisableBtn(false);
            }
        }
    })
    return (
        <>
            <div className="modal fade resumeModalViewer" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="col-lg-11 col-6 edu-title">
                                <h1>Add CV</h1>
                            </div>
                            <button type="button" className="btn-close" id="closeButtonCVViewwer" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className='mt-5' method='post' onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12 mb-3 d-grid" >
                                        <label htmlFor="" className='mb-2'>Upload Resume<span>*</span></label>
                                        <input type="file" accept='.doc,.docx,.pdf' id='resume'
                                            onChange={(e) => {
                                                formik.setFieldValue("resume", e.target.files[0]);
                                                 }}
                                            onBlur={formik.handleBlur}

                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.resume && formik.errors.resume ? (
                                                <div className='text-danger'>{formik.errors.resume}</div>
                                            ) : null}
                                        </div>

                                    </div>

                                    <div className="col-md-12 mt-5 mb-5 custm-btn-sec d-flex justify-content-center">
                                        <div className='custm-botton-btn mb-2'>
                                            <button type='submit' className='next mx-2' name="myButton"
                                               disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : "Submit"}</button>
                                        </div>

                                    </div>

                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default CvModal