import { React, useState, useEffect } from 'react'
import './scss/AnalysisChart.css'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getAnalyticsChartData } from '../../../Redux/action/RecruiterAction';
const Performance = () => {const dispatch = useDispatch();
    useEffect(() => {
        let authToken = localStorage.getItem("recruiter_token");
        dispatch(getAnalyticsChartData(authToken));
    }, []);

    return (
        <>
            

        </>
    )
}

export default Performance