import React, { useEffect, useRef, useState } from 'react';
import './scss/Chat.css'; // Ensure this path is correct
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRecruiterChat } from '../../../Redux/action/RecruiterAction';
const host = process.env.REACT_APP_API_SERVER;

const ChatModal = ({ candidateDetail, updateCount }) => {
  const [messageArr, setMessageArr] = useState([]);
  const dispatch = useDispatch();
  const [input, setInput] = useState("");

  const scrollToBottom = () => {
    const scrollableContent = document.getElementById('chat-container');
    if (scrollableContent) {
      scrollableContent.scrollTop = scrollableContent.scrollHeight;
    }
  };

  const {
    loadingRecruiterChatApi,
    recruiterChatApiData,
    recruiterChatApiError,
  } = useSelector((state) => state?.RecruiterReducer);

  useEffect(() => {
    dispatch(
      fetchRecruiterChat(
        candidateDetail?.id,
        localStorage.getItem('recruiter_token')
      )
    );
  }, [dispatch, candidateDetail?.id]);

  useEffect(() => {
    if (recruiterChatApiData) {
      setMessageArr(recruiterChatApiData);

      const unreadSeekerMessagesCount = recruiterChatApiData.filter(
        (message) => message.sender_type === 'SEEKER' && !message.is_read
      ).length;

      updateCount(unreadSeekerMessagesCount);
    }
  }, [recruiterChatApiData, updateCount]);

  useEffect(() => {
    const fetchData = () => {
      dispatch(
        fetchRecruiterChat(
          candidateDetail?.id,
          localStorage.getItem('recruiter_token')
        )
      ).catch((error) => {
        console.error('Error fetching chat list:', error);
      });
    };

    fetchData();
    scrollToBottom();
    const intervalId = setInterval(fetchData, 2000);

    return () => clearInterval(intervalId);
  }, [dispatch, candidateDetail?.id]);

  const sendMessage = () => {
    let sendButton = document.getElementById('send-message-button');
    try {
      let message = document.getElementById('message-input');
      const messageValue = input.trim();
      if (!messageValue) return;

      sendButton.disabled = true;
      let values = {
        sender_type: 'RECRUITER',
        seeker_id: candidateDetail?.id,
        message: input.trim(),
      };
      let config = {
        headers: { 'auth-token': localStorage.getItem('recruiter_token') },
      };
      axios
        .post(`${host}/chat/send-recruiter-message`, values, config)
        .then((response) => {
          if (response.status === 200) {
            message.value = '';
            setInput("")
            sendButton.disabled = false;
            const scrollableContent = document.getElementById('chat-container');
            if (scrollableContent) {
              scrollableContent.scrollTop = scrollableContent.scrollHeight;
            }
            dispatch(
              fetchRecruiterChat(
                candidateDetail?.id,
                localStorage.getItem('recruiter_token')
              )
            );
            // socket.emit("new message", response.data)
          } else {
            sendButton.disabled = false;
            toast.error(response.data.msg);
          }
        });
    } catch (error) {
      console.log(error.message);
      sendButton.disabled = false;
      toast.error('Internal server error.');
    }
  };

  function adjustHeight() {
    const textarea = document.getElementById('message-input');
    const lineHeight = 24; 
    textarea.style.height = 'auto';
    const newHeight = Math.min(textarea.scrollHeight, lineHeight * 3);
    textarea.style.height = newHeight + 'px';
  }

  return (
    <>
      <div
        className="modal fade"
        id="chatStaticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="chatStaticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-chat">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="chatStaticBackdropLabel">
                {candidateDetail?.firstName} {candidateDetail?.lastName}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="chat-progress">
                <div className="chat" id="chat-container">
                  {messageArr.map((msg, index) => {
                    return (
                      <div
                        className={
                          msg?.sender_type === 'RECRUITER'
                            ? `mine messages`
                            : `yours messages`
                        }
                        key={index + 1}
                      >
                        <div className="message">{msg?.message}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-3 custom-footer">
            
              <textarea
                type="text"
                className="form-control p-1 m-1 mb-3 chat-input w-100"
                id="message-input"
                placeholder="Enter your message"
                onChange={(event) => {
                  setInput(event.target.value);
                }}
                rows={1}
                onInput={adjustHeight}
             
              ></textarea>
            
              <button
                type="button"
                className="btn btn-sm btn-primary m-1 mb-3"
                title="Send message"
                onClick={sendMessage}
                id="send-message-button"
              >
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
              </button>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatModal;
