import React from 'react'
import Header from '../Commons/Header'
import Footer from '../Commons/Footer'
import ImpactOfTechnologyContent from './ImpactOfTechnologyContent'
const ImpactOfTechnology = () => {
  return (
    <>
    <Header />
    <ImpactOfTechnologyContent />
    <Footer />
    </>
  )
}
export default ImpactOfTechnology