
let host = process.env.REACT_APP_API_SERVER;


export const getCitiesData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-cities`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setCitiesData(resData?.data));
                  })
            }).catch((err) => {
                console.log("ERROR in getSeekerData function(redux):", err.message, err);
            })
    }
}

const setCitiesData = (resData) => {
    if (resData) {
        return {
            type: "SET_CITIES_DATA",
            payload: resData,
        }
    }
}
export const getJobroleData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-jobrole`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setJobroleData(resData?.data));
                     })
            }).catch((err) => {
                console.log("ERROR in getSeekerData function(redux):", err.message, err);
            })
    }
}

const setJobroleData = (resData) => {
    if (resData) {
        return {
            type: "SET_JOBROLE_DATA",
            payload: resData,
        }
    }
}
export const getQuestionType = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-question-types`, { method: "GET" })
            .then((res) => {
                 res.json().then((resData) => {
                    dispatch(setQuestionType(resData));
                     })
            }).catch((err) => {
                console.log("ERROR in getSeekerData function(redux):", err.message, err);
            })
    }
}
const setQuestionType = (resData) => {
    if (resData) {
        return {
            type: "SET_QUESTION_TYPE",
            payload: resData,
        }
    }
}
export const getIndustry = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-industry`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setIndustry(resData?.data));
                     })
            }).catch((err) => {
                console.log("ERROR in getIndustry function(redux):", err.message, err);
            })
    }
}

const setIndustry = (resData) => {
    if (resData) {
        return {
            type: "SET_INDUSTRY_DATA",
            payload: resData,
        }
    }
}

export const getJobFairJobs = () => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/show-all-job-fair-jobs`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setJobFairJobs(resData?.data));
                     })
            }).catch((err) => {
                console.log("ERROR in getJobFairJobs function(redux):", err.message, err);
            })
    }
}

const setJobFairJobs = (resData) => {
    if (resData) {
        return {
            type: "SET_FAIR_JOBS_DATA",
            payload: resData,
        }
    }
}

export const getImpaneledComapnies = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-impaneled-companies`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setImpaneledComapnies(resData?.data));
                   })
            }).catch((err) => {
                console.log("ERROR in getImpaneledComapnies function(redux):", err.message, err);
            })
    }
}

const setImpaneledComapnies = (resData) => {
    if (resData) {
        return {
            type: "SET_IMPANELED_COMPANIES",
            payload: resData,
        }
    }
} 

export const getHighestPayingJobs = () => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/highest-pay-in-demand-jobs`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setHighestPayingJobs(resData?.data));
                    })
            }).catch((err) => {
                console.log("ERROR in getHighestPayingJobs function(redux):", err.message, err);
            })
    }
}

const setHighestPayingJobs = (resData) => {
    if (resData) {
        return {
            type: "SET_HIGHEST_PAY_JOBS",
            payload: resData,
        }
    }
}

export const getJobsByIndustry = (id) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-jobs-by-industry-web?industry_id=${id}`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setJobsByIndustry(resData?.data));
                    })
            }).catch((err) => {
                console.log("ERROR in getJobsByIndustry function(redux):", err.message, err);
            })
    }
}

const setJobsByIndustry = (resData) => {
    if (resData) {
        return {
            type: "SET_JOBS_BY_INDUSTRY",
            payload: resData,
        }
    }
}

export const getJobsByIndustrySlug = (industry_slug) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-jobs-by-industry-slug-web?industry_slug=${industry_slug}`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setJobsByIndustrySlug(resData?.data));
                  })
            }).catch((err) => {
                console.log("ERROR in getJobsByIndustry function(redux):", err.message, err);
            })
    }
}

const setJobsByIndustrySlug = (resData) => {
    if (resData) {
        return {
            type: "SET_JOBS_BY_INDUSTRY_SLUG",
            payload: resData,
        }
    }
}

export const getIndustryById = (id) => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-industry-by-id?industry_id=${id}`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setIndustryById(resData?.data));
                   })
            }).catch((err) => {
                console.log("ERROR in getIndustryById function(redux):", err.message, err);
            })
    }
}

const setIndustryById = (resData) => {
    if (resData) {
        return {
            type: "SET_INDUSTRY_BY_ID",
            payload: resData,
        }
    }
}

export const getIndustryBySlug = (slug) => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-industry-by-slug?industry_slug=${slug}`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setIndustryBySlug(resData?.data));
                    })
            }).catch((err) => {
                console.log("ERROR in getIndustryBySlug function(redux):", err.message, err);
            })
    }
}

const setIndustryBySlug = (resData) => {
    if (resData) {
        return {
            type: "SET_INDUSTRY_BY_SLUG",
            payload: resData,
        }
    }
}

export const getSixInterviewQuestions = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-six-interview-questions`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setSixInterviewQuestions(resData?.data));
                    })
            }).catch((err) => {
                console.log("ERROR in getSixInterviewQuestions function(redux):", err.message, err);
            })
    }
}

const setSixInterviewQuestions = (resData) => {
    if (resData) {
        return {
            type: "SET_SIX_INTERVIEW_QUESTIONS",
            payload: resData,
        }
    }
}

export const getInterviewQuestions = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-interview-questions`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setInterviewQuestions(resData?.data));
                   })
            }).catch((err) => {
                console.log("ERROR in getInterviewQuestions function(redux):", err.message, err);
            })
    }
}

const setInterviewQuestions = (resData) => {
    if (resData) {
        return {
            type: "SET_INTERVIEW_QUESTIONS",
            payload: resData,
        }
    }
}


export const getJobsInAfganistan = () => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/most-common-jobs-in-afganistan`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setJobsInAfganistan(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getJobsInAfganistan function(redux):", err.message, err);
            })
    }
}

const setJobsInAfganistan = (resData) => {
    if (resData) {
        return {
            type: "SET_JOBS_IN_AFGAN_DATA",
            payload: resData,
        }
    }
}


export const getAfganCities = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-afgan-cities`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setAfganCities(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getAfganCities function(redux):", err.message, err);
            })
    }
}

const setAfganCities = (resData) => {
    if (resData) {
        return {
            type: "SET_AFGAN_CITIES",
            payload: resData,
        }
    }
}


export const getDepartmentData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-department`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setDepartmentData(resData));
                   })
            }).catch((err) => {
                console.log("Error in getDepartmentData function(redux):", err.message, err);
            })
    }
}

export const setDepartmentData = (resData) => {
    if (resData) {
        return {
            type: "SET_DEPARTMENT_DATA",
            payload: resData
        }
    }
}

export const getCurrencyData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-currency`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setCurrencyData(resData?.data));
                    })
            }).catch((err) => {
                console.log("Error in getCurrencyData function(redux):", err.message, err);
            })
    }
}

export const setCurrencyData = (resData) => {
    if (resData) {
        return {
            type: "SET_CURRENCY_DATA",
            payload: resData
        }
    }
}

export const getBlogWithCategoryData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-blogs-with-category`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setBlogWithCategoryData(resData?.data));
                   })
            }).catch((err) => {
                console.log("Error in getBlogWithCategoryData function(redux):", err.message, err);
            })
    }
}

export const setBlogWithCategoryData = (resData) => {
    if (resData) {
        return {
            type: "SET_BLOG_WITH_CATEGORY_DATA",
            payload: resData
        }
    }
}

export const getBlogsByCategory = (id) => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-blog-on-basis-of-category-and-details?id=${id}`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setBlogsByCategory(resData));
                    })
            }).catch((err) => {
                console.log("Error in getBlogsByCategory function(redux):", err.message, err);
            })
    }
}

export const setBlogsByCategory = (resData) => {
    if (resData) {
        return {
            type: "SET_BLOG_BY_CATEGORY_DATA",
            payload: resData
        }
    }
}

export const getFamousCompanies = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-famous-companies`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setFamousCompanies(resData?.data));
                   })
            }).catch((err) => {
                console.log("Error in getFamousCompanies function(redux):", err.message, err);
            })
    }
}

export const setFamousCompanies = (resData) => {
    if (resData) {
        return {
            type: "SET_FAMOUS_COMPANIES_DATA",
            payload: resData
        }
    }
}

export const getSearchedBlog = (searched_text) => {
    return async function (dispatch) {
        await fetch(`${host}/master/search-blogs-with-category?searched_text=${searched_text}`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setSearchedBlog(resData));
                    console.log("BlogWithCategory data in dispatch action:", resData)
                })
            }).catch((err) => {
                console.log("Error in getSearchedBlog function(redux):", err.message, err);
            })
    }
}

export const setSearchedBlog = (resData) => {
    if (resData) {
        return {
            type: "SEARCHED_BLOGS",
            payload: resData
        }
    }
}

export const getNewsPageData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-news-page-data`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setNewsPageData(resData?.data));
                   })
            }).catch((err) => {
                console.log("Error in getNewsPageData function(redux):", err.message, err);
            })
    }
}

export const setNewsPageData = (resData) => {
    if (resData) {
        return {
            type: "NEWSPAGE",
            payload: resData
        }
    }
}

export const getAllNewsData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-news-with-category`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setAllNewsData(resData?.data));
                   })
            }).catch((err) => {
                console.log("Error in getAllNewsData function(redux):", err.message, err);
            })
    }
}

export const setAllNewsData = (resData) => {
    if (resData) {
        return {
            type: "ALL_NEWS",
            payload: resData
        }
    }
}

export const getNewsBySlug = (slug) => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-news-by-slug?news_slug=${slug}`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setNewsBySlug(resData?.data));
                     })
            }).catch((err) => {
                console.log("Error in getNewsBySlug function(redux):", err.message, err);
            })
    }
}

export const setNewsBySlug = (resData) => {
    if (resData) {
        return {
            type: "NEWS_BY_SLUG",
            payload: resData
        }
    }
}

export const getNewsByCategorySlug = (slug) => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-news-by-category-slug?name_slug=${slug}`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setNewsByCategorySlug(resData?.data));
                   })
            }).catch((err) => {
                console.log("Error in getNewsByCategorySlug function(redux):", err.message, err);
            })
    }
}

export const setNewsByCategorySlug = (resData) => {
    if (resData) {
        return {
            type: "NEWS_BY_CATEGORY_SLUG",
            payload: resData
        }
    }
}

export const getFindJobCompanyBanner = () => {
    return async (dispatch) => {
        await fetch(`${host}/master/find-jobs-comapnies-banner`, { method: 'GET' })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setFindJobCompanyBanner(resData?.data));
                   })
            }).catch((error) => {
                console.log("Error in getFindJobCompanyBanner function", error.message);
            })
    }
}

export const setFindJobCompanyBanner = (resData) => {
    return {
        type: "SET_FIND_JOB_COMPANY_BANNER_DATA",
        payload: resData
    }
}

export const getPhoneCodeData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-phone-code`,
            {
                method: "GET",
            }
        ).then((res) => {
            res.json().then((responseData) => {
                dispatch(setPhoneCodeData(responseData));
            })
        }).catch((err) => {
            console.log("Error in getPhoneCodeData function(redux):", err.message, err);
        })
    }
}

export const setPhoneCodeData = (responseData) => {
    if (responseData) {
        return {
            type: "SET_PHONE_CODE_DATA",
            payload: responseData
        }
    }
}
