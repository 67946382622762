import React, { useEffect } from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import ResumeTemplatesContent from './ResumeTemplatesContent'
import Footer from '../../Commons/Footer'

const ResumeTemplates = () => {
   
    return (
        <>
            <AfterNavbar />
            <ResumeTemplatesContent />
            <Footer />
        </>
    )
}

export default ResumeTemplates
