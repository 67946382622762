import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import './scss/OurPartnerSlider.css';
import $ from 'jquery';
import Slider from 'react-slick';
import { getOurBrandsLeadershipSliderData } from '../../../../Redux/action/RecruiterAction';
const host = process.env.REACT_APP_API_SERVER;
const OurBrandsLeadershipSlider = () => {
    const dispatch = useDispatch();
    useEffect(() => { dispatch(getOurBrandsLeadershipSliderData());}, [])
    const ourBrandsLeadershipSliderData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.ourBrandsLeadershipSliderDataList?.data) : []);
    const settingsOurBrands = {dots: false,infinite: true,autoplay: true,autoplaySpeed: 3000,slidesToShow: 1,slidesToScroll: 1,arrows: true,responsive: [{breakpoint: 1024,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: true,dots: true}},{breakpoint: 768,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: true,dots: true,arrows: false}},{breakpoint: 767,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: true,dots: true,arrows: false}},{breakpoint: 480,settings: {slidesToShow: 1,slidesToScroll: 1,infinite: true,dots: true,arrows: false}}]}
    return (
        <>
            <section className='our-partner-sec'>
                <div className="container">
                    <section className="slider-section mt-5">
                        <div className="row">

                            <div className="col-lg-12 mt-4">
                                <Slider {...settingsOurBrands} className="slider-card">
                                    {ourBrandsLeadershipSliderData?.map((data) => (
                                        <div className="card-desgin-item">
                                            <div className="brand-section-bg mt-5">
                                                <div className="brand-section-background">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="brand-image-bg mb-3">
                                                                <div>
                                                                    <img
                                                                        src={data?.img ? `${host}/${data?.img}` : "./assets/about-us/brand-img.png"}
                                                                        className="img-fluid"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="group-brand">
                                                                <div className='group-brand-img'>
                                                                    <img src={data?.icon ? `${host}/${data?.icon}` : "./assets/about-us/group-brand-img.png"} alt="" className='w-100' />
                                                                </div>
                                                                <div className="brand-last-head mx-3 mt-2">
                                                                    <h3> {data?.title ? data?.title : "Branding"}</h3>
                                                                    {data?.description ? parse(data?.description) :
                                                                        <p>
                                                                            Lorem Ipsum is simply dummy text of the printing and
                                                                            typesetting industry. Lorem Ipsum has been the industry
                                                                            standard dummy text ever since the 1500s, when an unknown
                                                                            printer took a galley of type
                                                                        </p>}                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}


                                </Slider>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </>
    )
}

export default OurBrandsLeadershipSlider
