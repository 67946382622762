import React, { useState, useEffect } from 'react'
import './scss/Questions.css'
import { Link, useParams, useNavigate } from 'react-router-dom'
import FlagIcon from '@mui/icons-material/Flag';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Select from 'react-select'
import { getQuestionType } from '../../../../Redux/action/MasterAction';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';
import Loader from '../../Loader';
import Error from '../../../Common/Error/Error';
import { questionDataAPI } from '../../../../Redux/action/SeekerAction';
const host = process.env.REACT_APP_API_SERVER;
const Questions = ({ company_name, company_slug }) => {
    const { name } = useParams();
    const navigate = useNavigate();
    const [expandedState, setExpandedState] = useState({});
    const dispatch = useDispatch();
    let [showQuesCat, setShowQueCat] = useState(true);
    let [allQueData, setAllQueData] = useState([]);
    let [queData, setQueData] = useState([]);
    let [activeSlug, setActiveSlug] = useState("");
    let [showCloseBtn, setShowCloseBtn] = useState(false);
    let [activeQueFilter, setActiveQueFiler] = useState("");
    function convertDate(isoDateStr) {
        let date = new Date(isoDateStr);
        let day = date.getUTCDate();
        let month = date.getUTCMonth(); 
        let year = date.getUTCFullYear();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let monthName = monthNames[month];
        let formattedDay = day < 10 ? '0' + day : day;
        let formattedDate = `${formattedDay}-${monthName}-${year}`;
        return formattedDate;}
    const isExpanded = expandedState[0];
    const {QU_DataError,QU_Data,loadingQuData,} = useSelector(state => state?.SeekerReducer);
    useEffect(() => {setQueData(QU_Data?.data); setAllQueData(QU_Data?.data);}, [QU_Data])
    const {QuestionType} = useSelector(state => state?.MasterReducer);
    useEffect(() => { dispatch(questionDataAPI(name, localStorage.getItem("seeker_token")));dispatch(getQuestionType(localStorage.getItem("seeker_token")));}, [dispatch, name]);
    const handleTabChange = () => {let tab = document.getElementById("ask-a-question-tab");
        if (tab) { tab.click() } setShowQueCat(false);resetFilter();};
    const options = QuestionType?.data?.map((value) => ({ value: value?.id,label: value?.question_type,
    }));
    const validationSchema = Yup.object({
        question_type_id: Yup.object().required('Please select a question category.'),
        question: Yup.string()
            .min(10, 'Question must be at least 10 characters.')
            .required('Please enter a question.'),
    });
    const formik = useFormik({
        initialValues: {
            question_type_id: '',
            question: '',
            company_slug: name
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            if (!localStorage.getItem("seeker_token")) {
                let loginModalQuestionsBtn = document.getElementById("loginModalQuestionsBtn");
                if (loginModalQuestionsBtn) {
                    loginModalQuestionsBtn.click();
                }
            } else {
                const submissionValues = {
                    ...values,
                    question_type_id: values.question_type_id?.value,
                };
                try {
                    const response = await axios.post(
                        `${host}/web/recruiter-question-page-ask-question`,
                        submissionValues,
                        {
                            headers: { "auth-token": localStorage.getItem("seeker_token") },
                        }
                    );
                    if (response.status === 200) {
                        if (response.data?.data.active === true) {
                            toast.success("Question added succesfully.");
                            dispatch(questionDataAPI(name, localStorage.getItem("seeker_token")));
                        } else {
                            toast.warn(response.data?.msg);
                        }
                    } else {
                        toast.error(response.data?.msg);
                    }
                } catch (error) {
                    console.error('Error submitting form', error.message);
                    toast.error("Internal server error");
                }
                setSubmitting(false);
                resetForm();
            }


        },
    });

    const handleSelectChange = (selectedOption) => {
        formik.setFieldValue('question_type_id', selectedOption);
    };

    const urlString = window.location.href;
    const url1 = new URL(urlString);
    const path = url1.pathname.slice(1);
    const searchParams = url1.search;
    const q = path + searchParams;

    const handleLoginRedirect = () => {
        let loginPopUpModalQuestionsCloseBtn = document.getElementById("loginPopUpModalQuestionsCloseBtn");
        if (!localStorage.getItem("seeker_token")) {
            if (loginPopUpModalQuestionsCloseBtn) {
                loginPopUpModalQuestionsCloseBtn.click();
                navigate(`/signin?q=${encodeURIComponent(q)}`);
            }
        } else {
            if (loginPopUpModalQuestionsCloseBtn) {
                loginPopUpModalQuestionsCloseBtn.click();
            }
        }
    };


    const filterData = (question_type_slug, question_type) => {
        setActiveSlug(question_type_slug);setActiveQueFiler(question_type);
        let filter = allQueData?.filter(question =>question.question_type && question.question_type.slug === question_type_slug
        );setQueData(filter);setShowCloseBtn(true);}
    const resetFilter = () => {setQueData(allQueData); setShowCloseBtn(false);setActiveSlug(""); }
    if (loadingQuData) {
        return (
            <div className="overlay-form-submission">
                <Loader />
            </div>
        );
    }

    if (QU_DataError) {
        return <Error message={QU_DataError} />;
    }

    return (
        <>
            <div className=" Questions-section">
                
                <div className="col-12">
                    {showCloseBtn ?
                        <h4 className='my-3'>{activeQueFilter} at {company_name} <span class="reset-filters mx-2" onClick={resetFilter}><b>Clear</b></span></h4>
                        :
                        <h4 className='my-3'>Questions and Answers about {company_name}</h4>
                    }
                    {showQuesCat && <div className="d-flex gap-3 review-box-container mt-3 mb-5">
                        {QuestionType?.data?.map((data, index) => {
                            return (
                              
                                <div className={`review-box cursor-pointer ${data.slug === activeSlug ? 'active-question-type' : ''}`} key={index + 1} onClick={() => filterData(data?.slug, data?.question_type)}>{data?.question_type}</div>
                            )
                        })}
                    </div>}
                </div>
                <div className="row">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a
                                className="nav-link py-3 px-4 active"
                                id="home-tab"
                                data-bs-toggle="tab"
                                onClick={() => { setShowQueCat(true) }}
                                href="#home"
                                role="tab"
                                aria-controls="home"
                                aria-selected="true"
                            >
                                <QuestionAnswerIcon /> Browse questions
                                ({queData?.length})
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                className="nav-link py-3 px-4"
                                id="ask-a-question-tab"
                                data-bs-toggle="tab"
                                onClick={() => { setShowQueCat(false); resetFilter(); }}
                                href="#profile"
                                role="tab"
                                aria-controls="profile"
                                aria-selected="false">
                                <ModeEditIcon /> Ask a question
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content mt-5" id="myTabContent">
                        <div
                            className="tab-pane active"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                        >
                            <div className="row justify-content-lg-between">
                                <div className="col-lg-8 ">
                                    <label htmlFor="jobTitle">
                                        {queData?.length} questions about working at {company_name}
                                    </label>
                                   
                                    <div className="mt-4">
                                        {queData?.map((value, index) => {

                                            const handleToggle = (index) => {
                                                setExpandedState((prevState) => ({
                                                    ...prevState,
                                                    [index]: !prevState[index],
                                                }));
                                            };
                                            const truncateText = (text, wordLimit) => {
                                                const words = text.split(' ');
                                                if (words.length > wordLimit) {
                                                    return words.slice(0, wordLimit).join(' ') + '...';
                                                }
                                                return text;
                                            };
                                            const truncatedDescription = value?.recruiter_question_answer[0]?.answer ? truncateText(value?.recruiter_question_answer[0]?.answer, 100) : '';
                                            return (
                                                <div className=" col-12  feedback">
                                                    <h3 className='cursor-pointer' onClick={() => {

                                                        navigate(`/profile/${name}/Answer/${value?.slug}/`, { state: { questionID: value?.id } });

                                                    }}>{value?.question}</h3>
                                                    <span>Asked {convertDate(value?.createdAt)}</span>
                                                    <div className='my-2'>

                                                        <p className="mb-0 text">
                                                            {value?.recruiter_question_answer?.length > 0 && isExpanded ? value?.recruiter_question_answer[0]?.answer : truncatedDescription}
                                                        </p>
                                                        {value?.recruiter_question_answer?.length > 0 && value.recruiter_question_answer[0]?.answer?.split(' ').length > 100 && (
                                                            <Link onClick={() => handleToggle(0)}>
                                                                {isExpanded ? "Show Less" : "Show More"}
                                                            </Link>
                                                        )}
                                                    </div>
                                                   
                                                    <div className="my-3 d-flex justify-content-between align-items-center ">
                                                        <div className='d-flex justify-content-start align-items-center gap-3'>

                                                            <div onClick={() => {

                                                                navigate(`/profile/${name}/Answer/${value?.slug}/`, { state: { questionID: value?.id } });

                                                            }} className="update-button text-decoration-none mt-sm-0 mt-2 cursor-pointer">
                                                                Answer
                                                            </div>
                                                            {
                                                                value?.recruiter_question_answer?.length === 0 &&
                                                                <div onClick={() => {

                                                                    navigate(`/profile/${name}/Answer/${value?.slug}/`, { state: { questionID: value?.id } });

                                                                }} className="ans d-flex justify-content-center align-items-center cursor-pointer">
                                                                    <p className="mb-0">
                                                                        Be the first to answer!
                                                                    </p>
                                                                </div>
                                                            }
                                                            <div className="">
                                                                {
                                                                    value?.recruiter_question_answer?.length > 0 &&
                                                                    <div onClick={() => {

                                                                        navigate(`/profile/${name}/Answer/${value?.slug}/`, { state: { questionID: value?.id } });
                                                                    }} className='font-semibold d-flex justify-content-center align-items-center cursor-pointer '>
                                                                        See all answers
                                                                        <ChevronRightRoundedIcon />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                           
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-xl-3">
                                    <div className="box-rounded mb-3 text-center">
                                        <h4 className="mb-2 text-center">
                                            Can't find your question about {company_name}?
                                        </h4>
                                        <button className="update-button mt-sm-0 mt-3 w-fit" onClick={handleTabChange}>
                                            Ask a Question
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane"
                            id="profile"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                        >
                            <div className="help-full-ans col-lg-8">

                                <form onSubmit={formik.handleSubmit}>
                                    <h3>Select category</h3>
                                    <div className='mb-4'>

                                        <Select
                                            options={options}
                                            placeholder="Select your question category"
                                            onChange={handleSelectChange}
                                            value={formik.values.question_type_id}
                                            onBlur={() => formik.setFieldTouched('question_type_id')}
                                        />
                                        {formik.touched.question_type_id && formik.errors.question_type_id ? (
                                            <div className="text-danger">{formik.errors.question_type_id}</div>
                                        ) : null}
                                    </div>
                                    <h3>Tips to get helpful answers</h3>
                                    <div className="d-sm-flex justify-content-between align-items-end mb-4">
                                        <ul className='list-style-none ps-0 mb-0'>
                                            <li>
                                                <p className="mb-0">Check that your question hasn't already been asked</p>
                                            </li>
                                            <li>
                                                <p className="mb-0">Ask a direct question</p>
                                            </li>
                                            <li>
                                                <p className="mb-0">Check your spelling and grammar</p>
                                            </li>
                                        </ul>
                                      
                                    </div>
                                    <div className="mb-3">

                                        <textarea
                                            className="form-control "
                                            id="exampleFormControlTextarea1"
                                            rows={3}
                                            placeholder='Type your question here'
                                            value={formik.values.question}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="question"
                                        />
                                        {formik.touched.question && formik.errors.question ? (
                                            <div className="text-danger">{formik.errors.question}</div>
                                        ) : null}
                                    </div>
                                   
                                    <button type="submit" className="submit-btn">Submit</button>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

                <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#loginPopUpModalQuestions" id="loginModalQuestionsBtn">
                    Launch demo modal
                </button>
                <div className="modal fade" id="loginPopUpModalQuestions" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-center">
                                <h5 className="modal-title" id="exampleModalLabel"><img src="/assets/images/logo.png" /></h5>
                                <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" id="loginPopUpModalQuestionsCloseBtn"></button>
                            </div>
                            <div className="modal-body">
                                <div className="card-body p-3">
                                    <h3 className="text-center" style={{
                                        fontSize: '20px !important',
                                        marginBottom: '20px',
                                    }}>
                                     
                                        Ready to take next step ?
                                    </h3>
                                    <p className="text-center" style={{ fontSize: "15px" }}>Sign in to Karlatoon.</p>
                                    <p className="text-muted text-justify">
                                        <small>
                                            You may also apply directly on the company's website. By
                                            creating an account or signing in, you understand and agree to
                                            Karlatoon's <Link to="/terms-conditions" target='_blank'>Terms <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You also consent to our{" "}
                                            <Link to="/privacy-policy" target='_blank'>Privacy policies <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You will receive
                                            marketing messages from Karlatoon and may opt out at any time by
                                            following the unsubscribe link in our messages, or as detailed in
                                            our terms.
                                        </small>
                                    </p>

                                    <div className="text-center mb-3"></div>
                                    <div className="d-grid gap-2 mb-3 justify-content-center">
                                        <button className="btn btn-outline-secondary popup-login-btn" type="button" onClick={handleLoginRedirect}
                                            style={{
                                                padding: "5px 20px",
                                                borderRadius: "40px",
                                                background: "#1B375C",
                                                textDecoration: "none",
                                                color: "#FFF",
                                                textAlign: "center",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                border: "1px solid #1B375C",
                                                width: "fit-content"
                                            }}
                                        >
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        </>
    )
}

export default Questions