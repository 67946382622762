import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../Loader";
import './scss/RecCandidateProfile.css'
import { getCandidateProfileData } from '../../../Redux/action/RecruiterAction';
import { toast } from 'react-toastify';

const host = process.env.REACT_APP_API_SERVER;
const RecCandidateProfileContent = (seeker_id) => {

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const candidate_id = queryParams.get('seeker_id');
        let authToken = localStorage.getItem("recruiter_token");
        dispatch(getCandidateProfileData(authToken, candidate_id));
        if (!seeker_id) {
            toast.error("seeker_id is missing.");
            navigate(-1); 
        }
    }, []);


    const candidateProfileData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.candidateProfileDataList?.data) : "")
 
    const [showLoader, setShowLoader] = useState(true);
    useEffect(() => {
        if (candidateProfileData) {
            setShowLoader(false);
        }
    }, [candidateProfileData]);

  
    let defaultResumeName = '';
    let defaultResumeId = 0;

    if (candidateProfileData?.resume_list?.length) {
        for (let i = 0; i < candidateProfileData?.resume_list?.length; i++) {
            const resume = candidateProfileData?.resume_list[i];
            if (resume?.set_default === true) {
                if (resume?.resume !== null) {
                    defaultResumeName = resume?.resume;
                    defaultResumeId = resume?.id
                    break;
                } else {
                    let j = i - 1;
                    while (j >= 0 && candidateProfileData?.resume_list[j]?.resume === null) {
                        j--;
                    }
                    if (j >= 0) {
                        defaultResumeName = candidateProfileData?.resume_list[j]?.resume;
                        defaultResumeId = candidateProfileData?.resume_list[j]?.id
                    }
                    break;
                }
            }
        }
    }

    const professionalDetails = candidateProfileData && candidateProfileData.Seeker_professional_details ? candidateProfileData.Seeker_professional_details : {};
    const educationalData = candidateProfileData?.seekerEducationalDetail ? candidateProfileData.seekerEducationalDetail : [];
    const projectDetails = candidateProfileData && candidateProfileData.Seeker_project_details;
    const skillsDetails = candidateProfileData && candidateProfileData.Seeker_keyskills;

    const certificationDetails = candidateProfileData && candidateProfileData.Seeker_certifications;
    const socialMediaDetails = candidateProfileData && candidateProfileData.Seeker_social_links ? candidateProfileData.Seeker_social_links : [];



    const handleViewandIncreaseCount = (defaultResumeName, resume_id) => {
        try {
            if (!defaultResumeId) {
                toast.error("Resume not uploaded on profile.");
                return;
            }
            let config = {
                headers: {
                    "auth-token": localStorage.getItem("recruiter_token")
                }
            }
            const pdfUrl = `${host}/uploaded-files/${defaultResumeName}`;
            console.log(pdfUrl);
            window.open(pdfUrl, '_blank');
            const queryParams = new URLSearchParams(location.search);
            const seeker_id = queryParams.get('seeker_id');
            const values = {
                seeker_id: seeker_id,
                resume_id: resume_id
            }

            axios.post(`${host}/recruiter/increase-resume-download-count`, values, config)
                .then((response) => {
                    console.log(response.data.msg);
                }).catch(error => {
                    console.log(error.message)
                })

        } catch (error) {
            console.log(error.message);
        }
    }
    return (
        <>
            <section className='rec-candidate-profile-sec'>
                <div className='container'>
                    <div className='candidate-pro-section-inner-dv'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-12'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-4 col-md-4 col-12'>
                                        <div className='condidate-pro-heading'>
                                            <h2>Candidate Profile</h2>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            {candidateProfileData?.profile_img && <div className='col-lg-4 col-md-4 col-12 mt-lg-5 mt-md-3 mt-3'>
                                <div className='sidepanel-img-tabs-parent'>
                                    <div className='sidepanel-img'>
                                        {(candidateProfileData && <img
                                            src={
                                                candidateProfileData?.profile_img
                                                    ? `${host}/uploaded-files/${candidateProfileData?.profile_img}`
                                                    : "assets/images/cv-builder/account.png"
                                            }
                                            alt="Avatar"
                                            style={{ cursor: 'pointer', borderRadius: '50%', objectFit: 'cover' }}
                                            width={200} height={200}
                                        />)}
                                    </div>
                                    {skillsDetails?.length > 0 && <div className='skill-heading mt-4'>
                                        <h2 className='skill-head'>Skills</h2>

                                        {skillsDetails?.map((data) => (<button className='skill-btn'>{data?.skill_name == '0' ? data?.other_skill_name : data?.skill?.skill}</button>))}

                                    </div>}

                                </div>
                            </div>}
                            <div className='col-lg-8 col-md-8 col-12 mt-lg-5 mt-md-3 mt-3'>
                                <div className='rght-dv-profile-details'>
                                    <div className='top-heading'>
                                        {candidateProfileData?.firstName && <h2>{candidateProfileData?.firstName ? candidateProfileData?.firstName : ''} {candidateProfileData?.lastName ? candidateProfileData?.lastName : ''}</h2>}
                                        {candidateProfileData?.designation?.designation && <h5>{candidateProfileData?.designation?.designation ? candidateProfileData?.designation?.designation : ''}</h5>}
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <div className='social-details'>
                                                    {candidateProfileData?.mobile && <h6><strong>Phone: </strong><a href='tel:9889468998'>{candidateProfileData?.mobile ? candidateProfileData?.mobile : ''}</a></h6>}
                                                </div>
                                            </div>
                                          
                                            {candidateProfileData?.email && <div className='col-lg-6 col-md-6 col-12'>
                                                <div className='social-details'>
                                                    <h6><strong>Email: </strong><a href={candidateProfileData?.email ? candidateProfileData?.email : ''}>{candidateProfileData?.email ? candidateProfileData?.email : ''}</a></h6>
                                                </div>
                                            </div>}
                                          
                                        </div>
                                    </div>
                                    {professionalDetails?.[0] &&
                                        <div className='top-heading top-heading2'>
                                            <h2>Experience</h2>
                                            {candidateProfileData && professionalDetails && professionalDetails?.map((data) => (<div className='resume-details-company'>

                                                <h5>{data?.designation ? data?.designation : ''}  {data?.current_last_company ? `for ${data?.current_last_company}` : ''} {data?.company_location === null ? '' : `@${data?.company_location}`}</h5>
                                                <p> {data?.joining_date} to {data?.relieving_date ? data?.relieving_date : 'Present'} </p>
                                              
                                            </div>))}

                                        </div>}
                                    {projectDetails?.[0] &&
                                        <div className='top-heading top-heading2'>
                                            <h2>Personal Project</h2>
                                            {candidateProfileData && projectDetails && projectDetails?.map((data) => (<div className='resume-details-company'>
                                                <h5>{data?.project_title}</h5>
                                                <p>{data?.project_status ? 'In-Progress' : 'Finished'}</p>
                                                <p className='mt-2'>
                                                    <span > {data?.project_start_date} - {data?.project_end_date ? data?.project_end_date : 'present'} </span>
                                                </p>
                                              
                                            </div>))}


                                        </div>}
                                    {educationalData?.[0] &&
                                        <div className='top-heading top-heading2'>
                                            <h2>Education</h2>
                                            {candidateProfileData && educationalData && educationalData?.map((data) => (<div className='resume-details-company'>
                                                <h5>{data?.educationlevel?.educationlevel}</h5>
                                                <span>{data.specialization}</span>
                                                <p>{data?.start_year && data.start_year.slice(0, 4)} to {data?.end_year ? data?.end_year && data.end_year.slice(0, 4) : `Present`} </p>
                                            </div>))}

                                        </div>}
                                    {certificationDetails?.[0] &&
                                        <div className='top-heading top-heading2'>
                                            <h2>Certification</h2>
                                            {candidateProfileData && certificationDetails && certificationDetails?.map((data) => (<div className='resume-details-company'>
                                                <h5>{data.certification_name}</h5>

                                                <p>{data.certification_number ? data.certification_number : ""}</p>
                                            </div>))}

                                        </div>}
                                  
                                </div>
                            </div>
                        </div>
                        {candidateProfileData?.resume_list?.length > 0 &&
                          
                            defaultResumeId && <button className="btn btn-success" style={{ backgroundColor: '#212529', borderColor: '#212529' }} onClick={() => { handleViewandIncreaseCount(defaultResumeName, defaultResumeId) }}>Download Resume</button>}
                    </div>

                </div>
                {
                    showLoader && <div className="overlay-form-submission">
                        <Loader />
                    </div>
                }
            </section >
        </>
    )
}

export default RecCandidateProfileContent