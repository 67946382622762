import React, { useEffect, useState } from 'react'
import './scss/CreateAnEmployerAccount.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getCompanysizeData, getRecruiterregistrationData } from '../../../../Redux/action/RecruiterAction';
import { useFormik } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
import { FallingLines } from 'react-loader-spinner';
const host = process.env.REACT_APP_API_SERVER

const CreateAnEmployerAccountContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setisLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    useEffect(() => {
        dispatch(getCompanysizeData());
        if (localStorage.getItem("recruiter_token")) {
            setIsLoggedIn(true);
            let authToken = localStorage.getItem("recruiter_token");
            dispatch(getRecruiterregistrationData(authToken));
        }
    }, []);
    const companysizeData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.companysizeDataList?.data) : "");
    const recruiterRegistrationData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.recruiterRegisterDataList?.data) : "");


    let [initialValues, setInitialValues] = useState({
        company_name: "",
        size: "",
        company_contactperson_fname: "",
        company_contactperson_lname: "",
        company_contactperson_mobile: "",
        how_you_heard_about_us: "",
        tnc: false,
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            company_name: Yup.string().required("Company Name is required."),
            size: Yup.string().required("Please select a Company Size."),
            company_contactperson_fname: Yup.string().required("First Name is required."),
            company_contactperson_lname: Yup.string().required("Last Name is required."),
            company_contactperson_mobile: Yup.string().required("Mobile No. is required.").matches(/^[0-9]{8,15}$/, "Please enter a valid mobile number."),
            how_you_heard_about_us: Yup.string().required("Please select an option."),
            tnc: Yup.boolean().oneOf([true], 'Please accept terms & conditions.')
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {

                setSubmitting(true);
                setShowLoader(true);
                document.body.style.overflow = 'hidden';
                const config = {
                    headers: {
                        'content-type': 'application/json',
                    }
                };
                await axios.post(`${host}/api/recruiter-employer-register`, values, config)
                    .then(function (response) {

                        if (response.status == 200) {
                            localStorage.setItem("recruiter_token", response.data.authToken)
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Form submitted successfully!' });
                            navigate('/company-details');
                        } else {
                            console.log("in else block of .then method:", response.data.msg);
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        console.log(error.message);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    })


            } catch (error) {
                console.log("catch:", error);
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            } finally {
                
            }
        }
    });

    useEffect(() => {
        if (companysizeData) {

            setisLoading(true);
        }
    }, [companysizeData])

    return (
        <>
            {isLoading && <section className='create-an-employer-ac-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2>Create an employer Account</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='form-area'>
                                <form method='post' onSubmit={formik.handleSubmit}>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='form-inner-top'>
                                                <p>You haven’t post a job before, so you’ll need to create an employer account.</p>
                                                <a href='#'>Not here to post a job?</a>
                                            </div>
                                        </div>
                                        {/* Company name */}
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='input-label'>
                                                <label htmlFor='company-name'>Your company name</label>
                                                <input
                                                    type="text"
                                                    id="company_name"
                                                    className='form-control'
                                                    placeholder='Your company name'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.company_name}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.company_name && formik.errors.company_name ? (
                                                    <div className='text-danger'>{formik.errors.company_name}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Company Size */}
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='input-label'>
                                                <label htmlFor='company-no'>Your company’s number of employees</label>
                                                <select
                                                    className='form-control'
                                                    name='size'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.size}
                                                >
                                                    <option value="">-Select an option-</option>
                                                    {
                                                        companysizeData.map(size => {
                                                            return <option key={size.id} value={size.id}>{size.companysize}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.size && formik.errors.size ? (
                                                    <div className='text-danger'>{formik.errors.size}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* First name */}
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='input-label'>
                                                <label htmlFor='company_contactperson_fname'>Your first name</label>
                                                <input
                                                    type="text"
                                                    id="company_contactperson_fname"
                                                    name="company_contactperson_fname"
                                                    className='form-control'
                                                    placeholder='Your first and last name'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.company_contactperson_fname}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.company_contactperson_fname && formik.errors.company_contactperson_fname ? (
                                                    <div className='text-danger'>{formik.errors.company_contactperson_fname}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Last name */}
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='input-label'>
                                                <label htmlFor='company_contactperson_lname'>Your last name</label>
                                                <input
                                                    type="text"
                                                    id="company_contactperson_lname"
                                                    name="company_contactperson_lname"
                                                    className='form-control'
                                                    placeholder='Your last name'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.company_contactperson_lname}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.company_contactperson_lname && formik.errors.company_contactperson_lname ? (
                                                    <div className='text-danger'>{formik.errors.company_contactperson_lname}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Mobile */}
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='input-label'>
                                                <label htmlFor='company_contactperson_mobile'>Your phone number</label>
                                                <input
                                                    type="text"
                                                    id="company_contactperson_mobile"
                                                    name="company_contactperson_mobile"
                                                    className='form-control'
                                                    placeholder='Your phone number'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.company_contactperson_mobile}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.company_contactperson_mobile && formik.errors.company_contactperson_mobile ? (
                                                    <div className='text-danger'>{formik.errors.company_contactperson_mobile}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* How you heard about us */}
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='input-label'>
                                                <label htmlFor='company-no'>How you heard about us</label>
                                                <select className='form-control'
                                                    name='how_you_heard_about_us'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.how_you_heard_about_us}
                                                >
                                                    <option value="">-Select an Option-</option>
                                                    <option value="Radio(AM/FM/XM)">Radio(AM/FM/XM)</option>
                                                    <option value="Newspaper">Newspaper</option>
                                                    <option value="Online Video">Online Video</option>
                                                    <option value="Streaming Audio (ex. Spotify)">Streaming Audio (ex. Spotify)</option>
                                                    <option value="TV">TV</option>
                                                    <option value="Social Media">Social Media</option>
                                                    <option value="Podcast">Podcast</option>
                                                    <option value="Search Engine (ex. Google, Yahoo)">Search Engine (ex. Google, Yahoo)</option>
                                                    <option value="Mail">Mail</option>
                                                    <option value="Billboard">Billboard</option>
                                                    <option value="Word of mouth">Word of mouth</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.how_you_heard_about_us && formik.errors.how_you_heard_about_us ? (
                                                    <div className='text-danger'>{formik.errors.how_you_heard_about_us}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Tnc */}
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='input-label-checkbox'>
                                                <input type='checkbox'
                                                    id='tnc'
                                                    className='form-controld'
                                                    name="tnc"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.tnc}
                                                    checked={formik.values.tnc ? true : false}
                                                />
                                                <label htmlFor='tnc'>By clicking this box and providing your telephone or wireless number, you agree to receive marketing and informational calls and texts from Karlatoon (including prerecorded or artificial voice messages and autodialed calls and texts) at the telephone or wireless number provided. Your agreement to this is not required to obtain any product or service.</label>
                                            </div>
                                        </div>
                                        <div className="help-block with-errors">
                                            {formik.touched.tnc && formik.errors.tnc ? (
                                                <div className='text-danger'>{formik.errors.tnc}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='bottom-button-dv'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6 col-12 text-end'>
                                                <div className='bottom-btn'>
                                                    <Link to='/post-job' type='submit' className='a-tag-btn1'>Back</Link>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12 text-start'>
                                                <div className='bottom-btn'>
                                                    <Link to='/like-to-post-job' type='submit' className='a-tag-btn2'>Continue</Link>
                                                    <button className="btn-sm" type='submit'>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {formik.isSubmitting && <div className="overlay-form-submission">
                    <FallingLines
                        color="#1b375c"
                        width="100"
                        visible={true}
                        ariaLabel='falling-lines-loading'
                    />
                </div>
                }
            </section>}
        </>
    )
}

export default CreateAnEmployerAccountContent