import React, { useEffect, useState } from 'react'
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import "./CareerPreferencesPopups.css"
import { Formik, useFormik } from 'formik'
import * as Yup from "yup";
import { toast } from 'react-toastify'
import CareerPreferencesPopupsJobTitle from './CareerPreferencesPopupsJobTitle'
import { useDispatch, useSelector } from 'react-redux'
import { getScheduleData } from '../../../Redux/action/RecruiterAction'
import Select from 'react-select';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER

const CareerPreferencesPopupsShift = ({ data, reload }) => {
    const dispatch = useDispatch();
    const [scheudules, setSchedules] = useState([]);
    useEffect(() => {
        dispatch(getScheduleData());
    }, []);

    const scheduleSelector = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.scheduleDataList : []);
       const scheduleOptions = [];
    scheduleSelector && scheduleSelector?.data && scheduleSelector?.data?.map(schedule => {
        scheduleOptions.push({ value: schedule.id, label: `${schedule.schedulename}` })
    })
    useEffect(() => {
        if (scheduleSelector) {setSchedules(scheduleSelector);
        }
    }, [scheduleSelector]);

    const initialValues = {
        schedule: [],
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            schedule: Yup.array().min(1, 'Please select a schedule.').required('Please select a schedule.'),
        }),
        onSubmit: (values, { resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            const config = { headers: { "auth-token": localStorage.getItem("seeker_token") } };
            try {
                axios.post(`${host}/mobile/add-career-prefernces-schedule`, values, config)
                    .then(response => {
                        if (response.status == 200) {
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                           reload();
                            const button = document.getElementById('close-schedule-modal-btn');
                            button.click();
                        } else {
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
            } catch (error) {
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server Error." });
            }
        }
    })

    useEffect(() => {
        if (data) {
            if (data?.schedule) {
                const arr = data?.schedule.map(value => value.schedule_id);
                formik.setValues({ schedule: arr })
            }
        }
    }, [data])


    return (
        <>
            <div className="modal fade add-work-schedule-body-parent" id="add-work-schedule" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form method="post" onSubmit={formik.handleSubmit}>
                            <div className="modal-header">
                                <h1 className="modal-title" id="exampleModalLabel">
                                    Add Preferred Schedule
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body add-work-schedule-body">
                                <div className='add-job-types-body'>
                                    <h4>What are your preferred schedules?</h4>
                                    <h5 className='pt-2'>Days</h5>
                                    <Select
                                        id="schedule"
                                        name="schedule"
                                        placeholder="Select Preferred Shifts/Schedules"
                                        options={scheduleOptions}
                                        value={scheduleOptions.filter(option => formik.values.schedule.includes(option.value))}
                                        onChange={schedule => {
                                            formik.setFieldValue(
                                                'schedule',
                                                schedule ? schedule.map(option => option.value) : []
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        isMulti
                                    />
                                    <div className="help-block with-errors">
                                        {formik.touched.schedule && formik.errors.schedule ? (
                                            <div className='text-danger'>{formik.errors.schedule}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary cancel-btn" data-bs-dismiss="modal" id='close-schedule-modal-btn'>
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary save-btn">
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    )
}

export default CareerPreferencesPopupsShift