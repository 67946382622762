import React, { useState } from 'react'
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import "./CareerPreferencesPopups.css"
import { Formik, useFormik } from 'formik'
import * as Yup from "yup";
import { toast } from 'react-toastify'
import CareerPreferencesPopupsJobTitle from './CareerPreferencesPopupsJobTitle'
import CareerPreferencesPopupsJobTypes from './CareerPreferencesPopupsJobTypes'
import CareerPreferencesPopupsLocation from './CareerPreferencesPopupsLocation'
import CareerPreferencesPopupsShift from './CareerPreferencesPopupsShift'
import CareerPreferencesPopupsEmployementType from './CareerPreferencesPopupsEmployementType'
const CareerPreferencesPopups = ({ data, reload }) => {
    const [state, setState] = useState([]);

    return (
        <>
            {/* =========================add-job-titles-body-parent start============================= */}
            <CareerPreferencesPopupsJobTitle data={data} reload={reload} />
            {/* =========================add-job-types-body-parent end============================= */}
            {/* =========================add-job-types-body-parent start============================= */}
            <CareerPreferencesPopupsJobTypes data={data} reload={reload} />
            <CareerPreferencesPopupsLocation data={data} reload={reload} />
            <CareerPreferencesPopupsShift data={data} reload={reload} />
            <CareerPreferencesPopupsEmployementType data={data} reload={reload} />

        </>

    )
}

export default CareerPreferencesPopups