import React, { useEffect, useState } from 'react'
import RegisterHeader from '../CvBuilder/CvBuilderHeader/RegisterHeader'
import Footer from '../Commons/Footer'
import EnterpasswordContent from './EnterpasswordContent'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loader from '../Loader'


const Enterpassword = () => {
  let [showContent, setShowContent] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { email_mobile } = location.state || "";
  useEffect(() => {
    if (localStorage.getItem("seeker_token")) {
      toast.warn("Already logged in.");
      navigate(-1)
    }
    if (email_mobile) {
      setShowContent(true);
    } else {
      toast.error("Please Enter a valid Email.");
      setShowContent(false);
      navigate("/signin")
    }
  })
  return (
    <>
      {showContent ? <>
        <RegisterHeader />
        <EnterpasswordContent email_mobile={email_mobile} />
        <Footer />
      </> :
        <div className="overlay-form-submission">
          <Loader />
        </div>
      }
    </>
  )
}

export default Enterpassword