import React, { useState } from 'react';
import "./scss/Chat.css";
import { createImageFromInitials, getRandomColor } from '../../Utils';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
const host = process.env.REACT_APP_API_SERVER;
const ReadChatContent = ({ data, company, refresh, addMessageToChatData }) => {
    const navigate = useNavigate();
    let [input, setInput] = useState("");
    function adjustHeight() {
        const textarea = document.getElementById('message-input');
        const lineHeight = 24; textarea.style.height = 'auto';        
        const newHeight = Math.min(textarea.scrollHeight, lineHeight * 3);textarea.style.height = newHeight + 'px';}
    const sendMessage = () => {
        let sendButton = document.getElementById("send-message-button");
        try {
            let message = document.getElementById("message-input");            
            const messageValue = input.trim();
            if (!messageValue) return;sendButton.disabled = true;
            let values = {sender_type: "SEEKER",recruiter_id: company?.id,message: input.trim(),};
            let config = { headers: { "auth-token": localStorage.getItem("seeker_token") } };
            addMessageToChatData(values);
            axios.post(`${host}/chat/send-seeker-message`, values, config)
                .then(response => {
                    if (response.status == 200) {message.value = "";setInput("");sendButton.disabled = false;refresh();
                        const scrollableContent = document.getElementById('chat-container');scrollableContent.scrollTop = scrollableContent.scrollHeight;                      
                    } else { sendButton.disabled = false; toast.error(response.data.msg);}
                })
        } catch (error) {console.log(error.message);sendButton.disabled = false;toast.error("Internal server error.")}
    }

    return (
        <div className="container seeker-chat-messages-container">
            <div className="d-flex my-1">

                <div className="mx-1 d-flex align-items-center p-0 m-0" >
                    <Link to={-1} type="button" className="btn btn-secondary btn-sm " title='Go Back'><i className="fa-solid fa-arrow-left"></i></Link>
                </div>
                <div className='mx-1 p-0 m-0 d-flex align-items-center'>
                    <img src={`${host}/uploaded-files/${company?.logo}`} alt={company?.company_name} className='seeker-chat-message-company-logo'
                        onChange={(event) => setInput(event.target.value)}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = createImageFromInitials(500, company?.company_name, getRandomColor());
                        }}
                    />
                </div>
                <div className='mx-1 d-flex align-items-center p-0 m-0'>
                    <h3>{company ? (company?.company_name ?? "Karlatoon Chat") : "Karlatoon Chat"}</h3>
                </div>
            </div>
            <div className="row seeker-chat-messages">
                <div className="chat-progress">
                    <div className="chat" id="chat-container">
                        {data && data?.length > 0 ? data?.map((chat, index) => {
                            return <div className={chat?.sender_type === "SEEKER" ? `mine messages` : `your messages`} key={index + 1}>
                                <div className="message">
                                    {chat?.message}
                                </div>
                            </div>
                        }) :
                            <div className="my-3 text-center">
                                Loading messages...
                            </div>
                        }
                    </div>
                </div>
                <div className="d-flex justify-content-center gap-3 custom-footer w-100 mt-2">
                    <textarea type="text" className="form-control p-1 m-1 mb-3 w-100 chat-input" id="message-input" placeholder='Enter your message'
                        rows={1}
                        onChange={(event) => setInput(event.target.value)}
                        onInput={adjustHeight}
                    
                    ></textarea>
                    
                    <div className=" snd-wid ">
                        <button type='button' className="btn btn-sm btn-primary m-1 mb-3" title='Send message' id="send-message-button" onClick={sendMessage}><i className="fa fa-paper-plane" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReadChatContent