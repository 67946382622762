import React, { useEffect, useState } from 'react'
import ShowJobscontent from './ShowJobscontent'
import Footer from '../../Commons/Footer'
import AfterNavbarwithoutLogin from '../../Commons/AfterLoginHeader/AfterNavbarwithoutLogin'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar';


const ShowJobs = () => {
  const [loadPage, setLoadPage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const industry_id = queryParams.get('i');
  useEffect(() => {
    if (!industry_id) {
      toast.error("Please enter a valid URL.")
      navigate(-1);
    }
  }, [])
  return (
    <>
      <AfterNavbar />
      <ShowJobscontent industry_id={industry_id} />
      <Footer />
    </>
  )
}

export default ShowJobs