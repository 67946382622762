import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import AddJobBasicsContent from './AddJobBasicsContent'
import Footer from '../../Commons/Footer'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loader from '../../Loader'
const host = process.env.REACT_APP_API_SERVER;
const AddJobBasics = () => {
  const navigate = useNavigate();
  const [recruiterLogin, setRecruiterLogin] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem("recruiter_token")) {
      navigate("/recruiter-login");
      toast.error("Please Login to continue.");
    } else {
      let config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } }
      axios.get(`${host}/api/check-recruiter-profile-completion`, config)
        .then(response => {
          if (response.status === 200) {
            if (response.data.data === true) {
              setRecruiterLogin(true);
            } else {
              setRecruiterLogin(false);
              toast.error("Please complete your profile before continuing.")
              navigate("/company-details");
            }
          } else {
            setRecruiterLogin(false);
            toast.error(response.data.msg);
            navigate(-1);
          }
        }).catch(err => {
          console.log(err.message);
          setRecruiterLogin(false);
          toast.error("Internal server error.");
          navigate(-1);
        })
    }
  }, []);

  return (
    <>
      {recruiterLogin ? <>
        <RecAfterLoginNavbar />
        <AddJobBasicsContent />
        <Footer />
      </> :
        <div className="overlay-form-submission">
          <Loader />
        </div>
      }
    </>
  )
}

export default AddJobBasics