import React, { useEffect, useState } from 'react'
import EnterOTPScreen from '../EnterOTPScreen/EnterOTPScreen'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Formik, Form, Field, useFormik } from "formik";
import { FocusError } from 'focus-formik-error';
import * as Yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getBenefitData, getBusinesstypeData, getCitybyStateData, getCitybyStateDataHQ, getCityData, getCompanysizeData, getCountryData, getIndustryData, getRecruiterregistrationData, getStatebyCountryData, getStatebyCountryDataHQ, resetRegistrationForm } from '../../../../Redux/action/RecruiterAction';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FallingLines } from 'react-loader-spinner';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getCurrencyData, getDepartmentData } from '../../../../Redux/action/MasterAction';
import EnterOTPScreen2 from '../EnterOTPScreen/EnterOTPScreen2';
const host = process.env.REACT_APP_API_SERVER

const CompanyDetailsForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setisLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [timerStarted, setTimerStarted] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const [disabled, setDisabled] = useState(false);
    const currentYear = new Date().getFullYear();
    const handleStartTimer = () => {
        setTimerStarted(true);
    };
    useEffect(() => {
        let intervalId;
        if (timerStarted && seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [timerStarted, seconds]);
    const [email, setEmail] = useState('');
    const handleEmailChange = (e) => {setEmail(e.target.value);};
    const countryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.countryDataList?.data) : "");
    const stateData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.stateDataList?.data) : "");
    const industryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.industryDataList?.data) : "");
    const benefitData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.benefitDataList?.data) : "");
    const departments_listData = useSelector((state) => (state?.MasterReducer) ? (state?.MasterReducer?.departmentList?.data) : "");
    const businesstypeData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.businesstypeDataList?.data) : "");
    const currencyData = useSelector((state) => (state?.MasterReducer) ? (state?.MasterReducer?.currencyList) : "");
    const companysizeData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.companysizeDataList?.data) : "");
    const recruiterRegistrationData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.recruiterRegisterDataList?.data) : "");
    const benefitOptions = [];
    const departments_listOptions = [];
    benefitData?.map(benefit => {let key = benefit.id;benefitOptions.push({ value: benefit.id, label: benefit.benefitname })
    })
    departments_listData?.map(department => {let key = department.id;departments_listOptions.push({ value: department.id, label: department.department })
    })  
    let [initialValues, setInitialValues] = useState({company_name: "",company_ceo: "",location: "",location_state: "",location_country: "",founded_year: "",website: "",industry: "",size: "",hq_location: "",hq_location_state: "",hq_location_country: "",official_address: "",logo: "",currency: "",net_worth: "",business_type: "",departments_list: [],benefits: [],about: "",company_landline: "",company_mobile: "",company_email: "",password: "",cpassword: "",company_contactperson_fname: "",company_contactperson_lname: "",company_contactperson_designation: "",company_contactperson_mobile: "",company_contactperson_email: "",privacy_policy: false,tnc: false
    });
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            company_name: Yup.string()             
                .required("Please enter company name."),
            company_ceo: Yup.string()
                .required("Please enter founder name.")
                .matches(/^[a-zA-Z .]+$/, "Name can only contain letters."),
            company_email: Yup.string().required("Please enter email-id for registration.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid Email-Id."),
            location_country: Yup.string().required("Please select a country."),
            location_state: Yup.string().required("Please select a state."),
            location: Yup.string().required("Please select a city."),
            founded_year: Yup.string().required("Please enter founded year.")
                .matches(/^[0-9]{4}$/, "Enter a valid year.")
                .test(
                    "is-valid-year", 
                    "Year should not be in the future.", 
                    (value) => {
                        const currentYear = new Date().getFullYear();
                        return parseInt(value, 10) <= currentYear;
                    }
                ),
            website: Yup.string().url("Invalid URL format").required("Please enter company website link."),
            industry: Yup.string().required("Please select a industry."),
            logo: localStorage.getItem("recruiter_token") ? Yup.string() : Yup.mixed().required("Please select a company logo."),
            currency: Yup.string().required("Select a currency."),
            net_worth: Yup.string().required("Please enter net worth."),
            business_type: Yup.string().required("Please select a business type."),
            departments_list: Yup.array().min(1, 'Please select a department.').required('Please select a department.'),
            benefits: Yup.array().min(1, 'Please select a benefit.').required('Please select a benefit.'),
            about: Yup.string().test('required', 'Please enter something about company.', (value) => {
                if (value) {
                    const strippedValue = value.replace(/<[^>]*>/g, '');
                    return strippedValue.trim()?.length > 0;} else {return 0;} }),
            size: Yup.string().required("Please select company size."),
            hq_location_country: Yup.string().required("Please select a country."),
            hq_location_state: Yup.string().required("Please select a state."),
            hq_location: Yup.string().required("Please select a city."),
            official_address: Yup.string().required("Please enter official Address."),
            password: Yup.string().required('Please enter password.')
                .min(6, "Password should be 6 to 20 characters long."),
            cpassword: Yup.string().required("Please enter confirm Password.")
                .oneOf([Yup.ref('password'), null], 'Passwords must match'),
            company_landline: Yup.string(), 
            company_mobile: Yup.string().required("Please enter mobile no.").matches(/^[0-9]{8,15}$/, "Please enter a valid mobile number."),
            company_contactperson_fname: Yup.string()
                .required("Please enter first name.")
                .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, "Please enter a valid first name."),
            company_contactperson_lname: Yup.string()
                .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, "Please enter a valid last name."),
                       company_contactperson_designation: Yup.string().required("Please enter designation.")
                .matches(/^[a-zA-Z .]+$/, "Designation can only contain letters."),
            company_contactperson_mobile: Yup.string().required("Please enter phone no.").matches(/^[0-9]{8,15}$/, "Please enter a valid phone number."),
            company_contactperson_email: Yup.string().required("Please enter email-id.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid Email-Id"),
            privacy_policy: Yup.boolean().oneOf([true], 'Please accept privacy policy.'),
            tnc: Yup.boolean().oneOf([true], 'Please accept terms & conditions.')
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {setSubmitting(true);setShowLoader(true);
                document.body.style.overflow = 'hidden';
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                    }
                };
                const formData = new FormData();
                formData.append("company_name", values.company_name)
                formData.append("company_ceo", values.company_ceo)
                formData.append("location_country", values.location_country)
                formData.append("location_state", values.location_state)
                formData.append("location", values.location)
                formData.append("founded", values.founded_year)
                formData.append("website", values.website)
                formData.append("industry", values.industry)
                formData.append("size", values.size)
                formData.append("hq_location_country", values.hq_location_country);
                formData.append("hq_location_state", values.hq_location_state);
                formData.append("hq_location", values.hq_location);
                formData.append("official_address", values.official_address);
                formData.append("logo", values.logo)
                formData.append("currency", values.currency);
                formData.append("net_worth", values.net_worth)
                formData.append("business_type", values.business_type)
                formData.append("departments_list", values.departments_list)
                formData.append("benefits", values.benefits)
                formData.append("about", values.about)
                formData.append("company_landline", values.company_landline)
                formData.append("company_mobile", values.company_mobile)
                formData.append("company_email", values.company_email)
                formData.append("password", values.password)
                formData.append("cpassword", values.cpassword)
                formData.append("company_contactperson_fname", values.company_contactperson_fname)
                formData.append("company_contactperson_lname", values.company_contactperson_lname)
                formData.append("company_contactperson_designation", values.company_contactperson_designation)
                formData.append("company_contactperson_mobile", values.company_contactperson_mobile)
                formData.append("company_contactperson_email", values.company_contactperson_email)
                formData.append("tnc", values.tnc)
                formData.append("privacy_policy", values.privacy_policy)
                if (localStorage.getItem("recruiter_token")) {
                    config.headers["auth-token"] = localStorage.getItem("recruiter_token");                   
                    let vals = { company_email: formik.values.company_email }; 
                    await axios.post(`${host}/api/compare-emails`, vals, config)
                        .then(function (res) {
                            if (res.status == 200) {
                                if (Number(res.data.sameEmail) == true) {
                                    axios.post(`${host}/api/recruiter-update-register`, formData, config)
                                        .then(function (response) {
                                            if (response.status == 200) {
                                                setSubmitting(false)
                                                document.body.style.overflow = 'auto';
                                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Details updated successfully!' });
                                                dispatch(resetRegistrationForm());
                                                navigate('/document-verification');
                                            } else {
                                                setSubmitting(false)
                                                document.body.style.overflow = 'auto';
                                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                                            }
                                        }).catch(function (error) {
                                            console.log(error.message);
                                            document.body.style.overflow = 'auto';
                                            setSubmitting(false)
                                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                                        })
                                } else {
                                    setEmail(formik.values.company_email)
                                    setSubmitting(false)
                                    document.body.style.overflow = 'auto';
                                    toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `Otp is ${res.data.otp}. Please verify.` });
                                    const modal = document.getElementById('signup-otp2');
                                    if (modal) {
                                        modal.classList.add('show');
                                        modal.style.display = 'block';
                                        handleStartTimer();
                                    }
                                }
                            } else {
                                setSubmitting(false)
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: res.data.msg });
                            }
                        })

                } else {
                    await axios.post(`${host}/api/recruiter-register`, formData, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                localStorage.setItem("recruiter_id", response.data.data.id)
                                setSubmitting(false)
                                document.body.style.overflow = 'auto';
                                const otpResponse = response.data.otp_email;;
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `Otp is ${otpResponse}. Please verify.` });
                                const modal = document.getElementById('signup-otp');
                                if (modal) {
                                    modal.classList.add('show');
                                    modal.style.display = 'block';
                                    handleStartTimer();
                                }
                            } else {
                                console.log("in else block of .then method:", response.data.msg);
                                setSubmitting(false)
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        })
                        .catch(function (error) {
                            console.log(error.message);
                            document.body.style.overflow = 'auto';
                            setSubmitting(false)
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                        })
                }

            } catch (error) {
                console.log("catch:", error);
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            } finally {
               
            }
        }
    });

    useEffect(() => {setisLoading(false);dispatch(getIndustryData());dispatch(getCountryData());dispatch(getBenefitData());dispatch(getDepartmentData());dispatch(getBusinesstypeData());dispatch(getCompanysizeData());dispatch(getCurrencyData());setisLoading(true);
        if (localStorage.getItem("recruiter_token")) {
            setIsLoggedIn(true);
            let authToken = localStorage.getItem("recruiter_token");
            dispatch(getRecruiterregistrationData(authToken));
        } else { }}, []);
    useEffect(() => {
        if (recruiterRegistrationData) {
            function toNumber(value) {return Number(value);}
            const departmentArray = recruiterRegistrationData?.[0]?.company_department.map(value => value.department_id);
            dispatch(getStatebyCountryData(recruiterRegistrationData?.[0]?.location_country));
            dispatch(getCitybyStateData(recruiterRegistrationData?.[0]?.location_state))
            dispatch(getStatebyCountryDataHQ(recruiterRegistrationData?.[0]?.hq_location_country));
            dispatch(getCitybyStateDataHQ(recruiterRegistrationData?.[0]?.hq_location_state))
            formik.setValues({
                company_name: recruiterRegistrationData?.[0]?.company_name ?? "",
                company_ceo: recruiterRegistrationData?.[0]?.company_ceo ?? "",
                location_country: recruiterRegistrationData?.[0]?.location_country ?? "",
                location_state: recruiterRegistrationData?.[0]?.location_state ?? "",
                location: recruiterRegistrationData?.[0]?.location ?? "",
                founded_year: recruiterRegistrationData?.[0]?.founded ?? "",
                website: recruiterRegistrationData?.[0]?.website ?? "",
                industry: recruiterRegistrationData?.[0]?.industry ?? "",
                size: recruiterRegistrationData?.[0]?.size ?? "",
                hq_location_country: recruiterRegistrationData?.[0]?.hq_location_country ?? "",
                hq_location_state: recruiterRegistrationData?.[0]?.hq_location_state ?? "",
                hq_location: recruiterRegistrationData?.[0]?.hq_location ?? "",
                official_address: recruiterRegistrationData?.[0]?.official_address ?? "",
                logo: "",
                currency: recruiterRegistrationData?.[0]?.currency_id ?? "",
                net_worth: recruiterRegistrationData?.[0]?.net_worth ?? "",
                business_type: recruiterRegistrationData?.[0]?.business_type ?? "",
                departments_list: departmentArray ?? [],
                benefits: recruiterRegistrationData?.[0].benefits ? recruiterRegistrationData?.[0]?.benefits?.split(",").map(toNumber) : "",
                about: recruiterRegistrationData?.[0].about ?? "",
                company_landline: recruiterRegistrationData?.[0]?.company_landline ?? "",
                company_mobile: recruiterRegistrationData?.[0]?.company_mobile ?? "",
                company_email: recruiterRegistrationData?.[0]?.company_email ?? "",
                password: recruiterRegistrationData?.[0]?.raw_password ?? "",
                cpassword: recruiterRegistrationData?.[0]?.raw_password ?? "",
                company_contactperson_fname: recruiterRegistrationData?.[0]?.company_contactperson_fname ?? "",
                company_contactperson_lname: recruiterRegistrationData?.[0]?.company_contactperson_lname ?? "",
                company_contactperson_designation: recruiterRegistrationData?.[0]?.company_contactperson_designation ?? "",
                company_contactperson_mobile: recruiterRegistrationData?.[0]?.company_contactperson_mobile ?? "",
                company_contactperson_email: recruiterRegistrationData?.[0]?.company_contactperson_email ?? "",
                privacy_policy: recruiterRegistrationData?.[0]?.privacy_policy ?? false,
                tnc: recruiterRegistrationData?.[0]?.tnc ?? false
            });
          
        }
    }, [recruiterRegistrationData]);

    const handleChangeState = (country_id) => {
        dispatch(getStatebyCountryData(country_id));
    }
    const statebycountryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.statebycountryDataList?.data) : "");

    const handleChangeCity = (state_id) => {
        dispatch(getCitybyStateData(state_id))
    }
    const citybystateData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.citybystateDataList?.data) : "");

    const handleChangeStateHQ = (country_id) => {
        dispatch(getStatebyCountryDataHQ(country_id));
    }
    const statebycountryDataHQ = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.statebycountryHQDataList?.data) : "");

    const handleChangeCityHQ = (state_id) => {
        dispatch(getCitybyStateDataHQ(state_id))
    }
    const citybystateDataHQ = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.citybystateHQDataList?.data) : "");


    const form = document.getElementById("recruiter-registration-form");

    useEffect(() => {
        if (form) {
            if (!localStorage.getItem("recruiter_token")) {formik.setValues(initialValues);} }}, [form]);
    const handlereset = () => {formik.setValues(initialValues)};
    const reloadData = () => {
        if (localStorage.getItem("recruiter_token")) {let authToken = localStorage.getItem("recruiter_token")
            dispatch(getRecruiterregistrationData(authToken));}}
    const handleSendOTPandOpenModal = () => {
        if (!localStorage.getItem("recruiter_token")) { window.scrollTo(0, 0);toast.warn("Please register to continue.")
        } else {toast.warn("Submit form to Verify.") } }
    return (
        <>
            {isLoading && <section className='company-details-form'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='form-head-dv'>
                                <form method='post' onSubmit={formik.handleSubmit} encType='multipart/form-data' id="recruiter-registration-form">
                                    <FocusError formik={formik} />
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='cd-heading'>
                                                <h2>Company Details</h2>
                                            </div>
                                        </div>
                                        {/* Name */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Company Name<span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    max={20}
                                                    placeholder='Company Name'
                                                    name='company_name'
                                                    id='company_name'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.company_name}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.company_name && formik.errors.company_name ? (
                                                    <div className='text-danger'>{formik.errors.company_name}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* CEO */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Name of the Founder<span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    placeholder='Name of the Founder'
                                                    name='company_ceo'
                                                    id="company_ceo"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.company_ceo}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.company_ceo && formik.errors.company_ceo ? (
                                                    <div className='text-danger'> {formik.errors.company_ceo}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Location */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Country<span className='text-danger'>*</span></label>
                                                {/* <input type='text' placeholder='Location' name='' /> */}
                                                <select
                                                    className='input-dropdown'
                                                    name="location_country"
                                                    id="location_country"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        handleChangeState(e.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.location_country}
                                                >
                                                    <option value="">--Select a Country--</option>
                                                    {countryData?.map(country => {
                                                        let key = country?.id
                                                        return <option key={key} value={country?.id}>{country?.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.location_country && formik.errors.location_country ? (
                                                    <div className='text-danger'>{formik.errors.location_country}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>State<span className='text-danger'>*</span></label>
                                              
                                                <select
                                                    className='input-dropdown'
                                                    name="location_state"
                                                    id="location_state"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        handleChangeCity(e.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.location_state}
                                                >
                                                    <option value="">--Select a state--</option>
                                                    {statebycountryData?.map(state => {
                                                        return <option key={state?.id} value={state?.id}>{state?.state_name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.location_state && formik.errors.location_state ? (
                                                    <div className='text-danger'>{formik.errors.location_state}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>City<span className='text-danger'>*</span></label>
                                             
                                                <select
                                                    className='input-dropdown'
                                                    name="location"
                                                    id="location"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.location}
                                                >
                                                    <option value="">--Select a city--</option>
                                                    {citybystateData?.map(city => {
                                                        return <option key={city?.id} value={city?.id}>{city?.city_name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.location && formik.errors.location ? (
                                                    <div className='text-danger'>{formik.errors.location}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Founded year */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Founded Year<span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    placeholder='ex:- 1991'
                                                    name='founded_year'
                                                    id='founded_year'
                                                    maxLength={4}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.founded_year}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.founded_year && formik.errors.founded_year ? (
                                                    <div className='text-danger'>{formik.errors.founded_year}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Website */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Company Website<span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    placeholder='Company Website'
                                                    name='website'
                                                    id='website'
                                                    maxLength={100}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.website}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.website && formik.errors.website ? (
                                                    <div className='text-danger'>{formik.errors.website}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                     
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Industry<span className='text-danger'>*</span></label>
                                             
                                                <select
                                                    className='input-dropdown form-control'
                                                    name="industry"
                                                    id="industry"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.industry}
                                                >
                                                    <option value="">--Select a industry--</option>
                                                    {industryData?.map(industry => {
                                                        let key = industry?.id
                                                        return <option key={key} value={industry?.id}>{industry?.industry}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.industry && formik.errors.industry ? (
                                                    <div className='text-danger'>{formik.errors.industry}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* size */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Company Size<span className='text-danger'>*</span></label>
                                               
                                                <select
                                                    className='input-dropdown form-control'
                                                    name="size"
                                                    id="size"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.size}
                                                >
                                                    <option value="">--Select an option--</option>
                                                    {companysizeData?.map(companysize => {
                                                        let key = companysize?.id
                                                        return <option key={key} value={companysize?.id}>{companysize?.companysize}</option>
                                                    })}

                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.size && formik.errors.size ? (
                                                    <div className='text-danger'>{formik.errors.size}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* HQ Location */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Headquarter Country<span className='text-danger'>*</span></label>
                                              
                                                <select
                                                    className='input-dropdown form-control'
                                                    name="hq_location_country"
                                                    id="hq_location_country"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        handleChangeStateHQ(e.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.hq_location_country}
                                                >
                                                    <option value="">--Select a country--</option>
                                                    {countryData?.map(country => {
                                                        let key = country?.id
                                                        return <option key={key} value={country?.id}>{country?.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.hq_location_country && formik.errors.hq_location_country ? (
                                                    <div className='text-danger'>{formik.errors.hq_location_country}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Headquarter State<span className='text-danger'>*</span></label>
                                             
                                                <select
                                                    className='input-dropdown form-control'
                                                    name="hq_location_state"
                                                    id="hq_location_state"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        handleChangeCityHQ(e.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.hq_location_state}
                                                >
                                                    <option value="">--Select a state--</option>
                                                    {statebycountryDataHQ?.map(state => {
                                                        return <option key={state?.id} value={state?.id}>{state?.state_name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.hq_location_state && formik.errors.hq_location_state ? (
                                                    <div className='text-danger'>{formik.errors.hq_location_state}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Headquarter City<span className='text-danger'>*</span></label>
                                             
                                                <select
                                                    className='input-dropdown form-control'
                                                    name="hq_location"
                                                    id="hq_location"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.hq_location}
                                                >
                                                    <option value="">--Select a city--</option>
                                                    {citybystateDataHQ?.map(city => {
                                                        return <option key={city?.id} value={city?.id}>{city?.city_name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.hq_location && formik.errors.hq_location ? (
                                                    <div className='text-danger'>{formik.errors.hq_location}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Official Address */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Official Address <span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    placeholder='Official Address'
                                                    name='official_address'
                                                    id="official_address"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.official_address}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.official_address && formik.errors.official_address ? (
                                                    <div className='text-danger'>{formik.errors.official_address}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Net worth */}
                                        <div className='col-lg-6 my-2'>
                                            <div className="row">
                                                <label className='d-block'>Net Worth<span className='text-danger'>*</span></label>
                                                <div className="col-md-4">
                                                    <div className='cd-form-f'>
                                                        <select
                                                            className='input-dropdown form-control'
                                                            name="currency"
                                                            id="currency"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.currency}
                                                        >
                                                            <option value="">--Currency--</option>
                                                            {currencyData?.map(currency => {
                                                                let key = currency?.id
                                                                return <option key={key} value={currency?.id}>{currency?.currencysymbol} ({currency?.currencyname})</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="help-block with-errors">
                                                        {formik.touched.currency && formik.errors.currency ? (
                                                            <div className='text-danger'>{formik.errors.currency}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className='cd-form-f'>
                                                       
                                                        <input
                                                            type='text'
                                                            placeholder='Net worth'
                                                            name='net_worth'
                                                            id="net_worth"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.net_worth}
                                                            maxLength={13}
                                                        />
                                                    </div>
                                                    <div className="help-block with-errors">
                                                        {formik.touched.net_worth && formik.errors.net_worth ? (
                                                            <div className='text-danger'>{formik.errors.net_worth}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Business Type<span className='text-danger'>*</span></label>
                                                <select
                                                    className='input-dropdown form-control'
                                                    name="business_type"
                                                    id="business_type"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.business_type}
                                                >
                                                    <option value="">--Select an option--</option>
                                                    {businesstypeData?.map(businesstype => {
                                                        let key = businesstype?.id
                                                        return <option key={key} value={businesstype?.id}>{businesstype?.businesstype}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.business_type && formik.errors.business_type ? (
                                                    <div className='text-danger'>{formik.errors.business_type}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                       
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Departments<span className='text-danger'>*</span></label>
                                            
                                                <Select
                                                    id="departments_list"
                                                    name="departments_list"
                                                    placeholder="Select Deparment"
                                                    options={departments_listOptions}
                                                    value={departments_listOptions.filter(option => formik.values.departments_list.includes(option.value))}
                                                    onChange={departments_list => {
                                                        formik.setFieldValue(
                                                            'departments_list',
                                                            departments_list ? departments_list.map(option => option.value) : []
                                                        );
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    isMulti
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.departments_list && formik.errors.departments_list ? (
                                                    <div className='text-danger'>{formik.errors.departments_list}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Benefits */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Benefits<span className='text-danger'>*</span></label>
                                           
                                                <Select
                                                    id="benefits"
                                                    name="benefits"
                                                    placeholder="Benefits"
                                                    options={benefitOptions}
                                                  
                                                    value={benefitOptions.filter(option => formik.values.benefits.includes(option.value))}
                                                    onChange={benefits => {
                                                        formik.setFieldValue(
                                                            'benefits',
                                                            benefits ? benefits.map(option => option.value) : []
                                                        );
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    isMulti
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.benefits && formik.errors.benefits ? (
                                                    <div className='text-danger'>{formik.errors.benefits}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Landline number */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Landline Number</label>
                                                <input
                                                    type='text'
                                                    placeholder='Landline Number e.g. 987654321012'
                                                    name='company_landline'
                                                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                    id="company_landline"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.company_landline}
                                                    maxLength={15}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.company_landline && formik.errors.company_landline ? (
                                                    <div className='text-danger'>{formik.errors.company_landline}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Mobile number */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f check-circle-parent'>
                                                <label className='d-block'>Mobile Number<span className='text-danger'>*</span></label>
                                                <div className='check-circle-relative'>
                                                    <input
                                                        type='text'
                                                        placeholder='Mobile Number'
                                                        name='company_mobile'
                                                        id='company_mobile'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.company_mobile}
                                                        maxLength={15}
                                                    />
                                                
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.company_mobile && formik.errors.company_mobile ? (
                                                        <div className='text-danger'>{formik.errors.company_mobile}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Company Logo<span className='text-danger'>*</span></label>
                                                <input
                                                    type='file'
                                                    placeholder='Company Logo'
                                                    accept="image/*"
                                                    name='logo'
                                                    id="logo"
                                                    onChange={(e) => formik.setFieldValue("logo", e.target.files[0])}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.logo && formik.errors.logo ? (
                                                    <div className='text-danger'>{formik.errors.logo}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/*Display logo*/}
                                        <div className='col-lg-6 my-2'>
                                            {localStorage.getItem("recruiter_token") && recruiterRegistrationData?.[0]?.logo && <img className='logoImagesmall' src={`${host}/uploaded-files/${recruiterRegistrationData?.[0]?.logo}`} style={{ maxHeight: "100px", maxWidth: "100px", border: "1px solid black", borderRadius: "5px" }} data-bs-toggle="modal" data-bs-target="#logoImage" />}
                                            <div className="modal fade" id="logoImage" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="logoImageLabel" aria-hidden="true">
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="logoImageLabel">{recruiterRegistrationData?.[0]?.company_name}</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <img src={`${host}/uploaded-files/${recruiterRegistrationData?.[0]?.logo}`} className='img-fluid' />
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Text editor-about */}
                                        <div className='col-lg-12 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>About<span className='text-danger'>*</span></label>
                                                <CKEditor
                                                    name="about"
                                                    editor={ClassicEditor}
                                                    data={formik.values.about}
                                                    // onBlur={formik.handleBlur}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        formik.setFieldValue('about', data);
                                                    }}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.about && formik.errors.about ? (
                                                    <div className='text-danger'>{formik.errors.about}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='col-lg-12 mt-4'>
                                            <div className='cd-heading'>
                                                <h2>Contact Person Details</h2>
                                            </div>
                                        </div>
                                        {/* Contact person first name */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>First Name<span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    placeholder='First Name'
                                                    name='company_contactperson_fname'
                                                    id='company_contactperson_fname'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.company_contactperson_fname}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.company_contactperson_fname && formik.errors.company_contactperson_fname ? (
                                                    <div className='text-danger'>{formik.errors.company_contactperson_fname}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Contact person last name */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Last Name</label>
                                                <input
                                                    type='text'
                                                    placeholder='Last Name'
                                                    name='company_contactperson_lname'
                                                    id='company_contactperson_lname'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.company_contactperson_lname}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.company_contactperson_lname && formik.errors.company_contactperson_lname ? (
                                                    <div className='text-danger'>{formik.errors.company_contactperson_lname}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Designation */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Designation<span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    placeholder='Designation'
                                                    name='company_contactperson_designation'
                                                    id='company_contactperson_designation'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.company_contactperson_designation}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.company_contactperson_designation && formik.errors.company_contactperson_designation ? (
                                                    <div className='text-danger'>{formik.errors.company_contactperson_designation}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Phone number */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Phone Number<span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    placeholder='Phone Number'
                                                    name='company_contactperson_mobile'
                                                    id='company_contactperson_mobile'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.company_contactperson_mobile}
                                                    maxLength={15}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.company_contactperson_mobile && formik.errors.company_contactperson_mobile ? (
                                                    <div className='text-danger'>{formik.errors.company_contactperson_mobile}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Email id */}
                                        <div className='col-lg-6 my-2'>
                                            <div className='cd-form-f check-circle-parent'>
                                                <label className='d-block'>Email-ID<span className='text-danger'>*</span></label>
                                                <div className='check-circle-relative'>
                                                    <input
                                                        type='email'
                                                        placeholder='Email-ID'
                                                        name='company_contactperson_email'
                                                        id='company_contactperson_email'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.company_contactperson_email}
                                                    />
                                                 
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.company_contactperson_email && formik.errors.company_contactperson_email ? (
                                                        <div className='text-danger'>{formik.errors.company_contactperson_email}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div className='col-lg-12 mt-4'>
                                            <div className='cd-heading'>
                                                <h2>Account Details</h2>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between">
                                            <div className='col-lg-6 col-md-6 my-2'>
                                               
                                                <div className='cd-form-f check-circle-parent'>
                                                    <label className='d-block'>Email-ID for Registration<span className='text-danger'>*</span></label>
                                                    <div className='check-circle-relative'>
                                                        <input
                                                            type='email'
                                                            placeholder='Email-ID for Registration'
                                                            name='company_email'
                                                            id='company_email'
                                                           
                                                            onChange={(e) => { formik.handleChange(e); handleEmailChange(e); }}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.company_email}
                                                        />
                                                        {localStorage.getItem("recruiter_token") && recruiterRegistrationData && recruiterRegistrationData?.[0] && Number(recruiterRegistrationData?.[0]?.company_email_verified) == 1 && <div className='greencheck-circle-absolute'>
                                                            <img src='./assets/images_another-ak/green-check.png' />
                                                        </div>}
                                                        <div className="help-block with-errors">
                                                            {formik.touched.company_email && formik.errors.company_email ? (
                                                                <div className='text-danger'>{formik.errors.company_email}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            {localStorage.getItem("recruiter_token") ? (
                                                recruiterRegistrationData && recruiterRegistrationData?.[0] && Number(recruiterRegistrationData?.[0]?.company_email_verified) == 1 ? <div className='col-lg-2 col-md-2 my-2'>
                                                    <div className='cd-form-f cd-form-f-btn'>
                                                        <label className='d-block'></label>
                                                        <input type='button' value='Verified' />
                                                    </div>
                                                </div> : <div className='col-lg-2 col-md-2 my-2'>
                                                    <div className='cd-form-f cd-form-f-btn'>
                                                        <label className='d-block'></label>
                                                       
                                                        <button type='button' className='verify-profile-btn' onClick={handleSendOTPandOpenModal} ><i className="fa fa-spinner" aria-hidden="true" id='fa-spinner-email' style={{ display: "none" }}></i> Verify</button>
                                                    </div>
                                                </div>
                                            )
                                                : null
                                            }
                                        </div>
                                    
                                        <div className='row m-0 p-0'>
                                            <div className='col-lg-6 my-2'>
                                                <div className='cd-form-f'>
                                                    <label className='d-block'>Create Password<span className='text-danger'>*</span></label>
                                                    <input
                                                        type='password'
                                                        placeholder='Create Password'
                                                        name='password'
                                                        id='password'
                                                        maxLength={20}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.password} />
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.password && formik.errors.password ? (
                                                        <div className='text-danger'>{formik.errors.password}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* CONFIRM password */}
                                            <div className='col-lg-6 my-2'>
                                                <div className='cd-form-f'>
                                                    <label className='d-block'>Confirm Password<span className='text-danger'>*</span></label>
                                                    <input
                                                        type='password'
                                                        placeholder='Confirm Password'
                                                        name='cpassword'
                                                        id='cpassword'
                                                        maxLength={20}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.cpassword} />
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.cpassword && formik.errors.cpassword ? (
                                                        <div className='text-danger'>{formik.errors.cpassword}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>

                                        {/* tnC */}
                                        <div className='col-lg-12'>
                                            <div className='checkbox-dv'>
                                                <div className='checkbox-1'>
                                                    <input
                                                        type='checkbox'
                                                        name='privacy_policy'
                                                        id='privacy_policy'
                                                        checked={formik.values.privacy_policy ? true : false}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.privacy_policy}
                                                    />
                                                    <label htmlFor='privacy-pol'> Accept all <Link to='/privacy-policy' className='a-tag-color'>Privacy & Policy</Link></label>
                                                    <div className="help-block with-errors">
                                                        {formik.touched.privacy_policy && formik.errors.privacy_policy ? (
                                                            <div className='text-danger'>{formik.errors.privacy_policy}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className='checkbox-2'>
                                                    <input
                                                        type='checkbox'
                                                        name='tnc'
                                                        id='tnc'
                                                        checked={formik.values.tnc ? true : false}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.tnc}
                                                    />
                                                    <label htmlFor='term-con'> Accept all <Link to='/terms-conditions' className='a-tag-color'>Terms & Conditions</Link></label>
                                                    <div className="help-block with-errors">
                                                        {formik.touched.tnc && formik.errors.tnc ? (
                                                            <div className='text-danger'>{formik.errors.tnc}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-12 my-2 text-center'>
                                            <div className='next-button'>
                                            
                                                <button type='submit' className='next-btn'>Save & Next</button>
                                              
                                            </div>
                                        </div>
                                        <div className='row mt-4 '>
                                            <div className='col-lg-12 col-md-12 col-12 text-center'>
                                                <p className='bottm-signin'><Link to="/recruiter-login">Already have an account? Sign In</Link></p>
                                            </div>
                                            <div className='col-lg-12 col-md-12 col-12 text-center'>
                                                <p className='bottm-signin'><Link to="/">Go to Home</Link></p>
                                            </div>
                                        </div>
                                        {/* Loader */}
                                        {formik.isSubmitting && <div className="overlay-form-submission">
                                            <FallingLines
                                                color="#1b375c"
                                                width="100"
                                                visible={true}
                                                ariaLabel='falling-lines-loading'
                                            />
                                        </div>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            }
            <EnterOTPScreen email={email} timerStarted={timerStarted} setTimerStarted={setTimerStarted} handleStartTimer={handleStartTimer} seconds={seconds} setSeconds={setSeconds} />
            <EnterOTPScreen2 email={email} timerStarted={timerStarted} setTimerStarted={setTimerStarted} handleStartTimer={handleStartTimer} seconds={seconds} setSeconds={setSeconds} reloadData={reloadData} />
           
        </>
    )
}

export default CompanyDetailsForm