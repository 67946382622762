import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'

import Footer from '../../Commons/Footer'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getRecommendedJobTemplateData } from '../../../../Redux/action/RecruiterAction'
import { toast } from 'react-toastify'
import Loader from '../../Loader'
import ShowRecommJobTemplateContent from './ShowRecommJobTemplateContent'
import axios from 'axios'
const host = process.env.REACT_APP_API_SERVER;

const ShowRecommJobTemplateList = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(true);
    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            navigate("/recruiter-login");
            toast.error("Please Login to continue.");
        } else {
            let config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } }
            setShowLoader(true);
            axios.get(`${host}/api/check-recruiter-profile-completion`, config)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.data === true) {
                            dispatch(getRecommendedJobTemplateData());
                            setShowLoader(false);
                        } else {
                            setShowLoader(true);
                            toast.error("Please complete your profile before continuing.")
                            navigate("/company-details");
                        }
                    } else {
                        setShowLoader(true);
                        toast.error(response.data.msg);
                        navigate(-1);
                    }
                }).catch(err => {
                    console.log(err.message);
                    setShowLoader(true);
                    toast.error("Internal server error.");
                    navigate(-1);
                })
        }
    }, []);
    const recommJobTemplateData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.recommendedJobTemplateDataList) : "")

    useEffect(() => {
        if (Array.isArray(recommJobTemplateData)) {
            setShowLoader(false);
        }
    }, [recommJobTemplateData])
    return (
        <> {!showLoader ? <>
            <RecAfterLoginNavbar />
            <ShowRecommJobTemplateContent postedJobList={recommJobTemplateData} />
            <Footer />
        </> : <div className="overlay-form-submission">
            <Loader />
        </div>
        }</>
    )
}

export default ShowRecommJobTemplateList