import React, { useEffect, useState } from 'react'
import './scss/PostAJobOverview.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { fetchJobDetailsSlug, getJobDetailsData, getRecruiterregistrationData } from '../../../../Redux/action/RecruiterAction';
import Loader from '../../Loader';
import { toast } from 'react-toastify';
import axios from 'axios';
import ErrorMessage from './ErrorMessage';
const host = process.env.REACT_APP_API_SERVER;

const RecJobDetailsPageContent2 = ({ job_id,ji }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isJobDetailsLoaded, setIsJobDetailsLoaded] = useState(true);
    const [showDescription, setshowDescription] = useState(false);
    const [showAbout, setshowAbout] = useState(false);
    const [foundCompanyDetails, setFoundCompanyDetails] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const {
        loadingJobDetailsSlugApi,
        jobDetailsSlugApiData,
        jobDetailsSlugApiError,
    } = useSelector(state => state?.RecruiterReducer);

    useEffect(() => {
        if (!job_id) {
            toast.error("job_id is missing");
            navigate(-1);
        } else {
            dispatch(fetchJobDetailsSlug(job_id, localStorage.getItem("recruiter_token")));
        }
    }, [dispatch]);


    useEffect(() => {
        if (localStorage.getItem("recruiter_token")) {
            let authToken = localStorage.getItem("recruiter_token");
            dispatch(getRecruiterregistrationData(authToken));
        }
    }, []);
    const jobDetailsData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.jobdetailsDataList?.jobDetails) : "");
    const companyDetailsData = useSelector((state) => state?.RecruiterReducer ? state?.RecruiterReducer?.recruiterRegisterDataList : []);
    const skills_required = jobDetailsData?.job_skills_required

    useEffect(() => {
        if (companyDetailsData?.data) {
            setFoundCompanyDetails(true);
        } else {
            setFoundCompanyDetails(false);
        }
    }, [companyDetailsData])

    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };
    const wordCount = jobDetailsData?.description ? jobDetailsData?.description?.split(/\s+/)?.length : 0;
    const wordLimit = 100;
    const handleDescription = () => {
        setshowDescription(!showDescription)
    }
    const handleAbout = () => {
        setshowAbout(!showAbout)
    }

    const handleEdit = (job_id) => {
        localStorage.setItem("job_id", ji);
        navigate("/edit-job-basics");
    }

    if (loadingJobDetailsSlugApi) {
        return <div className="overlay-form-submission"><Loader /></div>;
    }

    if (jobDetailsSlugApiError) {
        return <ErrorMessage message={jobDetailsSlugApiError} />;
    }

    return (
        <>
            {!isJobDetailsLoaded ?
                <div className="overlay-form-submission">
                    <Loader />
                </div> :
                <section className='post-a-joboverview-sec'>
                    <div className='container'>
                        <div className='create-an-emp-inner'>
                            <div className='container'>
                                <div className='row my-lg-2 my-md-2 my-1'>
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='heading'>
                                            <h2>Overview</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className='row my-lg-2 my-md-2 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>{jobDetailsSlugApiData?.job_title} - {jobDetailsSlugApiData?.Company_details?.company_name}</h4>
                                        </div>
                                    </div>
                                </div>
                                {jobDetailsSlugApiData?.industryList?.industry &&
                                    <div className='row my-lg-2 my-md-2 my-1'>
                                        <div className='col-md-8 col-lg-8 col-8'>
                                            <div className='overview-top-head'>
                                                <h4>Industry: {jobDetailsSlugApiData?.industryList?.industry}</h4>
                                            </div>
                                        </div>
                                    </div>}

                                {(jobDetailsSlugApiData?.job_city?.city_name || jobDetailsSlugApiData?.job_state?.state_name) && <div className='row my-lg-2 my-md-2 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Location: <small>{jobDetailsSlugApiData?.job_city?.city_name}{`,${jobDetailsSlugApiData?.job_state?.state_name ? jobDetailsSlugApiData?.job_state?.state_name : ''}`}{`,${jobDetailsSlugApiData?.job_country ? jobDetailsSlugApiData?.job_country?.name : ''}`}</small></h4>
                                        </div>
                                    </div>
                                </div>}
                                {jobDetailsSlugApiData?.application_deadline !== "0" && <div className='row my-lg-2 my-md-2 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Application Deadline: <small>{jobDetailsSlugApiData?.application_deadline} days</small></h4>
                                        </div>
                                    </div>

                                </div>}
                                <div className='row my-lg-2 my-md-2 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        {jobDetailsSlugApiData?.show_pay_by === "Range" &&
                                            <div className='overview-top-head'>
                                                <h4>Salary Range: <small>{`${jobDetailsSlugApiData?.currency}${jobDetailsSlugApiData?.min_pay}`} - {`${jobDetailsSlugApiData?.currency}${jobDetailsSlugApiData?.max_pay}`}</small></h4>
                                            </div>
                                        }
                                        {jobDetailsSlugApiData?.show_pay_by !== "Range" &&
                                            <div className='overview-top-head'>
                                                <h4>Salary: <small>{`$${jobDetailsSlugApiData?.amount}`} {jobDetailsSlugApiData?.rate_per_hour}</small></h4>
                                            </div>
                                        }
                                    </div>
                                   
                                </div>
                                <div className='row mt-lg-5 mt-md-2 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Job Description</h4>
                                        </div>
                                    </div>
                                    <div className='col-lg-8 col-md-8 col-8'>
                                        {!showDescription ? <div className='job-description-list'>
                                            {jobDetailsSlugApiData?.description && parse(`${truncateText(jobDetailsSlugApiData?.description, 30)}`)}
                                        </div> : <div className='job-description-list'>
                                            {jobDetailsSlugApiData?.description && parse(jobDetailsSlugApiData?.description)}
                                        </div>}
                                    </div>

                                </div>
                                {
                                    wordCount > wordLimit && (
                                        <div className='row my-lg-3 my-md-3 my-1'>
                                            <div className='col-md-12 col-lg-12 col-12 text-center'>
                                                <div className='overview-button'>
                                                    <Link to="" className='see-full-descriptions' onClick={handleDescription}>
                                                        {!showDescription ? `See full descriptions` : `Hide descriptions`}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {/* this */}
                                {jobDetailsSlugApiData?.job_skills_required && <div className='row my-lg-1 my-md-1 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Skills</h4>
                                        </div>
                                    </div>

                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='supplement-pay-btn'>

                                            {jobDetailsSlugApiData?.job_skills_required?.map(skills => {
                                                return <div key={skills?.id} className='supplement-item my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>

                                                        <p className='btn-circle'>

                                                            <label>{skills?.skill_name === '0' ? skills?.other_skill_name : skills?.skill?.skill} </label>
                                                        </p>
                                                    </div>

                                                </div>
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>}
                                {/* this */}
                                {jobDetailsSlugApiData?.job_type_list && <div className='row my-lg-1 my-md-1 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Job Type</h4>
                                        </div>
                                    </div>

                                    <div className='col-lg-12 col-md-12 col-12 skill-pay-btn'>


                                        <div className='supplement-pay-btn'>

                                            {jobDetailsSlugApiData?.job_type_list?.map(jobType => {
                                                return <div key={jobType?.job_type_id} className='supplement-item my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>
                                                       
                                                        <p className='btn-circle'>

                                                            <label>{jobType?.jobType?.jobtypename}</label>
                                                        </p>
                                                    </div>

                                                </div>
                                            })
                                            }

                                        </div>
                                    </div>
                                </div>}
                                {/* this */}
                                {jobDetailsSlugApiData?.schedule_lists && <div className='row my-lg-1 my-md-1 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Schedule</h4>
                                        </div>
                                    </div>

                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='supplement-pay-btn'>
                                            {jobDetailsSlugApiData?.schedule_lists?.map(schedule => {
                                                return <div key={schedule?.schedule_id} className='supplement-item my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>

                                                        <p className='btn-circle'>

                                                            <label>{schedule?.schedule_detail?.schedulename}</label>
                                                        </p>
                                                    </div>

                                                </div>
                                            })
                                            }
                                        </div>

                                    </div>

                                </div>}
                                {/* this */}
                                {jobDetailsSlugApiData?.workplace_list && <div className='row my-lg-1 my-md-1 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Workplace</h4>
                                        </div>
                                    </div>

                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='supplement-pay-btn'>
                                            {jobDetailsSlugApiData?.workplace_list?.map(workplace => {
                                                return <div key={workplace?.workplace_id} className='supplement-item my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>

                                                        <p className='btn-circle'>

                                                            <label>{workplace?.workplace_detail?.workplace}</label>
                                                        </p>
                                                    </div>

                                                </div>
                                            })
                                            }
                                        </div>

                                    </div>
                                </div>}
                                {/* Education Level */}
                                {jobDetailsSlugApiData?.education_level_list && <div className='row my-lg-1 my-md-1 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Education Level</h4>
                                            <hr className='py-1 my-1'></hr>
                                        </div>
                                    </div>
                                   
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='supplement-pay-btn'>
                                            {jobDetailsSlugApiData?.education_level_list?.map(education_level => {
                                                return <div key={education_level?.education_level_id} className='supplement-item my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>

                                                        <p className='btn-circle'>
                                                            <label>{education_level?.education_level_detail?.educationlevel}</label>
                                                        </p>
                                                    </div>

                                                </div>
                                            })
                                            }
                                        </div>

                                    </div>
                                </div>}
                                {/* Supplement Pay */}
                                {jobDetailsSlugApiData?.supplement_pay_list && <div className='row my-lg-1 my-md-1 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Supplement Pay</h4>
                                            <hr className='py-1 my-1'></hr>
                                        </div>
                                    </div>
                                 
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='supplement-pay-btn'>
                                            {jobDetailsSlugApiData?.supplement_pay_list?.map(supplement_pay => {
                                                return <div key={supplement_pay?.supplement_pay_id} className='supplement-item my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>

                                                        <p className='btn-circle'>
                                                            <label>{supplement_pay?.supplement_pay_detail?.supplementpayname}</label>
                                                        </p>
                                                    </div>

                                                </div>
                                            })
                                            }
                                        </div>

                                    </div>
                                </div>}
                                {/* Benefits*/}
                                {jobDetailsSlugApiData?.benefit_list && <div className='row my-lg-1 my-md-1 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Benefits</h4>
                                            <hr className='py-1 my-1'></hr>
                                        </div>
                                    </div>
                                   
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='supplement-pay-btn'>
                                            {jobDetailsSlugApiData?.benefit_list?.map(benefit => {
                                                return <div key={benefit?.benefit_id} className='supplement-item my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>

                                                        <p className='btn-circle'>
                                                            <label>{benefit?.benefit_detail?.benefitname}</label>
                                                        </p>
                                                    </div>

                                                </div>
                                            })
                                            }
                                        </div>

                                    </div>
                                </div>}

                                <div className='row my-lg-2 my-md-2 my-1'>
                                    <div className='col-md-12 col-lg-12 col-12'>
                                        <div className='overview-top-head'>
                                            <h5>Assessment</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='setting-row-parent'>
                                    <div className='row my-lg-2 my-md-2 my-1'>



                                        <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                            <div className="setting-row">
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head'>
                                                        <h6>Assessment Included:</h6>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='inpt-data-rpint'>
                                                <input className="int-tag" type='text' placeholder="Include CV" value={Number(jobDetailsSlugApiData?.include_assessment) ? "Yes" : "No"} readOnly />
                                            </div>
                                        </div>
                                        {Number(jobDetailsSlugApiData?.include_assessment) == 1 && <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                            <div className="setting-row">
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head'>
                                                        <h6>Assessment Name:</h6>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='inpt-data-rpint'>
                                                <input className="int-tag" type='text' placeholder="Include CV" value={jobDetailsSlugApiData?.assessment?.title ?? 'Assessment name not found.'} readOnly />
                                            </div>
                                        </div>}

                                    </div>
                                </div>
                                <div className='row my-lg-2 my-md-2 my-1'>
                                    <div className='col-md-12 col-lg-12 col-12'>
                                        <div className='overview-top-head'>
                                            <h5>Settings</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='setting-row-parent'>
                                    <div className='row my-lg-2 my-md-2 my-1'>
                                        

                                        {jobDetailsSlugApiData?.no_of_people_to_hire && <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                            <div className="setting-row">
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head'>
                                                        <h6>Vaccancy Available</h6>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='inpt-data-rpint'>
                                                <input className="int-tag" type='text' value={jobDetailsSlugApiData?.no_of_people_to_hire} readOnly />
                                            </div>
                                        </div>}
                                        {jobDetailsSlugApiData?.communication_email &&
                                            <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                                <div className="setting-row">
                                                    <div className='setting-column1'>
                                                        <div className='overview-top-head'>
                                                            <h6>Candidate Contact you on (Mail)</h6>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='inpt-data-rpint'>
                                                    <input className="int-tag" type='text' placeholder={jobDetailsSlugApiData?.communication_email} value={jobDetailsSlugApiData?.communication_email} readOnly />
                                                </div>
                                            </div>
                                        }
                                        {jobDetailsSlugApiData?.calling_no &&
                                            <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                                <div className="setting-row">
                                                    <div className='setting-column1'>
                                                        <div className='overview-top-head'>
                                                            <h6>Candidate Contact you on (Phone)</h6>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='inpt-data-rpint'>
                                                    <input className="int-tag" type='text' placeholder='No' value={jobDetailsSlugApiData?.calling_no} readOnly />
                                                </div>
                                            </div>
                                        }
                                        {jobDetailsSlugApiData?.recruitement_timeline && <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                            <div className="setting-row">
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head'>
                                                        <h6>Hiring Timeline</h6>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='inpt-data-rpint'>
                                                <input className="int-tag" type='text' value={jobDetailsSlugApiData?.recruitement_timeline} readOnly />
                                            </div>
                                        </div>}

                                        {jobDetailsSlugApiData?.planned_start_date &&
                                            <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                                <div className="setting-row">
                                                    <div className='setting-column1'>
                                                        <div className='overview-top-head'>
                                                            <h6>Expected Start Date </h6>
                                                        </div>

                                                    </div>

                                                </div>

                                                <div className='inpt-data-rpint'>
                                                    <input className="int-tag" type='text' placeholder={jobDetailsSlugApiData?.planned_start_date} value="25-06-2023" readOnly />
                                                </div>

                                            </div>
                                        }
                                        {jobDetailsSlugApiData?.communication_email && <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                            <div className="setting-row">
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head'>
                                                        <h6>Email-ID for Registration</h6>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='inpt-data-rpint'>
                                                <input className="int-tag" type='text' placeholder='Email-ID for Registraion' value={jobDetailsSlugApiData?.communication_email} readOnly />
                                            </div>
                                        </div>}
                                        {jobDetailsSlugApiData?.calling_no && <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                            <div className="setting-row">
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head'>
                                                        <h6>Phone Number</h6>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='inpt-data-rpint'>
                                                <input className="int-tag" type='text' placeholder='Phone Number' value={jobDetailsSlugApiData?.calling_no} readOnly />
                                            </div>
                                        </div>}
                                    </div>
                                </div>



                                <div className='bottom-button-dv'>
                                    <div className='row my-lg-2 my-md-2 my-1'>
                                        <div className='col-lg-6 col-md-6 col-12 text-end'>
                                            <div className='bottom-btn'>
                                                <Link to={-1} type='submit' className='a-tag-btn1'>Back</Link>
                                            </div>
                                        </div>
                                        {jobDetailsSlugApiData?.current_status != "CLOSED" && <div className='col-lg-6 col-md-6 col-12 text-start'>
                                            <div className='bottom-btn'>
                                                <button onClick={() => handleEdit(job_id)} type='submit' className='a-tag-btn2'>Edit</button>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showLoader && <div className="overlay-form-submission">
                        <Loader />
                    </div>}
                </section >}
        </>
    )
}

export default RecJobDetailsPageContent2