import React from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import CareerStepper from '../CareerStepper/CareerStepper'
import SubmittedSuccessfullyReturnToJobContent from './SubmittedSuccessfullyReturnToJobContent'
import Footer from '../../Commons/Footer'
const SubmittedSuccessfullyReturnToJob = () => {
  return (
    <>
        <AfterNavbar/>
         <SubmittedSuccessfullyReturnToJobContent/>
        <Footer/>
    </>
  )
}
export default SubmittedSuccessfullyReturnToJob