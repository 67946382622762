import React, { useEffect, useState } from 'react'
import './scss/LikeToPostYourJob.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';

const ShowRecommJobTemplateContent = ({ postedJobList }) => {
    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 10;
    useEffect(() => {

        const endOffset = dataOffset + dataPerPage;
        setCurrentData(postedJobList?.slice(dataOffset, endOffset));
        setPageCount(Math.ceil(postedJobList?.length / dataPerPage));
    }, [dataOffset, dataPerPage, postedJobList])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage) % postedJobList?.length;
        setDataOffset(newOffset);
    };
    return (
        <div className='container mt-2'>
            <div className='create-an-emp-inner mt-2'>
                <div className='container'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">S. No.</th>
                                <th scope="col">Job Title</th>
                                <th scope="col">Date Posted</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                postedJobList?.length ?
                                    currentData.map((job, index) => {
                                        return <tr key={job?.id}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{job?.job_title}</td>
                                            <td>{new Date(job?.createdAt).toDateString()}</td>
                                            <td><Link to={`/rec-recomm-jobtemplate-review-page?old_job_id=${job?.id}`} className="btn text-light rounded-pill p-2 px-4 btn-sm post-similar-btn" style={{ backgroundColor: '#212529' }}>Post Similar</Link></td>
                                        </tr>
                                    })
                                    : <tr>
                                        <td colSpan="3"><i>No data found.</i></td>
                                    </tr>
                            }
                        </tbody>
                    </table>

                </div>
                <div className='row justify-content-center'>
                    <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                        <section className='pagination-section'>
                            <div className='container'>
                                <nav aria-label="Page navigation example" className='nav-class'>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">>"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount}
                                        previousLabel="<<"
                                        renderOnZeroPageCount={null}
                                        containerClassName='pagination'
                                        pageLinkClassName='page-num'
                                        previousLinkClassName='page-num'
                                        nextLinkClassName='page-num'
                                        activeClassName='active'
                                    />
                                </nav>
                            </div>
                        </section>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default ShowRecommJobTemplateContent
