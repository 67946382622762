import React, { useEffect, useState } from 'react'
import './scss/FindRightPlacesCompanyListing.css'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanybyIndustry, getIndustryById, getIndustryData, getbusinesstypeData, getCountryData, getcompanyCountData, getfilterCompany, getCityStateData } from '../../../../Redux/action/SeekerAction';
import ReactPaginate from 'react-paginate';
import { createImageFromInitials, getRandomColor } from '../../../Utils';
import parse from 'html-react-parser';
import CareerSearchSectionSearchCompanyResult from './CareerSearchSectionSearchCompanyResult';
import { getBusinesstypeData, getCompanysizeData } from '../../../../Redux/action/RecruiterAction';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { getAfganCities, getDepartmentData } from '../../../../Redux/action/MasterAction';
const host = process.env.REACT_APP_API_SERVER;

const SearchCompanyResultListing = () => {
    let [showData, setShowData] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let [company_data, setCompany_data] = useState({});
    let [reload, setReload] = useState(false);
    let [companyCount, setCompanyCount] = useState(0);
    let [showCity, setShowCity] = useState(false);
    let [showDepartment, setShowDepartment] = useState(false);
    let [showIndustry, setShowIndustry] = useState(false);
    let [showSize, setShowSize] = useState(false);
    let [showBusinessType, setShowBusinessType] = useState(false);
    let [showFilterCompany, setShowFilterCompany] = useState(false);
    const [cities, setCities] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    let id = 1;
    useEffect(() => {dispatch(getcompanyCountData());dispatch(getIndustryData());dispatch(getCountryData());dispatch(getCityStateData());dispatch(getCompanysizeData());dispatch(getbusinesstypeData());dispatch(getAfganCities());dispatch(getDepartmentData());}, []);
    let companyCountData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.companyCountDataList : 0);
    const countryData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.countryDataList : []);
    const cityData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.cityStateDataList : []);
    const industryData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.industryTypeDataList : []);
    const sizeData = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.companysizeDataList : []);
    const businessTypeData = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.businesstypeDataList : []);
    let afganCity = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.afganCitiesList : []);
    let departmentsData = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.departmentList : []);
    useEffect(() => {
        if (afganCity) {setCities(afganCity);setFilteredCities(afganCity);}
    }, [afganCity]);

    const handleInputChange = event => {
        const inputValue = event.target.value.toLowerCase();
        formik.handleChange(event);

        if (inputValue === '' || inputValue?.length === 1) {
            setFilteredCities(cities);
        } else {
            const filtered = cities.filter(city =>
                city.city_name.toLowerCase().includes(inputValue)
            );
            setFilteredCities(filtered);
        }
    };

    useEffect(() => {
        if (companyCountData) {setCompanyCount(companyCountData);}
    }, [companyCountData]);
    useEffect(() => {
        if (cityData?.data) {
            setShowCity(true);
        } else {
            setShowCity(false);
        }
    }, [cityData]);
    useEffect(() => {
        if (departmentsData?.data) {
            setShowDepartment(true);
        } else {
            setShowDepartment(false);
        }
    }, [departmentsData]);
    useEffect(() => {
        if (industryData?.data) {
            setShowIndustry(true);
        } else {
            setShowIndustry(false);
        }
    }, [industryData]);
    useEffect(() => {
        if (sizeData?.data) {
            setShowSize(true);
        } else {
            setShowSize(false);
        }
    }, [sizeData]);
    useEffect(() => {
        if (businessTypeData) {
            setShowBusinessType(true);
        } else {
            setShowBusinessType(false);
        }
    }, [businessTypeData]);
    let initialValues = {company_name: "",location: "",industry: "",size: "",business_type: "",departments_list_text: "",
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({}),
        onSubmit: async (values) => {
            function removeEmptyStringValues(obj) {
                for (const key in obj) {
                    if (obj[key] === '') {
                        delete obj[key];
                    }
                }
                return obj;
            }
            const objectWithoutEmptyStrings = removeEmptyStringValues(values);
            let create_search_string = ""
            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                    create_search_string = create_search_string + key + "=" + objectWithoutEmptyStrings[key] + "&";
                }
            }
            navigate(`/search-company-result?${create_search_string}`);
        }
    });
    const url = new URL(window.location.href);
    const URLhref = url.href;
    useEffect(() => {
        const queryParams = new URLSearchParams(url.search);
        const queryParamsOject = {};

        for (const [key, value] of queryParams) {
            queryParamsOject[key] = value;
        }
        setCompany_data(queryParamsOject)
        setReload(!reload);

    }, [URLhref]);
    useEffect(() => {
        const queryParams = new URLSearchParams(url.search);
        const queryParamsOject = {};

        for (const [key, value] of queryParams) {
            queryParamsOject[key] = value;
        }
        formik.setValues({
            company_name: queryParamsOject.hasOwnProperty("company_name") ? queryParamsOject.company_name : "",
            location: queryParamsOject.hasOwnProperty("location") ? queryParamsOject.location : "",
            size: queryParamsOject.hasOwnProperty("size") ? queryParamsOject.size : "",
            industry: queryParamsOject.hasOwnProperty("industry") ? queryParamsOject.industry : "",
            business_type: queryParamsOject.hasOwnProperty("business_type") ? queryParamsOject.business_type : "",
            departments_list_text: queryParamsOject.hasOwnProperty("departments_list_text") ? queryParamsOject.departments_list_text : "",
        })
    }, [reload]);
    useEffect(() => {
        if (Object.keys(company_data)?.length != 0) {
            dispatch(getfilterCompany(company_data))
        }
    }, [company_data])
    let filterCompanyData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.filterCompanyDataList : []);
    useEffect(() => {
        if (filterCompanyData) {
            setShowFilterCompany(true);
        }
    }, [filterCompanyData])
    const [currentCompanies, setCurrentCompanies] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [companyOffset, setCompanyOffset] = useState(0);
    const companyPerPage = 5;
    useEffect(() => {
        const endOffset = companyOffset + companyPerPage;
        setCurrentCompanies(filterCompanyData.slice(companyOffset, endOffset));
        setPageCount(Math.ceil(filterCompanyData?.length / companyPerPage));
    }, [companyOffset, companyPerPage, filterCompanyData])
    const handlePageClick = (event) => {
        const newOffset = (event.selected * companyPerPage) % filterCompanyData?.length;
        setCompanyOffset(newOffset);
    };
    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }return text;};

    const handleRemoveFilter = () => {
        formik.setValues({company_name: "",location: "",size: "",industry: "",business_type: "",departments_list_text: "",
        });};

    const handleShowAllCompany = () => {
        formik.setValues({company_name: "",location: "",size: "",industry: "",business_type: "",departments_list_text: "",
        });dispatch(getfilterCompany({}));
    };

    return (
        <>
            <section className='find-company-listing-section'>
                <section className="career-section">
                    <div className="career-start-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="career-top-heading">
                                        <h2>Explore the career you love </h2>
                                    </div>
                                    <div className="career-top-heading mt-4">
                                        <h1>
                                            Find Right <span> Places/Companies </span> to Work
                                        </h1>
                                    </div>
                                    <div className="career-second-heading mt-4">
                                        <h6><span>  {companyCount} </span> Companies <b>NOW</b> Hiring in Afghanistan
                                        </h6>
                                    </div>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div>
                                            <div className="custom-form-section mt-4">
                                                <div className="search-custom">
                                                    <img src=" ./assets/find-right-places-companies/magnifyingglass.png" className="img-fluid" alt="Search Companies" title='Search Companies' />
                                                    <input
                                                        type="text"
                                                        className="form-control form-custom"
                                                        placeholder="Company name"
                                                        name='company_name'
                                                        id='company_name'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.company_name}
                                                        style={{ paddingLeft: "50px" }}
                                                    />
                                                </div>
                                                <div className="search-custom">
                                                    <img src="./assets/find-right-places-companies/mappinline.png" className="img-fluid" alt="" />
                                                    <input
                                                        type="text"
                                                        className="form-control form-custom"
                                                        placeholder="Location"
                                                        id="loc"
                                                        name='loc'
                                                        // list="cities-list"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.loc}
                                                        style={{ paddingLeft: "50px" }}
                                                    />
                                                </div>
                                              
                                                <div>
                                                    <div>
                                                        <button type='submit' className="form-custom-btn">Find Company</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" dropdown-custom-btn">
                                            <div className="dropdown">
                                                <div className="dropdown">
                                                    <select
                                                        className='btn-custom p-2'
                                                      
                                                        id="location"
                                                     
                                                        title='Location'>
                                                        <option value="">Comapany Type</option>
                                                      
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="dropdown">
                                                {showCity && <div className="dropdown">
                                                    <select
                                                        className='btn-custom p-2'
                                                        name="loc"
                                                        id="location"
                                                        value={formik.values.loc}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        title='Location'>
                                                        <option value="">Location</option>
                                                        {
                                                            cityData?.data?.map((city) => {
                                                                return <option key={city.id} value={city.city_name}>{city.city_name}, {city?.state?.state_name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>}
                                            </div>

                                            <div className="dropdown">
                                                {showBusinessType && <div className="dropdown">
                                                    <select
                                                        className='btn-custom p-2'
                                                        name="business_type"
                                                        id="business_type"
                                                        value={formik.values.business_type}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        title='Business Type'>
                                                        <option value="">Nature of Business</option>
                                                        {
                                                            businessTypeData?.map((businesstype) => {
                                                                return <option key={businesstype.id} value={businesstype.id}>{businesstype.businesstype}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>}
                                            </div>

                                            <div className="dropdown">
                                                {showDepartment && <div className="dropdown">
                                                    <select
                                                        className='btn-custom p-2'
                                                        name="department"
                                                        id="department"
                                                        value={formik.values.department}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        title='Business Type'>
                                                        <option value="">Department</option>
                                                        {
                                                            departmentsData?.data?.map((dept) => {
                                                                return <option key={dept.id} value={dept.id}>{dept.department}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>}
                                            </div>

                                            <div className="dropdown">
                                                <div className="dropdown">
                                                    <select
                                                        className='btn-custom p-2'
                                                    
                                                        id="location"
                                                      
                                                        title='Experience'>
                                                        <option value="">Experience </option>
                                                        
                                                    </select>
                                                </div>
                                            </div>
                                            

                                        </div>
                                    </form>
                                </div>
                            </div>
                            {Object.keys(company_data)?.length != 0 && <button
                                className='mt-2 p-2'
                                style={{
                                    "borderRadius": "5px",
                                    "background": "#1B375C",
                                    "color": "#FFF",
                                    "fontSize": "15px",
                                    "fontWeight": "500",
                                }}
                                onClick={handleRemoveFilter}>Remove Filter</button>}

                            <button className="mt-2 p-2 mx-2"
                                style={{
                                    "borderRadius": "5px",
                                    "background": "#1B375C",
                                    "color": "#FFF",
                                    "fontSize": "15px",
                                    "fontWeight": "500",
                                }}
                                onClick={handleShowAllCompany}>
                                Show All Companies
                            </button>
                        </div>
                    </div>
                </section>
                <section className="main-section">
                    <div className="container">
                        <div className="card-bg">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card-heading">
                                        <h3>Search Result</h3>
                                    </div>
                                </div>
                                <div className="col-lg-12 mt-4">
                                    {filterCompanyData?.length > 0 ? currentCompanies.map(company => {
                                        return <div key={company?.company_data?.id} className="card card-custom mb-4">
                                            <div className="custom-company-card display-card">
                                                <div className='d-flex align-items-center'>
                                                    <div className='company-logo-dv'>
                                                        <img
                                                            className='img-fluid logo-img'
                                                            src={company?.company_data?.logo ? `${host}/uploaded-files/${company?.company_data?.logo}` : `${createImageFromInitials(500, company?.company_data?.company_name, getRandomColor())}`}
                                                            alt={company?.company_data?.company_name} title={company?.company_data?.company_name}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = createImageFromInitials(500, company?.company_data?.company_name, getRandomColor());
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="footer-heading footer-icon mx-2">
                                                        <div className='company-heading-icon'>
                                                            <div className='company-card'>
                                                               
                                                                <h3><Link to={`/profile/${company?.company_data?.company_slug}/snap-shot`}>{company?.company_data?.company_name}</Link></h3>
                                                            </div>
                                                        </div>
                                                        <p>
                                                            {company?.company_data?.business_type_detail ? company?.company_data?.business_type_detail?.businesstype : "-"}
                                                            {company?.company_data?.size && <span>
                                                                <a href="" className="mx-5" title="Company Size">
                                                                    {company?.company_data?.company_size?.companysize}
                                                                </a>
                                                            </span>}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="bookmark">
                                                 
                                                    <Link to={`/profile/${company?.company_data?.company_slug}/Jobs`} className="btn btn-success">Show jobs</Link>
                                                </div>
                                            </div>
                                            <div className="footer-list-card mt-2">
                                                <div>
                                                    {company?.about && <>{parse(`${truncateText(company?.about, 30)}`)}</>}
                                                </div>
                                            </div>
                                        </div>
                                    }) :
                                        <> <i>No Companies available.</i> </>
                                    }
                                </div>
                            </div>
                            {filterCompanyData?.length > 5 && <div className='row justify-content-center'>
                                <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                    <section className='pagination-section'>
                                        <div className='container'>
                                            <nav aria-label="Page navigation example" className='nav-class'>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel=">>"
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel="<<"
                                                    renderOnZeroPageCount={null}
                                                    containerClassName='pagination'
                                                    pageLinkClassName='page-num'
                                                    previousLinkClassName='page-num'
                                                    nextLinkClassName='page-num'
                                                    activeClassName='active'
                                                />
                                            </nav>
                                        </div>
                                    </section>

                                </div>
                            </div>}
                        </div>
                    </div>
                </section>


            </section>
        </>
    )
}

export default SearchCompanyResultListing