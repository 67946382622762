import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import HourglassLoader from '../../HourglassLoader';
import { useNavigate } from 'react-router-dom';
const host = process.env.REACT_APP_API_SERVER;

const AddProjectDetailModal = ({ id, refresh }) => {

    const [working, setworking] = useState(true);
    const [disableBtn, setDisableBtn] = useState(false);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    const CKEDITORConfig = {
        height: 200,
        validation: {
            maxLength: 500 // Set your desired maximum length here
        },
       
        fontSize: {
            options: [
                9,
                11,
                13,
                'default',
                17,
                19,
                21
            ]
        },
        toolbar: [
            // "heading",
            "undo",
            "redo",
            'bold',
            'italic',
            "fontSize",
            "bulletedList",
            "numberedList"
        ],
    }

    let initialValues = {
        id: "",
        project_title: "",
        client: "",
        start: "",
        end: "",
        in_progress: "",
        description: "",
    }

    let formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            project_title: Yup.string().required("Please enter project title."),
            client: Yup.string().required("Please enter client/company."),
            start: Yup.date()
                .max(new Date(), "Start date cannot be in the future")
                .required("Enter start date."),
            end: working ? Yup.date()
                .max(new Date(), "End date cannot be in the future")
                .min(
                    Yup.ref('start'),
                    "End date cannot be earlier than Start date")
                .required('Enter end date.') : Yup.string(),
        }),
        onSubmit: (values, { resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            if (values.in_progress == true) {
                values.in_progress = 1;
            } else {
                values.in_progress = 0;
            }
            console.log(values);
            setDisableBtn(true)
            try {
                values.cvbuilder_id = id
                if (values.currently_working === true) {
                    values.end = ''
                }
               
                let post_url;
                let config;

                if (localStorage.getItem("seeker_token")) {
                    post_url = `${host}/cvbuilder/add-cvbuilder-seeker-project`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "auth-token": localStorage.getItem("seeker_token")
                        }
                    }
                } else if (localStorage.getItem("karlatoon_deviceId")) {
                    post_url = `${host}/cvbuilder/add-cvbuilder-seeker-project-guest`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "device-token": localStorage.getItem("karlatoon_deviceId")
                        }
                    }
                } else {
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Token expired.' });
                    navigate('/build-resume')
                }
                axios.post(post_url, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            let closeBtn = document.getElementById(`closeBtnProjectDetail`);
                            closeBtn.click();
                            refresh();
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                        } else {
                            setLoader(false);
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        document.body.style.overflow = 'auto';
                        setLoader(false);
                        setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    });

            } catch (error) {

            }
        }
    });


    const handleChangeProjectEdit = (e) => {
        if (e.target.value == "false") {
            setworking(false);
            formik.setFieldValue("end", "");
        } else if (e.target.value == "true") {
            setworking(true);
        }
    };


    return (
        <>
            <div className="modal fade modal-lg" id={`addProjectDetailCvModal`} tabindex="-1" aria-labelledby="addProjectDetailCvModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addProjectDetailCvModalLabel">Add project details</h5>
                            <button type="button" className="btn-close" id={`closeBtnProjectDetail`} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <div className='row'>
                                    <div className="mb-3 col-md-6">
                                        <label htmlFor="Title" className="form-label">
                                            <b>Project Title<span className='text-danger'>*</span></b>
                                        </label>
                                        <input type="text" name='project_title' className='form-control' placeholder='Project Title'
                                            maxLength={150}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.project_title}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.project_title && formik.errors.project_title ? (
                                                <div className='text-danger'>{formik.errors.project_title}</div>
                                            ) : null}
                                        </div>

                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label htmlFor="Employer" className="form-label">
                                            <b>Client/Company<span className='text-danger'>*</span></b>
                                        </label>
                                        <input type="text" name='client' className='form-control' placeholder='Client/Company name'
                                            maxLength={150}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.client}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.client && formik.errors.client ? (
                                                <div className='text-danger'>{formik.errors.client}</div>
                                            ) : null}
                                        </div>

                                    </div>
                                    <div className="col-12"> <input type="checkbox" className="form-check-input" id="exampleCheck1" name='in_progress'
                                        onChange={(e) => {
                                            setworking(e.target.value == 'true');
                                            formik.handleChange(e);
                                        }}
                                        style={{ borderColor: "black" }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.in_progress}
                                        checked={formik.values.in_progress}
                                    />
                                        <label className="form-check-label ms-3" htmlFor="exampleCheck1">
                                            Project in process
                                        </label></div>

                                    <div className="mb-3 col-md-6">
                                        <label htmlFor="Start Date" className="form-label">
                                            <b> Start Date<span className='text-danger'>*</span></b>
                                        </label>
                                        <input type='date' name='start' className='form-control' placeholder='Start Month & Year'
                                            max={new Date().toISOString().split('T')[0]}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.start}

                                        />

                                        <div className="help-block with-errors">
                                            {formik.touched.start && formik.errors.start ? (
                                                <div className='text-danger'>{formik.errors.start}</div>
                                            ) : null}
                                        </div>

                                    </div>

                                    {(working) && <div className="mb-3 col-md-6">
                                        <label htmlFor="End Date" className="form-label">
                                            <b>  End Date<span className='text-danger'>*</span></b>
                                        </label>
                                        <input type='date' name='end' className='form-control' placeholder='End Month & Year'
                                            max={new Date().toISOString().split('T')[0]}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.end}

                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.end && formik.errors.end ? (
                                                <div className='text-danger'>{formik.errors.end}</div>
                                            ) : null}
                                        </div>


                                    </div>}
                                    <div className="mb-3 col-md-12">
                                        <label htmlFor="description" className="form-label">
                                            <b>Description</b>
                                        </label>
                                        <div>
                                            <CKEditor
                                                name="description"
                                                config={CKEDITORConfig}
                                                editor={ClassicEditor}
                                                data={formik.values.description}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    formik.setFieldValue('description', data);
                                                }}
                                            />
                                            <div className="help-block with-errors mt-2">
                                                {formik.touched.description && formik.errors.description ? (
                                                    <div className='text-danger'>{formik.errors.description}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="help-block with-errors">
                                            {formik.touched.description && formik.errors.description ? (
                                                <div className='text-danger'>{formik.errors.description}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="mb-3 col-md-12 text-center">
                                        <button type="submit" disabled={disableBtn} className="btn btn-primary btn-sm w-fit me-1">
                                            {disableBtn ? <HourglassLoader /> : "Add"}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddProjectDetailModal;
