import React, { useState, useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { useDispatch, useSelector } from 'react-redux';
import './scss/AnalysisChart.css';
import { getAnalyticsHiredChartData } from '../../../Redux/action/RecruiterAction';
const HiringInsightsOverview = () => {
    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;
    const dispatch = useDispatch();
    const [selectedInterviewValue, setSelectedInterviewValue] = useState("Week");
    const [renderKey, setRenderKey] = useState(0);
    const authToken = localStorage.getItem("recruiter_token");
    useEffect(() => {
        dispatch(getAnalyticsHiredChartData(authToken, selectedInterviewValue));
    }, [selectedInterviewValue, dispatch, authToken]);
    useEffect(() => {
        const handleTabChange = () => {
            if (document.getElementById('hiring-insights-tab').classList.contains('active')) {
                setRenderKey(renderKey + 1);
            }
        };
        document.getElementById('hiring-insights-tab')?.addEventListener('shown.bs.tab', handleTabChange);
        return () => {
            document.getElementById('hiring-insights-tab')?.removeEventListener('shown.bs.tab', handleTabChange);
        };
    }, [renderKey]);
    const handleInterviewSelect = (event) => {
        const value = event.target.value;
        setSelectedInterviewValue(value);
        dispatch(getAnalyticsHiredChartData(authToken, value));
    };
    const analyticsChartData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.analyticsHiredChartDataList?.data) : "");
    const total_hired_jobs = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.analyticsHiredChartDataList?.total_hired_jobs) : 0);
    CanvasJS.addColorSet("dataColor", ["#f5fbff"]);
    const mapData = (data, period) => {
        return data.map((dp, index) => ({
            x: period === 'Year' ? 12 - index : dp.DayMonth,
            y: dp.job_posted_count,
            label: dp.month
        }));
    };
    const arr = mapData(analyticsChartData || [], selectedInterviewValue);
    const options = {
        animationEnabled: true,
        colorSet: "dataColor",
        axisX: {
            minimum: 0.5,
            interval: 1,
            labelFormatter: (e) => {
                if (selectedInterviewValue === "Month") {
                    const index = arr.findIndex((dp) => dp.x === e.value);
                    return index % 3 === 0 ? e.label : "";
                }
                return e.label;
            },
        },
        axisY: {
            gridThickness: 1,
            gridColor: "#e8e7fe",
            minimum: 0,
            labelFormatter: e => {
                if (Number.isInteger(e.value)) { return e.value;
                } else {return ""; 
                }
            }
        },
        data: [{type: "splineArea",yValueFormatString: "#,##0.## Jobs Posted",showInLegend: false,lineColor: "#08aafd",lineThickness: 4,markerColor: "#FFF",markerBorderColor: "#08aafd",markerBorderThickness: 2,dataPoints: arr,
        }]
    };
    return (
        <>
            <section className="custm-chart">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="overview-head mb-4 d-flex justify-content-between">
                            <div className="textarea">
                                <h4>Hiring Insights overview</h4>
                                <p>Monitor and analyse Hirings</p>
                            </div>
                            <div className='dropdown'>
                                <select onChange={handleInterviewSelect} value={selectedInterviewValue}>
                                    <option value="Week">Last Week</option>
                                    <option value="Month">Last Month</option>
                                    <option value="Year">Last Year</option>
                                </select>
                            </div>
                        </div>
                        <div className="review d-flex justify-content-between">
                            <h5>Hiring overview</h5>
                        </div>
                        <div style={{ overflowX: 'auto' }}>
                            <div style={{ minWidth: '100%' }}>
                                <CanvasJSChart key={renderKey} options={options} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="performance py-5">
                <div className="container pb-5">
                    <div className="row justify-content-between">
                        <div className="heading my-4">
                            <h4>Performance summary</h4>
                        </div>
                        <div className="col-lg-12 my-5">
                            <div className="row campaign-card py-5 align-items-center">
                                <div className="col-lg-12 col-md-12 text-center">
                                    <h4>Total Hiring done</h4>
                                    <h1>{total_hired_jobs ?? 0}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HiringInsightsOverview;
