import React from 'react'
import './scss/InterviewedCard.css'
import { Link } from 'react-router-dom'
import JobCard from '../JobCard/JobCard'
const InterviewedCard = ({ jobs }) => {
    return (
        <>
            <JobCard jobs={jobs} />
        </>
    )
}

export default InterviewedCard