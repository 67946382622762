import { useFormik } from 'formik'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from "yup";
const CareerSearch = ({ blogCategoryData }) => {
    const navigate = useNavigate();
    let initialValues = {searched_text: '',}
    const formik = useFormik({initialValues: initialValues,
        validationSchema: Yup.object({searched_text: Yup.string().required("Please enter to start search")}),
        onSubmit: (values) => {            
            if (values.searched_text) { navigate(`/career-guide-search?s=${values.searched_text}`) }
        }
    })
    return (
        <section className="career-search">
            <div className="container">
                <div className="row">
                    <div className="career-title pt-5">
                        <h1>Career Guide</h1>
                        <h6>Turn your dreams into reality with the opinion of experts from industry specialists</h6>
                    </div>
                    <div className="custm-serch py-3">
                        <form method='post' onSubmit={formik.handleSubmit} className=''>
                      <div className='d-sm-flex text-center justify-content-center align-itmes-center'>
                        
                            <div className="serach-input col-lg-10 mt-5">
                                <img src="./assets/images/career-guide/search.png" alt="" />
                                <input type="search" className='form-control ps-5' placeholder='Career Guidance'
                                    name='searched_text'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.searched_text}
                                />
                              
                            </div>
                            <button className='mt-lg-5 col-lg-2 mt-sm-0 mt-2 mt-lg-2 search-career-guide-btn' type='submit' >Search</button>
                      </div>
                            <div className="help-block with-errors col-lg-10">
                                    {formik.touched.searched_text && formik.errors.searched_text ? (
                                        <div className='text-danger text-md-start text-center'>{formik.errors.searched_text}</div>
                                    ) : null}
                                </div>
                        </form>                       
                    </div>
                    <div className="custm-serch">
                        <div className="serach-input col-lg-10">
                            <div className="col-lg-10 justify-content-center ml-3">                                
                            </div>
                        </div>                        
                    </div>
                    <div className="">
                        <ul className="search-list mx-auto  ps-0">
                            {blogCategoryData && blogCategoryData?.map((blogCategory, index) => {
                                return <li key={index + 1}>
                                    <Link to={`/career-guide-category-new/${blogCategory?.name_slug}`}>{blogCategory?.name}
                                        <img src="./assets/images/career-guide/search.png" alt="" />
                                    </Link>
                                </li>
                            })}
                            
                        </ul>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default CareerSearch