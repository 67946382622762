import React, { useEffect, useState } from 'react'
import "./Scss/CareerStartHereSearch.css";
import { useDispatch, useSelector } from 'react-redux';
import { getEducationLevelData, getExperienceLevelData, getIndustryData, getMostViewedJobsData, getTypeOfJobData, getbusinesstypeData, getcityData, getjobCountData, getpositionlevelData, getscheduleData, getworkplaceData } from '../../../../Redux/action/SeekerAction';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { getAfganCities, getFindJobCompanyBanner } from '../../../../Redux/action/MasterAction';
import { toast } from 'react-toastify';
const host = process.env.REACT_APP_API_SERVER;
const CareerStartHereSearchContent = () => {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const [showWorkplace, setShowWorkplace] = useState(false);
    const [showCity, setShowCity] = useState(false);
    const [showEducationLevel, setShowEducationLevel] = useState(false);
    const [showExperience, setShowExperience] = useState(false);
    const [showSchedule, setShowSchedule] = useState(false);
    const [showJobtype, setShowJobtype] = useState(false);
    const [showPositionlevel, setShowPositionlevel] = useState(false);
    const [showIndustry, setShowIndustry] = useState(false);
    const [showBusinessType, setShowBusinessType] = useState(false);
    const [jobCount, setJobCount] = useState(0);
    const [cities, setCities] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    useEffect(() => { dispatch(getMostViewedJobsData());dispatch(getworkplaceData());dispatch(getcityData()); dispatch(getEducationLevelData()); dispatch(getExperienceLevelData()); dispatch(getscheduleData()); dispatch(getTypeOfJobData()); dispatch(getpositionlevelData());dispatch(getIndustryData());dispatch(getbusinesstypeData());dispatch(getjobCountData());dispatch(getAfganCities());dispatch(getFindJobCompanyBanner());}, [])
    let mostviewedJobs = useSelector(state => state?.SeekerReducer ? state.SeekerReducer?.mostviewedjobsList?.data : []);
    const workplaceData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.workplaceDataList : []);
    const cityData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.cityDataList : []);
    const educationLevelData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.educationLevelDataList : []);
    const experienceData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.experienceLevelDataList : []);
    const scheduleData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.scheduleDataList : []);
    const jobtypeData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.typeOfJobDataList : []);
    const positionlevelData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.positionlevelDataList : []);
    const industryData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.industryTypeDataList : []);
    const businesstypeData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.businesstypeDataList : []);
    let job_count_data = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.jobCountDataList : "0");
    let afganCity = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.afganCitiesList : []);
    useEffect(() => {
        if (workplaceData) {setShowWorkplace(true);}
    }, [workplaceData])
    useEffect(() => {
        if (cityData) {setShowCity(true);}}, [cityData])
    useEffect(() => { if (educationLevelData?.data) {setShowEducationLevel(true);}}, [educationLevelData])
    useEffect(() => {if (experienceData?.data) {setShowExperience(true);}}, [experienceData])
    useEffect(() => { if (scheduleData) {setShowSchedule(true);}}, [scheduleData])
    useEffect(() => {if (jobtypeData?.data) {setShowJobtype(true);}}, [jobtypeData])
    useEffect(() => {
        if (positionlevelData) { setShowPositionlevel(true);}}, [positionlevelData])
    useEffect(() => {if (industryData?.data) {setShowIndustry(true);}}, [industryData])
    useEffect(() => { if (businesstypeData) {setShowBusinessType(true);}}, [businesstypeData])
    useEffect(() => { if (job_count_data) {setJobCount(job_count_data);}}, [job_count_data])
    const initialValues = {serach_text: "",workplace: "",job_type: "",industry: "",
        address_city: "",location: "",company_type: "",education_level: "",min_pay: "",
        max_pay: "",experience_level: "",miles: "",benefit: "", position_level: "",department: "",natureofbusiness: "",
        schedule: "",date_posted: "",exp: ""}
    const formik = useFormik({initialValues: initialValues, validationSchema: Yup.object({}),        
        onSubmit: (values) => {function removeEmptyStringValues(obj) {for (const key in obj) {if (obj[key] === '') { delete obj[key];} }return obj;
            }
            const objectWithoutEmptyStrings = removeEmptyStringValues(values);
            let create_search_string = ""
            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) { create_search_string = create_search_string + key + "=" + objectWithoutEmptyStrings[key] + "&";
                }
            }
            console.log(create_search_string);if(!create_search_string || create_search_string==""){toast.error("Enter job title, location or experience to start a search.")
            }else{navigate(`/search-result?${create_search_string}`) } }
    });

    useEffect(() => {
        if (afganCity) {setCities(afganCity);setFilteredCities(afganCity);}}, [afganCity]);
    const handleInputChange = event => {
        const inputValue = event.target.value.toLowerCase();formik.handleChange(event);if (inputValue === '' || inputValue?.length === 1) {setFilteredCities(cities);
        } else {const filtered = cities.filter(city =>city.city_name.toLowerCase().includes(inputValue) ).slice(0, 20); setFilteredCities(filtered);}};
    const experienceOptionsData = [];
    for (let index = 0; index < 30; index++) { experienceOptionsData.push({ value: index + 1, label: `${index + 1} years` }) }
    let [imageUrl, setImageUrl] = useState("/assets/career-start/career-start-banner.png");
    const [imageFailed, setImageFailed] = useState(false);
    let [defaultImageUrl, setDefaultImageUrl] = useState("/assets/career-start/career-start-banner.png");
    let findBanners = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.findJobCompanyBanner : null);
    useEffect(() => { if (findBanners) {setImageUrl(`${host}/${findBanners?.findjobbanner}`);}}, [findBanners])
    const handleImageError = () => { setImageFailed(true);};
    const imageStyle = { backgroundImage: `url(${imageFailed ? defaultImageUrl : imageUrl})`};

    return (
        <>
            <section className='career-start-search-section'>
                <section className="career-section" style={imageStyle}>
                    <img
                        src={imageUrl}
                        onError={handleImageError}
                        style={{ display: 'none' }}
                        alt=""
                    />
                    <div className="career-start-section">
                        <div className="container">
                            <form method='get' onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="career-top-heading">
                                            <h2>
                                                Your <span> career </span> starts here
                                            </h2>
                                        </div>
                                        <div className="career-second-heading">
                                            <h6>
                                                Search <span> {jobCount} </span> Job Postings in Afghanistan
                                            </h6>
                                        </div>
                                        <div>
                                            <div className="custom-form-section">
                                                <div className="search-custom" style={{ width: "75vw" }}>
                                                    <img
                                                        src="/assets/career-start/magnifyingglass.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                    <input
                                                        type="text"
                                                        className="form-control form-custom"
                                                        placeholder="Search with keywords"
                                                        name="serach_text"
                                                        value={formik.values.serach_text}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                                <div className="search-custom">
                                                    <img src="/assets/career-start/mappinline.png" className="img-fluid" alt="" /><input
                                                        type="text"
                                                        className="form-control form-custom"
                                                        placeholder="Location"
                                                        name="location"
                                                        value={formik.values.location}
                                                        onBlur={formik.handleBlur}
                                                        onChange={e => {
                                                            formik.handleChange(e);
                                                            handleInputChange(e);
                                                        }
                                                        }
                                                    />
                                                </div>
                                                <datalist id="cities-list">
                                                    {filteredCities.map((city, index) => (
                                                        <option key={index + 1} value={city.city_name} >{city.city_name}</option>
                                                    ))}
                                                </datalist>
                                                <div className="search-custom">
                                                    <img src="/assets/career-start/uim_bag.png" className="img-fluid" alt="" />
                                                   

                                                    <select
                                                        id='exp'
                                                        name='exp'
                                                        className='form-control form-custom'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.exp}
                                                        placeholder="Experience"
                                                    >
                                                        <option>Experience</option>
                                                        {experienceOptionsData.map((value, index) => {
                                                            return <option key={index + 1} value={value.value}>{value.label}</option>
                                                        })}
                                                    </select>
                                                </div>
                                              
                                                <div className="form-custom-btn">
                                                    <button type="submit">Search job</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" dropdown-custom-btn">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </section >
        </>
    )
}

export default CareerStartHereSearchContent