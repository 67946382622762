import React, { useState, useEffect } from 'react'
import $ from "jquery";
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';

import './scss/RecForgetPassword.css';
import OtpSendEnterContent from './OtpSendEnter/OtpSendEnterContent';
import HourglassLoader from '../../Seeker/HourglassLoader';
const host = process.env.REACT_APP_API_SERVER

const RecForgetPasswordContent = () => {
    const [faIcon, setfaIcon] = useState('fa-eye-slash');
    const togglePasswordType = () => {
        let input_pass = document.getElementById('input_pass');
        const toggle_pass = document.getElementById("toggle_pass");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon('fa-eye-slash')
            input_pass.type = "password"
        }
    }



    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    const [company_email, setCompanyEmail] = useState('');
    const [timerStarted, setTimerStarted] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const [disableBtn, setDisableBtn] = useState(false);
    const handleStartTimer = () => {
        setTimerStarted(true);
    };
    useEffect(() => {
        let intervalId;

     
        if (timerStarted && seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }

    
        return () => clearInterval(intervalId);
    }, [timerStarted, seconds]);
    const handleEmailChange = (e) => {
        setCompanyEmail(e.target.value);
    };


    const [initialValues, setInitialValues] = useState({
        company_email: '',
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            company_email: Yup.string().required("Email is required.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid E-mail"),

        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setDisableBtn(true);
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {

                setSubmitting(true);
                setShowLoader(true);

                document.body.style.overflow = 'hidden';

                const config = {
                    headers: {
                        'content-type': 'application/json',
                    }
                };
                await axios.post(`${host}/api/forget-password-otp-request`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setDisableBtn(false);
                            setSubmitting(true);
                            document.body.style.overflow = 'auto';
                            const otpResponse = response.data.otp;
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `Otp is ${otpResponse}. Please verify.` });
                            navigate("/rec-otp-enter", { state: { company_email, seconds } })

                        } else {
                            setDisableBtn(false);
                            setSubmitting(false);
                            setSubmitting(true);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })

            }

            catch (error) {
                setDisableBtn(false);
                setSubmitting(true);
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    })

    return (
        <>
            <section className="forget-password-section">
                <div className="container">
                    <div className="second-section">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12 col-12 order-lg-1 order-md-1 order-2">
                                <form method='post' onSubmit={formik.handleSubmit}>
                                    <div className='text-start mt-custon-1'>
                                        <h3>Forgot Password</h3>
                                        <span className="para-3">Enter your registered email to reset your password.</span>
                                      
                                    </div>
                                    <div>
                                        <div className="row">
                                            <p className="para">Email</p>
                                            <div className="col-12">
                                                <div className='user-envlop-cls'>
                                                    <span><img src='././././assets/recruiter-images/singin/Vector1.png' /></span>
                                                    <input className="input-text" type="email" name="company_email" placeholder="Enter your email address" onChange={(e) => { formik.handleChange(e); handleEmailChange(e) }} onBlur={formik.handleBlur} value={formik.values.company_email} />
                                                </div>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.company_email && formik.errors.company_email ? (
                                                    <div className='text-danger'>{formik.errors.company_email}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                       
                                        <div className="row row-top">
                                           
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <div className='mt-5 login-btn'>
                                                   
                                                    <button type="submit" className='btn rounded-pill px-5' style={{ backgroundColor: "#1B375C", color: 'white' }} disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : 'Request Otp'}</button>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12 col-12 text-center'>
                                                <p className="bottm-signup"><Link to="/company-details">New here? Sign Up.</Link></p>
                                                <p className='bottm-signin'><Link to="/recruiter-login">Already have an account? Sign In.</Link></p>
                                               
                                            </div>
                                        </div>
                                    </div>



                                </form>
                            </div>
                            <div className="col-lg-6 col-md-12 col-12 order-lg-2 order-md-2 order-1">
                                <div>
                                    <img
                                        src="././././assets/recruiter-images/singin/back-girl.png" width="100%" height="100%"
                                        alt="" className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </section>

        </>
    )
}

export default RecForgetPasswordContent