import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import RecMySubsCriptionPlanContent from './RecMySubsCriptionPlanContent'
import Footer from '../Commons/Footer'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { getRecruiterAllPurchasedPlans, getRecruiterCurrentActivePlan } from '../../../Redux/action/RecruiterAction'
import Loader from '../Loader'

const RecMySubsCriptionPlan = () => {
    let navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(true);
    const [currentPlan, setCurrentPlan] = useState({});
    const dispatch = useDispatch();
    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            toast.error("Please login to continue.");
            navigate("/recruiter-login");
        } else {
            const token = localStorage.getItem("recruiter_token");
            dispatch(getRecruiterCurrentActivePlan(token));
        }
    }, []);

    const currentActivePlan = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.recruiterCurrentActivePlanDataList : null)

    useEffect(() => {
        if (currentActivePlan) {
            setCurrentPlan(currentActivePlan);
            setShowLoader(false);
        } else {
            setShowLoader(true);
        }
    }, [currentActivePlan])

    return (
        <>
            {!showLoader ? <>
                <RecAfterLoginNavbar />
                <RecMySubsCriptionPlanContent currentPlan={currentPlan} />
                <Footer />
            </> :
                <div className="overlay-form-submission">
                    <Loader />
                </div>
            }</>
    )
}

export default RecMySubsCriptionPlan