import { useFormik } from 'formik'
import * as Yup from "yup";
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { error } from 'jquery';
import { toast } from 'react-toastify';
import HourglassLoader from '../../../Seeker/HourglassLoader';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAssessmentQuestionList, getQuestionDetail } from '../../../../Redux/action/RecruiterAction';
const host = process.env.REACT_APP_API_SERVER;

const EditAssessmentQuestionsModal = ({ reloadData, assessment_id, question_id }) => {
    const initialValues = {
        assessment_id: Number(assessment_id),
        title: "",
        first_option: "",
        second_option: "",
        third_option: "",
        fourth_option: "",
        correct_option: 1,
        marks: 0,
        duration: 0
    }
    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (question_id) {
            dispatch(getAssessmentQuestionList(assessment_id, localStorage.getItem("recruiter_token")));
        }
    }, [question_id])

    const questionData = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.assessmentQuestionListData : null)

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().required("Please enter question."),
            first_option: Yup.string().required("Please enter first option."),
            second_option: Yup.string().required("Please enter second option."),
            third_option: Yup.string().required("Please enter third option."),
            fourth_option: Yup.string().required("Please enter fourth option."),
            correct_option: Yup.number().required("Please select correct option."),
            marks: Yup.number().required("Please enter marks.").min(1, "Mark must be greater than 0"),
            duration: Yup.number().required("Please enter duration.").min(1, "Duration must be greater than 0")
        }),
        onSubmit: (values, { resetForm }) => {
            values.id= question_id
            setDisabled(true);
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                const config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } }
                axios.post(`${host}/recruiter/update-assessment-question`, values, config)
                    .then(response => {
                        if (response.status == 200) {
                            setDisabled(false);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Question updated successfully" });
                            reloadData();
                            $(`#editAssessmentQuestion${question_id}CloseBtn`).click();
                            resetForm();
                        } else {
                            setDisabled(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    }).catch(error => {
                        setDisabled(false);
                        console.log(error.message)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
                    })
            } catch (error) {
                setDisabled(false);
                console.log(error.message)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
            }

        }
    });

    useEffect(() => {
        if (questionData) {
            const findQuestion = questionData.find(itme => itme.id === question_id)
            formik.setValues({
                assessment_id: Number(assessment_id),
                title: findQuestion?.title ?? "",
                first_option: findQuestion?.first_option ?? "",
                second_option: findQuestion?.second_option ?? "",
                third_option: findQuestion?.third_option ?? "",
                fourth_option: findQuestion?.fourth_option ?? "",
                correct_option: findQuestion?.correct_option ?? 1,
                marks: findQuestion?.marks ?? 0,
                duration: findQuestion?.duration ?? 0
            })
        }
    }, [questionData])


    return (
        <>
            <div className="modal fade" id={`editAssessmentQuestion${question_id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Edit Assessment Question : {question_id} : </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id={`editAssessmentQuestion${question_id}CloseBtn`}></button>
                        </div>
                        <div className="modal-body">
                            <form method="post" onSubmit={formik.handleSubmit}>
                                {/* Question */}
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <label htmlFor="title">Enter assessment question <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            name='title'
                                            placeholder='Enter assessment question'
                                            maxLength={250}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.title} />
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.title && formik.errors.title ? (
                                            <div className='text-danger'> {formik.errors.title}</div>
                                        ) : null}
                                    </div>
                                </div>
                                {/* First option */}
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <label htmlFor="first_option">Enter first option<span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            name='first_option'
                                            placeholder='Enter first option'
                                            maxLength={250}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.first_option} />
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.first_option && formik.errors.first_option ? (
                                            <div className='text-danger'> {formik.errors.first_option}</div>
                                        ) : null}
                                    </div>
                                </div>
                                {/* Second option */}
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <label htmlFor="second_option">Enter second option<span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            name='second_option'
                                            placeholder='Enter second option'
                                            maxLength={250}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.second_option} />
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.second_option && formik.errors.second_option ? (
                                            <div className='text-danger'> {formik.errors.second_option}</div>
                                        ) : null}
                                    </div>
                                </div>
                                {/* Third option */}
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <label htmlFor="third_option">Enter third option<span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            name='third_option'
                                            placeholder='Enter third option'
                                            maxLength={250}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.third_option} />
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.third_option && formik.errors.third_option ? (
                                            <div className='text-danger'> {formik.errors.third_option}</div>
                                        ) : null}
                                    </div>
                                </div>
                                {/* Fourth option */}
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <label htmlFor="fourth_option">Enter fourth option<span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            name='fourth_option'
                                            placeholder='Enter fourth option'
                                            maxLength={250}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.fourth_option} />
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.fourth_option && formik.errors.fourth_option ? (
                                            <div className='text-danger'> {formik.errors.fourth_option}</div>
                                        ) : null}
                                    </div>
                                </div>
                                {/* Select correct option + marks */}
                                <div className="row mt-2">
                                    <div className="col-md-6">
                                        <label htmlFor="correct_option">Select correct option<span className='text-danger'>*</span></label>
                                        <select
                                            name="correct_option"
                                            className='form-control'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.correct_option}>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                        </select>
                                        <div className="help-block with-errors">
                                            {formik.touched.correct_option && formik.errors.correct_option ? (
                                                <div className='text-danger'> {formik.errors.correct_option}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="marks">Enter marks<span className='text-danger'>*</span></label>
                                        <input
                                            type="number"
                                            className='form-control'
                                            name='marks'
                                            placeholder='Enter marks'
                                            maxLength={250}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.marks} />
                                        <div className="help-block with-errors">
                                            {formik.touched.marks && formik.errors.marks ? (
                                                <div className='text-danger'> {formik.errors.marks}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                </div>
                                {/* Duration */}
                                <div className="row mt-2">


                                    <div className="col-md-6">
                                        <label htmlFor="marks">Enter duration(in seconds)<span className='text-danger'>*</span></label>
                                        <input
                                            type="number"
                                            className='form-control'
                                            name='duration'
                                            placeholder='Enter duration'
                                            maxLength={250}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.duration} />
                                        <div className="help-block with-errors">
                                            {formik.touched.duration && formik.errors.duration ? (
                                                <div className='text-danger'> {formik.errors.duration}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                </div>

                                <div className="row mt-2">
                                    <div className="col-md-6">
                                        {disabled ?
                                            <HourglassLoader />
                                            : <button className="btn btn-primary btn-sm" disabled={disabled}>Submit</button>}
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default EditAssessmentQuestionsModal