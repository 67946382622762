import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import JobFairEditJobPageContent from './JobFairEditJobPageContent'
import Footer from '../Commons/Footer'
import JobFairEditJobPageBanner from './JobFairEditJobPageBanner'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Loader from '../Loader'
import { getJobDetailsData, getJobFairJobById } from '../../../Redux/action/RecruiterAction'

const JobFairEditJobPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const job_id = queryParams.get('job_id');
    const [loader, setLoader] = useState(true);
    const [job, setJob] = useState(false);
    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            toast.error("Please login to continue.")
            navigate("/recruiter-login");
        }
        if (!job_id) {
            toast.error("Job id is missing.");
            navigate(-1);
        }
        let token = localStorage.getItem("recruiter_token");
        dispatch(getJobFairJobById(job_id, token));
    }, [job_id]);

    const jobData = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.postedFairJobByIdList : []);


    useEffect(() => {
        if (jobData) {
            setLoader(false);
            setJob(jobData);
        }
    }, [jobData])

    return (
        <>
            {!loader ? <>
                <RecAfterLoginNavbar />
                <JobFairEditJobPageBanner />
                <JobFairEditJobPageContent job={job} />
                <Footer />
            </> :
                <div className="overlay-form-submission">
                    <Loader />
                </div>
            }
        </>
    )
}

export default JobFairEditJobPage