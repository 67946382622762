
import './scss/hiring.css'
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { getFeaturesData } from '../../../Redux/action/SeekerAction';
const host = process.env.REACT_APP_API_SERVER
const MatchingAndHiring = () => {
    const dispatch = useDispatch();
    let [image, setImage] = useState("/assets/images/matching-hiring/h-banner.png");
    useEffect(() => {dispatch(getFeaturesData());}, []);
    const featuresSeekerData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.featuresDataList?.data?.[1]?.featureseeker?.[0]) : "");
    const firstTitle = featuresSeekerData?.section_one_title ? featuresSeekerData?.section_one_title : 'Matching and hiring made easy'
    const firstSubtitle = featuresSeekerData?.section_one_sub_title ? featuresSeekerData?.section_one_sub_title : 'We have all the tools you need to find the people who fit your team and to start hiring today.'
    const img = featuresSeekerData?.section_one_banner_img ? `${host}/${featuresSeekerData?.section_one_banner_img}` : './assets/images/matching-hiring/h-banner.png';
    useEffect(() => {
        if (featuresSeekerData) {
            console.log("YOUTUBE")
            setImage(`${host}/${featuresSeekerData?.section_one_banner_img}`);
            console.log(image)} else {setImage("/assets/images/matching-hiring/h-banner.png");}}, [featuresSeekerData])
    return (
        <section className='matching-hiring'>
            <div className="container-fluid">
                <div className="row banner-area" style={{ backgroundImage: `url(${image})` }}>

                    <div className="col-lg-7 col-md-7 banner-sec-lft">
                        <div className="banner-text">
                            <h1>{firstTitle}</h1>
                            <p>{firstSubtitle}</p>
                            <Link to="/career-start-search">Find Job</Link>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-7 banner-sec-rt">
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MatchingAndHiring