import React from 'react'
import './scss/RejectedCard.css'
import { Link } from 'react-router-dom'
import JobCard from '../JobCard/JobCard'
const RejectedCard = ({ jobs }) => {
    return (
        <>        
            <JobCard jobs={jobs} />
        </>
    )
}

export default RejectedCard