import React from 'react'
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
const host = process.env.REACT_APP_API_SERVER;
const NewsSportsDynamic = ({ dataByCat }) => {
    const truncateText = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';}return text;
    };
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        const formattedDate = `${day}-${months[monthIndex]}-${year}`;
        return formattedDate;
    }
    return (

        <>
            {dataByCat &&
                dataByCat.length > 1 &&
                dataByCat.map((cat, index) => {
                    return (!index == 0) && <><section className='sports mb-5' key={index + 1}>
                        <div className="container pb-5">
                            <div className="row">
                                <div className="col-lg-12 py-4">
                                    <div className="heading">
                                        <h1>{cat.name}</h1>
                                    </div>
                                    <hr />
                                    {cat?.news && cat?.news.length > 0 ? <div className="row">
                                        <div className="col-lg-6 news-display pt-3">
                                            <img src={`${host}/${cat?.news[0]?.image}`} className='img-fluid' alt="" />
                                            <ul className=' pb-1 d-flex'>
                                                {/* <li>Craig Bator - </li> */}
                                                <li>{formatDate(cat?.news[0]?.createdAt)}</li>
                                            </ul>
                                            <Link className='remove-link-property-news-page' to={`/karlatoon-news-detail/${cat?.news[0]?.news_slug}`}><h3 className='line-clamp-2'>{cat?.news[0].title}</h3></Link>
                                            <p className='line-clamp-4-news first-description'>{parse(`${truncateText(cat?.news[0]?.description, 50)}`)}</p>
                                        </div>
                                        {cat?.news?.length > 1 ?
                                            <div className="col-lg-6 py-4 pt-3">

                                                {cat?.news.map((news, i) => {
                                                    return (!i == 0 && i < 6) && <div className="news-list row pb-3" key={i + 1}>
                                                        <div className="col-lg-4">
                                                            <img src={`${host}/${news?.image}`} className='img-fluid' alt="" />
                                                        </div>
                                                        <div className="col-lg-8">
                                                            <ul className='d-flex'>
                                                                {/* <li>Craig Bator - </li> */}
                                                                <li>{formatDate(news?.createdAt)}</li>
                                                            </ul>
                                                            <div className="para">
                                                                <Link className='remove-link-property-news-page' to={`/karlatoon-news-detail/${news?.news_slug}`}> <h3 className='line-clamp-2'>{news.title}</h3></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                               
                                            </div> :
                                            <div className="col-lg-6 py-4 pt-3">
                                                <div className="text-center">
                                                    <i> More news not available.</i>
                                                </div>
                                            </div>
                                        }
                                    </div> :
                                        <div className="text-center my-2">
                                            No news available.
                                        </div>
                                    }
                                </div>
                            </div>
                            {cat?.news?.length > 6 && <div className="row">
                                <div className="text-center">
                                    <Link to={`/karlatoon-news-category/${cat?.name_slug}`}>
                                        View All
                                    </Link>
                                </div>
                            </div>}
                        </div>
                    </section></>
                })

            }
        </>

    )
}

export default NewsSportsDynamic