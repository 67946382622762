import React, { useEffect, useState } from 'react'
import './scss/FindRightPlacesCompanyListing.css'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanybyIndustry, getIndustryById, getIndustryData, getbusinesstypeData, getCountryData, getcompanyCountData, getfilterCompany, getCityStateData } from '../../../../Redux/action/SeekerAction';
import ReactPaginate from 'react-paginate';
import { createImageFromInitials, getRandomColor } from '../../../Utils';
import parse from 'html-react-parser';
import CareerSearchSectionSearchCompanyResult from './CareerSearchSectionSearchCompanyResult';
import { getBusinesstypeData, getCompanysizeData } from '../../../../Redux/action/RecruiterAction';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER;



const JobFairJobListListing = ({ jobs, reload }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let [jobData, setJobData] = useState([]);
    useEffect(() => {
        if (jobs) {
            setJobData(jobs);
        }
    }, [jobs])


    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [companyOffset, setCompanyOffset] = useState(0);
    const companyPerPage = 5;
    useEffect(() => {
        const endOffset = companyOffset + companyPerPage;
        setCurrentData(jobData.slice(companyOffset, endOffset));
        setPageCount(Math.ceil(jobData?.length / companyPerPage));
    }, [companyOffset, companyPerPage, jobData])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * companyPerPage) % jobData?.length;
        setCompanyOffset(newOffset);
    };
    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };


    const handleApply = (job_id) => {
        try {
            Swal.fire({
                title: 'Apply?',
                text: 'Applying to Job Fair jobs will have no record on panel. You will be notified via email. Recruiter can visit your details on Karlatoon.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "rgb(27, 55, 92)",
                confirmButtonText: 'Yes, Apply!',
                cancelButtonText: 'No, cancel!',
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const loadingToastId1 = toast.info('Applying...', { autoClose: false });
                    const values = { job_id: job_id };
                    const config = { headers: { "auth-token": localStorage.getItem("seeker_token") } }
                    axios.post(`${host}/mobile/apply-job-fair-job`, values, config)
                        .then(response => {
                            if (response.status == 200) {
                                toast.update(loadingToastId1, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                                reload();
                            } else {
                                toast.update(loadingToastId1, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        }).catch(error => {
                            toast.update(loadingToastId1, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error." });
                        })
                }
            })
        } catch (error) {
            console.log(error.message);
            toast.error("Internal server Error")
        }
    }
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        const formattedDate = `${day}-${months[monthIndex]}-${year}`;

        return formattedDate;
    }


    const handleToast = () => {
        if (!localStorage.getItem("seeker_token")) {
            toast.error("Please login to continue.")
        }
    }

    return (
        <>
            <section className='find-company-listing-section'>
                <section className="career-section">
                    <div className="career-start-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="career-top-heading">
                                        <h2>Explore the career you love </h2>
                                    </div>
                                    <div className="career-top-heading mt-4">
                                        <h1>
                                            Apply to jobs in Karlatoon Job Fair
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="main-section">
                    <div className="container">
                        <div className="card-bg">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card-heading">
                                        <h3>Search Result</h3>
                                    </div>
                                </div>
                                <div className="col-lg-12 mt-4">
                                    {jobData && jobData?.length > 0 ? currentData.map((job, index) => {
                                        return <div key={index} className="card card-custom mb-4">
                                            <div className="custom-company-card display-card">
                                                <div className='d-flex align-items-center'>
                                                    <div className='company-logo-dv'>
                                                        <img
                                                            className='img-fluid logo-img'
                                                            src={job?.company_details?.logo ? `${host}/uploaded-files/${job?.company_details?.logo}` : `${createImageFromInitials(500, job?.company_details?.company_name, getRandomColor())}`}
                                                            alt={job?.company_details?.company_name} title={job?.company_details?.company_name}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = createImageFromInitials(500, job?.company_details?.company_name, getRandomColor());
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="footer-heading footer-icon mx-2">
                                                        <div className='company-heading-icon'>
                                                            <div className='company-card'>
                                                                <h3>{job?.job_title}</h3>
                                                            </div>
                                                        </div>
                                                        <p>
                                                          
                                                            <Link to={`/profile/${job?.company_details?.company_slug}/snap-shot`} onClick={handleToast}>{job?.company_details?.company_name}</Link>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="bookmark">
                                                    {job?.applied == true ? <button className="btn btn-success" >&#10003; Applied</button> : <button className="btn btn-success" onClick={() => { handleApply(job?.id) }}>&#10148; Send Application</button>}
                                                </div>
                                            </div>
                                            <div className="footer-list-card mt-2">
                                                <div>
                                                    <p><b>Start:</b> {formatDate(job?.start_date)}</p>
                                                    <p><b>End:</b> {formatDate(job?.end_date)}</p>
                                                    <p><b>Venue:</b> {job?.address}, {job?.city?.city_name}, {job?.state?.state_name}, {job?.country?.name}</p>
                                                    <p><b>Salary:</b>  {job?.currency} {job?.min_pay} -  {job?.currency} {job?.max_pay} {job?.rate}</p>
                                                </div>
                                                <div>
                                                    {parse(`${truncateText(job?.about, 30)}`)}
                                                </div>
                                            </div>
                                        </div>
                                    }) :
                                        <> <i>No Companies available.</i> </>
                                    }
                                </div>
                            </div>
                            {jobData && jobData?.length > 5 && <div className='row justify-content-center'>
                                <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                    <section className='pagination-section'>
                                        <div className='container'>
                                            <nav aria-label="Page navigation example" className='nav-class'>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel=">>"
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel="<<"
                                                    renderOnZeroPageCount={null}
                                                    containerClassName='pagination'
                                                    pageLinkClassName='page-num'
                                                    previousLinkClassName='page-num'
                                                    nextLinkClassName='page-num'
                                                    activeClassName='active'
                                                />
                                            </nav>
                                        </div>
                                    </section>

                                </div>
                            </div>}
                        </div>
                    </div>
                </section>


            </section >
        </>
    )
}

export default JobFairJobListListing