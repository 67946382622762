import React, { useEffect, useState } from 'react'
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import "./CareerPreferencesPopups.css"
import { Formik, useFormik } from 'formik'
import * as Yup from "yup";
import { toast } from 'react-toastify'
import CareerPreferencesPopupsJobTitle from './CareerPreferencesPopupsJobTitle'
import { useDispatch, useSelector } from 'react-redux'
import { getJobTypesData } from '../../../Redux/action/RecruiterAction'
import Select from 'react-select';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER

const CareerPreferencesPopupsJobTypes = ({ data, reload }) => {
    const dispatch = useDispatch();
    const [jobTypes, setJobTypes] = useState([]);
    useEffect(() => {
        dispatch(getJobTypesData());
    }, []);

    const jobTypeSelector = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.jobtypesDataList : []);
    const jobTypeOptions = [];
    jobTypeSelector && jobTypeSelector?.data && jobTypeSelector?.data?.map(jobtype => {
        jobTypeOptions.push({ value: jobtype.id, label: jobtype.jobtypename })
    })
    useEffect(() => {
        if (jobTypeSelector) {            
            setJobTypes(jobTypeSelector);
        }
    }, [jobTypeSelector]);

    const initialValues = {
        job_type: [],
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            job_type: Yup.array().min(1, 'Please select a job type.').required('Please select a job type.'),
        }),
        onSubmit: (values, { resetForm }) => {
            console.log(values);
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            const config = { headers: { "auth-token": localStorage.getItem("seeker_token") } };
            try {
                axios.post(`${host}/mobile/add-career-prefernces-job-type`, values, config)
                    .then(response => {
                        if (response.status == 200) {
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                           reload();
                            const button = document.getElementById('close-job-type-modal-btn');
                            button.click();
                        } else {
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
            } catch (error) {
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server Error." });
            }
        }
    })

    useEffect(() => {
        if (data) {
            if (data?.job_type) { const arr = data?.job_type.map(value => value.job_type_id);formik.setValues({ job_type: arr })
            }
        }
    }, [data])

    return (
        <>
            {/* =========================add-job-types-body-parent start============================= */}
            <div className="modal fade add-job-types-body-parent" id="add-job-type" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form method='post' onSubmit={formik.handleSubmit}>
                            <div className="modal-header">
                                <h1 className="modal-title" id="exampleModalLabel">
                                    Add type of job
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className='add-job-types-body'>
                                    <h4>What are your preferred type of job?</h4>
                                    <Select
                                        id="job_type"
                                        name="job_type"
                                        placeholder="Select Deparment"
                                        options={jobTypeOptions}
                                        value={jobTypeOptions.filter(option => formik.values.job_type.includes(option.value))}
                                        onChange={job_type => {
                                            formik.setFieldValue(
                                                'job_type',
                                                job_type ? job_type.map(option => option.value) : []
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        isMulti
                                    />
                                </div>
                                <div className="help-block with-errors">
                                    {formik.touched.job_type && formik.errors.job_type ? (
                                        <div className='text-danger'>{formik.errors.job_type}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary cancel-btn" data-bs-dismiss="modal" id="close-job-type-modal-btn">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary save-btn">
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    )
}

export default CareerPreferencesPopupsJobTypes