import React, { useState, useEffect } from 'react'
import $ from "jquery";
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';
import Loader from '../../Seeker/Loader';
import './scss/RecForgetPasswordSeeker.css';
import HourglassLoader from '../../Seeker/HourglassLoader';
import OtpSendEnterContentSeeker from './OtpSendEnter/OtpSendEnterContentSeeker';


import {
    LoginSocialGoogle, LoginSocialLinkedin,
} from 'reactjs-social-login'

import {
    GoogleLoginButton, LinkedInLoginButton,
} from 'react-social-login-buttons'
const client_id = process.env.REACT_APP_GG_APP_CLIENT_ID;
const host = process.env.REACT_APP_API_SERVER
const RecForgetPasswordContent = () => {

    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState(null)


    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    const [email, setCompanyEmail] = useState('');
    const [disableBtn, setDisableBtn] = useState(false);
    const [timerStarted, setTimerStarted] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const handleStartTimer = () => {
        setTimerStarted(true);
    };
    useEffect(() => {
        let intervalId;


        if (timerStarted && seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }


        return () => clearInterval(intervalId);
    }, [timerStarted, seconds]);
    const handleEmailChange = (e) => {
        setCompanyEmail(e.target.value);
    };


    const [initialValues, setInitialValues] = useState({
        email: ''
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            email: Yup.string().required("Email is required.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid E-mail"),

        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {

                setSubmitting(true);
                setShowLoader(true);
                setDisableBtn(true);
                document.body.style.overflow = 'hidden';

                const config = {
                    headers: {
                        'content-type': 'application/json',
                    }
                };
                values.type = "email"
                await axios.post(`${host}/mobile/forget-password-request`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            setDisableBtn(false);
                            setShowLoader(false)
                            document.body.style.overflow = 'auto';
                            const otpResponse = response.data.otp;
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `Otp is ${otpResponse}. Please verify.` });
                            navigate("/rec-otp-enter-seeker", { state: { email, seconds } })
                        } else {
                            setSubmitting(false)
                            setDisableBtn(false);
                            setShowLoader(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
            }

            catch (error) {

                document.body.style.overflow = 'auto';
                setSubmitting(false)
                setShowLoader(false)
                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    })

    return (
        <>
            <section className="forget-password-section">
                <div className="container">
                    <div className="second-section">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12 col-12 order-lg-1 order-md-1 order-2">
                                <form method='post' onSubmit={formik.handleSubmit}>
                                    <div className='text-start mt-custon-1'>
                                        <h3>Forgot Password</h3>
                                        <span className="para-3">Enter your registered email to reset your password.</span>
                                                                          </div>
                                    <div>
                                        <div className="row">
                                            <p className="para">Email</p>
                                            <div className="col-12">
                                                <div className='user-envlop-cls'>
                                                    <span><img src='././././assets/recruiter-images/singin/Vector1.png' /></span>
                                                    <input className="input-text" type="email" name="email" placeholder="Enter your email address" onChange={(e) => { formik.handleChange(e); handleEmailChange(e) }} onBlur={formik.handleBlur} value={formik.values.email} />
                                                </div>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.email && formik.errors.email ? (
                                                    <div className='text-danger'>{formik.errors.email}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="row row-top">

                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <div className='mt-5 login-btn'>

                                                    <button type="submit" className='btn rounded-pill px-5' style={{ backgroundColor: "#1B375C", color: 'white' }} disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : " Request Otp"}</button>

                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>



                                </form>
                            </div>
                            <div className="col-lg-6 col-md-12 col-12 order-lg-2 order-md-2 order-1">
                                <div>
                                    <img
                                        src="././././assets/recruiter-images/singin/back-girl.png" width="100%" height="100%"
                                        alt="" className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showLoader && <div className="overlay-form-submission">
                    <Loader />
                </div>}
            </section>

        </>
    )
}

export default RecForgetPasswordContent