import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import './scss/videoint.css'
import Loader from '../../Loader';
const VideoIntegration = () => {

    const featuresRecruiterData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.featuresDataList?.data?.[0]?.featurerecruiter?.[0]?.section_seven) : "");
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (featuresRecruiterData) {
            setLoader(false);
        }

    }, [featuresRecruiterData])

    let recsectionSevenCardData = [];

    if (featuresRecruiterData) {
        try {

            recsectionSevenCardData = JSON.parse(featuresRecruiterData);

        } catch (error) {
            console.error("Error parsing rec_section_seven_card data:", error);
        }
    }
    return (
        <section className="videoint py-5">
            <div className="container">
                <div className="row">

                    {recsectionSevenCardData?.map((data) => <div className="col-lg-6 col-md-6">
                        <div className="custm-card mb-4">
                            <h3>{data.title}</h3>
                            <ul>
                                {data.description}
                            </ul>
                        </div>
                    </div>)}

                </div>
            </div>
            {
                loader && (
                    <Loader />
                )
            }
        </section>
    )
}

export default VideoIntegration