import React, { useState, useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { useDispatch, useSelector } from 'react-redux';
import './scss/AnalysisChart.css';
import { getAnalyticsChartData } from '../../../Redux/action/RecruiterAction';
const JobsOverview = () => {
    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;
    const dispatch = useDispatch();
    const [selectedJobValue, setSelectedJobValue] = useState("Week");
    const [renderKey, setRenderKey] = useState(0);
    const authToken = localStorage.getItem("recruiter_token");
    useEffect(() => {
        dispatch(getAnalyticsChartData(authToken, selectedJobValue));
    }, [selectedJobValue, dispatch, authToken]);
    useEffect(() => {
        const handleTabChange = () => {
            if (document.getElementById('job-overview-tab').classList.contains('active')) {
                setRenderKey(renderKey + 1);}};
        const tabElement = document.getElementById('job-overview-tab');
        document.getElementById('job-overview-tab')?.addEventListener('shown.bs.tab', handleTabChange);
        return () => {document.getElementById('job-overview-tab')?.removeEventListener('shown.bs.tab', handleTabChange);
        };}, [renderKey]);
    const handleJobSelect = (event) => {
        const value = event.target.value;setSelectedJobValue(value);dispatch(getAnalyticsChartData(authToken, value));
    };
    const analyticsChartData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.analyticsChartDataList?.data) : "");
    const data = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.analyticsChartDataList?.other_details) : "");
    CanvasJS.addColorSet("dataColor", ["#f5fbff"]);
    const mapData = (data, period) => {
        return data.map((dp, index) => ({
            x: period === 'Year' ? 12 - index : dp.DayMonth,y: dp.job_posted_count,label: dp.month
        }));
    };
    const arr = mapData(analyticsChartData || [], selectedJobValue);
    const options = {animationEnabled: true,colorSet: "dataColor",
        axisX: {minimum: 0.5,interval: 1,
            labelFormatter: (e) => {
                if (selectedJobValue === "Month") {
                    const index = arr.findIndex((dp) => dp.x === e.value);
                    return index % 3 === 0 ? e.label : "";
                }
                return e.label; },
        },
        axisY: {
            gridThickness: 1,
            gridColor: "#e8e7fe",
            minimum: 0,
            labelFormatter: e => {
                if (Number.isInteger(e.value)) {return e.value;
                } else {return ""; 
                }
            }
        },
        data: [{type: "splineArea",yValueFormatString: "#,##0.## Jobs Posted",showInLegend: false,lineColor: "#08aafd",lineThickness: 4,markerColor: "#FFF",markerBorderColor: "#08aafd",markerBorderThickness: 2,dataPoints: arr
        }]
    };

    return (
        <>
            <section className="custm-chart">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="overview-head mb-4 d-flex justify-content-between">
                            <div className="textarea">
                                <h4>Analytics overview</h4>
                                <p>Monitor and analyse performance to optimise your spend across products</p>
                            </div>
                            <div className='dropdown'>
                                <select onChange={handleJobSelect} value={selectedJobValue}>
                                    <option value="Week">Last Week</option>
                                    <option value="Month">Last Month</option>
                                    <option value="Year">Last Year</option>
                                </select>
                            </div>
                        </div>
                        <div className="review d-flex justify-content-between">
                            <h5>Jobs overview</h5>
                        </div>
                        <div style={{ overflowX: 'auto' }}>
                            <div style={{ minWidth: '100%' }}>
                                <CanvasJSChart key={renderKey} options={options} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="performance py-5">
                <div className="container pb-5">
                    <div className="row justify-content-between">
                        <div className="heading my-4">
                            <h4>Performance summary</h4>
                        </div>
                        <div className="col-lg-12 my-5">
                            <div className="row campaign-card py-5 align-items-center justify-content-center">
                                <div className="col-lg-3 col-md-4 text-center">
                                    <h4>Total Jobs Posted</h4>
                                    <h1>{data?.posted_job_count}</h1>
                                </div>
                                <div className="col-lg-3 col-md-4 text-center">
                                    <h4>Total Rejected Candidates</h4>
                                    <h1>{data?.rejected_job_count}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default JobsOverview;
