import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { getCarrerGuide } from '../../../Redux/action/SeekerAction'
import { getBlogWithCategoryData } from '../../../Redux/action/MasterAction';
import BlogCategory from '../BlogPage/BlogCategory/BlogCategory';
import parse from 'html-react-parser';
const host = process.env.REACT_APP_API_SERVER;
const CareerAdviceDynamic2 = ({ blogData }) => {
    const navigate = useNavigate();
    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };
    const handleNavigate = (blog_id) => {
        navigate(`/blog-detail/${blog_id}`)
    }
    return (
        <section className="career-advice py-5">
            {blogData &&
                <div className="container">
                    <div className="row">
                        {
                            blogData?.length > 0 && blogData.map((blogCategory, index) => {
                                return <>
                                    <div className="title pb-3" key={index + 1}>
                                        <h1>{blogCategory.name}</h1>
                                    </div>
                                    {blogCategory && blogCategory.blog && blogCategory.blog?.length > 0 ?
                                        blogCategory?.blog?.map((blog, index) => {
                                            return index < 6 &&
                                                <div className="col-lg-4 col-md-6 my-3" key={index + 1}>
                                                    <div className="custm-career-box" onClick={() => handleNavigate(blog?.blog_slug)}>
                                                        <img src={`${host}/${blog?.image}`} className='img-fluid' alt="" />
                                                        <div className="textarea">
                                                            <h3>{blog.title}</h3>
                                                            <div className='blah-blah'> {blog?.description && parse(`${truncateText(blog?.description, 500)}`)}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                        })
                                        :
                                        <div className="col-lg-4 col-md-6" key={index + 1}>
                                            <div className="custm-career-box">
                                                No Blogs found.
                                            </div>
                                        </div>
                                    }
                                    {blogCategory && blogCategory.blog && blogCategory.blog?.length > 6 && <div className="view-btn d-flex justify-content-center"><Link to={`/career-guide-category-new/${blogCategory?.name_slug}`}>View All</Link></div>}
                                </>
                            })
                        }
                    </div>
                </div>}
        </section>
    )
}

export default CareerAdviceDynamic2;
