import React, { useEffect, useState } from 'react'
import './scss/SavedCard.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getSavedJobsData } from '../../../../../../Redux/action/SeekerAction';
import JobCard from '../JobCard/JobCard';
import Loader from '../../../../Loader';
const SavedCard = ({ jobs }) => {
    
    return (
        <>
            
            <JobCard jobs={jobs} />
        </>
    )
}

export default SavedCard