import React, { useEffect, useState } from 'react'

import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../Loader';

import HourglassLoader from '../HourglassLoader';
import './scss/RateAndReview.css'
import { getFeedbackData } from '../../../Redux/action/SeekerAction';
let host = process.env.REACT_APP_API_SERVER
const RateAndReview = ({ id, company }) => {
    const company_id = id;
    let authToken = localStorage.getItem("seeker_token");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {

            if (authToken && company_id != undefined) {
            } else {console.error("authToken or company_id is undefined");}
        } else {toast.error("Please register to update information.");navigate("/seeker-register");}
    }, [dispatch, navigate, company_id]);
    const FeedbackData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.FeedbackDataList) : "")
    const [rating, setRating] = useState(0);
    const handleRatingChange = (value) => {
        setRating(value);
    };
    const triggerModalClose = () => {
        const closeButton = document.querySelector('.modal.fade .btn-close');
        if (closeButton) {closeButton.click();
        }

    };
    let [initialValues, setInitialValues] = useState({rating: "",feedback: "",
    })
    const config = {
        headers: {
          'content-type': 'application/json',"auth-token": localStorage.getItem("seeker_token")
        }
    };
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            feedback: Yup.string().required("Feedback is required"),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);
                document.body.style.overflow = 'hidden';
                const value = {rating: rating,feedback: values.feedback,company_slug: company_id,}
                await axios.post(`${host}/mobile/add-company-feedback-by-slug`, value, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.success(response.data.msg);
                            triggerModalClose();
                        } else {
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.error(response.data.msg);
                        }
                    })
                    .catch(function (error) {
                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.error('Error submitting form. Please try again.');
                    })
            }
            catch (error) {
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
            }


        }
    });


    useEffect(() => {
        if (company) {
            formik.setValues({
                rating: company?.seekerFeedback ? company?.seekerFeedback?.rating : "",
                feedback: company?.seekerFeedback ? company?.seekerFeedback?.feedback : "",
            });
            if (company?.seekerFeedback) {
                setRating(Number(company?.seekerFeedback?.rating));
            }
        }
    }, [company])
    return (
        <>
          
            {/* <!-- Modal --> */}
            <div className="modal fade review-rate-modal-head-cls" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Write a Rate & Review</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="modal-body modal-review">

                                <div className='rating_class text-center'>
                                    <p className='rating-news-p'><b>Rate The Company</b></p>
                                    <div className='rate'>
                                        {[5, 4, 3, 2, 1].map((star) => (
                                            <React.Fragment key={star}>
                                                <input
                                                    type='radio'
                                                    id={`star${star}`}
                                                    name='rating'
                                                    value={star}
                                                    checked={parseInt(FeedbackData?.rating) === star ? true : rating === star}

                                                    onChange={() => handleRatingChange(star)}
                                                />
                                                <label htmlFor={`star${star}`} title={`${star} star`}>
                                                    {star} star
                                                </label>
                                            </React.Fragment>
                                        ))}
                                    </div>

                                </div>

                                <div className='rating_textarea'>
                                    <label htmlFor='review_rate'>Feedback</label>

                                    <textarea
                                        name='feedback'
                                        value={formik.values.feedback}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className='textarea-tag'
                                        rows='5'
                                      
                                        placeholder='Write down your feedback here...'
                                    />
                                    {formik.touched.feedback && formik.errors.feedback ? (
                                        <div className='error-message'>{formik.errors.feedback}</div>
                                    ) : null}


                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary later-btn-color" data-bs-dismiss="modal">Later</button>
                                <button type="submit" className="btn btn-primary submit-btn-color">Submit Review</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RateAndReview