import React, { useState, useEffect } from 'react'
import "./JobFairCreateJobPage.css"
import $ from "jquery";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { getAssessmentList, getBenefitData, getCitybyStateData, getCountryData, getEducationlevelData, getIndustryData, getJobTypesData, getScheduleData, getSkillsrequiredData, getStatebyCountryData, getSupplementpayData, getWorkplaceData } from '../../../Redux/action/RecruiterAction';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LocalHospital } from '@mui/icons-material';
import { toast } from 'react-toastify';
import axios from 'axios';
import Loader from '../Loader';
import { useNavigate } from 'react-router-dom';
import { FocusError } from 'focus-formik-error';
const host = process.env.REACT_APP_API_SERVER;
const JobFairEditJobPageContent = ({ job }) => {
    // console.log(job)
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [country_id, setCountry_id] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const authToken = localStorage.getItem('recruiter_token');
    useEffect(() => {
        dispatch(getCountryData());
        $(document).ready(function () {
            $(".head-cus-1 #jobtitle-hide-js").hide();
            $(".head-cus-2 #jobtitle-hide-js").hide();
            $(".head-cus-3 #jobtitle-hide-js").hide();
            $(".head-cus-4 #jobtitle-hide-js").hide();
            $(".head-cus-5 #jobtitle-hide-js").hide();
            $(".head-cus-6 #jobtitle-hide-js").hide();
            $(".head-cus-7 #jobtitle-hide-js").hide();
            // -----------------------------------------
            $(".head-cus-1 #pen-cls").on("click", function () {
                $(".head-cus-1 #jobtitle-hide-js").toggle();
                $(".head-cus-1 #jobtitle-name").toggle();
            });
            $(".head-cus-1 #save-btn-form").on("click", function () {
                $(".head-cus-1 #jobtitle-name").toggle();
                $(".head-cus-1 #jobtitle-hide-js").toggle();
            });
            // ------------------------------------------
            $(".head-cus-2 #pen-cls").on("click", function () {
                $(".head-cus-2 #jobtitle-hide-js").toggle();
                $(".head-cus-2 #jobtitle-name").toggle();
            });
            $(".head-cus-2 #save-btn-form").on("click", function () {
                $(".head-cus-2 #jobtitle-name").toggle();
                $(".head-cus-2 #jobtitle-hide-js").toggle();
            });
            // ------------------------------------------
            $(".head-cus-3 #pen-cls").on("click", function () {
                $(".head-cus-3 #jobtitle-hide-js").toggle();
                $(".head-cus-3 #jobtitle-name").toggle();
            });
            $(".head-cus-3 #save-btn-form").on("click", function () {
                $(".head-cus-3 #jobtitle-name").toggle();
                $(".head-cus-3 #jobtitle-hide-js").toggle();
            });
            // ------------------------------------------
            $(".head-cus-4 #pen-cls").on("click", function () {
                $(".head-cus-4 #jobtitle-hide-js").toggle();
                $(".head-cus-4 #jobtitle-name").toggle();
            });
            $(".head-cus-4 #save-btn-form").on("click", function () {
                $(".head-cus-4 #jobtitle-name").toggle();
                $(".head-cus-4 #jobtitle-hide-js").toggle();
            });
            // ------------------------------------------
            $(".head-cus-5 #pen-cls").on("click", function () {
                $(".head-cus-5 #jobtitle-hide-js").toggle();
                $(".head-cus-5 #jobtitle-name").toggle();
            });
            $(".head-cus-5 #save-btn-form").on("click", function () {
                $(".head-cus-5 #jobtitle-name").toggle();
                $(".head-cus-5 #jobtitle-hide-js").toggle();
            });
            // ------------------------------------------
            $(".head-cus-6 #pen-cls").on("click", function () {
                $(".head-cus-6 #jobtitle-hide-js").toggle();
                $(".head-cus-6 #jobtitle-name").toggle();
            });
            $(".head-cus-6 #save-btn-form").on("click", function () {
                $(".head-cus-6 #jobtitle-name").toggle();
                $(".head-cus-6 #jobtitle-hide-js").toggle();
            });
            // ------------------------------------------
            $(".head-cus-7 #pen-cls").on("click", function () {
                $(".head-cus-7 #jobtitle-hide-js").toggle();
                $(".head-cus-7 #jobtitle-name").toggle();
            });
            $(".head-cus-7 #save-btn-form").on("click", function () {
                $(".head-cus-7 #jobtitle-name").toggle();
                $(".head-cus-7 #jobtitle-hide-js").toggle();
            });
        });
    }, []);

    const countryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.countryDataList?.data) : "");
    const statebycountryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.statebycountryDataList?.data) : "");
    const citybystateData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.citybystateDataList?.data) : "");


    const initialValues = {
        job_title: "",
        company_email: "",
        address: "",
        city_id: "",
        state_id: "",
        country_id: "",
        postal_code: "",
        min_pay: 0,
        max_pay: 0,
        rate: "",
        vacancy: 0,
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
        about: "",
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            job_title: Yup.string().required("Job title is required."),
            company_email: Yup.string().required("Please enter an email").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid E-mail"),
            address: Yup.string().required("Please enter an address."),
            city_id: Yup.string().required("Please select a city."),
            state_id: Yup.string().required("Please select a state."),
            country_id: Yup.string().required("Please select a country."),
            postal_code: Yup.string().min(4, 'Invalid Postal Code').max(6, 'Invalid Postal Code').required("Postal code is required.").matches(/^[0-9]+$/, "Enter a valid postal code."),
            min_pay: Yup.number()
                .min(1, 'Value must be positive.')
                .required('This field is required.'),
            max_pay: Yup.number()
                .min(1, 'Value must be positive.')
                .required('This field is required.')
                .moreThan(
                    Yup.ref('min_pay'),
                    'Should be more than minimum.'
                ),
            rate: Yup.string(),
            vacancy: Yup.number()
                .min(1, 'Value must be positive.')
                .required('This field is required.'),
            start_date: Yup.date()
                .required('Start date is required'),
            end_date: Yup.date()
                .required('End date is required')
                .min(new Date(), 'End date must be greater than today')
                .min(Yup.ref('start_date'), 'End date must be greater than start date')
                .when('start_date', (start_date, schema) => start_date && schema.min(start_date, 'End date must be greater than start date')),
            about: Yup.string().required("Job desription is required."),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            // console.log(values);
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                document.body.style.overflow = 'hidden';
                setShowLoader(true);
                let config = {
                    headers: {
                        "auth-token": localStorage.getItem("recruiter_token")
                    }
                }
                axios.post(`${host}/recruiter/update-job-fair-job?job_id=${job.id}`, values, config)
                    .then(response => {
                        // console.log(response);
                        if (response.status == 200) {
                            document.body.style.overflow = 'auto';
                            setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Job Updated successfully!' });
                            resetForm();
                            navigate("/list-of-job-fair");
                        } else {
                            document.body.style.overflow = 'auto';
                            setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    }).catch(error => {
                        console.log(error.message); document.body.style.overflow = 'auto';
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    })
            } catch (error) {
                document.body.style.overflow = 'auto';
                setShowLoader(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }

        }
    })

    useEffect(() => {
        if (job) {
            dispatch(getStatebyCountryData(job?.country_id));
            dispatch(getCitybyStateData(job?.state_id))
            formik.setValues({
                job_title: job?.job_title ?? "",
                company_email: job?.company_email ?? "",
                address: job?.address ?? "",
                city_id: job?.city_id ?? "",
                state_id: job?.state_id ?? "",
                country_id: job?.country_id ?? "",
                postal_code: job?.postal_code ?? "",
                min_pay: job?.min_pay ?? "",
                max_pay: job?.max_pay ?? "",
                rate: job?.rate ?? "",
                vacancy: job?.vacancy ?? "",
              
                start_date: job?.start_date ?? new Date().toISOString().substr(0, 10),
                end_date: job?.end_date ?? new Date().toISOString().substr(0, 10),
                about: job?.about ?? "",
            })
        }
    }, [job]);




    const handleChangeState = (country_id) => {
        dispatch(getStatebyCountryData(country_id));
    }
    const handleChangeCity = (state_id) => {
        dispatch(getCitybyStateData(state_id))
    }

    return (
        <>
            <section className='review-page-section'>
                <div className="container">
                    <div className="you-used-a-previous-d">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="verify-row">
                                    <div className="verify-col">
                                        <div className="verify-img me-lg-3 me-md-3 me-0">
                                            <img src="./assets/review-page/verified.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className="verify-col">
                                        <div className="verify-content">
                                            <h5 className='mb-0'><b>Post a job to show on Job Fair on Karlatoon</b></h5>
                                            <p>Edit the information to reflect changes needed for your new job post in job fair.</p>
                                            {/* <p>On </p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="job-details-head-parent">
                        <div className="you-used-heading-dv">
                            <h5><b>Contact Details</b></h5>
                        </div>
                        <form method="POST" onSubmit={formik.handleSubmit}>
                            <FocusError formik={formik} />
                            <div className="you-used-inner">
                                {/* Job title */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Enter email on which you want to receive job request</b></p>
                                    <div className='jobtitle-hide-js w-100' id="jobtitle-hide-js">
                                        <input type="text"
                                            className="form-control"
                                            name='company_email'
                                            id='company_email'
                                            value={formik.values.company_email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.company_email && formik.errors.company_email ? (
                                                <div className='text-danger'>{formik.errors.company_email}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="you-used-heading-dv">
                                    <h5><b>Job details</b></h5>
                                </div>
                                <div className="heading-edit-icon-heading">
                                    <p><b>Job Title</b></p>
                                    <div className='jobtitle-hide-js w-100' id="jobtitle-hide-js">
                                        <input type="text"
                                            className="form-control"
                                            name='job_title'
                                            id='job_title'
                                            value={formik.values.job_title}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.job_title && formik.errors.job_title ? (
                                                <div className='text-danger'>{formik.errors.job_title}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Address */}
                                <div className="show-address-div">
                                    <div className="heading-edit-icon-heading">
                                        <p><b>Address</b></p>
                                        <div className='jobtitle-hide-js w-100' id="jobtitle-hide-js">
                                            <input type="text"
                                                className="form-control"
                                                name='address'
                                                id='address'
                                                value={formik.values.address}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <div className="help-block with-errors">
                                                {formik.touched.address && formik.errors.address ? (
                                                    <div className='text-danger'>{formik.errors.address}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="heading-edit-icon-heading">
                                        <p><b>Country</b></p>
                                        <div className='jobtitle-hide-js w-100' id="jobtitle-hide-js">

                                            <select
                                                className='form-control'
                                                id="country_id"
                                                name="country_id"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    setCountry_id(e.target.value);
                                                    handleChangeState(e.target.value);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.country_id}
                                            >
                                                <option value="">--Select a Country--</option>
                                                {countryData?.map(country => {
                                                    let key = country?.id
                                                    return <option key={key} value={country?.id}>{country?.name}({country?.shortname})</option>
                                                })}
                                            </select>
                                            <div className="help-block with-errors">
                                                {formik.touched.country_id && formik.errors.country_id ? (
                                                    <div className='text-danger'>{formik.errors.country_id}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="heading-edit-icon-heading">
                                        <p><b>Province</b></p>
                                        <div className='jobtitle-hide-js w-100' id="jobtitle-hide-js">

                                            <select
                                                className='form-control'
                                                id="state_id"
                                                name='state_id'
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    handleChangeCity(e.target.value);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.state_id}
                                            >
                                                <option value="">--Select a Province--</option>
                                                {statebycountryData?.map(state => {
                                                    return <option key={state?.id} value={state?.id}>{state?.state_name}</option>
                                                })}
                                            </select>
                                            <div className="help-block with-errors">
                                                {formik.touched.state_id && formik.errors.state_id ? (
                                                    <div className='text-danger'>{formik.errors.state_id}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="heading-edit-icon-heading">
                                        <p><b>City</b></p>
                                        <div className='jobtitle-hide-js w-100' id="jobtitle-hide-js">
                                            <select
                                                className='form-control'
                                                id="city_id"
                                                name='city_id'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.city_id}
                                            >
                                                <option value="">--Select a City--</option>
                                                {citybystateData?.map(city => {
                                                    return <option key={city?.id} value={city?.id}>{city?.city_name}</option>
                                                })}
                                            </select>
                                            <div className="help-block with-errors">
                                                {formik.touched.city_id && formik.errors.city_id ? (
                                                    <div className='text-danger'>{formik.errors.city_id}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                    </div>
                                    {/* <hr /> */}
                                    <div className="heading-edit-icon-heading">
                                        <p><b>Postal Code</b></p>
                                        <div className='jobtitle-hide-js w-100' id="jobtitle-hide-js">
                                            <input type="text"
                                                className="form-control"
                                                name='postal_code'
                                                id='postal_code'
                                                maxLength={6}
                                                value={formik.values.postal_code}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <div className="help-block with-errors">
                                                {formik.touched.postal_code && formik.errors.postal_code ? (
                                                    <div className='text-danger'>{formik.errors.postal_code}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Min pay Max Pay Amount */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Min Pay</b></p>
                                    <div className='jobtitle-hide-js w-100' id="jobtitle-hide-js">
                                        <input
                                            type='number'
                                            className='form-control me-2'
                                            id='min_pay'
                                            name="min_pay"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.min_pay}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.min_pay && formik.errors.min_pay ? (
                                                <div className='text-danger'>{formik.errors.min_pay}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="heading-edit-icon-heading">
                                    <p><b>Min Pay</b></p>
                                    <div className='jobtitle-hide-js w-100' id="jobtitle-hide-js">
                                        <input
                                            type='number'
                                            className='form-control me-2'
                                            id='max_pay'
                                            name="max_pay"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.max_pay}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.max_pay && formik.errors.max_pay ? (
                                                <div className='text-danger'>{formik.errors.max_pay}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Rate */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Rate</b></p>
                                    <div className='jobtitle-hide-js w-100' id="jobtitle-hide-js">
                                        <select
                                            className='form-control'
                                            id="rate"
                                            name='rate'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.rate}
                                        >
                                            <option value="per hour">per hour</option>
                                            <option value="per day">per day</option>
                                            <option value="per week">per week</option>
                                            <option value="per month">per month</option>
                                            <option value="per year">per year</option>
                                        </select>
                                        <div className="help-block with-errors">
                                            {formik.touched.rate && formik.errors.rate ? (
                                                <div className='text-danger'>{formik.errors.rate}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                                {/* No of vacancy */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>No. of Vacancies</b></p>
                                    <div className='jobtitle-hide-js w-100' id="jobtitle-hide-js">
                                        <input
                                            type='number'
                                            className='form-control me-2'
                                            id='vacancy'
                                            name="vacancy"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.vacancy}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.vacancy && formik.errors.vacancy ? (
                                                <div className='text-danger'>{formik.errors.vacancy}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                                {/* Start and end date*/}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Start Date</b></p>
                                    <div className='jobtitle-hide-js w-100' id="jobtitle-hide-js">
                                        <input
                                            type='date'
                                            className='form-control me-2'
                                            id='start_date'
                                            name="start_date"
                                            min={new Date().toISOString().substr(0, 10)}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.start_date}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.start_date && formik.errors.start_date ? (
                                                <div className='text-danger'>{formik.errors.start_date}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="heading-edit-icon-heading">
                                    <p><b>End Date</b></p>
                                    <div className='jobtitle-hide-js w-100' id="jobtitle-hide-js">
                                        <input
                                            type='date'
                                            className='form-control me-2'
                                            id='end_date'
                                            name="end_date"
                                            min={new Date().toISOString().substr(0, 10)}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.end_date}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.end_date && formik.errors.end_date ? (
                                                <div className='text-danger'>{formik.errors.end_date}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                                {/* Desription */}
                                <div className="heading-edit-icon-heading">
                                    <label htmlFor='no-peaple' className='my-2'><b>Job Description</b><span className='text-danger'>*</span></label>
                                </div>
                                <div>
                                    <CKEditor
                                        name="about"
                                        config={{ height: 200 }}
                                        editor={ClassicEditor}
                                        data={formik.values.about}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            formik.setFieldValue('about', data);
                                        }}
                                    />
                                    <div className="help-block with-errors mt-2">
                                        {formik.touched.about && formik.errors.about ? (
                                            <div className='text-danger'>{formik.errors.about}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="text-center submit-btn">
                                <button type="submit">Edit Job</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            {
                showLoader && <div className="overlay-form-submission">
                    <Loader />
                </div>
            }
        </>
    )
}

export default JobFairEditJobPageContent