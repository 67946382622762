import React, { useEffect, useState } from 'react'
import { quiz } from './quiz'

import './scss/Quizy.css'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Assessment } from '@mui/icons-material'
import { getAssessmentQuestions } from '../../../Redux/action/SeekerAction'
const Quizy = ({ job_id, questionList, assessmentDetail }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activeQuestion, setActiveQuestion] = useState(0)
    const [selectedAnswer, setSelectedAnswer] = useState('')
    const [showResult, setShowResult] = useState(false)
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
    const [confirmNavigation, setConfirmNavigation] = useState(false);
    const [timer, setTimer] = useState(0);   
    const [result, setResult] = useState({score: 0, sumscore: 0, correct_attempted: [], wrong_attempted: [], not_attempted: [], correctAnswers: 0, wrongAnswers: 0, notattemptedAnswers: 0
    });    
    const questions = questionList
    const { title, first_option, second_option, third_option, fourth_option, marks, duration, correct_option } = questions[activeQuestion]
    const [questionTimer, setQuestionTimer] = useState(questions[0].duration ?? 0);
    const onClickNext = () => {
        setSelectedAnswerIndex(null)
        setResult((prev) =>
            selectedAnswer
                ? {
                    ...prev,
                    sumscore: prev.sumscore + questions[activeQuestion].marks,
                    score: prev.score + questions[activeQuestion].marks,
                    correctAnswers: prev.correctAnswers + 1,
                    correct_attempted: [...prev.correct_attempted, questions[activeQuestion]]
                }
                : {
                    ...prev,
                    sumscore: prev.sumscore + questions[activeQuestion].marks,
                    wrong_attempted: [...prev.wrong_attempted, questions[activeQuestion]],
                    wrongAnswers: prev.wrongAnswers + 1
                }
        )
        if (activeQuestion !== questions?.length - 1) {
            setQuestionTimer(questions[activeQuestion + 1].duration)
            setActiveQuestion((prev) => prev + 1);
        } else {
            setQuestionTimer(questions[0].duration);
            setActiveQuestion(0)
            setShowResult(true)
        }
    }

    const onAnswerSelected = (answer, index) => {       
        setSelectedAnswerIndex(index)
        if (answer == index) {
            setSelectedAnswer(true)
        } else {
            setSelectedAnswer(false)
        }
    }
    const addLeadingZero = (number) => (number > 9 ? number : `0${number}`)
    let timerObj = null; 
    useEffect(() => { 
        let tiktik = setInterval(() => {
            if (activeQuestion == questions?.length - 1) {
                setQuestionTimer(prevTimer => {
                    if (prevTimer > 0) {
                        return prevTimer - 1;
                    } else {
                        setShowResult(true);
                    }
                });
            } else {
                setQuestionTimer(prevTimer => {
                    if (prevTimer > 0) {
                        return prevTimer - 1;
                    } else {
                        setResult((prev) => ({
                            ...prev,
                            sumscore: prev.sumscore + questions[activeQuestion].marks,
                            not_attempted: [...prev.not_attempted, questions[activeQuestion]],
                            notattemptedAnswers: prev.notattemptedAnswers + 1
                        })
                        )
                        setQuestionTimer(questions[activeQuestion + 1].duration + 1)
                        setActiveQuestion((prev) => prev + 1);
                        setQuestionTimer((prevTimer) => prevTimer - 1);
                        clearInterval(tiktik)
                        setSelectedAnswerIndex(null);
                    }
                });
            }
        }, 1000)
        return () => clearInterval(tiktik);
    }, [activeQuestion]);

    const handleBackNavigation = () => {
        const confirm = window.confirm("Are you sure you want to leave? Your progress will be lost.");
        if (confirm) {
            setConfirmNavigation(true);
            window.history.back();
        }
    };

    const handleFreeNavigation = () => {
        setConfirmNavigation(true);        
        let result_percent = ((result.score / result.sumscore) * 100).toFixed(2);        

        if (parseFloat(result_percent) >= assessmentDetail?.assessmentDetails?.passing_percent) {
            navigate(`/assessment-score?j=${job_id}`, { state: { result: result, passed_status: true, job_id: job_id } })
        } else {
            navigate(`/assessment-score?j=${job_id}`, { state: { result: result, passed_status: false, job_id: job_id } })
        }        
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (confirmNavigation) {                
                return;
            }
            event.preventDefault();
            event.returnValue = '';
            return '';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [confirmNavigation]);

    const navigateBack = () => {
        navigate(-1);
    };

    return (
        <>
            <section className="quizy-sec">
                <div className="quizy-button-parent">                   
                    <button className="btn btn-sm" onClick={handleBackNavigation}>Go back</button>
                </div>
                <div className='quiz-body'>
                    <div className="quiz-container">
                        {!showResult ? (
                            <div>
                                <div className='question-number-count-parent'>
                                    <div>
                                        <span className="active-question-no">{addLeadingZero(activeQuestion + 1)}</span>
                                        <span className="total-question">/{addLeadingZero(questions?.length)}</span>
                                    </div>
                                    <div>
                                        <span className='question-no-count-timer'>{questionTimer} seconds</span>
                                    </div>
                                </div>
                                <h2>{title}</h2>
                                <ul>
                                    
                                    <li onClick={() => onAnswerSelected(correct_option, 1)}                                        
                                        className={selectedAnswerIndex == 1 ? 'selected-answer' : null}>
                                        {first_option}
                                    </li>
                                    <li onClick={() => onAnswerSelected(correct_option, 2)}                                        
                                        className={selectedAnswerIndex == 2 ? 'selected-answer' : null}>
                                        {second_option}
                                    </li>
                                    <li onClick={() => onAnswerSelected(correct_option, 3)}                                        
                                        className={selectedAnswerIndex == 3 ? 'selected-answer' : null}>
                                        {third_option}
                                    </li>
                                    <li onClick={() => onAnswerSelected(correct_option, 4)}                                        
                                        className={selectedAnswerIndex == 4 ? 'selected-answer' : null}>
                                        {fourth_option}
                                    </li>
                                </ul>
                                <div className="flex-right">                                    
                                    {activeQuestion === questions?.length - 1 ?
                                        <button onClick={onClickNext} disabled={selectedAnswerIndex === null} id='finish-btn-click'>
                                            Finish
                                        </button>
                                        :
                                        <button onClick={onClickNext} disabled={selectedAnswerIndex === null} id='next-btn-click'>
                                            Submit and Next
                                        </button>
                                    }
                                </div>
                            </div>
                        ) : (
                            <div className="result">                                
                                <h3>Thankyou for attempting the assessment.</h3>
                                <p className='text-center'>
                                    <button onClick={handleFreeNavigation}>Continue</button>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </section>

        </>
    )
}

export default Quizy
