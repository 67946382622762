import React, { useState } from 'react'

import "./CareerPreferences.css"
import CareerPreferencesPopups from './CareerPreferencesPopups'
const CareerPreferencesContent = ({ data, reload }) => {
    return (
        <>
            <section className='career-preferences-section'>
                <div className="container">
                    <div className="col-lg-12">
                        <div className="heading-prefe">
                            <h2>Career preferences</h2>
                            <p>Add details about your preferred job profile. This helps us personalize your job recommendations.</p>
                            
                        </div>
                    </div>
                    <div className="col-lg-12">                       
                        <div className="job-title-strip-parent" data-bs-toggle="modal" data-bs-target="#add-job-titles">
                            <div className="icon-heading-name">
                                <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" className="css-78jk54 eac13zx0"><path fillRule="evenodd" d="M12 10c1.3817 0 2.5-1.1183 2.5-2.5S13.3817 5 12 5 9.5 6.1183 9.5 7.5 10.6183 10 12 10zm-7 8.749V19h14v-.251c.0002-.0191.002-.179-.3403-.4949-.363-.335-.9618-.6931-1.777-1.0197C15.249 16.58 13.2519 16.25 12 16.25s-3.249.33-4.8827.9844c-.8152.3266-1.414.6847-1.777 1.0197-.3423.3159-.3405.4758-.3403.4949zM16.5 7.5c0 2.4863-2.0138 4.5-4.5 4.5-2.4863 0-4.5-2.0137-4.5-4.5S9.5137 3 12 3c2.4862 0 4.5 2.0137 4.5 4.5zM3 18.75c0-2.9925 5.9962-4.5 9-4.5 3.0037 0 9 1.5075 9 4.5v1.75a.5.5 0 01-.5.5h-17a.5.5 0 01-.5-.5v-1.75z" clipRule="evenodd"></path></svg>
                                <h5>Preferred Job Role</h5>
                            </div>
                            <div className="plus-icon-dv">
                                <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="css-1w4e6e7 eac13zx0"><path d="M12 4c-.5523 0-1 .4477-1 1v6H5c-.5523 0-1 .4477-1 1s.4477 1 1 1h6v6c0 .5523.4477 1 1 1s1-.4477 1-1v-6h6c.5523 0 1-.4477 1-1s-.4477-1-1-1h-6V5c0-.5523-.4477-1-1-1z"></path></svg>
                            </div>
                        </div>
                        <div className="job-title-strip-parent" data-bs-toggle="modal" data-bs-target="#add-relocation-titles">
                            <div className="icon-heading-name">
                                <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" className="css-78jk54 eac13zx0"><path fillRule="evenodd" d="M14.5 8.9922c0 1.3807-1.1193 2.5-2.5 2.5s-2.5-1.1193-2.5-2.5 1.1193-2.5 2.5-2.5 2.5 1.1193 2.5 2.5zm-2 0a.5.5 0 11-1 0 .5.5 0 011 0z" clipRule="evenodd"></path><path fillRule="evenodd" d="M5 9c0-3.87 3.13-7 7-7s7 3.13 7 7c0 4.5229-5.1954 11.0927-6.6344 12.8256-.1934.2329-.5378.2329-.7312 0C10.1954 20.0927 5 13.5229 5 9zm2 0c0-2.7654 2.2346-5 5-5s5 2.2346 5 5c0 1.6576-1.0176 4.0118-2.485 6.4176-.8512 1.3955-1.7694 2.6756-2.515 3.6535-.7456-.9779-1.6638-2.258-2.515-3.6535C8.0176 13.0118 7 10.6576 7 9z" clipRule="evenodd"></path></svg>
                                <h5>Preferred Cities</h5>
                            </div>
                            <div className="plus-icon-dv">
                                <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="css-1w4e6e7 eac13zx0"><path d="M12 4c-.5523 0-1 .4477-1 1v6H5c-.5523 0-1 .4477-1 1s.4477 1 1 1h6v6c0 .5523.4477 1 1 1s1-.4477 1-1v-6h6c.5523 0 1-.4477 1-1s-.4477-1-1-1h-6V5c0-.5523-.4477-1-1-1z"></path></svg>
                            </div>
                        </div>
                        <div className="job-title-strip-parent" data-bs-toggle="modal" data-bs-target="#add-job-type">
                            <div className="icon-heading-name">
                                <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" className="css-78jk54 eac13zx0"><path fillRule="evenodd" d="M7.1901 6.3487c.517-1.3982 1.6262-2.5093 3.0233-3.0288A5.1152 5.1152 0 0111.9999 3c.6283 0 1.2302.113 1.7865.3199 1.397.5194 2.5063 1.6306 3.0232 3.0288.078.211.1425.4284.1925.6513H20c1.1046 0 2 .8954 2 2v10c0 1.1046-.8954 2-2 2H4c-1.1046 0-2-.8954-2-2V9c0-1.1046.8954-2 2-2h2.9977c.05-.223.1144-.4404.1924-.6513zM4 13v6h16v-6h-6c0 1.1046-.8954 2-2 2s-2-.8954-2-2H4zm16-2H4V9h16v2zm-9.8205-5.4156A3.112 3.112 0 0111.9999 5c.6791 0 1.3077.2165 1.8204.5844.491.3522.8757.8432 1.0969 1.4156H9.0826a3.1358 3.1358 0 011.0969-1.4156z" clipRule="evenodd"></path></svg>
                                <h5>Type of Job</h5>
                            </div>
                            <div className="plus-icon-dv">
                                <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="css-1w4e6e7 eac13zx0"><path d="M12 4c-.5523 0-1 .4477-1 1v6H5c-.5523 0-1 .4477-1 1s.4477 1 1 1h6v6c0 .5523.4477 1 1 1s1-.4477 1-1v-6h6c.5523 0 1-.4477 1-1s-.4477-1-1-1h-6V5c0-.5523-.4477-1-1-1z"></path></svg>
                            </div>
                        </div>
                        <div className="job-title-strip-parent" data-bs-toggle="modal" data-bs-target="#add-pay">
                            <div className="icon-heading-name">
                                <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" className="css-78jk54 eac13zx0"><path fillRule="evenodd" d="M12.5 10c0 1.3807-1.1193 2.5-2.5 2.5S7.5 11.3807 7.5 10 8.6193 7.5 10 7.5s2.5 1.1193 2.5 2.5zm-2 0a.5.5 0 11-1.0001-.0001A.5.5 0 0110.5 10z" clipRule="evenodd"></path><path fillRule="evenodd" d="M2.5 4a.5.5 0 00-.5.5v11a.5.5 0 00.5.5h15a.5.5 0 00.5-.5v-11a.5.5 0 00-.5-.5h-15zM4 11.3988V8.612A3.7478 3.7478 0 006.6118 6h6.7729a3.7446 3.7446 0 00.9626 1.6517A3.7489 3.7489 0 0016 8.6146v2.7825a3.7472 3.7472 0 00-2.4636 2.1785 3.74 3.74 0 00-.1467.4244H6.6084a3.7451 3.7451 0 00-.959-1.6396 3.7452 3.7452 0 00-1.2157-.8124A3.7684 3.7684 0 004 11.3988z" clipRule="evenodd"></path><path fillRule="evenodd" d="M21 8.9746c.5523 0 1 .4477 1 1v8.9491c0 .5557-.4463 1.0509-1.05 1.0509H6c-.5523 0-1-.4477-1-1s.4477-1 1-1h14v-8c0-.5523.4477-1 1-1z" clipRule="evenodd"></path></svg>
                                <h5>Employement type</h5>
                            </div>
                            <div className="plus-icon-dv">
                                <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="css-1w4e6e7 eac13zx0"><path d="M12 4c-.5523 0-1 .4477-1 1v6H5c-.5523 0-1 .4477-1 1s.4477 1 1 1h6v6c0 .5523.4477 1 1 1s1-.4477 1-1v-6h6c.5523 0 1-.4477 1-1s-.4477-1-1-1h-6V5c0-.5523-.4477-1-1-1z"></path></svg>
                            </div>
                        </div>
                        <div className="job-title-strip-parent" data-bs-toggle="modal" data-bs-target="#add-work-schedule">
                            <div className="icon-heading-name">
                                <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" className="css-78jk54 eac13zx0"><path d="M11 8c0-.5523.4477-1 1-1s1 .4477 1 1v4.0955l2.8521 1.6467c.4783.2761.6421.8877.366 1.366-.2761.4783-.8877.6422-1.366.366L11 13.2502V8z"></path><path fillRule="evenodd" d="M22 11.9961c0 5.5228-4.4772 10-10 10-5.5229 0-10-4.4772-10-10 0-5.5229 4.4771-10 10-10 5.5228 0 10 4.4771 10 10zM20 12c0 4.4183-3.5817 8-8 8s-8-3.5817-8-8 3.5817-8 8-8 8 3.5817 8 8z" clipRule="evenodd"></path></svg>
                                <h5>Schedule</h5>
                            </div>
                            <div className="plus-icon-dv">
                                <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="css-1w4e6e7 eac13zx0"><path d="M12 4c-.5523 0-1 .4477-1 1v6H5c-.5523 0-1 .4477-1 1s.4477 1 1 1h6v6c0 .5523.4477 1 1 1s1-.4477 1-1v-6h6c.5523 0 1-.4477 1-1s-.4477-1-1-1h-6V5c0-.5523-.4477-1-1-1z"></path></svg>
                            </div>
                        </div>
                    </div>
                </div>
                <CareerPreferencesPopups data={data} reload={reload} />
            </section>
        </>
    )
}

export default CareerPreferencesContent