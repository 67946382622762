import React, { useState, useEffect } from 'react'
import { useAccordionButton } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FallingLines } from 'react-loader-spinner';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getRecruiterregistrationData } from '../../../../Redux/action/RecruiterAction';
import { FocusError } from 'focus-formik-error';
import FeaturedCompany from './FeaturedCompany';
const host = process.env.REACT_APP_API_SERVER

const ProfileDetailsForm = () => {
    const navigate = useNavigate();
    let [loggedIn, isLoggedIn] = useState(false);
    const dispatch = useDispatch();
    let initialValues = {
        banner: "",
        motto: "",
        sub_motto: "",
        why_us: "",
        benefits: "",
        give_book: ""
    }
    useEffect(() => {
        if (localStorage.getItem("recruiter_token")) {
            isLoggedIn(true);
            let authToken = localStorage.getItem("recruiter_token");
            dispatch(getRecruiterregistrationData(authToken));
        } else {
            isLoggedIn(false);
            toast.error("Please fill Company Details first.");
            navigate("/company-details");
        }
    }, []);
    const recruiterRegistrationData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.recruiterRegisterDataList?.data) : "");
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            banner: Yup.string().required("Banner is required."),
            motto: Yup.string().required("Motto is required."),
            sub_motto: Yup.string().required("Sub Motto is required."),
            why_us: Yup.string().required("Why choose you is required."),
            benefits: Yup.string().required("Benefits is required."),
            give_book: Yup.string().required("Please select an option.")
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {

                setSubmitting(true);
                document.body.style.overflow = 'hidden';
                const config = {
                    headers: {
                        // 'content-type': 'multipart/form-data',
                        'auth-token': localStorage?.getItem("recruiter_token")
                    }
                };

                await axios.post(`${host}/api/update-recruiter-confirmations`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Form submitted successfully!' });
                            navigate('/rec-choose-your-plan');
                        } else {

                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        console.log(error.message);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    })
            } catch (error) {
                console.log("catch:", error);
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    });
   
    return (
        <>
            {isLoggedIn &&
                <section className='rec-confirmation-sec'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='form-head-dv'>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='cd-heading'>
                                                <h2>Profile Details</h2>
                                                <p>These details will be reflected when Job seeker views your profile.</p>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="cd-heading">
                                                <h3>Your company is featured in: </h3>
                                                <div className="text-center"><button className="btn btn-sm btn-light border border-primary" data-bs-toggle="modal" data-bs-target="#featuredCompayModal">+Add featured companies</button></div>
                                                <div className='my-2'>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">Logo</th>
                                                                <th scope="col">Company Name</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">1</th>
                                                                <td>Mark</td>
                                                                <td>Otto</td>
                                                                <td>@mdo</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">2</th>
                                                                <td>Jacob</td>
                                                                <td>Thornton</td>
                                                                <td>@fat</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">3</th>
                                                                <td colspan="2">Larry the Bird</td>
                                                                <td>@twitter</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <form method='post' onSubmit={formik.handleSubmit}>
                                            <FocusError formik={formik} />
                                            {/* Banner */}
                                            <div className='col-lg-6 my-2 my-md-2 my-lg-2'>
                                                <label htmlFor="motto"><b>Upload company banner:</b></label>
                                                <div className='cd-form-f form-control'>
                                                    <div className='confirm-icon-content w-100'>
                                                       
                                                        <div className='content w-100'>
                                                            <input
                                                                type="file"
                                                                className="w-100 remove-border"
                                                                name="banner"
                                                                id="banner"
                                                               
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.banner}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.banner && formik.errors.banner ? (
                                                        <div className='text-danger d-block'>{formik.errors.banner}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Motto */}
                                            <div className='col-lg-6 my-2 my-md-2 my-lg-2'>
                                                <label htmlFor="motto"><b>Company Motto:</b></label>
                                                <div className='cd-form-f form-control'>
                                                    <div className='confirm-icon-content w-100'>
                                                       
                                                        <div className='content w-100'>
                                                            <input
                                                                type="text"
                                                                className="w-100 remove-border"
                                                                name="motto"
                                                                id="motto"
                                                                placeholder='e.g.: Start career with us '
                                                                maxLength={100}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.motto}
                                                            />
                                                        </div>
                                                    </div>
                                                  
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.motto && formik.errors.motto ? (
                                                        <div className='text-danger d-block'>{formik.errors.motto}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Sub Motto */}
                                            <div className='col-lg-6 my-2 my-md-2 my-lg-2'>
                                                <label htmlFor="sub_motto"><b>Company Sub Motto:</b></label>
                                                <div className='cd-form-f form-control'>
                                                    <div className='confirm-icon-content w-100'>
                                                      
                                                        <div className='content w-100'>
                                                            <input
                                                                type="text"
                                                                className="w-100 remove-border"
                                                                name="sub_motto"
                                                                id="sub_motto"
                                                                maxLength={300}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.sub_motto}
                                                            />
                                                        </div>
                                                    </div>
                                                 
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.sub_motto && formik.errors.sub_motto ? (
                                                        <div className='text-danger d-block'>{formik.errors.sub_motto}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Why choose us */}
                                            <div className='col-lg-12 my-2 my-md-2 my-lg-2'>
                                                <label htmlFor="why_us"><b>Why choose you:</b></label>
                                                <div className='cd-form-f form-control'>
                                                    <div className='confirm-icon-content w-100'>
                                                        <div className='content w-100'>
                                                            <textarea
                                                                className="w-100 remove-border"
                                                                name="why_us"
                                                                id="why_us"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.why_us}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.why_us && formik.errors.why_us ? (
                                                        <div className='text-danger d-block'>{formik.errors.why_us}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Why choose us */}
                                            <div className='col-lg-12 my-2 my-md-2 my-lg-2'>
                                                <label htmlFor="benefits"><b>Benefits of Your company:</b></label>
                                                <div className='cd-form-f form-control'>
                                                    <div className='confirm-icon-content w-100'>
                                                        <div className='content w-100'>
                                                            <textarea
                                                                className="w-100 remove-border"
                                                                name="benefits"
                                                                id="benefits"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.benefits}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.benefits && formik.errors.benefits ? (
                                                        <div className='text-danger d-block'>{formik.errors.benefits}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Join e mail list a */}
                                            <div className='col-lg-12 my-2 my-md-2 my-lg-2'>
                                                <label htmlFor="give_book"><b>Do you want user to give option to fill email list form and give free industry book:</b></label>
                                                <div className='cd-form-f form-control'>
                                                    <div className='confirm-icon-content w-100'>
                                                        <div className='content w-100'>
                                                            <select name="give_book" id="give_book" className='form-control'
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.give_book}
                                                            >
                                                                <option value={0}>No</option>
                                                                <option value={1}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.give_book && formik.errors.give_book ? (
                                                        <div className='text-danger d-block'>{formik.errors.give_book}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className='col-lg-12 my-2'>
                                                <div className='next-pre-btn'>
                                                    <div className='prev-button'>
                                                        <Link to="/document-verification" className='prev-btn'> Previous</Link>
                                                    </div>
                                                    <div className='next-button'>
                                                        <button type='submit' className='next-btn mt-0'> Save & Next </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal */}
                    <FeaturedCompany />
                    {formik.isSubmitting && <div className="overlay-form-submission">
                        <FallingLines
                            color="#1b375c"
                            width="100"
                            visible={true}
                            ariaLabel='falling-lines-loading'
                        />
                    </div>
                    }
                </section>
            }
        </>
    )
}

export default ProfileDetailsForm